import { ITEM_TYPE_VIDEO } from 'api';
import { SegmentProps, ContentTitle } from 'components';
import { BuilderVideo, useContent, useSaveContext } from 'providers';
import { InputSize } from 'theme';

interface VideoTitleProps extends SegmentProps {
  placeholder?: string;
  label?: string;
  size?: InputSize;
  showDeepLink?: boolean;
  disabled?: boolean;
}

export const VideoTitle = ({
  VideoId,
  Title,
  size = 'small',
  ...props
}: Pick<BuilderVideo, 'VideoId' | 'Title'> & VideoTitleProps) => {
  const { setVideoValue } = useContent();

  const { setVideoValueToSave } = useSaveContext();

  const handleTitleChange = (name: string) => {
    setVideoValue(VideoId, 'Title', name);
    setVideoValueToSave(VideoId, 'Title', name);
  };

  return (
    <ContentTitle
      size={size}
      itemId={VideoId}
      itemType={ITEM_TYPE_VIDEO}
      name={Title}
      onChange={handleTitleChange}
      {...props}
    />
  );
};
