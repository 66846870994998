import { DataSource, SOURCE_KAJABI, SOURCE_WORDPRESS } from 'api';

export const getTypeLabel = (type: string, dataSource: DataSource) => {
  if (type === 'video') {
    if (dataSource === SOURCE_KAJABI) {
      return 'lesson';
    } else if (dataSource === SOURCE_WORDPRESS) {
      return 'post';
    }
    return type;
  }
  return type;
};
