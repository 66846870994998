import { useQuery, UseQueryOptions } from 'react-query';
import { getProducts, GetProductsResponse, useAxiosInstance } from 'api';
import { useAppBeingEdited } from 'app-context';

export const useProducts = (
  staleTime?: UseQueryOptions['staleTime'],
  refetchOnMount?: UseQueryOptions['refetchOnMount'],
) => {
  const client = useAxiosInstance();
  const appId = useAppBeingEdited();
  return useQuery<GetProductsResponse>(['products', appId], () => getProducts(client, appId), {
    staleTime,
    refetchOnMount,
    enabled: !!appId, // Don't call fetcher with empty AppId
  });
};
