import { useMemo, useState } from 'react';
import styled from 'styled-components';
import DOMPurify from 'dompurify';

import { BuilderCollection, useContent, useSaveContext } from 'providers';
import { getCollectionHTMLDescription } from 'utils';
import { CollectionDescription, ColumnModal, CustomButton } from 'components';
import { FONT_14PX_SEMIBOLD, FONT_16PX_REGULAR } from 'font';
import { NEUTRAL_10_COLOUR, NEUTRAL_5_COLOUR, NEUTRAL_7_COLOUR } from 'theme';
import { PenIcon, RevertIcon } from 'icons';

const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 40px;
`;

const Header = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
`;

const Title = styled.div`
  color: ${NEUTRAL_10_COLOUR};
  ${FONT_14PX_SEMIBOLD};
`;

const Buttons = styled.div`
  display: flex;
`;

const EditButton = styled(CustomButton)`
  &&&& {
    margin-left: 4px;
  }
`;

const PreviewContainer = styled.div<{ isPlaceholder: boolean }>`
  color: ${({ isPlaceholder }) => (isPlaceholder ? NEUTRAL_7_COLOUR : 'unset')};
  width: 100%;
  height: 200px;
  padding: 8px 12px;
  ${FONT_16PX_REGULAR};
  border: 1px solid ${NEUTRAL_5_COLOUR};
  border-radius: 6px;
  overflow-y: auto;
`;

const DescriptionWrapper = styled.div`
  .mce-container {
    border-radius: 8px;
  }
`;

export const CollectionDescriptionPreview = ({
  collection,
  revertText,
}: {
  collection: BuilderCollection;
  revertText?: string;
}) => {
  const { setCollectionValue } = useContent();
  const { setCollectionValueToSave } = useSaveContext();

  const [open, setOpen] = useState(false); // Modal is open

  const description = getCollectionHTMLDescription({ ...collection, SourceDescription: '' });
  const processedSourceDesc = collection.SourceDescription
    ? getCollectionHTMLDescription({ ...collection, Description: '' })
    : undefined;

  const purifiedDesc = useMemo(() => {
    return DOMPurify.sanitize(description);
  }, [description]);

  const isPlaceholder = description === '';

  const handleRevert = () => {
    setCollectionValue(collection.TabId, 'Description', collection.SourceDescription);
    setCollectionValueToSave(collection.TabId, 'Description', collection.SourceDescription);
  };

  return (
    <Wrapper id="description-preview-wrapper">
      <Header>
        <Title>Description</Title>
        <Buttons>
          {!!processedSourceDesc && description != processedSourceDesc && (
            <CustomButton medium tertiary icon={<RevertIcon />} onClick={handleRevert}>
              {revertText ?? 'Revert to Source'}
            </CustomButton>
          )}
          <ColumnModal
            title="Description"
            cols={4}
            align="right"
            onConfirm={() => setOpen(false)}
            open={open}
            onOpenChange={setOpen}
            primaryText="Done"
            hideClose
            hideSecondary
            hideDialogActionsBorder
            hasTinyMCE
            triggerButton={
              <EditButton medium tertiaryHighlight icon={<PenIcon />}>
                Edit
              </EditButton>
            }
          >
            <DescriptionWrapper>
              <CollectionDescription
                TabId={collection.TabId}
                Description={collection.Description}
                SourceDescription={collection.SourceDescription}
                height={305}
                maxHeight={470}
                hideTitle
                revertText={revertText}
                bottomRevertButton
              />
            </DescriptionWrapper>
          </ColumnModal>
        </Buttons>
      </Header>
      <PreviewContainer
        isPlaceholder={isPlaceholder}
        dangerouslySetInnerHTML={{ __html: isPlaceholder ? 'No description' : purifiedDesc }}
      />
    </Wrapper>
  );
};
