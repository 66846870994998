import { useMutation, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';

import { createOneSignalNotification, CreateOneSignalNotificationOptions, useAxiosInstance } from 'api';
import { useSecureAppProperties } from 'hooks';

export const useCreateOneSignalNotification = () => {
  const client = useAxiosInstance();
  const { data: secureAppProperties } = useSecureAppProperties();
  const queryClient = useQueryClient();

  return useMutation<unknown, AxiosError, CreateOneSignalNotificationOptions>(
    async (options) => {
      await createOneSignalNotification(
        client,
        options,
        secureAppProperties?.OneSignalAppId,
        secureAppProperties?.OneSignalApiKey,
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['oneSignalNotifications', secureAppProperties?.OneSignalAppId]);
      },
    },
  );
};
