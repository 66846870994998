import { useMemo } from 'react';

import { SOURCE_TYPE_WORKOUT } from 'api';
import { useContent } from 'providers';

import { ContentContainer, TabContent } from './components';

export const Workouts = () => {
  const { collections } = useContent();

  const workouts = useMemo(
    () =>
      collections
        ? Object.values(collections)
            .filter(({ SourceId, SourceType }) => SourceType === SOURCE_TYPE_WORKOUT && !SourceId.includes('TempTabId'))
            .sort((a, b) => a.Name.localeCompare(b.Name))
        : undefined,
    [collections],
  );

  return (
    <ContentContainer>
      <TabContent
        searchPlaceholder={`Search workouts (${workouts ? workouts.length : 0})...`}
        sections={[{ key: 'Workouts', type: 'collections', collections: workouts }]}
      />
    </ContentContainer>
  );
};
