import { AxiosInstance } from 'axios';
import { DataSource } from 'api';

export interface StringAppBasicInfo {
  [key: string]: string;
}

export interface SpecialAppBasicInfo {
  DataSource: DataSource;
  Id: number;
  PaymentStatus: number;
  RatingMatureThemes: 1 | 0;
  RatingRealisticViolence: 1 | 0;
  RatingYouTubeLink: 1 | 0;
  SameDescription: 1 | 0;
  Type: 1 | 2 | 3;
  UploadLimit: number | null;
  UsedGB: number | null;
}

export type AppBasicInfo = StringAppBasicInfo & SpecialAppBasicInfo;

export type AppBasicInfoKey = keyof AppBasicInfo;

export type AppBasicInfoToSave = Record<string, string | number>;

export const getAppBasicInfo = (client: AxiosInstance, appId: string) => {
  return client.get<AppBasicInfo>(`apps/${appId}`).then((response) => response.data);
};

export interface AvailableApp {
  AppleTitle: string;
  AppId: string;
  PaymentStatus: number;
}

export const getAvailableApps = (client: AxiosInstance) => {
  return client.get<AppBasicInfo[]>(`apps`).then((response) =>
    response.data.map((app) => ({
      AppleTitle: app.AppleTitle,
      AppId: app.AppId,
      PaymentStatus: app.PaymentStatus,
    })),
  );
};

export const saveAppBasicInfo = async (
  client: AxiosInstance,
  appId: string,
  appBasicInfoToSave: AppBasicInfoToSave,
) => {
  return client.put(`/apps/${appId}`, appBasicInfoToSave);
};

export interface PaymentStatus {
  Id: number;
  Name: string;
}

export const getPaymentStatuses = (client: AxiosInstance) => {
  return client.get<PaymentStatus[]>(`misc/payment-statuses`).then((response) => response.data);
};
