import { useMemo, useState } from 'react';
import styled from 'styled-components';
import { FixedSizeList } from 'react-window';

import { HIGHLIGHT_PRIMARY_COLOUR, NEUTRAL_10_COLOUR } from 'theme';
import { FONT_14PX_SEMIBOLD } from 'font';
import { BuilderVideo, useDataSource } from 'providers';
import { checkVideoType } from 'utils';
import { ModalSearchBox } from 'components';
import { CheckIcon } from 'icons';

import { ContentCard } from 'app/modules/build-dragdrop/Builder/components';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 25px 0 17px 0;
`;

const Heading = styled.div`
  color: ${NEUTRAL_10_COLOUR};
  ${FONT_14PX_SEMIBOLD};
  margin-bottom: 13px;
`;
const Results = styled.div`
  padding-top: 16px;
`;

const VideoWrapper = styled.div`
  padding: 0 20px;
  height: 74px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

const BlueCheck = styled.div`
  color: ${HIGHLIGHT_PRIMARY_COLOUR};
  font-size: 16px;
`;

const RowSpacing = styled.div`
  padding: 0 20px;
`;

interface VideoOptionsProps {
  availableVideos: BuilderVideo[];
  includedVideos: (BuilderVideo['VideoId'] | string)[]; // Videos in the current collection
  onVideoClick: (videoId: BuilderVideo['VideoId']) => void;
}

export const ModalVideoOptions = ({ availableVideos, onVideoClick, includedVideos }: VideoOptionsProps) => {
  const datasource = useDataSource();
  const [query, setQuery] = useState('');

  const videoOptions = useMemo(() => {
    return Object.values(availableVideos).filter((video) => {
      const lowerQuery = query?.toLowerCase();

      if (
        video.SourceTitle?.toLowerCase()?.includes(lowerQuery) ||
        video.Title?.toLowerCase()?.includes(lowerQuery) ||
        video.SourceId?.toLowerCase().includes(lowerQuery)
      ) {
        if (checkVideoType(video, { audio: true, text: true })) {
          return true;
        }
      }
      return false;
    });
  }, [availableVideos, datasource, query]);

  return (
    <Container>
      <RowSpacing>
        <Heading>All</Heading>
        <ModalSearchBox onChange={setQuery} />
      </RowSpacing>
      <Results>
        <FixedSizeList height={397} width={377} itemSize={74} itemCount={videoOptions.length}>
          {({ index, style }) => {
            const video = videoOptions[index];
            return (
              <VideoWrapper key={video.VideoId} style={style} onClick={() => onVideoClick(video.VideoId)}>
                <ContentCard content={video} type="video" />
                {includedVideos.includes(video.VideoId) && (
                  <BlueCheck>
                    <CheckIcon />
                  </BlueCheck>
                )}
              </VideoWrapper>
            );
          }}
        </FixedSizeList>
      </Results>
    </Container>
  );
};
