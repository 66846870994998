import styled from 'styled-components';

import {
  ITEM_TYPE_COLLECTION,
  ITEM_TYPE_SECTIONHEADER,
  ITEM_TYPE_VIDEO,
  SOURCE_TYPE_CIRCUIT,
  SOURCE_TYPE_COLLECTION_2,
  SOURCE_TYPE_COURSE,
  SOURCE_TYPE_PLAYLIST,
  SOURCE_TYPE_TEXT,
  SOURCE_TYPE_WEBSITE,
  SOURCE_TYPE_WORKOUT,
  VIDEO_TYPE_POST,
  VIDEO_TYPE_REST,
} from 'api';
import {
  AppVideoIcon,
  CollectionIcon,
  CubeIcon,
  DumbbellIcon,
  FileBlankIcon,
  FileLinkedIcon,
  FolderIcon,
  GlobeIcon,
  LinkedCollectionIcon,
  LinkedCubeIcon,
  LinkedFolderIcon,
  PostIcon,
  SectionHeaderIcon,
  StopwatchIcon,
  TextImageIcon,
  UISyncIcon,
} from 'icons';
import { getCollectionSourceTypeEquivalent } from 'utils';

const StyledVideoIcon = styled(AppVideoIcon)`
  svg {
    fill: none;
  }
`;
const StyledCollectionIcon = styled(CollectionIcon)`
  svg {
    fill: none;
  }
`;

interface ContentIconProps {
  itemType: string;
  contentType: string;
  dataSource: string;
  isFile?: boolean;
  linked?: boolean;
}
export const ContentIcon = ({ itemType, contentType, dataSource, isFile, linked }: ContentIconProps) => {
  if (isFile) {
    return linked ? <FileLinkedIcon /> : <FileBlankIcon />;
  }

  if (itemType.toLowerCase() === ITEM_TYPE_SECTIONHEADER.toLowerCase()) {
    return <SectionHeaderIcon />;
  }

  if (itemType === ITEM_TYPE_VIDEO) {
    return contentType === VIDEO_TYPE_POST ? (
      <PostIcon />
    ) : contentType === VIDEO_TYPE_REST ? (
      <StopwatchIcon />
    ) : (
      <StyledVideoIcon />
    );
  }
  if (contentType === SOURCE_TYPE_WEBSITE) {
    return <GlobeIcon />;
  }
  if (contentType === SOURCE_TYPE_TEXT) {
    return <TextImageIcon />;
  }

  if (itemType === ITEM_TYPE_COLLECTION) {
    const equivalentType = getCollectionSourceTypeEquivalent(dataSource, contentType);
    if (equivalentType === SOURCE_TYPE_COURSE) {
      return linked ? <LinkedCubeIcon /> : <CubeIcon />;
    } else if (equivalentType === SOURCE_TYPE_COLLECTION_2) {
      return linked ? <LinkedCollectionIcon /> : <StyledCollectionIcon />;
    } else if (equivalentType === SOURCE_TYPE_PLAYLIST) {
      return linked ? <LinkedFolderIcon /> : <FolderIcon />;
    } else if (equivalentType === SOURCE_TYPE_WORKOUT) {
      return <DumbbellIcon />;
    } else if (equivalentType === SOURCE_TYPE_CIRCUIT) {
      return <UISyncIcon />;
    }
  }

  return <StyledCollectionIcon />;
};
