import { useState } from 'react';
import styled from 'styled-components';

import { CustomButton } from 'components';
import { TrashIcon } from 'icons';
import { FONT_12PX_MEDIUM, FONT_14PX_MEDIUM } from 'font';
import { Collection, SOURCE_TYPE_BUILDMYAPP, SOURCE_VIDAPP, TAB_LEVEL_PROPERTIES } from 'api';
import { useSaveContext } from 'providers';

import { updateCollectionPositions } from 'app/modules/build-dragdrop/Builder/util';
import { BuilderCollectionItem, useBuilderContext, useContent } from 'app/modules/build-dragdrop/Builder/providers';
import { useMockupContext } from 'app/modules/build-dragdrop/Builder/mockup';
import { useHistory } from 'react-router-dom';

const Row = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const ConfirmText = styled.div`
  ${FONT_12PX_MEDIUM};
  width: 100%;
  margin-bottom: 8px;
`;

const ButtonText = styled.span`
  ${FONT_14PX_MEDIUM};
`;

const CancelButton = styled(CustomButton)`
  margin-left: 12px !important;
`;

const DeleteButton = styled(CustomButton)`
  margin-top: 11px !important;
`;

export const DrawerDeleteButton = ({
  item,
  isDeleteSubTab,
}: {
  item: BuilderCollectionItem;
  isDeleteSubTab?: boolean;
}) => {
  const { setCollections, collections, deleteCollection, setCollectionValue, setCollectionProperty } = useContent();
  const { setCollectionItemsToSave, setCollectionToDelete, setCollectionValueToSave, setCollectionPropertyToSave } =
    useSaveContext();
  const { visiblePageId, setDrawerContext } = useBuilderContext();
  const { currentTabId, setCurrentPage, setCurrentTabId } = useMockupContext();
  const [confirmed, setConfirmed] = useState(false);
  const history = useHistory();

  const removeSubNav = (lastItem: BuilderCollectionItem) => {
    const currentTab = currentTabId ? collections[currentTabId] : undefined;
    const lastSubTabId = lastItem.ChildId;

    if (!currentTab || !currentTabId) {
      console.error('DrawerDeleteButton', 'Unable to find the subnav to be removed');
      return;
    }

    // Update the last subtab to be a main tab
    // Position and IsMainTab are expected to have values on the parent tab
    const tabValues = [
      { name: 'Position', value: currentTab.Position ?? 0 },
      { name: 'IsMainTab', value: currentTab.IsMainTab ?? 0 },
      { name: 'Icon', value: currentTab.Icon ?? '' },
    ] as { name: keyof Collection; value: string | number }[];
    for (const { name, value } of tabValues) {
      setCollectionValue(lastSubTabId, name, value);
      setCollectionValueToSave(lastSubTabId, name, value);
    }

    // Copy tab specific properties back to the subtab
    for (const [key, value] of Object.entries(currentTab.Properties || [])) {
      if (TAB_LEVEL_PROPERTIES.includes(key)) {
        setCollectionProperty(lastSubTabId, key, value);
        setCollectionPropertyToSave(lastSubTabId, key, value);
      }
    }

    // Begin removal of the subnav
    deleteCollection(currentTabId);
    setCollectionToDelete(currentTabId);

    setCurrentTabId(lastSubTabId); // Last subtab tab is now the current tab in the simulator
    history.replace(`/builder/${lastSubTabId}`, { skipUnsavedChangesPrompt: true });
  };

  const handleDelete = () => {
    // For sections we delete from the visible page, for subtabs we delete from the visible tab
    const parentCollectionId = isDeleteSubTab ? currentTabId : visiblePageId;
    if (parentCollectionId) {
      const newCollections = { ...collections };
      const newCollection = newCollections[parentCollectionId];

      // Delete the Section (CollectionItem)
      const idx = newCollection.Items.findIndex((i) => i.TabItemId === item.TabItemId);
      if (idx > -1) {
        newCollection.Items.splice(idx, 1);
        updateCollectionPositions(newCollection);
        setCollections(newCollections);
        setCollectionItemsToSave(parentCollectionId);
      } else {
        console.error('Unable to delete item with details', { tabId: visiblePageId, tabItemId: item.TabItemId });
      }

      if (item.ChildId) {
        const childCollection = collections[item.ChildId];
        // Delete the collection as well if it was a BuildMyApp collection
        if (childCollection.DataSource === SOURCE_VIDAPP && childCollection.SourceType === SOURCE_TYPE_BUILDMYAPP) {
          setCollectionToDelete(item.ChildId);
          deleteCollection(item.ChildId);
        }
      }
      if (isDeleteSubTab) {
        // If there is only one subtab left, remove the subnav
        if (newCollection.Items.length === 1) {
          removeSubNav(newCollection.Items[0]);
        }
        // The currently selected SubTab will nolonger be available
        setCurrentPage(undefined);
      }
      setDrawerContext(undefined);
    }
  };

  return (
    <>
      {confirmed ? (
        <Row>
          <ConfirmText>Are you sure?</ConfirmText>
          <CustomButton medium danger onClick={handleDelete}>
            <ButtonText>Confirm</ButtonText>
          </CustomButton>
          <CancelButton medium secondary onClick={() => setConfirmed(false)}>
            <ButtonText>Cancel</ButtonText>
          </CancelButton>
        </Row>
      ) : (
        <DeleteButton medium secondary danger icon={<TrashIcon />} onClick={() => setConfirmed(true)}>
          Delete
        </DeleteButton>
      )}
    </>
  );
};
