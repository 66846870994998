import { Dispatch, SetStateAction, useCallback } from 'react';
import styled from 'styled-components';

import { BuilderCollection, useContent, useSaveContext } from 'providers';
import { Segment, SettingsSelectInput } from 'components';
import { SOURCE_TYPE_CHALLENGE, SOURCE_TYPE_COURSE, SOURCE_TYPE_OTHER, SOURCE_TYPE_PROGRAM } from 'api';
import { FONT_10PX_REGULAR } from 'font';
import { DANGER_COLOUR } from 'theme';

const SeriesTypes = [SOURCE_TYPE_CHALLENGE, SOURCE_TYPE_COURSE, SOURCE_TYPE_PROGRAM, SOURCE_TYPE_OTHER];
const SelectOptions = SeriesTypes.map((t) => ({ name: t, value: t }));

const StyledSegment = styled(Segment)`
  position: relative;
  margin-top: 0px;
  margin-bottom: 28px;
`;

const Error = styled.div`
  ${FONT_10PX_REGULAR};
  color: ${DANGER_COLOUR};
  position: absolute;
  bottom: -20px;
  left: 1px;
`;

interface CollectionSourceTypeSelectProps {
  collection: BuilderCollection;
  errorVisible: boolean;
  setErrorVisible: Dispatch<SetStateAction<boolean>>;
}

export const CollectionSourceTypeSelect = ({
  collection,
  errorVisible,
  setErrorVisible,
}: CollectionSourceTypeSelectProps) => {
  const { setCollectionValue } = useContent();
  const { setCollectionValueToSave } = useSaveContext();

  const handleChange = useCallback(
    (value: string) => {
      if (collection) {
        setErrorVisible(false);
        setCollectionValue(collection.TabId, 'SourceType', value);
        setCollectionValueToSave(collection.TabId, 'SourceType', value);
      }
    },
    [setCollectionValue, setCollectionValueToSave, setErrorVisible],
  );

  return (
    <StyledSegment header="Type">
      <SettingsSelectInput
        options={
          collection.SourceType === 'unselected'
            ? [{ name: ' ', value: 'unselected' }, ...SelectOptions]
            : SelectOptions
        }
        value={collection.SourceType}
        onChange={handleChange}
      />
      {errorVisible && <Error>Please select a series type</Error>}
    </StyledSegment>
  );
};
