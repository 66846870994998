import { useCallback } from 'react';
import styled from 'styled-components';
import { Select, Typography } from 'antd';

import { HIGHLIGHT_PRIMARY_COLOUR, NEUTRAL_5_COLOUR, NEUTRAL_7_COLOUR } from 'theme';
import { DataSource } from 'api';
import { CustomButton } from 'components';

import { VideoCollection, CollectionCollection } from '../CalendarPage';
import { getTypeLabel } from '../utils';

const Heading = styled(Typography.Text)`
  #react-app && {
    display: block;
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    text-align: center;
  }
`;

const SelectContainer = styled.div`
  #react-app && {
    margin-bottom: 32px;
  }
`;

const SelectWrapper = styled.div`
  #react-app && {
    position: relative;
    margin-right: 53px;

    .ant-select-selector {
      border: 1px solid ${NEUTRAL_5_COLOUR};
      border-radius: 2px 0 0 2px;
      transition: none;

      :hover {
        border-color: ${HIGHLIGHT_PRIMARY_COLOUR};
      }
    }

    .ant-select-focused:not(.ant-select.disabled) .ant-select-selector {
      box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    }

    .ant-select-selection-search-input {
      border: none;
    }
  }
`;

const OptionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 12px;
`;

const OptionTitle = styled.div`
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 3px;
  flex: 1 1 auto;
`;

const OptionId = styled.div`
  display: inline-block;
  text-align: right;
  color: ${NEUTRAL_7_COLOUR};
  flex: 1 0 auto;
  min-width: 70px;
`;

const AddButton = styled(CustomButton)`
  #react-app && {
    position: absolute;
    top: 0;
    right: -53px;
    width: 53px;
    height: 100%;
    border-radius: 0 2px 2px 0;
    border-left: none;
    color: ${HIGHLIGHT_PRIMARY_COLOUR};
    font-weight: 400;

    :hover {
      -webkit-box-shadow: inset 1px 0 0 0 ${HIGHLIGHT_PRIMARY_COLOUR};
      box-shadow: inset 1px 0 0 0 ${HIGHLIGHT_PRIMARY_COLOUR};
    }

    :not([disabled]):active {
      -webkit-box-shadow: inset 1px 0 0 0 ${HIGHLIGHT_PRIMARY_COLOUR};
      box-shadow: inset 1px 0 0 0 ${HIGHLIGHT_PRIMARY_COLOUR};
      border-color: ${HIGHLIGHT_PRIMARY_COLOUR};
    }
  }
`;

interface AddItemsProps {
  videoCollections: VideoCollection[];
  collectionCollections: CollectionCollection[];
  selectedItems: Record<string, number[]>;
  setSelectedItems: React.Dispatch<React.SetStateAction<Record<string, number[]>>>;
  handleAddItem: (dataSource: string, type: 'video' | 'workout') => void;
}

export const AddItems = ({
  videoCollections,
  collectionCollections,
  selectedItems,
  setSelectedItems,
  handleAddItem,
}: AddItemsProps) => {
  const handleItemSelect = useCallback(
    (value: number[], dataSource: string) => {
      const updatedSelectedItems = { ...selectedItems, [dataSource]: value };
      setSelectedItems(updatedSelectedItems);
    },
    [selectedItems, setSelectedItems],
  );

  return (
    <>
      {collectionCollections.map((collection) => {
        const { dataSource } = collection;
        return (
          <SelectContainer key={`workout-select-container`}>
            <Heading data-testid="additems-select-heading">Add Circuit Workouts</Heading>
            <SelectWrapper>
              <Select
                key={`workout-select`}
                mode="multiple"
                allowClear={false}
                style={{ width: '100%' }}
                placeholder={`Add Circuit Workouts`}
                value={selectedItems[`${dataSource}Collections`]}
                filterOption={(input, option) => option?.title.toLowerCase().includes(input.toLowerCase())}
                onChange={(value) => handleItemSelect(value, `${dataSource}Collections`)}
                onKeyDown={(e) => e.stopPropagation()}
                getPopupContainer={(node) => node.parentNode.parentNode.parentNode}
              >
                {collection.options.map((video) => {
                  const { Name, TabId, SourceId } = video;
                  return (
                    <Select.Option key={TabId} title={`${Name} ${SourceId}`} value={TabId}>
                      <OptionWrapper>
                        <OptionTitle>{Name}</OptionTitle>
                        <OptionId>{SourceId}</OptionId>
                      </OptionWrapper>
                    </Select.Option>
                  );
                })}
              </Select>
              <AddButton medium secondary onClick={() => handleAddItem(`${dataSource}Collections`, 'workout')}>
                Add
              </AddButton>
            </SelectWrapper>
          </SelectContainer>
        );
      })}
      {videoCollections.map((collection) => {
        const { dataSource } = collection;
        return (
          <SelectContainer key={`${dataSource}-select-container`}>
            <Heading data-testid="additems-select-heading">
              Add {dataSource} {`${getTypeLabel('video', dataSource as DataSource)}s`}
            </Heading>
            <SelectWrapper>
              <Select
                key={`${dataSource}-select`}
                mode="multiple"
                allowClear={false}
                style={{ width: '100%' }}
                placeholder={`Add ${dataSource} videos`}
                value={selectedItems[dataSource]}
                filterOption={(input, option) => option?.title.toLowerCase().includes(input.toLowerCase())}
                onChange={(value) => handleItemSelect(value, dataSource)}
                onKeyDown={(e) => e.stopPropagation()}
                getPopupContainer={(node) => node.parentNode.parentNode.parentNode}
              >
                {collection.options.map((video) => {
                  const { Title, VideoId, SourceId } = video;
                  return (
                    <Select.Option key={VideoId} title={`${Title} ${SourceId}`} value={VideoId}>
                      <OptionWrapper>
                        <OptionTitle>{Title}</OptionTitle>
                        <OptionId>{SourceId}</OptionId>
                      </OptionWrapper>
                    </Select.Option>
                  );
                })}
              </Select>
              <AddButton medium secondary onClick={() => handleAddItem(dataSource, 'video')}>
                Add
              </AddButton>
            </SelectWrapper>
          </SelectContainer>
        );
      })}
    </>
  );
};
