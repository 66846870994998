import { Draggable, Droppable } from 'react-beautiful-dnd';
import styled from 'styled-components';

import { ITEM_TYPE_COLLECTION, MIXED_TEMPLATE, SOURCE_VIDAPP, SUBNAV_TEMPLATE } from 'api';
import { FONT_14PX_REGULAR, FONT_16PX_MEDIUM } from 'font';
import { BuilderCollection, useContent, useDataSource } from 'providers';
import { CUSTOM_SCROLLBAR, NEUTRAL_10_COLOUR, NEUTRAL_8_COLOUR } from 'theme';

import { useMockupContext } from 'app/modules/build-dragdrop';
import { getPageName, isNetflixTemplate } from 'app/modules/build-screens/utils';
import { ToolboxMainTabSelector } from '../ToolboxMainTabSelector';
import { TreeAddSubTab } from './TreeAddSubTab';
import { TreeCollection } from './TreeCollection';
import { CONTENT_TEMPLATES } from '../../../mockup/const';

const Container = styled.div`
  padding: 24px 8px 0 8px;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
`;
const ScrollContainer = styled.div`
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  flex-grow: 1;
  padding-right: 4px;
  padding-bottom: 30px;
  ${CUSTOM_SCROLLBAR};
`;

const StyledSubTabWrapper = styled.div`
  margin-top: 12px;
`;

const NonCustomizable = styled.div`
  width: 212px;
  height: 100%;
  margin: 0 auto 58px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const Title = styled.div`
  color: ${NEUTRAL_10_COLOUR};
  ${FONT_16PX_MEDIUM};
  margin-bottom: 4px;
`;

const Description = styled.div`
  color: ${NEUTRAL_8_COLOUR};
  ${FONT_14PX_REGULAR};
`;

export const TreePage = ({ drawerWidth }: { drawerWidth: number }) => {
  const { collections, getContentForItem } = useContent();
  const { currentTabId } = useMockupContext();
  const dataSource = useDataSource();

  const currentTab = currentTabId ? collections[currentTabId] : undefined;
  if (!currentTab) {
    console.error('Rendering TreePage of missing tab', { currentTabId });
    return null;
  }

  const collectionItems = currentTab?.Items.filter((i) => i.Type === ITEM_TYPE_COLLECTION);
  const isSubNav = currentTab.TemplateId === SUBNAV_TEMPLATE;
  const isCustomizable =
    currentTab.DataSource === SOURCE_VIDAPP &&
    !isNetflixTemplate(currentTab, dataSource) &&
    [...CONTENT_TEMPLATES, SUBNAV_TEMPLATE, MIXED_TEMPLATE].includes(currentTab.TemplateId);
  const tabType = getPageName(currentTab, dataSource);

  return (
    <Container>
      <ToolboxMainTabSelector currentTab={currentTab} drawerWidth={drawerWidth} />
      {isCustomizable ? (
        <ScrollContainer>
          {isSubNav ? (
            <Droppable droppableId={`${currentTabId?.toString()}|TreePage`} type="tree">
              {(provided) => {
                return (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {collectionItems.map((item, idx) => {
                      const content = getContentForItem(item) as BuilderCollection;
                      // All items in a subnav should be collections
                      // Ignore any other items if found
                      const draggableId = `${item.ChildId}|${item.TabItemId}|treesubtab`;
                      return (
                        <Draggable draggableId={draggableId} index={idx} key={draggableId}>
                          {(provided, snapshot) => {
                            return (
                              <StyledSubTabWrapper ref={provided.innerRef} {...provided.draggableProps}>
                                <TreeCollection
                                  type="subtab"
                                  collection={content as BuilderCollection}
                                  item={item}
                                  defaultActive={idx === 0}
                                  key={`TreeSubTab__${item.TabItemId}`}
                                  linked={content.DataSource !== SOURCE_VIDAPP}
                                  isDragging={snapshot.isDragging}
                                  dragHandleProps={provided.dragHandleProps}
                                />
                              </StyledSubTabWrapper>
                            );
                          }}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </div>
                );
              }}
            </Droppable>
          ) : (
            <StyledSubTabWrapper>
              <TreeCollection
                type="subtab"
                key={`TreeSubTab__${currentTab.TabId}--single`}
                collection={currentTab}
                defaultActive
                linked={currentTab.DataSource !== SOURCE_VIDAPP}
                singleSubTab
              />
            </StyledSubTabWrapper>
          )}
          <TreeAddSubTab />
        </ScrollContainer>
      ) : (
        <NonCustomizable>
          <Title>{`${tabType}`} Tab</Title>
          <Description>{`${tabType}`} tabs do not have customizable sections.</Description>
        </NonCustomizable>
      )}
    </Container>
  );
};
