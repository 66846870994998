import { AxiosInstance } from 'axios';
import { getUserEmail } from 'utils';

export interface Action {
  ID: string;
  Time: string;
  Email: string;
  Action: string;
  Details: Record<string, unknown>;
}

const transformDate = (dt: string) => {
  // We append Z because the dates received are in server time which is UTC
  return new Date(`${dt}Z`).toLocaleString(undefined, { hour12: true });
};

export const getActionLog = (client: AxiosInstance, appId: string) => {
  return client.get<Action[]>(`misc/${appId}/actions`).then((response) => {
    const { data } = response;
    return data.map((action) => {
      return {
        ...action,
        Time: transformDate(action.Time),
      };
    });
  });
};

export const recordAction = (
  client: AxiosInstance,
  appId: string,
  action: string,
  details: Record<string, unknown> = {},
) => {
  // details can only be empty or JSON format with any key-value pairs inside
  return client
    .post('misc/record-action', { AppID: appId, Email: getUserEmail(), Action: action, Details: details })
    .then(() => console.log(action + ' action recorded'));
};
