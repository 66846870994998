import { createContext, Dispatch, SetStateAction, useCallback, useContext, useEffect, useState } from 'react';

import { BuilderCollection, useContent, useLocalAppProperties, useSaveContext } from 'providers';
import { COMMUNITY_TEMPLATE } from 'api';
import { DropResult } from 'react-beautiful-dnd';
import { useAppBasicInfo } from 'hooks';

interface ContextProps {
  activeTabs: BuilderCollection[] | undefined;
  inactiveTabs: BuilderCollection[] | undefined;
  hiddenTabs: BuilderCollection[] | undefined;
  profileTab: BuilderCollection | undefined;
  onDragEnd: (result: DropResult) => void;
  saveTimestamp: () => void;
  setActiveTabs: Dispatch<SetStateAction<BuilderCollection[] | undefined>>;
  setInactiveTabs: Dispatch<SetStateAction<BuilderCollection[] | undefined>>;
}

interface ProviderProps {
  children: React.ReactNode;
}

const defaultFunction = () => {
  console.warn('Unexpected function call build-nav-provider');
};

const BuildNavContext = createContext<ContextProps>({
  activeTabs: undefined,
  inactiveTabs: undefined,
  hiddenTabs: undefined,
  profileTab: undefined,
  onDragEnd: defaultFunction,
  saveTimestamp: defaultFunction,
  setActiveTabs: defaultFunction,
  setInactiveTabs: defaultFunction,
});

const BuildNavProvider = ({ children }: ProviderProps) => {
  const { collections, setCollectionValue } = useContent();
  const { setCollectionValueToSave } = useSaveContext();
  const { properties } = useLocalAppProperties();
  const { isMigratedLegacy } = useAppBasicInfo();

  const [activeTabs, setActiveTabs] = useState<BuilderCollection[]>();
  const [inactiveTabs, setInactiveTabs] = useState<BuilderCollection[]>();
  const [hiddenTabs, setHiddenTabs] = useState<BuilderCollection[]>();
  const [profileTab, setProfileTab] = useState<BuilderCollection>();
  const [timestamp, setTimestamp] = useState(0);

  const tabIsVisible = useCallback(
    (collection: BuilderCollection) => {
      if (collection.TemplateId === 33) {
        return properties.DisplayMyLibrary === '1';
      } else if (collection.TemplateId === COMMUNITY_TEMPLATE) {
        return properties.RolloutCommunity === '1';
      }
      return true;
    },
    [properties],
  );

  const initiateTabs = useCallback(() => {
    const active: BuilderCollection[] = [];
    const inactive: BuilderCollection[] = [];
    const hidden: BuilderCollection[] = [];

    Object.values(collections)
      .filter((collection) => collection.Position && collection.Position !== 0)
      .forEach((collection) => {
        if (properties.OptionalProfileTab !== '1' && collection.TemplateId === 31) {
          setProfileTab(collection);
        } else if (tabIsVisible(collection)) {
          if (collection.IsMainTab === 1) {
            active.push(collection);
          } else {
            inactive.push(collection);
          }
        } else {
          hidden.push(collection);
        }
      });

    setActiveTabs(active.sort((a, b) => (a.Position as number) - (b.Position as number)));
    setInactiveTabs(inactive.sort((a, b) => (a.Position as number) - (b.Position as number)));
    setHiddenTabs(hidden);
  }, [collections, properties, setActiveTabs, setInactiveTabs, setHiddenTabs, setProfileTab, tabIsVisible]);

  useEffect(() => {
    if (!activeTabs && collections && properties) {
      initiateTabs();
    }
  }, [activeTabs, collections, properties]);

  useEffect(() => initiateTabs(), [timestamp]);

  const handleUpdatePosition = useCallback(
    (tabId: number | string, position: number, isMainTab: 1 | 0) => {
      setCollectionValue(tabId, 'Position', position);
      setCollectionValue(tabId, 'IsMainTab', isMainTab);
      setCollectionValueToSave(tabId, 'Position', position);
      setCollectionValueToSave(tabId, 'IsMainTab', isMainTab);
    },
    [setCollectionValueToSave, setCollectionValue],
  );

  const onDragEnd = useCallback(
    (result: DropResult) => {
      if (activeTabs && inactiveTabs) {
        const { destination, source, draggableId } = result;
        if (!destination) {
          return; // Drag was cancelled - Do nothing
        }

        const updatedActiveTabs = [...activeTabs];
        const updatedInActiveTabs = [...inactiveTabs];

        const originIsActive = source.droppableId === 'activeDroppable';
        const destinationIsActive = destination.droppableId === 'activeDroppable';

        // Find the dragged tab in the source array
        const draggedTab = originIsActive
          ? updatedActiveTabs.find((tab: BuilderCollection) => tab.TabId.toString() === draggableId)
          : updatedInActiveTabs.find((tab: BuilderCollection) => tab.TabId.toString() === draggableId);

        // Remove the dragged tab from the source array
        originIsActive ? updatedActiveTabs.splice(source.index, 1) : updatedInActiveTabs.splice(source.index, 1);

        // Insert the dragged tab into the destination array
        if (draggedTab) {
          destinationIsActive
            ? updatedActiveTabs.splice(destination.index, 0, draggedTab)
            : updatedInActiveTabs.splice(destination.index, 0, draggedTab);
        }

        if (!isMigratedLegacy) {
          // activeTabs can have a max of 5 items, so move any overflow to start of inactiveTabs
          updatedInActiveTabs.unshift(...updatedActiveTabs.splice(properties.OptionalProfileTab === '1' ? 5 : 4)); // If no OptionalProfileTab app property, use 4 as Profile is locked to 5th position and handled outside of activeTabs
        }

        setActiveTabs(updatedActiveTabs);
        setInactiveTabs(updatedInActiveTabs);

        const activeTabsToSave = [...updatedActiveTabs];
        if (profileTab) {
          activeTabsToSave?.push(profileTab);
        }

        activeTabsToSave?.forEach(({ TabId }, idx) => {
          handleUpdatePosition(TabId, idx + 1, 1);
        });

        const inactiveCombined = updatedInActiveTabs.concat(hiddenTabs ?? []); // Save hidden tabs as part of inactive, so they aren't displayed in-app

        inactiveCombined?.forEach(({ TabId }, idx) => {
          handleUpdatePosition(TabId, idx + 1, 0);
        });
      }
    },
    [activeTabs, inactiveTabs, setActiveTabs, setInactiveTabs, properties, isMigratedLegacy, handleUpdatePosition],
  );

  return (
    <BuildNavContext.Provider
      value={{
        activeTabs,
        inactiveTabs,
        setActiveTabs,
        setInactiveTabs,
        hiddenTabs,
        profileTab,
        onDragEnd,
        saveTimestamp: () => setTimestamp(Date.now()),
      }}
    >
      {children}
    </BuildNavContext.Provider>
  );
};

const useBuildNav = () => {
  const context = useContext(BuildNavContext);
  if (context === undefined) {
    throw new Error('useBuildNavContext must be used within a BuildNavProvider');
  }
  return context;
};

export { BuildNavProvider, useBuildNav };
