import { Form } from 'antd';

import {
  PageContainer,
  SettingsGroup,
  SettingsFormItem,
  SettingsTextInput,
  SettingsTextArea,
  SettingsSelectInput,
  SettingsImageInput,
  CustomButton,
  SettingsSegmentDivider,
} from 'components';
import { AppBasicInfoKey } from 'api';
import { SETTINGS_ITEM_MARGIN_BOTTOM } from 'theme';

import { useAppStore, PropertyType } from '../../hooks/useAppStore';
import { ScreenshotInput, ScreensContainer, AddScreenshotButton } from '../../components';

// Map of all properties being edited on this screen
const PROPERTIES: Record<string, PropertyType> = {
  AndroidTitle: { type: 'appBasicInfo' },
  AndroidShortDescription: { type: 'appBasicInfo' },
  AndroidFullDescription: { type: 'appBasicInfo' },

  AndroidCategory: { type: 'appProperty' },
  AndroidFeatureGraphic: { type: 'appProperty', isFile: true },
  AndroidTVBanner: { type: 'appProperty', isFile: true },
};

interface Field {
  name: string;
  label: string;
  type: 'text' | 'textArea' | 'select';
  propertyType: 'appBasicInfo' | 'appProperty';
  maxLength?: number;
  tooltip?: string;
  width?: '100%';
}

const FIELDS: Field[] = [
  {
    name: 'AndroidTitle',
    label: 'Android Title',
    type: 'text',
    propertyType: 'appBasicInfo',
    maxLength: 30,
    tooltip: 'Displayed in the Android app store.',
  },
  {
    name: 'AndroidShortDescription',
    label: 'Android Short Description',
    type: 'text',
    propertyType: 'appBasicInfo',
    maxLength: 80,
    tooltip: 'A short description displayed in the Google Play app store.',
  },
  {
    name: 'AndroidFullDescription',
    label: 'Android Full Description',
    type: 'textArea',
    propertyType: 'appBasicInfo',
    maxLength: 4000,
    tooltip: 'A full-length description displayed in the Google Play app store.',
    width: '100%',
  },
  {
    name: 'AndroidCategory',
    label: 'Category',
    propertyType: 'appProperty',
    type: 'select',
    tooltip: 'The main category that your app most aligns with.',
  },
];

export const AppStoreAndroidSettings = () => {
  const {
    appBasicInfo,
    appProperties,
    form,
    unsavedChanges,
    isSaving,
    isLoading,
    isError,
    appleCategories,
    filenames,
    screenshots,
    addScreenshot,
    removeScreenshot,
    onValuesChange,
    onFilenameChange,
    onScreenshotChange,
    saveForm,
  } = useAppStore(PROPERTIES, 'Android');

  const displayAndroidTV = appProperties?.DisplayAndroidTV === '1';

  return (
    <PageContainer
      heading="Android App Store Details"
      subheading={
        <>
          Update information presented in the Android app store. For more information on each element please visit our{' '}
          <a href="https://vidapp.com/android-app-store-product-page-vidapp/" target="_blank" rel="noopener noreferrer">
            Android App Store Product page here.
          </a>
        </>
      }
      headingButton={
        <CustomButton medium onClick={() => form?.submit()} loading={isSaving} disabled={!unsavedChanges}>
          Save
        </CustomButton>
      }
      isLoading={isLoading}
      isError={isError}
    >
      <Form name="settings-form" onValuesChange={onValuesChange} onFinish={saveForm} autoComplete="off" form={form}>
        <SettingsGroup>
          <SettingsImageInput
            heading="Android Feature Graphic"
            tooltip="Displayed in the Google Play app store."
            width="270px"
            height="132px"
            requiredDimensions={{ requiredWidth: 1024, requiredHeight: 500 }}
            requirementsLabel="JPG/PNG. 1024 x 500"
            acceptedFileTypes="image/png, image/jpeg"
            hideDelete
            downloadable
            filename={filenames.AndroidFeatureGraphic?.value}
            scaleSpinner={80}
            onFilenameChange={(_filename) => onFilenameChange('AndroidFeatureGraphic', _filename)}
          />
        </SettingsGroup>
        <SettingsGroup>
          {displayAndroidTV && (
            <SettingsImageInput
              heading="Android TV Banner"
              tooltip="Displayed in the Google Play app store."
              width="338px"
              height="190px"
              requiredDimensions={{ requiredWidth: 1280, requiredHeight: 720 }}
              requirementsLabel="JPG/PNG. 1280 x 720"
              acceptedFileTypes="image/png, image/jpeg"
              hideDelete
              downloadable
              filename={filenames.AndroidTVBanner?.value}
              scaleSpinner={80}
              onFilenameChange={(_filename) => onFilenameChange('AndroidTVBanner', _filename)}
            />
          )}
        </SettingsGroup>
        <SettingsGroup wrapRow $marginBottom="0">
          {FIELDS.map((field) => {
            const defaultValue =
              (field.propertyType === 'appBasicInfo' && appBasicInfo
                ? appBasicInfo[field.name as AppBasicInfoKey]
                : field.propertyType === 'appProperty' && appProperties
                ? appProperties[field.name]
                : undefined) ?? undefined;

            return (
              <SettingsFormItem
                key={field.name}
                name={field.name}
                width={field.width || '49%'}
                $marginBottom={SETTINGS_ITEM_MARGIN_BOTTOM}
              >
                {field.type === 'text' ? (
                  <SettingsTextInput
                    defaultValue={defaultValue}
                    label={field.label}
                    maxLength={field.maxLength}
                    tooltip={field.tooltip}
                  />
                ) : field.type === 'textArea' ? (
                  <SettingsTextArea
                    defaultValue={defaultValue}
                    label={field.label}
                    maxLength={field.maxLength}
                    tooltip={field.tooltip}
                    rows={6}
                  />
                ) : (
                  <SettingsSelectInput
                    defaultVal={defaultValue}
                    label={field.label}
                    tooltip={field.tooltip}
                    options={appleCategories}
                  />
                )}
              </SettingsFormItem>
            );
          })}
        </SettingsGroup>
        <SettingsSegmentDivider />
        <SettingsGroup title="Android Phone Screenshots">
          <ScreensContainer>
            {screenshots.androidPhone?.map((screenshot, idx) => (
              <ScreenshotInput
                key={screenshot.id}
                heading={`Screenshot ${idx + 1}`}
                filename={screenshot.filename}
                id={screenshot.id}
                width="206px"
                height="366px"
                requiredDimensions={{ requiredWidth: 1242, requiredHeight: 2208 }}
                device="androidPhone"
                handleRemove={() => removeScreenshot(screenshot.id, 'androidPhone')}
                handleFilenameChange={onScreenshotChange}
              />
            ))}
          </ScreensContainer>
          <AddScreenshotButton
            text="Add an Android (Phone) screenshot"
            disabled={screenshots.androidPhone?.length > 7}
            device="androidPhone"
            handleClick={addScreenshot}
          />
        </SettingsGroup>
        <SettingsSegmentDivider />
        <SettingsGroup title="Android 10 Inch Tablet Screenshots">
          <ScreensContainer>
            {screenshots.androidTablet?.map((screenshot, idx) => (
              <ScreenshotInput
                key={screenshot.id}
                heading={`Screenshot ${idx + 1}`}
                filename={screenshot.filename}
                id={screenshot.id}
                width="206px"
                height="275px"
                requiredDimensions={{ requiredWidth: 1536, requiredHeight: 2048 }}
                device="androidTablet"
                handleRemove={() => removeScreenshot(screenshot.id, 'androidTablet')}
                handleFilenameChange={onScreenshotChange}
              />
            ))}
          </ScreensContainer>
          <AddScreenshotButton
            text="Add an Android (10 Inch Tablet) screenshot"
            disabled={screenshots.androidTablet?.length > 7}
            device="androidTablet"
            handleClick={addScreenshot}
          />
        </SettingsGroup>
        {displayAndroidTV && (
          <>
            <SettingsSegmentDivider />
            <SettingsGroup title="Android TV Screenshots">
              <ScreensContainer>
                {screenshots.androidTV?.map((screenshot, idx) => (
                  <ScreenshotInput
                    key={screenshot.id}
                    heading={`Screenshot ${idx + 1}`}
                    subheading="JPG. 1920px by 1080px"
                    filename={screenshot.filename}
                    id={screenshot.id}
                    width="314px"
                    height="177px"
                    maxDimensions={{ maxWidth: 3840, maxHeight: 2160 }}
                    maxFileSize={8}
                    device="androidTV"
                    handleRemove={() => removeScreenshot(screenshot.id, 'androidTV')}
                    handleFilenameChange={onScreenshotChange}
                  />
                ))}
              </ScreensContainer>
              <AddScreenshotButton
                text="Add an Android (10 Inch Tablet) screenshot"
                disabled={screenshots.androidTV?.length > 7}
                device="androidTV"
                handleClick={addScreenshot}
              />
            </SettingsGroup>
          </>
        )}
      </Form>
    </PageContainer>
  );
};
