import Icon, { LoadingOutlined } from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';
import {
  UilAngleDown,
  UilAngleLeft,
  UilAngleLeftB,
  UilAngleRight,
  UilAngleRightB,
  UilAngleUp,
  UilApps,
  UilCalender,
  UilChartGrowth,
  UilCheck,
  UilCheckCircle,
  UilCog,
  UilCommentAltLines,
  UilCopy,
  UilCube,
  UilDollarSignAlt,
  UilDumbbell,
  UilEllipsisH,
  UilEllipsisV,
  UilExclamationCircle,
  UilEye,
  UilFileAlt,
  UilFileBlank,
  UilFolder,
  UilGlobe,
  UilHeadphones,
  UilImageV,
  UilImport,
  UilInfoCircle,
  UilLink,
  UilListUiAlt,
  UilListUl,
  UilPen,
  UilPhoneAlt,
  UilPlay,
  UilPlus,
  UilSadDizzy,
  UilSave,
  UilSearch,
  UilSmile,
  UilStopwatch,
  UilSync,
  UilTimes,
  UilTimesCircle,
  UilTopArrowFromTop,
  UilTrash,
  UilUpload,
  UilUserCheck,
  UilUserTimes,
  UilUsersAlt,
  UilVideo,
} from '@iconscout/react-unicons';
import React from 'react';
import styled from 'styled-components';
import { DANGER_COLOUR, HIGHLIGHT_PRIMARY_COLOUR, SUCCESS_COLOUR, WARNING_COLOUR } from 'theme';

import { ReactComponent as AddToPlaylistSvg } from './AddToPlaylist.svg';
import { ReactComponent as AppChevronLeftSvg } from './AppChevronLeft.svg';
import { ReactComponent as AppChevronRightSvg } from './AppChevronRight.svg';
import { ReactComponent as AppChevronUpSvg } from './AppChevronUp.svg';
import { ReactComponent as AppFilterSvg } from './AppFilter.svg';
import { ReactComponent as AppStoreSvg } from './AppStore.svg';
import { ReactComponent as BellSvg } from './Bell.svg';
import { ReactComponent as BellAltSvg } from './BellAlt.svg';
import { ReactComponent as BellFilledSvg } from './BellFilled.svg';
import { ReactComponent as BuildSvg } from './Build.svg';
import { ReactComponent as CalendarSvg } from './Calendar.svg';
import { ReactComponent as CalendarCompleteSvg } from './CalendarComplete.svg';
import { ReactComponent as CalendarMissedSvg } from './CalendarMissed.svg';
import { ReactComponent as CalendarScheduledSvg } from './CalendarScheduled.svg';
import { ReactComponent as CloudDownloadSvg } from './CloudDownload.svg';
import { ReactComponent as CollectionSvg } from './Collection.svg';
import { ReactComponent as CustomSubTabSvg } from './CustomSubTabIcon.svg';
import { ReactComponent as DashboardSvg } from './Dashboard.svg';
import { ReactComponent as DesignSvg } from './Design.svg';
import { ReactComponent as DownloadCloudSvg } from './DownloadCloud.svg';
import { ReactComponent as DragSvg } from './Drag.svg';
import { ReactComponent as ExportSvg } from './Export.svg';
import { ReactComponent as EditSvg } from './Edit.svg';
import { ReactComponent as ToolSvg } from './Tool.svg';
import { ReactComponent as FavouriteSvg } from './Favourite.svg';
import { ReactComponent as FileLinkedSvg } from './FileLinked.svg';
import { ReactComponent as FilledRightArrowSvg } from './FilledRightArrow.svg';
import { ReactComponent as FilterSvg } from './Filter.svg';
import { ReactComponent as HamburgerSvg } from './Hamburger.svg';
import { ReactComponent as HeadphonesSvg } from './Headphones.svg';
import { ReactComponent as HistoryClockSvg } from './HistoryClock.svg';
import { ReactComponent as ImpersonateSvg } from './Impersonate.svg';
import { ReactComponent as LinkedCollectionSvg } from './LinkedCollection.svg';
import { ReactComponent as LinkedCubeSvg } from './LinkedCube.svg';
import { ReactComponent as LinkedFolderSvg } from './LinkedFolder.svg';
import { ReactComponent as LinkedSectionSvg } from './LinkedSection.svg';
import { ReactComponent as ListViewSvg } from './ListView.svg';
import { ReactComponent as MediaSvg } from './Media.svg';
import { ReactComponent as MultiFolderSvg } from './MultiFolder.svg';
import { ReactComponent as PadlockSvg } from './Padlock.svg';
import { ReactComponent as PlayBoxSvg } from './PlayBox.svg';
import { ReactComponent as PlayCircleSvg } from './PlayCircle.svg';
import { ReactComponent as PostSvg } from './Post.svg';
import { ReactComponent as ProfileSvg } from './Profile.svg';
import { ReactComponent as RevertSvg } from './Revert.svg';
import { ReactComponent as RightArrowSvg } from './RightArrow.svg';
import { ReactComponent as AngleDownBSvg } from './AngleDownB.svg';
import { ReactComponent as SectionSvg } from './Section.svg';
import { ReactComponent as SectionHeaderSvg } from './SectionHeader.svg';
import { ReactComponent as TextImageSvg } from './TextImage.svg';
import { ReactComponent as TickCompleteSvg } from './TickComplete.svg';
import { ReactComponent as WatchlistSvg } from './Watchlist.svg';
import { ReactComponent as WebhooksSvg } from './Webhooks.svg';
import { ReactComponent as FileSvg } from './file.svg';
import { ReactComponent as VideoSvg } from './video.svg';

interface IconProps {
  style?: React.CSSProperties;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  spin?: boolean;
}

function wrapIconInAntIcon(name: string, component: IconComponentProps['component']) {
  const icon = ({ style, ...props }: IconProps) => {
    return <Icon component={component} style={style} {...props} />;
  };
  icon.displayName = name; // Required for linting to set React display name for generated component
  return icon;
}

export const ExportIcon = wrapIconInAntIcon('ExportIcon', ExportSvg);
export const EditIcon = wrapIconInAntIcon('ExportIcon', EditSvg);
export const ToolIcon = wrapIconInAntIcon('ToolIcon', ToolSvg);
export const CallIcon = wrapIconInAntIcon('CallIcon', UilPhoneAlt);
export const LoadingSpinner = LoadingOutlined;
export const PadlockIcon = wrapIconInAntIcon('PadlockIcon', PadlockSvg);
export const XIcon = wrapIconInAntIcon('XIcon', UilTimes);
export const TrashIcon = wrapIconInAntIcon('TrashIcon', UilTrash);
export const CopyIcon = wrapIconInAntIcon('FileTwoIcon', UilCopy);
export const InfoCircleIcon = wrapIconInAntIcon('InfoCircleIcon', UilInfoCircle);
export const ExclamationCircleIcon = wrapIconInAntIcon('ExclamationCircleIcon', UilExclamationCircle);
export const SmileIcon = wrapIconInAntIcon('SmileIcon', UilSmile);
export const PenIcon = wrapIconInAntIcon('PenIcon', UilPen);
export const RevertIcon = wrapIconInAntIcon('RevertIcon', RevertSvg);
export const PostIcon = wrapIconInAntIcon('PostIcon', PostSvg);
export const SadDizzyIcon = wrapIconInAntIcon('SadDizzyIcon', UilSadDizzy);
export const BanMemberIcon = wrapIconInAntIcon('BanMemberIcon', UilUserTimes);
export const UnbanMemberIcon = wrapIconInAntIcon('UnbanMemberIcon', UilUserCheck);
export const StopwatchIcon = wrapIconInAntIcon('StopwatchIcon', UilStopwatch);
export const ImpersonateIcon = wrapIconInAntIcon('ImpersonateIcon', ImpersonateSvg);
export const ImageIcon = wrapIconInAntIcon('ImageIcon', UilImageV);
export const PublishIcon = wrapIconInAntIcon('PublishIcon', UilTopArrowFromTop);
export const CheckCircleIcon = wrapIconInAntIcon('CheckCircleIcon', UilCheckCircle);
export const ErrorCircleIcon = wrapIconInAntIcon('ErrorCircleIcon', UilTimesCircle);
export const SaveIcon = wrapIconInAntIcon('SaveIcon', UilSave);

// App Icons (Used within the Mobile App)
export const ProfileIcon = wrapIconInAntIcon('ProfileIcon', ProfileSvg);
export const HamburgerIcon = wrapIconInAntIcon('HamburgerIcon', HamburgerSvg);
export const MusicIcon = wrapIconInAntIcon('MusicIcon', UilHeadphones);
export const MediaIcon = wrapIconInAntIcon('MediaIcon', MediaSvg);
export const FileIcon = wrapIconInAntIcon('FileIcon', UilFileAlt);
export const SearchIcon = wrapIconInAntIcon('SearchIcon', UilSearch);
export const ListViewIcon = wrapIconInAntIcon('ListViewIcon', ListViewSvg);
export const BellAltIcon = wrapIconInAntIcon('BellAltIcon', BellAltSvg);
export const BellFilledIcon = wrapIconInAntIcon('BellFilledIcon', BellFilledSvg);
export const DownloadCloudIcon = wrapIconInAntIcon('DownloadCloudIcon', DownloadCloudSvg);
export const HeadphonesIcon = wrapIconInAntIcon('HeadphonesIcon', HeadphonesSvg);
export const HistoryClockIcon = wrapIconInAntIcon('HistoryClockIcon', HistoryClockSvg);
export const WatchlistIcon = wrapIconInAntIcon('WatchlistIcon', WatchlistSvg);
export const EllipsisIcon = wrapIconInAntIcon('EllipsisIcon', UilEllipsisH);
export const StarIcon = wrapIconInAntIcon('StarIcon', FavouriteSvg);
export const AddToPlaylistIcon = wrapIconInAntIcon('AddToPlaylistIcon', AddToPlaylistSvg);
export const CloudDownloadIcon = wrapIconInAntIcon('CloudDownloadIcon', CloudDownloadSvg);
export const TickCompleteIcon = wrapIconInAntIcon('TickCompleteIcon', TickCompleteSvg);
export const CalendarAppIcon = wrapIconInAntIcon('CalendarAppIcon', CalendarSvg);
export const AppVideoIcon = wrapIconInAntIcon('AppVideoIcon', VideoSvg);
export const AppFileIcon = wrapIconInAntIcon('AppFileIcon', FileSvg);
export const AppChevronUpIcon = wrapIconInAntIcon('AppChevronUpIcon', AppChevronUpSvg);
export const AppChevronLeftIcon = wrapIconInAntIcon('AppChevronLeftIcon', AppChevronLeftSvg);
export const AppChevronRightIcon = wrapIconInAntIcon('AppChevronRightIcon', AppChevronRightSvg);
export const NoThumbnailsIcon = wrapIconInAntIcon('NoThumbnailsIcon', PlayCircleSvg);
export const PreviewVideoIcon = wrapIconInAntIcon('PreviewVideoIcon', UilPlay);
export const AppFilterIcon = wrapIconInAntIcon('AppFilterIcon', AppFilterSvg);

// Arrow Icons
export const OldRightArrowIcon = wrapIconInAntIcon('RightArrowIcon', RightArrowSvg);
export const AppBackIcon = wrapIconInAntIcon('AppBackIcon', UilAngleLeftB);
export const ForwardIcon = wrapIconInAntIcon('AppForwardIcon', UilAngleRightB);
export const ChevronUpIcon = wrapIconInAntIcon('ChevronUpIcon', UilAngleUp);
export const ChevronDownIcon = wrapIconInAntIcon('ChevronDownIcon', UilAngleDown);
export const ChevronDownBIcon = wrapIconInAntIcon('ChevronDownIcon', AngleDownBSvg);
export const ChevronLeftIcon = wrapIconInAntIcon('ChevronLeftIcon', UilAngleLeft);
export const ChevronRightIcon = wrapIconInAntIcon('ChevronRightIcon', UilAngleRight);
export const FilledRightArrow = wrapIconInAntIcon('RightArrowIcon', FilledRightArrowSvg);

// Wizard Icons
export const UISyncIcon = wrapIconInAntIcon('UISyncIcon', UilSync);

// Calendar Icons
export const CalendarCompleteIcon = wrapIconInAntIcon('CalendarCompleteIcon', CalendarCompleteSvg);
export const CalendarMissedIcon = wrapIconInAntIcon('CalendarMissedIcon', CalendarMissedSvg);
export const CalendarScheduledIcon = wrapIconInAntIcon('CalendarScheduledIcon', CalendarScheduledSvg);

// AppBuilder (DragDrop) Icons
export const LinkIcon = wrapIconInAntIcon('LinkIcon', UilLink);
export const SectionIcon = wrapIconInAntIcon('SectionIcon', SectionSvg);
export const LinkedSectionIcon = wrapIconInAntIcon('LinkedSectionIcon', LinkedSectionSvg);
export const CustomSubTabIcon = wrapIconInAntIcon('CustomSubTabIcon', CustomSubTabSvg);
export const FileBlankIcon = wrapIconInAntIcon('FileBlankIcon', UilFileBlank);
export const FileLinkedIcon = wrapIconInAntIcon('FileLinkedIcon', FileLinkedSvg);
export const ListAltIcon = wrapIconInAntIcon('ListAltIcon', UilListUiAlt);
export const ListIcon = wrapIconInAntIcon('ListIcon', UilListUl);
export const MultiFolderIcon = wrapIconInAntIcon('MultiFolderIcon', MultiFolderSvg);
export const FolderIcon = wrapIconInAntIcon('FolderIcon', UilFolder);
export const CollectionIcon = wrapIconInAntIcon('CollectionIcon', CollectionSvg);
export const CubeIcon = wrapIconInAntIcon('CubeIcon', UilCube);
export const CheckIcon = wrapIconInAntIcon('CheckIcon', UilCheck);
export const GlobeIcon = wrapIconInAntIcon('GlobeIcon', UilGlobe);
export const TextImageIcon = wrapIconInAntIcon('TextImageIcon', TextImageSvg);
export const EyeIcon = wrapIconInAntIcon('EyeIcon', UilEye);
export const LinkedFolderIcon = wrapIconInAntIcon('LinkedFolderIcon', LinkedFolderSvg);
export const LinkedCollectionIcon = wrapIconInAntIcon('LinkedCollectionIcon', LinkedCollectionSvg);
export const LinkedCubeIcon = wrapIconInAntIcon('LinkedCubeIcon', LinkedCubeSvg);
export const SectionHeaderIcon = wrapIconInAntIcon('SectionHeaderIcon', SectionHeaderSvg);

// SideNav Icons
export const DashboardIcon = wrapIconInAntIcon('DashboardIcon', DashboardSvg);
export const DesignIcon = wrapIconInAntIcon('DesignIcon', DesignSvg);
export const BuildIcon = wrapIconInAntIcon('BuildIcon', BuildSvg);
export const AppStoreIcon = wrapIconInAntIcon('AppStoreIcon', AppStoreSvg);
export const WebhooksIcon = wrapIconInAntIcon('WebhooksIcon', WebhooksSvg);
export const DumbbellIcon = wrapIconInAntIcon('DumbbellIcon', UilDumbbell);
export const BellIcon = wrapIconInAntIcon('BellIcon', BellSvg);
export const PlayBoxIcon = wrapIconInAntIcon('PlayBoxIcon', PlayBoxSvg);
export const DollarSignIcon = wrapIconInAntIcon('DollarSignIcon', UilDollarSignAlt);
export const CalendarIcon = wrapIconInAntIcon('CalendarIcon', UilCalender);
export const VideoIcon = wrapIconInAntIcon('VideoIcon', UilVideo);
export const CommentIcon = wrapIconInAntIcon('CommentIcon', UilCommentAltLines);
export const CogIcon = wrapIconInAntIcon('CogIcon', UilCog);
export const UploadIcon = wrapIconInAntIcon('UploadIcon', UilUpload);
export const DownloadIcon = wrapIconInAntIcon('DownloadIcon', UilImport);
export const PlusIcon = wrapIconInAntIcon('PlusIcon', UilPlus);
export const DragIcon = wrapIconInAntIcon('DraggableIcon', DragSvg);
export const FilterIcon = wrapIconInAntIcon('FilterIcon', FilterSvg);
export const CommunityIcon = wrapIconInAntIcon('CommunityIcon', UilUsersAlt);
export const AnalyticsIcon = wrapIconInAntIcon('AnalyticsIcon', UilChartGrowth);
export const AppFeaturesIcon = wrapIconInAntIcon('AppFeaturesIcon', UilApps);
export const VericalEllipsisIcon = wrapIconInAntIcon('VerticalEllipsisIcon', UilEllipsisV);

export const SuccessIcon = styled(CheckCircleIcon)`
  &&&& {
    font-size: 35px;
    color: ${SUCCESS_COLOUR};
  }
`;

export const ErrorIcon = styled(ErrorCircleIcon)`
  &&&& {
    color: ${DANGER_COLOUR};
  }
`;

export const WarningIcon = styled(ExclamationCircleIcon)`
  &&&& {
    font-size: 35px;
    color: ${WARNING_COLOUR};
  }
`;

export const InfoIcon = styled(InfoCircleIcon)`
  &&&& {
    color: ${HIGHLIGHT_PRIMARY_COLOUR};
  }
`;
