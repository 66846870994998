import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import { useSaveAppProperties } from 'hooks';
import { CustomButton, Modal } from 'components';
import { XIcon } from 'icons';

const StyledModal = styled(Modal)`
  &&&& {
    .ant-modal-content {
      background: none;
      box-shadow: none;
    }

    iframe {
      border: none;
    }
  }
`;
const CloseButton = styled(CustomButton)`
  &&&& {
    position: fixed;
    top: 10px;
    right: 10px;
    border-radius: 50%;
    width: 32px;
    height: 32px;

    span {
      font-size: 28px;
    }
  }
`;

interface SalesCallProps {
  modalVisible: boolean;
  setModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SalesCall = ({ modalVisible, setModalVisible }: SalesCallProps) => {
  const saveAppProperties = useSaveAppProperties();
  const [closeButtonVisible, setCloseButtonVisible] = useState(false);

  useEffect(() => {
    if (modalVisible) {
      setTimeout(() => {
        // Delay showing close button to avoid button moving with modal opening animation
        setCloseButtonVisible(true);
      }, 500);
    }
  }, [modalVisible]);

  const handleClose = useCallback(() => {
    setCloseButtonVisible(false);
    // Make sure close button is hidden before closing animation begins
    setTimeout(() => {
      setModalVisible(false);
    }, 10);
    saveAppProperties.mutate([{ Name: 'OnboardingChecklistSalesCall', Value: '1' }]);
  }, [setCloseButtonVisible, setModalVisible, saveAppProperties]);

  return (
    <StyledModal
      open={modalVisible}
      $height="678px"
      $contentWidth="830px"
      $padding="0"
      destroyOnClose
      maskClosable
      onCancel={handleClose}
    >
      {closeButtonVisible && <CloseButton secondary medium onClick={handleClose} icon={<XIcon />} />}
      <iframe
        style={{
          width: '100%',
          height: '100%',
        }}
        src={'https://meetings.hubspot.com/nick-gibbons/builder?embed=true'}
      />
    </StyledModal>
  );
};
