import { DefaultAppPropertiesProvider, DefaultContentProvider, SaveProvider } from 'providers';

import { CoreStatsInternal } from './CoreStatsInternal';

export const CoreStats = () => (
  <DefaultContentProvider>
    <SaveProvider>
      <DefaultAppPropertiesProvider>
        <CoreStatsInternal />
      </DefaultAppPropertiesProvider>
    </SaveProvider>
  </DefaultContentProvider>
);
