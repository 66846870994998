import { useCallback, useState } from 'react';
import styled from 'styled-components';
import { Checkbox, Input } from 'antd';

import { CustomButton, Modal } from 'components';
import { FONT_12PX_REGULAR, FONT_16PX_MEDIUM } from 'font';
import { BuildOrSubmitOptions } from 'api';

import { useAdminActions } from '../hooks/useAdminActions';

const Content = styled.div`
  width: 420px;
  height: 160px;
  padding: 32px 32px 0;
`;

const MarginBottom = styled.div`
  margin-bottom: 20px;
`;
const Header = styled.div`
  ${FONT_16PX_MEDIUM};
`;
const Subtitle = styled.div`
  ${FONT_12PX_REGULAR};
`;

const Footer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const ConfirmButton = styled(CustomButton)`
  &&&& {
    margin-left: 10px;
    width: 70px;
  }
`;

interface BranchModalProps {
  action: { options: BuildOrSubmitOptions; successMessage: string } | undefined;
  setAction: React.Dispatch<
    React.SetStateAction<{ options: BuildOrSubmitOptions; successMessage: string } | undefined>
  >;
}

export const BranchModal = ({ action, setAction }: BranchModalProps) => {
  const { handleBuildOrSubmit } = useAdminActions();
  const [branch, setBranch] = useState<string>('');
  const [useMonorepo, setUseMonorepo] = useState(false);
  const platform = action?.options.platform;

  const handleConfirm = useCallback(() => {
    const repository = useMonorepo || platform === 'AndroidTV' ? 'monorepo' : '';
    if (action && platform) {
      handleBuildOrSubmit(
        {
          platform,
          stage: action.options.stage,
          branch,
          repository,
        },
        action.successMessage,
      );
    }
    setBranch('');
    setAction(undefined);
  }, [branch, useMonorepo, action, platform, handleBuildOrSubmit, setBranch, setAction]);

  const handleCancel = useCallback(() => {
    setBranch('');
    setAction(undefined);
  }, [setBranch, setAction]);

  return (
    <Modal
      open={!!action}
      onCancel={handleCancel}
      width="auto"
      $height="100%"
      $radius="6px"
      destroyOnClose
      footer={[
        <Footer key="modal-footer">
          <CustomButton medium secondary onClick={handleCancel}>
            Cancel
          </CustomButton>
          <ConfirmButton medium onClick={handleConfirm} disabled={branch.length === 0}>
            OK
          </ConfirmButton>
        </Footer>,
      ]}
    >
      <Content>
        <MarginBottom>
          <Header>Building {platform}</Header>
          <Subtitle>Please enter your git branch name:</Subtitle>
        </MarginBottom>
        <Input value={branch} onChange={(e) => setBranch(e.target.value)} />
        {platform !== 'AndroidTV' ? (
          <Checkbox onChange={(e) => setUseMonorepo(e.target.checked)} style={{ marginTop: '10px' }}>
            Build from Monorepo
          </Checkbox>
        ) : (
          <Checkbox disabled checked style={{ marginTop: '10px' }}>
            Build from Monorepo
          </Checkbox>
        )}
      </Content>
    </Modal>
  );
};
