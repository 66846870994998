import styled from 'styled-components';
import { useCallback } from 'react';

import { useSaveAppProperties, useRecordAction } from 'hooks';
import { NEUTRAL_9_COLOUR } from 'theme';

import { Header, Dot, SplitPanel, GraphicPanel } from './index';
import { FONT_20PX_NORMAL } from '../font';
import { useOnboardingEmail } from '../hooks/useOnboardingEmail';
import { WizardButton } from '../components/Common';

const Wrapper = styled.div`
  padding-top: 10px;
`;
const SpacedDot = styled(Dot)`
  margin-right: 16px;
`;
const Row = styled.div`
  display: flex;
`;
const Text = styled.div`
  margin-top: 20px;
  ${FONT_20PX_NORMAL};
  color: ${NEUTRAL_9_COLOUR};
`;
const UpperText = styled(Text)`
  margin-top: 32px;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 35px;
  align-items: center;
`;

export const FinalStep = () => {
  const onboardingEmail = useOnboardingEmail();
  const recordAction = useRecordAction();
  const appProperties = useSaveAppProperties();

  const completeWizard = useCallback(async () => {
    recordAction.mutate({ action: 'Finished Onboarding Wizard' });
    onboardingEmail.mutate();
    appProperties.mutate([{ Name: 'OnboardingWizardCompleted', Value: '1' }], {
      onSuccess: () => {
        window.location.reload();
      },
    });
  }, [recordAction, onboardingEmail, appProperties]);

  return (
    <SplitPanel
      leftContent={<GraphicPanel />}
      disableLeftPadding
      rightContent={
        <Wrapper>
          <Header>
            Congrats! 🎉 <br />
            Your free test app is ready for you.
          </Header>
          <UpperText>To check it out:</UpperText>
          <Row>
            <SpacedDot outlined>1</SpacedDot>
            <Text>{'Search "VidApp" in the iOS App Store on your iPhone or iPad'}</Text>
          </Row>
          <Row>
            <SpacedDot outlined>2</SpacedDot>
            <Text>{'Download the "VidApp - Access your content" app'}</Text>
          </Row>
          <Row>
            <SpacedDot outlined>3</SpacedDot>
            <Text>Login using your email and password</Text>
          </Row>
          <Footer>
            <WizardButton $fontSize="17px" $height="48px" onClick={completeWizard} loading={appProperties.isLoading}>
              Explore Builder
            </WizardButton>
          </Footer>
        </Wrapper>
      }
    />
  );
};
