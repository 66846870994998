import { useCallback } from 'react';
import styled from 'styled-components';

import { InfoModal } from 'components';
import { AppBasicInfoKey, BuildPlatform, BuildStage, SOURCE_KAJABI, SOURCE_VHX } from 'api';
import { useAppProperties, useSecureAppProperties, useAppBasicInfo, useProducts } from 'hooks';

interface MandatoryField {
  name: string;
  id: string;
  isColor?: boolean;
  isHttps?: boolean;
  mustInclude?: string;
}

const ErrorList = styled.ul`
  list-style: disc;
  padding-left: 17px;

  li {
    margin-bottom: 5px;

    span {
      font-weight: 500;
    }
  }
`;

export const useMandatoryFields = (dataSource?: string) => {
  const { data: appProperties } = useAppProperties({ staleTime: Infinity });
  const { data: secureAppProperties } = useSecureAppProperties();
  const { data: appBasicInfo } = useAppBasicInfo(Infinity);
  const { data: productsData } = useProducts(Infinity);

  const validateMandatoryFields = useCallback(
    (platformToPublishOn: BuildPlatform, stage: BuildStage) => {
      const fields: MandatoryField[] = [
        { name: 'Splash Screen Image', id: 'SplashScreenImage', isColor: false },
        { name: 'App Icon', id: 'AppIconImage' },
        { name: 'Android Feature Graphic', id: 'AndroidFeatureGraphic' },
        { name: 'iOS Display Name', id: 'AppleDisplayName' },
      ];

      if (stage === 'Preview') {
        fields.push({ name: 'Highlight Color', id: 'HighlightHEX', isColor: true });
        fields.push({ name: 'Header Background Color', id: 'NavBarBackgroundHEX', isColor: true });
        fields.push({ name: 'Header Highlight Color', id: 'NavBarTitleHEX', isColor: true });
        fields.push({ name: 'Tab Bar Background Color', id: 'TabBarBackgroundHEX', isColor: true });
        fields.push({ name: 'Tab Bar Highlight Color', id: 'TabBarTintHEX', isColor: true });
      }

      if (platformToPublishOn === 'Android') {
        fields.push({ name: 'Android App Name', id: 'AndroidTitle' });
        fields.push({ name: 'Android Developer Account JSON', id: 'AndroidJsonFilename' });
        fields.push({ name: 'Android Developer Name', id: 'AndroidDeveloperName' });
        fields.push({ name: 'Google Play Public Key', id: 'GooglePlayPublicKey' });
        fields.push({ name: 'Android Firebase Config File', id: 'AndroidFirebaseConfigFile' });
        fields.push({
          name: 'Android Sentry DSN',
          id: 'SentryDSNAndroid',
          isHttps: true,
          mustInclude: 'ingest.sentry.io',
        });
        if (stage === 'Live') {
          fields.push({ name: 'Android Phone Screenshot', id: 'AppStoreAndroidPhoneScreenshot2' });
          fields.push({ name: 'Android Tablet Screenshot', id: 'AppStoreAndroid10InchTabletScreenshot2' });
          fields.push({ name: 'Android Short Description', id: 'AndroidShortDescription' });
        }
      } else if (platformToPublishOn === 'iOS' || platformToPublishOn === 'tvOS') {
        fields.push({ name: 'iOS App Name', id: 'AppleTitle' });
        fields.push({ name: 'iTunes User ID', id: 'ITunesUserId' });
        fields.push({ name: 'iTunes Password', id: 'ITunesPassword' });
        fields.push({ name: 'iTunes Team ID', id: 'ITunesTeamId' });
        fields.push({ name: 'iTunes Team Name (or Developer Title)', id: 'ITunesCompanyName' });
        fields.push({ name: 'Developer Title - App Store', id: 'ITunesDeveloperTitle' });
        fields.push({ name: 'Apple Shared Secret', id: 'SharedSecret' });
        fields.push({ name: 'Apple Key ID', id: 'AppleKeyId' });
        fields.push({ name: 'Apple Issuer ID', id: 'AppleIssuerId' });
        fields.push({ name: 'Apple Auth Key', id: 'AppleAuthKey' });
        fields.push({ name: 'iOS Review Test User Email Address', id: 'iOSTestUserEmail' });
        fields.push({ name: 'iOS Review Test User Password', id: 'iOSTestUserPassword' });
        fields.push({ name: 'iOS Sentry DSN', id: 'SentryDSNIOS', isHttps: true, mustInclude: 'ingest.sentry.io' });
        fields.push({ name: 'iOS Firebase Config File', id: 'iOSFirebaseConfigFile' });
        if (stage === 'Live') {
          fields.push({ name: 'iPhone Screenshot', id: 'AppStoreIPhoneScreenshot2' });
          fields.push({ name: 'iPhone X Screenshot', id: 'AppStoreIPhonexsmaxScreenshot2' });
          fields.push({ name: 'iPad Screenshot', id: 'AppStoreIPadScreenshot2' });
          fields.push({ name: 'iOS Subtitle', id: 'AppleShortDescription' });
          fields.push({ name: 'Apple Keywords', id: 'AppleKeywords' });
          fields.push({ name: 'Apple Full Description', id: 'AppleFullDescription' });
          fields.push({ name: 'Primary Category', id: 'PrimaryCategory' });
          fields.push({ name: 'Secondary Category', id: 'SecondaryCategory' });
          fields.push({ name: 'iOS Marketing URL', id: 'MarketingURL' });
          fields.push({ name: 'iOS Support URL', id: 'SupportURL' });
          fields.push({ name: 'One Signal App Id', id: 'OneSignalAppId' });
        }
        if (platformToPublishOn === 'tvOS') {
          fields.push({ name: 'tvOS Firebase Config File', id: 'tvOSFirebaseConfigFile' });
        }
      } else if (platformToPublishOn === 'Roku') {
        fields.push({ name: 'Roku Name', id: 'RokuName' });
        fields.push({ name: 'TV Logo Image', id: 'RokuNavBarImage' });
        fields.push({ name: 'Roku App Icon (FHD)', id: 'RokuAppIconFHD' });
        fields.push({ name: 'Roku Launch Image', id: 'RokuAppLaunchImage' });
      }

      if (dataSource === SOURCE_VHX) {
        fields.push({ name: 'VHX API Key', id: 'VHXAPI' });
        fields.push({ name: 'VHX Site URL', id: 'VHXSiteURL' });
      } else if (dataSource === SOURCE_KAJABI) {
        fields.push({ name: 'Kajabi Site ID', id: 'SourceChannelId' });
        fields.push({ name: 'Kajabi Site URL', id: 'KajabiSiteURL' });
        fields.push({ name: 'Kajabi Assistant Email', id: 'KajabiAdminEmail' });
        fields.push({ name: 'Kajabi Assistant Password', id: 'KajabiAdminPassword' });
      }

      if (appProperties?.FacebookAppId) {
        fields.push({ name: 'Facebook Client ID', id: 'FacebookClientId' });
      }

      const errors: JSX.Element[] = [];
      fields.forEach((field, idx) => {
        const { id, name, isColor, isHttps, mustInclude } = field;

        let fieldValue = '';
        if (secureAppProperties?.hasOwnProperty(id)) {
          fieldValue = secureAppProperties[id];
        } else if (appProperties?.hasOwnProperty(id)) {
          fieldValue = appProperties[id];
        } else if (appBasicInfo?.hasOwnProperty(id)) {
          if (appBasicInfo[id as AppBasicInfoKey] === null) {
            fieldValue = '';
          } else {
            fieldValue = appBasicInfo[id as AppBasicInfoKey] as string;
          }
        }

        if (fieldValue === '') {
          // IF MANDATORY FIELD IS BLANK
          errors.push(
            <li key={idx}>
              <span>Missing mandatory field: </span>
              {`'${name}'`}
            </li>,
          );
        } else if (isColor && !/^[0-9A-F]{6}$/i.test(fieldValue.toUpperCase())) {
          // IF MANDATORY COLOR IS INCORRECT
          errors.push(<li key={idx}>{`Please ensure '${name}' is entered correctly`}</li>);
        } else if (isHttps && !fieldValue.toLowerCase().startsWith('https://')) {
          // IF FIELD IS MISSING HTTPS
          errors.push(<li key={idx}>{`Please ensure '${name}' starts with 'https://'`}</li>);
        } else if (mustInclude && !fieldValue.toLowerCase().includes(mustInclude)) {
          // IF FIELD IS MISSING REQUIRED STRING
          errors.push(<li key={idx}>{`Please ensure '${name}' includes '${mustInclude}'`}</li>);
        }
      });

      const errorProducts: string[] = [];
      productsData?.products.forEach((product) => {
        if (product.ReadyForSale === 'yes') {
          productsData.products.forEach((productToCheck) => {
            if (
              productToCheck.ReadyForSale === 'yes' && // ONLY CHECK AGAINST LIVE PRODUCTS
              productToCheck.IsIndividualPurchase == 0 && // ONLY CHECK BUNDLES (BECAUSE INDIVIDUAL PRODUCTS DON'T HAVE CUSTOM DISPLAY NAMES)
              product.ProductId != productToCheck.ProductId && // ONLY CHECK AGAINST OTHER PRODUCTS, NOT THE SAME PRODUCT
              product.CustomDisplayName === productToCheck.CustomDisplayName
            ) {
              if (!errorProducts.includes(product.DisplayName)) {
                errorProducts.push(productToCheck.DisplayName);
                errors.push(
                  <li
                    key={product.ProductId}
                  >{`Your product '${product.DisplayName}' has the same External Title as '${productToCheck.DisplayName}'`}</li>,
                );
              }
            }
          });
        }
      });

      if (errors.length > 0) {
        InfoModal(
          `We've found a few items that need to be fixed before we can ${
            stage === 'Preview' ? 'build' : 'submit'
          } your app`,
          <ErrorList>{errors}</ErrorList>,
          'error',
        );
        return false;
      } else {
        return true;
      }
    },
    [appProperties, secureAppProperties, appBasicInfo, productsData],
  );

  return {
    validateMandatoryFields,
  };
};
