import { useCallback, useState } from 'react';
import styled from 'styled-components';
import { Select } from 'antd';
import { format, addMonths, subMonths } from 'date-fns';

import { CustomButton, InfoModal, SegmentTitle } from 'components';
import { VIDAPP_HELP_URL, saveCalendarMonthStatus, useAxiosInstance } from 'api';
import { useAppBeingEdited } from 'app-context';
import { NEUTRAL_10_COLOUR, NEUTRAL_5_COLOUR, SECONDARY_SUCCESS_COLOUR, SUCCESS_COLOUR } from 'theme';
import { AppBackIcon, CheckIcon, ChevronDownIcon, FileBlankIcon, ForwardIcon } from 'icons';

import { PublishedStatus } from '../CalendarPage';
import { FONT_12PX_MEDIUM } from 'font';

const { Option, OptGroup } = Select;

const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 19px;
`;

const MonthHeadingWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const TitleWrapper = styled.div`
  width: 146px;
`;

const MonthControls = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ActiveDate = styled.div`
  width: 100px;
  color: ${NEUTRAL_10_COLOUR};
  ${FONT_12PX_MEDIUM};
  text-align: center;
`;

const PublishedStatusSelect = styled(Select)<{ value: 0 | 1 }>`
  #react-app && {
    width: ${({ value }) => (value === 1 ? '107px' : '86px')};

    & .ant-select-selector {
      border-style: solid;
      border-width: 1px;
      border-color: ${({ value }) => (value === 1 ? SUCCESS_COLOUR : NEUTRAL_5_COLOUR)};
      border-radius: 4px;
      height: 22px;
      overflow: hidden;
      ${FONT_12PX_MEDIUM};
    }

    & .ant-select-selection-item {
      background-color: ${({ value }) => (value === 1 ? SECONDARY_SUCCESS_COLOUR : '#fff')};
      border-radius: 2px;
      transition: none !important;
      height: 22px;
    }

    & .ant-select-item {
      padding: 4px 8px;
    }

    & .ant-select-selector {
      padding: 0 !important;
    }

    & .ant-select-item-option {
      background-color: #fff !important;
      :hover {
        background-color: red;
      }
    }

    & .ant-select-arrow {
      right: 6px;

      span {
        svg {
          color: ${({ value }) => (value === 1 ? SUCCESS_COLOUR : NEUTRAL_10_COLOUR)};
        }
        height: 100%;
        margin-right: 0;
      }
    }

    & .ant-select-arrow .anticon:not(.ant-select-suffix) {
      pointer-events: none;
      color: #000;
      transition: none;
    }

    .anticon {
      margin-right: 6px;
      font-size: 14px;
    }
  }
`;

const PublishedStatusOption = styled.span`
  height: 100%;
  border-radius: 2px;
  display: flex;
  align-items: center;
  padding-left: 6px;
`;

const OptionDraft = styled(PublishedStatusOption)`
  color: ${NEUTRAL_10_COLOUR};
`;

const OptionPublished = styled(PublishedStatusOption)`
  background-color: ${SECONDARY_SUCCESS_COLOUR};
  color: ${SUCCESS_COLOUR};
`;

interface CalendarHeaderProps {
  activeDate: Date;
  startDate: Date;
  endDate: Date;
  publishedStatus: PublishedStatus;
  setPublishedStatus: React.Dispatch<React.SetStateAction<PublishedStatus>>;
  setActiveDate: React.Dispatch<React.SetStateAction<Date>>;
  refetchCalendar: () => void;
}

export const CalendarHeader = ({
  activeDate,
  startDate,
  endDate,
  publishedStatus,
  setPublishedStatus,
  setActiveDate,
  refetchCalendar,
}: CalendarHeaderProps) => {
  const appBeingEdited = useAppBeingEdited();
  const axiosInstance = useAxiosInstance();
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const handleMonthChange = useCallback(
    (direction) => {
      direction === 'backwards' ? setActiveDate(subMonths(activeDate, 1)) : setActiveDate(addMonths(activeDate, 1));
      refetchCalendar();
    },
    [activeDate, setActiveDate, refetchCalendar],
  );

  const handleStatusChange = useCallback(
    async (value) => {
      setIsSaving(true);
      const month = format(activeDate, 'yyyy-MM');
      setPublishedStatus({ ...publishedStatus, [month]: value });
      await saveCalendarMonthStatus(axiosInstance, appBeingEdited, {
        Month: month,
        Published: value,
      }).then((response) => {
        if (response.status === 201) {
          if (value === 1) {
            InfoModal(
              `${format(activeDate, 'MMMM')} Calendar is now published`,
              'Change status to Draft to un-publish.',
              'success',
              'Done',
            );
          } else {
            InfoModal(
              `${format(activeDate, 'MMMM')} Calendar has been set to Draft`,
              'Your scheduled content for this month is no longer visible in the app.',
              'info',
              'Done',
            );
          }
        } else {
          setPublishedStatus(publishedStatus); // Save failed, set status to previous state
          InfoModal(
            `Failed To Publish`,
            <span>
              Your calendar failed to publish. Please try again or click{' '}
              <a href={VIDAPP_HELP_URL} target="_blank" rel="noopener noreferrer">
                here
              </a>{' '}
              to get in contact with the VidApp support team.
            </span>,
            'error',
            'Done',
          );
        }
        setIsSaving(false);
      });
    },
    [publishedStatus, setPublishedStatus, activeDate, saveCalendarMonthStatus, setIsSaving],
  );

  return (
    <HeaderContainer>
      <MonthHeadingWrapper>
        <TitleWrapper>
          <SegmentTitle title={`${format(activeDate, 'MMMM')} Calendar`} $marginBottom="0" />
        </TitleWrapper>
        <PublishedStatusSelect
          value={publishedStatus[format(activeDate, 'yyyy-MM')] ?? 0}
          onChange={handleStatusChange}
          getPopupContainer={(node) => node.parentNode}
          loading={isSaving}
          disabled={isSaving}
          bordered={false}
          suffixIcon={<ChevronDownIcon />}
          dropdownMatchSelectWidth={false}
          dropdownStyle={{ width: '180px', borderRadius: '8px' }}
        >
          <OptGroup label={`Set ${format(activeDate, 'MMMM')} Calendar to:`}>
            <Option value={0}>
              <OptionDraft>
                <FileBlankIcon />
                Draft
              </OptionDraft>
            </Option>
            <Option value={1}>
              <OptionPublished>
                <CheckIcon />
                Published
              </OptionPublished>
            </Option>
          </OptGroup>
        </PublishedStatusSelect>
      </MonthHeadingWrapper>
      <MonthControls>
        <CustomButton
          small
          tertiary
          onClick={() => handleMonthChange('backwards')}
          disabled={format(activeDate, 'MM-yy') === format(startDate, 'MM-yy')}
          data-testid="left-arrow-button"
          icon={<AppBackIcon />}
        />
        <ActiveDate>{format(activeDate, 'MMMM yyyy')}</ActiveDate>
        <CustomButton
          small
          tertiary
          onClick={() => handleMonthChange('forwards')}
          disabled={format(activeDate, 'MM-yy') === format(endDate, 'MM-yy')}
          data-testid="right-arrow-button"
          icon={<ForwardIcon />}
        />
      </MonthControls>
    </HeaderContainer>
  );
};
