import { Form } from 'antd';

import { CustomButton, PageContainer, SettingsGroup, SettingsSegmentDivider, SettingsToggleInput } from 'components';
import { GROUP_LABEL, SOURCE_KAJABI } from 'api';
import { useDataSource } from 'providers';

import { useAddons } from './hooks/useAddOns';
import { AddCommunityTab } from 'app/modules/add-ons/AddOns/components/AddCommunityTab';

export const AddOns = () => {
  const {
    form,
    unsavedChanges,
    isSaving,
    isLoading,
    isError,
    pushNotificationsDisabled,
    commentsModeratorDisabled,
    CMSDisabled,
    onValuesChange,
    saveForm,
  } = useAddons();
  const dataSource = useDataSource();
  const rolloutCommunity = Form.useWatch('RolloutCommunity', form);

  return (
    <>
      <PageContainer
        heading="Add-Ons"
        subheading="Configure an app’s premium features and add-ons."
        headingButton={
          <CustomButton medium onClick={() => form?.submit()} loading={isSaving} disabled={!unsavedChanges}>
            Save
          </CustomButton>
        }
        isLoading={isLoading}
        isError={isError}
      >
        <Form name="addons-form" onValuesChange={onValuesChange} onFinish={saveForm} form={form}>
          <SettingsGroup title="App Features" $marginBottom="24px">
            <SettingsToggleInput name="DisplayCoreStats" label="Three Core Stats" />
            <SettingsToggleInput
              name="DisplayMyLibrary"
              label="My Playlists"
              description={
                <>
                  The <b>My Lists</b> view is the base offering that allows users to favourite, save, and download items
                  and collections in an app. In addition to features in <b>My Lists</b>, the <b>My Playlists</b> view
                  further enables users to create custom playlists.
                  {'\n\n'}
                  Toggling this on will enable clients to select <b>My Library</b> as a primary tab on the Build screen.
                  {'\n\n'}
                  If the <b>My Lists</b> feature is already selected as a primary tab on the Build screen, then a client
                  must replace this tab with the <b>My Library</b> tab type for app users to see the <b>My Library</b>{' '}
                  feature, and vice versa.
                  {'\n\n'}
                  If neither <b>My Lists</b> nor <b>My Library</b> are selected as primary tabs then app users see the{' '}
                  <b>My Lists</b> feature which they can navigate to from the side menu.
                </>
              }
              expandableDescription
            />
            <SettingsToggleInput
              name="DisplayCircuitWorkouts"
              label="Circuit Workouts"
              description="Circuit Workouts is an app and Builder add-on that allows clients to design their own workouts from short exercise demos which are then strung together into full workouts to be experienced by users in-app."
              expandableDescription
            />
            <SettingsToggleInput
              name="DisplayCalendarScheduler"
              label="Calendar Scheduler"
              description="Gives app owners the ability to schedule videos and daily quotes/descriptions into the Calendar view."
            />
            <SettingsToggleInput
              name="DisplayDeepLinking"
              label="Deep Linking"
              description="Adds functionality throughout the Builder that allows a client to copy a link to tabs, collections and individual pieces of content within their app."
            />
          </SettingsGroup>
          <SettingsSegmentDivider />
          <SettingsGroup title="TV Apps" $marginBottom="24px">
            <SettingsToggleInput name="DisplayAppleTV" label="Apple TV" />
            <SettingsToggleInput name="DisplayAndroidTV" label="Android TV" />
            <SettingsToggleInput name="DisplayRoku" label="Roku" />
          </SettingsGroup>
          <SettingsSegmentDivider />
          <SettingsGroup title="Builder Access" $marginBottom="24px">
            <SettingsToggleInput
              name="DisplayCMS"
              label={`CMS (Playlists, ${GROUP_LABEL}s, Series)`}
              description="Adds functionality to Content, allowing clients to build and edit VidApp content."
              disabled={CMSDisabled}
              tooltip={CMSDisabled ? `Overridden by CMS (${GROUP_LABEL}s)` : undefined}
            />
            <SettingsToggleInput
              name="DisplaySimpleCMS"
              label={`CMS (${GROUP_LABEL}s)`}
              description={`Adds functionality to Content, allowing clients to build and edit VidApp ${GROUP_LABEL}s.`}
            />
            <SettingsToggleInput
              name="PushNotificationScheduling"
              label="Push Notification Scheduling"
              disabled={pushNotificationsDisabled}
              tooltip={pushNotificationsDisabled ? `Not available for childrens apps` : undefined}
            />
            <SettingsToggleInput name="DisplayCategoriesAndTags" label="Filters" />
            <SettingsToggleInput
              name="DisplayCommentsModerator"
              label="Manage Comments"
              description="Gives app owners the ability to moderate comments made from within their apps only. Comments made from the web platform cannot be moderated from the builder."
              disabled={commentsModeratorDisabled}
              tooltip={commentsModeratorDisabled ? `Not available for ${dataSource} apps` : undefined}
            />
            <SettingsToggleInput
              name="RolloutOffersScreen"
              label="Offers Screen (editable)"
              description="Display the full Offers Screen to the client, instead of the read-only version."
            />
            <SettingsToggleInput
              name="DisplayOnboardingChecklist"
              label="Onboarding Checklist"
              description="Display the onboarding checklist in the Dashboard."
            />
          </SettingsGroup>
          <SettingsSegmentDivider />
          <SettingsGroup title="Experimental Features">
            <SettingsToggleInput
              name="RolloutCommunity"
              label="Community Tab Available"
              description="Make the Community Tab available in Build My App"
            />
            {rolloutCommunity && <AddCommunityTab />}
            {dataSource !== SOURCE_KAJABI && (
              <SettingsToggleInput
                name="RolloutCourseView"
                label="Course/Program & In-Progress"
                description="Enable access to the Course/Program view and In-Progress collection"
              />
            )}
            <SettingsToggleInput
              name="RolloutAlternateThumbnails"
              label="Rollout Alternate Thumbnails"
              description="Show Square/Portrait Thumbnails throughout the Builder"
            />
            <SettingsToggleInput
              name="DisplayWebhooks"
              label="Webhooks"
              description="Display the Webhook page to the client for sending user events data."
            />
          </SettingsGroup>
        </Form>
      </PageContainer>
    </>
  );
};
