import { useQuery } from 'react-query';

import { DateIncrement, getVideoAnalyticsMetricByDate, useAxiosInstance } from 'api';
import { useAppBeingEdited } from 'app-context';

import { MetricKey } from '../const';

export const useVideoAnalyticsMetricByDate = (
  metric: MetricKey,
  startDate: Date,
  endDate: Date,
  byDate: DateIncrement,
) => {
  const client = useAxiosInstance();
  const appId = useAppBeingEdited();
  return useQuery(
    ['videoAnalyticsMetricByDate', appId, metric, startDate, endDate, byDate],
    () => getVideoAnalyticsMetricByDate(client, appId, metric, startDate, endDate, byDate),
    {
      enabled: !!appId, // Don't call fetcher with empty AppId
    },
  );
};
