import { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';

import { CustomButton, CustomCell, CustomTable2, PageContainer, TableColumn } from 'components';
import { useSaveAppProperties } from 'hooks';
import { PenIcon, PlusIcon } from 'icons';
import { useLocalAppProperties } from 'providers';
import { PAGE_CONTAINER_WIDE_WIDTH } from 'theme';

import { EVENT_TYPES } from '../../const';
import { getTypeLabel } from '../../utils';
import { DeleteWebhookButton } from './DeleteWebhookButton';
import { WebhookDetails } from './WebhookDetails';

const PAGE_NAME = 'Webhooks';

//* Webhooks are saved as EventPublishingConfig app property in a JSON string in this format:
//* {
//*   UserPurchase: {
//*     Format: 'VidApp',
//*     Destination: 'CustomUrl',
//*     DestinationUrl: 'https://hooks.zapier.com/hooks/catch/14076367/b7yoail/',
//*   },
//*   UserTransaction: {
//*     Format: 'VidApp',
//*     Destination: 'GoogleAnalytics',
//*   }
//* }

export type EventType = (typeof EVENT_TYPES)[number];
type Destination = 'CustomUrl' | 'GoogleAnalytics';

export interface WebhookPropertyItem {
  Format: 'VidApp';
  Destination: Destination;
  DestinationUrl?: string;
}

interface WebhookTableItem {
  id: EventType;
  destination: Destination;
  destinationUrl?: string;
}

const ButtonWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const WebhooksInternal = () => {
  const { properties, setAppProperty } = useLocalAppProperties();
  const saveAppProperties = useSaveAppProperties();
  const [existingType, setExistingType] = useState<EventType>();
  const [formVisible, setFormVisible] = useState(false);

  const existingWebhooks: Partial<Record<EventType, WebhookPropertyItem>> = useMemo(() => {
    if (properties.EventPublishingConfig) {
      return JSON.parse(properties.EventPublishingConfig);
    }
    return {};
  }, [properties]);

  const dataSource: WebhookTableItem[] = useMemo(() => {
    const arr: WebhookTableItem[] = [];

    Object.entries(existingWebhooks).forEach(([key, value]) => {
      if (['UserPurchase', 'UserTransaction', 'UserCancellation', 'UserExpiry'].includes(key)) {
        arr.push({
          id: key as EventType,
          destination: value.Destination,
          destinationUrl: value.DestinationUrl,
        });
      }
    });

    return arr;
  }, [existingWebhooks]);

  const handleDelete = useCallback(
    (type: EventType) => {
      const updatedProperty = { ...existingWebhooks };
      delete updatedProperty[type];

      setAppProperty('EventPublishingConfig', JSON.stringify(updatedProperty));

      saveAppProperties.mutate([{ Name: 'EventPublishingConfig', Value: JSON.stringify(updatedProperty) }]);
    },
    [existingWebhooks, setAppProperty, saveAppProperties],
  );

  const heading = formVisible ? undefined : PAGE_NAME;
  const subheading = formVisible ? undefined : 'Send common user events data out of VidApp to a preferred destination.';

  const columns: TableColumn<WebhookTableItem>[] = [
    {
      heading: 'Event Type',
      width: 172,
      render: ({ id }) => <CustomCell>{getTypeLabel(id)}</CustomCell>,
    },
    {
      heading: 'Destination',
      width: 'grow',
      render: ({ destination, destinationUrl }) => (
        <CustomCell>{destination === 'CustomUrl' ? destinationUrl : 'Google Analytics'}</CustomCell>
      ),
    },
    {
      heading: '',
      id: 'delete',
      width: 24,
      render: ({ id }) => (
        <ButtonWrapper onClick={(e) => e.stopPropagation()}>
          <DeleteWebhookButton handleDelete={() => handleDelete(id)} />
        </ButtonWrapper>
      ),
    },
    {
      heading: '',
      id: 'edit',
      width: 73,
      render: ({ id }) => (
        <ButtonWrapper onClick={(e) => e.stopPropagation()}>
          <CustomButton
            icon={<PenIcon />}
            small
            tertiaryHighlight
            onClick={() => {
              setFormVisible(true);
              setExistingType(id);
            }}
          >
            Edit
          </CustomButton>
        </ButtonWrapper>
      ),
    },
  ];

  return (
    <PageContainer
      heading={heading}
      subheading={subheading}
      isBeta={!formVisible}
      isLoading={!properties}
      contentMaxWidth={formVisible ? undefined : PAGE_CONTAINER_WIDE_WIDTH}
      headingButton={
        formVisible ? undefined : (
          <CustomButton
            medium
            icon={<PlusIcon />}
            onClick={() => setFormVisible(true)}
            disabled={dataSource.length >= EVENT_TYPES.length}
          >
            Create New Webhook
          </CustomButton>
        )
      }
    >
      {formVisible ? (
        <WebhookDetails
          existingType={existingType}
          handleBack={() => {
            setExistingType(undefined);
            setFormVisible(false);
          }}
        />
      ) : (
        <CustomTable2
          pageSize={30}
          columns={columns}
          data={dataSource}
          emptyTitle="No Webhooks"
          emptyDescription="Get started by creating a new webhook."
        />
      )}
    </PageContainer>
  );
};
