import { VideoAnalyticsTotals } from 'api';
import { METRIC_KEY_AVERAGE_WATCHED, METRIC_KEY_COMPLETIONS, METRIC_KEY_TOTAL_PLAYS } from './const';

export const getKeyAnalytics = (data?: VideoAnalyticsTotals) =>
  data
    ? {
        [METRIC_KEY_TOTAL_PLAYS]: data.VideoPlays?.Plays,
        [METRIC_KEY_COMPLETIONS]: data.VideoCompletions?.Completions,
        [METRIC_KEY_AVERAGE_WATCHED]: data.AverageCompletions
          ? parseInt(data.AverageCompletions.AverageCompletion)
          : undefined,
      }
    : undefined;
