// convert array of strings to a string literal
import { AxiosInstance } from 'axios';
import { DataSource, ITEM_TYPE_COLLECTION, ITEM_TYPE_TAB, SOURCE_VIDAPP } from './const';
import { BuilderCollection } from 'providers';
import { builderAPI, ContentDescription, ContentFlags, ContentThumbnails, IncludedInAppData } from 'api';

export const COLLECTION_PROPERTY_WEBSITE_URL = 'Website';
export const COLLECTION_PROPERTY_HTMLPAGE_DESC = 'AboutDesc';

// Content API will provide collections according to this interface
export interface IndexedCollections {
  [key: number]: Collection;
}

// Builder will also have unsaved collections which meet this interface
export interface BuilderIndexedCollections {
  [key: number | string]: BuilderCollection;
}

export interface GetCollectionsResponse {
  collections: Collection[];
  collection_by_id: IndexedCollections;
}

export interface Resource {
  OriginalUrl: string | null;
  Title: string;
  ParentId: string;
  Type: string;
  Url: string;
  ParentType: string;
  ResourceId: number | string;
}

export interface Collection extends ContentThumbnails, ContentFlags, ContentDescription {
  AppId: string;
  DataSource: DataSource;
  DisplayInTVApp?: 1 | 0;
  Drip?: 1 | 0;
  DripDays?: number;
  Icon?: string;
  Items: CollectionItem[];
  IsMainTab?: 1 | 0;
  KajabiDataThemeId?: number;
  ModularViewDisplayLimit?: number;
  Name: string;
  NavBarTitleText?: string;
  Published?: string;
  Position?: number;
  PreviewVideoId?: number;
  Properties?: Record<string, string>;
  Resources?: Resource[];
  SourceId: string;
  SourceName: string;
  SourceType: string;
  TabId: number;
  Tag?: string;
  TemplateId: number;
  TemplateName: string;
  Type: string;
  Checksum?: string;
  ItemsChecksum?: string;
  CreatedAt?: string;
  UpdatedAt?: string;
  SourceProductIDs?: string[];
}

export interface CollectionItem {
  AlottedDuration: number;
  AppId: string;
  Category?: string;
  ChildId: number;
  DataSource: DataSource;
  IsAlternate: 1 | 0;
  Position: number;
  PreExerciseCountInDuration: number;
  RepNumber: number;
  RestTime: number;
  SourceProductIDs?: string[];
  SubType: CollectionItemSubType;
  TabId: number;
  TabItemId?: number | string; // Only string for temporary ids in Builder
  TemplateId?: number;
  Type: string;
}

export type CollectionItemSubType = 'collection' | 'post' | 'pdf' | 'mp3' | 'video';

export const DEFAULT_COLLECTION_ITEM = {
  DataSource: SOURCE_VIDAPP as DataSource,
  IsAlternate: 0 as const,
  AlottedDuration: 30,
  PreExerciseCountInDuration: 10,
  RepNumber: 1,
  RestTime: 0,
};
export const DEFAULT_COLLECTION = {
  Name: 'Untitled',
  Description: '',
  ThumbnailSource: '',
  DataSource: SOURCE_VIDAPP as DataSource,
  IncludedInAppData: 1 as IncludedInAppData,
  Type: ITEM_TYPE_COLLECTION as CollectionItemSubType,
  SourceType: '',
  SourceDescription: '',
  Tag: '',
  Position: 0 as const,

  // Satisfy the interface but are not important
  SourceId: '',
  SourceName: '',
  SourceThumbnailSource: '',
  TemplateName: '',
};

export const DEFAULT_TAB = {
  Name: 'New Tab',
  Description: '',
  ThumbnailSource: '',
  DataSource: SOURCE_VIDAPP as DataSource,
  IncludedInAppData: 1 as IncludedInAppData,
  Type: ITEM_TYPE_TAB,
  SourceType: 'Tab',
  SourceDescription: '',
  Tag: '',
  Position: 99 as const,
};

export type CollectionToSave = Partial<Omit<Collection, 'Resources'>> & { Resources: Partial<Resource>[] };

export type CollectionKey = keyof Collection;

export interface CollectionPropertiesToSave {
  collectionId: number;
  properties: CollectionPropertyToSave[];
}

export interface CollectionPropertyToSave {
  Name: string;
  Value: string;
}

export interface CollectionItemsToSave {
  collectionId: number;
  items: CollectionItem[];
}

export interface CollectionToUpdate {
  collectionId: number;
  properties: Record<string, string | number | Resource[]>;
}

export const getCollections = (client: AxiosInstance, appId: string) => {
  return client
    .get(`${builderAPI.contentApiURL}/data/${appId}/builder/collections?update_cache=false&no_emoji=false`, {
      headers: {
        accept: 'application/json',
      },
    })
    .then(({ data }) => {
      const COLLECTION_BY_ID: IndexedCollections = {};
      for (const collection of data) {
        if (typeof collection.TabId === 'number') {
          COLLECTION_BY_ID[collection.TabId] = collection;
        }
      }
      return { collections: data, collection_by_id: COLLECTION_BY_ID };
    });
};

export const createCollection = async (
  client: AxiosInstance,
  appId: string,
  collection: CollectionToSave,
  refresh: boolean,
) => {
  return client.post(`/content/collection/${appId}?refresh=${refresh}`, JSON.stringify(collection));
};

export const updateCollection = async (
  client: AxiosInstance,
  appId: string,
  collectionToUpdate: CollectionToUpdate,
  refresh: boolean,
) => {
  return client.put(
    `/content/collection/${appId}/${collectionToUpdate.collectionId}?refresh=${refresh}`,
    JSON.stringify(collectionToUpdate.properties),
  );
};

export const saveCollectionProperties = async (
  client: AxiosInstance,
  appId: string,
  collectionPropertiesToSave: CollectionPropertiesToSave,
  refresh: boolean,
) => {
  return client.put(
    `/content/collection/${appId}/${collectionPropertiesToSave.collectionId}/properties?refresh=${refresh}`,
    JSON.stringify(collectionPropertiesToSave.properties),
  );
};

export const saveCollectionItems = async (
  client: AxiosInstance,
  appId: string,
  collectionItemsToSave: CollectionItemsToSave,
  refresh: boolean,
) => {
  return client.put(
    `/content/collection/${appId}/${collectionItemsToSave.collectionId}/items?refresh=${refresh}`,
    JSON.stringify(collectionItemsToSave.items),
  );
};

export const deleteCollection = async (
  client: AxiosInstance,
  appId: string,
  collectionId: Collection['TabId'],
  refresh: boolean,
) => {
  return client.delete(`/content/collection/${appId}/${collectionId}?refresh=${refresh}`);
};

export const refreshContent = async (
  client: AxiosInstance,
  appId: string,
  content: 'collections' | 'videos',
  ids: number[],
) => {
  return client.get(`${builderAPI.contentApiURL}/refresh/${appId}/builder?${content}=${ids.join(',')}`);
};
