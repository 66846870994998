import { useMutation, useQueryClient } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';

import { Product, updateProduct, useAxiosInstance } from 'api';
import { useAppBeingEdited } from 'app-context';
import { useRecordAction } from 'hooks';

export const useUpdateProduct = (invalidateQuery = true) => {
  const appId = useAppBeingEdited();
  const client = useAxiosInstance();
  const queryClient = useQueryClient();
  const recordAction = useRecordAction();

  return useMutation<AxiosResponse<{ Id: number }>, AxiosError, Partial<Product>>(
    (valuesToUpdate) => {
      return updateProduct(client, appId, valuesToUpdate);
    },
    {
      onSuccess: async (_response, payload) => {
        if (invalidateQuery) {
          await queryClient.invalidateQueries(['products', appId]);
        }
        recordAction.mutate({ action: 'Update Product', detail: { updatedValues: payload } });
      },
    },
  );
};
