import { Dispatch, SetStateAction, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { FONT_12PX_SEMIBOLD, FONT_18PX_SEMIBOLD } from 'font';
import { useMainTabs } from 'providers';
import {
  HIGHLIGHT_PRIMARY_ORANGE_COLOUR,
  NEUTRAL_10_COLOUR,
  NEUTRAL_3_COLOUR,
  NEUTRAL_5_COLOUR,
  NEUTRAL_8_COLOUR,
  PRIMARY_ORANGE_COLOUR,
  PRIMARY_ORANGE_HOVER_COLOUR,
  SECONDARY_ORANGE_COLOUR,
} from 'theme';
import { BUILDER_IMAGES_DOMAIN, TintImage, getTabIconFromCollection } from 'utils';
import { Dropdown, DropdownOption } from 'components';
import { ChevronDownBIcon } from 'icons';

import { DRAWER_TYPE_BMA } from 'app/modules/build-dragdrop/Builder/const';
import { BuilderCollection, useBuilderContext } from 'app/modules/build-dragdrop/Builder/providers';
import { useMockupContext } from '../../mockup';

const TabRow = styled.div<{ $selected?: boolean }>`
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 8px;
  margin-left: 12px;
  margin-right: 4px;
  cursor: pointer;
  color: ${NEUTRAL_10_COLOUR};

  :hover {
    background-color: ${NEUTRAL_3_COLOUR};
  }

  ${({ $selected }) =>
    $selected &&
    css`
      color: ${PRIMARY_ORANGE_COLOUR};
      background-color: ${SECONDARY_ORANGE_COLOUR};

      :hover {
        background-color: ${SECONDARY_ORANGE_COLOUR};
      }
    `}
`;

const Details = styled.div`
  display: flex;
`;

const TabIconWrapper = styled.div`
  width: 20px;
  height: 20px;
  margin-right: 8px;
`;
const TabName = styled.div`
  ${FONT_18PX_SEMIBOLD};
`;

const StyledChevronDownIcon = styled(ChevronDownBIcon)<{ $rotate: string; $tabSelected?: boolean }>`
  &&&& {
    padding: 7px;
    display: flex;
    align-items: center;
    font-size: 20px;
    rotate: ${({ $rotate }) => $rotate};
    color: ${({ $tabSelected }) => ($tabSelected ? PRIMARY_ORANGE_COLOUR : NEUTRAL_10_COLOUR)};
    cursor: pointer;

    :hover:not(:active) {
      color: ${({ $tabSelected }) => ($tabSelected ? PRIMARY_ORANGE_HOVER_COLOUR : NEUTRAL_8_COLOUR)};
    }
  }
`;

const DropdownContent = styled.div`
  padding: 5px 0;
`;

const DropdownSectionHeader = styled.div`
  ${FONT_12PX_SEMIBOLD};
  color: ${NEUTRAL_10_COLOUR};
  margin-bottom: 8px;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  margin-top: 4px;
  margin-bottom: 12px;
  background-color: ${NEUTRAL_5_COLOUR};
`;

export const StyledDropdownOption = styled(DropdownOption)<{ $isActive: boolean }>`
  &&&& {
    color: ${({ $isActive }) => ($isActive ? PRIMARY_ORANGE_COLOUR : NEUTRAL_10_COLOUR)};
    background-color: ${({ $isActive }) => ($isActive ? HIGHLIGHT_PRIMARY_ORANGE_COLOUR : 'none')};

    :not(:last-child) {
      margin-bottom: 6px;
    }
  }
`;

const TabIcon = ({ tab, isActive }: { tab: BuilderCollection; isActive?: boolean }) => (
  <TabIconWrapper>
    <TintImage
      src={getTabIconFromCollection(tab) || `${BUILDER_IMAGES_DOMAIN}/icons/blocked.png`}
      color={isActive ? PRIMARY_ORANGE_COLOUR : NEUTRAL_10_COLOUR}
      dimensions={{ width: 20, height: 20 }}
    />
  </TabIconWrapper>
);

const TabDropdownOption = ({
  tab,
  isActive,
  setDropdownOpen,
}: {
  tab: BuilderCollection;
  isActive: boolean;
  setDropdownOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const history = useHistory();
  const location = useLocation();

  const locationState = location.state ? (location.state as Record<string, boolean>) : {}; // If builder was entered via Dashboard shortcut, location.state will include viaDashboard: true

  const { TabId, Name } = tab;

  return (
    <StyledDropdownOption
      tertiary
      large
      $isActive={isActive}
      onClick={() => {
        setDropdownOpen(false);
        history.push(`/builder/${TabId}`, { ...locationState, resetSaveProvider: true });
      }}
    >
      <TabIcon tab={tab} isActive={isActive} />
      {Name}
    </StyledDropdownOption>
  );
};

export const ToolboxMainTabSelector = ({
  currentTab,
  drawerWidth,
}: {
  currentTab: BuilderCollection;
  drawerWidth: number;
}) => {
  const { setDrawerContext, drawerContext } = useBuilderContext();
  const { setCurrentPage } = useMockupContext();
  const { activeTabs, inactiveTabs } = useMainTabs();

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const tabSelected = drawerContext?.drawerType === DRAWER_TYPE_BMA;

  return (
    <TabRow
      onClick={() => {
        setDrawerContext({ drawerType: DRAWER_TYPE_BMA });
        setCurrentPage(undefined);
      }}
      $selected={tabSelected}
    >
      <Details>
        <TabIcon key={`${currentTab.TabId}${tabSelected}`} tab={currentTab} isActive={tabSelected} />
        <TabName>{currentTab.Name ?? currentTab?.SourceName}</TabName>
      </Details>
      <Dropdown
        trigger={
          <StyledChevronDownIcon
            onClick={(e) => e.stopPropagation()}
            $rotate={dropdownOpen ? '180deg' : '0deg'}
            $tabSelected={tabSelected}
          />
        }
        isOpen={dropdownOpen}
        onOpenChange={setDropdownOpen}
        menuMinWidth={`${drawerWidth - 40}px`}
        menuMarginTop="12px"
        content={
          <DropdownContent>
            {!!activeTabs && activeTabs.length > 0 && (
              <>
                <DropdownSectionHeader>Bottom Tab Bar</DropdownSectionHeader>
                {activeTabs.map((tab) => (
                  <TabDropdownOption
                    key={tab.TabId}
                    tab={tab}
                    setDropdownOpen={setDropdownOpen}
                    isActive={tab.TabId === currentTab.TabId}
                  />
                ))}
              </>
            )}
            {!!inactiveTabs && inactiveTabs.length > 0 && (
              <>
                <Divider />
                <DropdownSectionHeader>Not Active</DropdownSectionHeader>
                {inactiveTabs.map((tab) => (
                  <TabDropdownOption
                    key={tab.TabId}
                    tab={tab}
                    setDropdownOpen={setDropdownOpen}
                    isActive={tab.TabId === currentTab.TabId}
                  />
                ))}
              </>
            )}
          </DropdownContent>
        }
      />
    </TabRow>
  );
};
