import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { ITEM_TYPE_COLLECTION, ITEM_TYPE_VIDEO, SOURCE_KAJABI, SOURCE_VIDAPP, Video } from 'api';
import { BreadcrumbItem, BreadcrumbProps, CustomButton, Dropdown, InfoModal, PageContainer } from 'components';
import { useSyncKajabiProduct } from 'hooks';
import { EllipsisIcon, UISyncIcon } from 'icons';
import { BuilderCollection, useSaveContext, useUnsavedChanges } from 'providers';
import { NEUTRAL_9_COLOUR } from 'theme';
import { collectionIsProtected, getCollectionSourceName, getVideoSourceTitle } from 'utils';

import { ContentBreadcrumb, useContentNavigationContext } from 'app/modules/content/providers';
import { Screen, screenHeadings } from '../../ContentInternal';
import { CMSMockup } from '../../cms-mockup/CMSMockup';
import { DeleteCollectionButton } from '../../components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const SaveWrapper = styled.div`
  display: flex;
`;

const SaveButton = styled(CustomButton)`
  &&&& {
    margin-left: 8px;
  }
`;

const DropdownButton = styled(CustomButton)`
  &&&& {
    color: ${NEUTRAL_9_COLOUR};
  }
`;

interface ContentViewContainerProps {
  collection?: BuilderCollection;
  video?: Video;
  blockSave?: () => void;
  isLoading?: boolean;
  children: React.ReactNode;
}

export const ContentViewContainer = ({
  collection,
  video,
  blockSave,
  isLoading,
  children,
}: ContentViewContainerProps) => {
  const { breadcrumbs, blockTreeNavigation, tempTabId, goToItem, goToNewCollection, updateBreadcrumbItem } =
    useContentNavigationContext();
  const { screen } = useParams<{ screen: Screen }>();
  const { isSaving, saveChanges } = useSaveContext();
  const { unsavedChanges } = useUnsavedChanges();
  const syncKajabiProduct = useSyncKajabiProduct({ isOnboarding: false, syncToApp: true });

  const showSync = useMemo(() => {
    if (collection) {
      return collection.DataSource === SOURCE_KAJABI && collection.SourceType === 'Product';
    }
    return false;
  }, [collection]);

  const showDelete = useMemo(() => {
    if (!collection) {
      return false;
    }

    const isTempId = typeof collection.TabId === 'string' && collection.TabId.startsWith('Temp');

    if (isTempId || blockTreeNavigation) {
      return false;
    }

    return !collectionIsProtected(collection.SourceId) && (showSync || collection.DataSource === SOURCE_VIDAPP);
  }, [collection, blockTreeNavigation, showSync]);

  const showDropdown = (showSync || showDelete) && collection;

  const handleSync = useCallback(() => {
    syncKajabiProduct.mutate({ productId: collection?.SourceId, themeId: collection?.KajabiDataThemeId });

    InfoModal(
      `Your ${SOURCE_KAJABI} course is being synced`,
      "You'll receive an email once this is complete",
      'success',
    );
  }, [syncKajabiProduct]);

  const handleSave = async () => {
    const { getPermanentCollectionId } = await saveChanges();

    // If a new collection has been created, navigate to the newly created permanent TabId
    const newTabId = getPermanentCollectionId(tempTabId);
    if (newTabId && collection) {
      goToNewCollection(newTabId, collection.Name);
    }

    // Update the breadcrumb item with the new collection name
    else if (collection) {
      updateBreadcrumbItem(collection.TabId, collection.Name);
    }
  };

  const breadcrumbProps: BreadcrumbProps | undefined = useMemo(() => {
    if (breadcrumbs) {
      const breadcrumbItems: ContentBreadcrumb[] = [
        { id: undefined, label: screen ? screenHeadings[screen].heading : 'Content' },
      ];
      breadcrumbItems.push(...breadcrumbs);

      const items: BreadcrumbItem[] = breadcrumbItems.map((item) => ({
        label: item.label,
        handleClick: () => {
          const updatedItems = [...breadcrumbs];
          const idx = updatedItems.findIndex((i) => i.id === item.id);
          updatedItems.length = idx + 1;
          goToItem('collection', item.id, { breadcrumbs: updatedItems, appendToExisting: false });
        },
      }));

      const currentScreen = items.pop()?.label ?? '';
      return { items, currentScreen };
    }
    return undefined;
  }, [breadcrumbs, goToItem]);

  if (!collection && !video) {
    return <PageContainer isLoading mockup={<CMSMockup isLoading />} />;
  }

  return (
    <PageContainer
      heading={collection ? getCollectionSourceName(collection) || ' ' : getVideoSourceTitle(video as Video) || ' '}
      isLoading={isLoading}
      breadcrumbs={breadcrumbProps}
      headingButton={
        !isLoading && (
          <SaveWrapper>
            {showDropdown && (
              <Dropdown
                trigger={<DropdownButton icon={<EllipsisIcon />} tertiary />}
                content={
                  <>
                    {showSync && (
                      <CustomButton icon={<UISyncIcon />} onClick={handleSync} tertiary medium>
                        Sync
                      </CustomButton>
                    )}
                    {showDelete && (
                      <DeleteCollectionButton
                        tabId={collection.TabId as number}
                        sourceType={collection.SourceType}
                        dataSource={collection.DataSource}
                      />
                    )}
                  </>
                }
                menuMinWidth="130px"
              />
            )}
            <SaveButton
              medium
              onClick={blockSave ?? handleSave}
              loading={isSaving}
              disabled={!unsavedChanges || isSaving}
            >
              Save
            </SaveButton>
          </SaveWrapper>
        )
      }
      mockup={
        (collection || video) && (
          <CMSMockup
            currentPage={
              collection
                ? { itemId: collection.TabId, itemType: ITEM_TYPE_COLLECTION }
                : { itemId: video?.VideoId, itemType: ITEM_TYPE_VIDEO }
            }
          />
        )
      }
    >
      <Container key={collection ? collection.SourceId : video?.SourceId}>{children}</Container>
    </PageContainer>
  );
};
