import { useCallback, useEffect, useState } from 'react';
import { Form } from 'antd';

import { SaveFailed } from 'components';
import { useDataSource, useUnsavedChanges } from 'providers';
import { AppPropertyToSave, SOURCE_KAJABI, SOURCE_USCREEN, SOURCE_VHX } from 'api';
import { useAppProperties, useSaveAppProperties } from 'hooks';

const PROPERTIES = [
  'DisplayCoreStats',
  'DisplayMyLibrary',
  'DisplayCircuitWorkouts',
  'DisplayCalendarScheduler',
  'DisplayDeepLinking',
  'DisplayWebhooks',
  'DisplayAppleTV',
  'DisplayAndroidTV',
  'DisplayRoku',
  'PushNotificationScheduling',
  'DisplayCategoriesAndTags',
  'DisplayCommentsModerator',
  'RolloutCommunity',
  'RolloutOffersScreen',
  'RolloutCourseView',
  'RolloutAlternateThumbnails',
  'DisplayCMS',
  'DisplaySimpleCMS',
  'DisplayOnboardingChecklist',
] as const;

const DEFAULT_ON = ['DisplayOnboardingChecklist']; // The toggles for these properties should default to on if undefined

export const useAddons = () => {
  const {
    data: appProperties,
    isLoading: appPropertiesIsLoading,
    isRefetching: appPropertiesIsRefetching,
    isError: appPropertiesIsError,
  } = useAppProperties({ staleTime: Infinity, refetchOnMount: 'always' });
  const { unsavedChanges, setUnsavedChanges } = useUnsavedChanges();
  const saveAppProperties = useSaveAppProperties();
  const dataSource = useDataSource();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [formInitialized, setFormInitialized] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [CMSDisabled, setCMSDisabled] = useState(false);

  const pushNotificationsDisabled = appProperties?.TargetsChildren === '1';
  const commentsModeratorDisabled = [SOURCE_KAJABI, SOURCE_VHX, SOURCE_USCREEN].includes(dataSource);

  const initializeForm = useCallback(() => {
    if (appProperties) {
      PROPERTIES.forEach((property) => {
        // Set form initial values from appProperties
        const value = DEFAULT_ON.includes(property)
          ? appProperties[property] !== '0'
          : ['1', 'Yes'].includes(appProperties[property]);

        form.setFields([
          {
            name: property,
            value,
          },
        ]);
      });

      if (appProperties.DisplaySimpleCMS === '1') {
        form.setFields([{ name: 'DisplayCMS', value: false }]);
        setCMSDisabled(true);
      }
      if (pushNotificationsDisabled) {
        form.setFields([{ name: 'PushNotificationScheduling', value: false }]);
      }
      if (commentsModeratorDisabled) {
        form.setFields([{ name: 'DisplayCommentsModerator', value: false }]);
      }
    }

    setFormInitialized(true);
  }, [appProperties, pushNotificationsDisabled, commentsModeratorDisabled, setFormInitialized]);

  const onValuesChange = useCallback(
    (changedValues: Record<string, boolean>) => {
      if ('DisplaySimpleCMS' in changedValues) {
        setCMSDisabled(changedValues['DisplaySimpleCMS']);
        form.setFields([{ name: 'DisplayCMS', value: false }]);
      }
      if (!unsavedChanges) {
        setUnsavedChanges(true);
      }
    },
    [unsavedChanges, setUnsavedChanges],
  );

  const getValueToSave = useCallback(
    (field: string) => {
      if (field === 'DisplayAppleTV' || field === 'DisplayRoku') {
        return form.getFieldValue(field) ? 'Yes' : 'No';
      } else {
        return form.getFieldValue(field) ? '1' : '0';
      }
    },
    [form],
  );

  const saveForm = useCallback(async () => {
    setIsSaving(true);
    const fieldsToSave = form.getFieldsValue(true, (meta) => meta.touched);
    const appPropertiesToSave: AppPropertyToSave[] = [];

    for (const field in fieldsToSave) {
      appPropertiesToSave.push({ Name: field, Value: getValueToSave(field) });
    }

    if (appPropertiesToSave.length > 0) {
      try {
        await saveAppProperties.mutateAsync(appPropertiesToSave);
      } catch (error) {
        SaveFailed(error as Error);
        setUnsavedChanges(true);
        setIsSaving(false);
      }
    }

    setIsSaving(false);
    setUnsavedChanges(false);
  }, [setIsSaving, setUnsavedChanges, form, appProperties, saveAppProperties]);

  useEffect(() => {
    if (appPropertiesIsRefetching === false && !isSaving) {
      form.resetFields();
      initializeForm();
    }
  }, [appPropertiesIsRefetching, isSaving, form, initializeForm]);

  return {
    form,
    unsavedChanges,
    isSaving,
    isLoading: appPropertiesIsLoading || !formInitialized,
    isError: appPropertiesIsError,
    pushNotificationsDisabled,
    commentsModeratorDisabled,
    CMSDisabled,
    onValuesChange,
    saveForm,
  };
};
