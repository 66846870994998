import { SOURCE_KAJABI, SOURCE_USCREEN, SOURCE_VHX } from 'api';
import { useAppProperties } from 'hooks';
import { useDataSource } from 'providers';
import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { getScreenFromPathname } from 'utils';

const PROPERTY_MAP: Record<string, string> = {
  'calendar-scheduler': 'DisplayCalendarScheduler',
  'circuit-configuration': 'DisplayCircuitWorkouts',
  workouts: 'DisplayCircuitWorkouts',
  filters: 'DisplayCategoriesAndTags',
  community: 'RolloutCommunity',
  posts: 'RolloutCommunity',
  moderation: 'RolloutCommunity',
  members: 'RolloutCommunity',
  'apple-tv': 'DisplayAppleTV',
  roku: 'DisplayRoku',
  webhooks: 'DisplayWebhooks',
  'csv-upload': 'DisplayCSVUpload',
};

export const useFeatureFlags = () => {
  const { pathname } = useLocation();
  const dataSource = useDataSource();
  const { data: properties } = useAppProperties({
    staleTime: Infinity,
  });

  const isLoading = !dataSource || !properties;

  const hasAccess = useCallback(
    (screen?: string) => {
      const screenName = screen ?? getScreenFromPathname(pathname);

      if (!screenName || isLoading) {
        return false;
      }

      // Unique cases
      switch (screenName) {
        case 'content-tagging':
          return (
            properties.DisplayCategoriesAndTags === '1' && // Can only be displayed if Categories & Tags is enabled
            (properties.DisplayContentTagging === '1' || // Display if feature flag is on
              (dataSource === SOURCE_KAJABI && properties.DisplayCSVUpload !== '1')) // OR for all Kajabi apps with Categories & Tags (but not CSV Upload)
          );
        case 'push-notifications':
          return properties.TargetsChildren !== '1';
        case 'comments-moderator':
          return (
            properties.DisplayCommentsModerator === '1' &&
            ![SOURCE_KAJABI, SOURCE_VHX, SOURCE_USCREEN].includes(dataSource)
          );
        case 'synced-content':
        case 'playlists':
        case 'series':
        case 'advanced-cms':
          return properties.DisplayCMS === '1' && properties.DisplaySimpleCMS !== '1';
      }

      // Standard app properties
      if (PROPERTY_MAP[screenName]) {
        const value = properties[PROPERTY_MAP[screenName]];
        if (value) {
          return ['1', 'Yes'].includes(value);
        }
      }

      return false;
    },
    [pathname, dataSource, properties, isLoading],
  );

  return {
    hasAccess,
    isLoading,
  };
};
