import { useCallback } from 'react';
import styled from 'styled-components';

import { GroupTitle, SettingsCheckboxInput, SettingsGroup, SettingsImageInput, SettingsSegment } from 'components';
import { useLocalAppProperties, useSaveContext } from 'providers';

import { AppPropertyTextInput } from 'app/modules/design-screens';
import { SIDE_MENU_MAX } from '../../const';

const sideMenuItems = [
  {
    heading: 'Profile',
    name: 'Profile',
    hideUrl: true,
    maxLength: 14,
    tooltip: 'This will take your users to the Profile tab.',
  },
  {
    heading: 'Manage My Subscription',
    name: 'WebsiteSubscription',
    urlFieldName: 'WebsiteSubscriptionLink',
  },
  {
    heading: 'Link 1',
    name: 'FAQ',
    labelPlaceholder: 'FAQs',
  },
  {
    heading: 'Link 2',
    name: 'Support',
    labelPlaceholder: 'Support',
    urlPlaceholder: 'www.vidapp.com/support',
  },
];

const FlexRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const SideMenuSettings = () => {
  const { properties, setAppProperty } = useLocalAppProperties();
  const { setAppPropertyToSave } = useSaveContext();
  const { NavBarTitleImage, ColorScheme } = properties;
  const imageInputBackgroundColor = NavBarTitleImage ? (ColorScheme === 'Dark' ? '#000' : '#fefefe') : undefined;

  const handleCheckboxChange = useCallback(
    (checked: boolean, property: string) => {
      setAppProperty(property, checked ? '0' : '1');
      setAppPropertyToSave(property, checked ? '0' : '1');
    },
    [setAppProperty, setAppPropertyToSave],
  );

  const handleHeaderLogoFilenameChange = useCallback(
    (_filename) => {
      setAppProperty('NavBarTitleImage', _filename);
      setAppPropertyToSave('NavBarTitleImage', _filename);
    },
    [setAppProperty, setAppPropertyToSave],
  );

  return (
    <>
      <SettingsSegment
        title="Header Logo"
        tooltip="This logo will only be applied in the new app version, coming in October 2023"
      >
        <SettingsImageInput
          filename={NavBarTitleImage}
          backgroundColor={imageInputBackgroundColor}
          requirementsLabel="PNG. 1400 x 300"
          width="320px"
          height="68px"
          requiredDimensions={{ requiredWidth: 1400, requiredHeight: 300 }}
          acceptedFileTypes="image/png"
          onFilenameChange={handleHeaderLogoFilenameChange}
        />
      </SettingsSegment>
      {sideMenuItems.map(({ name, heading, tooltip, maxLength, labelPlaceholder, urlPlaceholder, hideUrl }) => (
        <SettingsGroup key={name} $marginBottom="24px">
          <FlexRow>
            <GroupTitle title={heading} tooltip={tooltip} />
            <SettingsCheckboxInput
              label={`Display in Side Menu`}
              disabled={name === 'WebsiteSubscription'}
              checked={properties && properties[`HideSidebar${name}`] !== '1'}
              onChange={(e) => handleCheckboxChange(e.target.checked, `HideSidebar${name}`)}
              width="fit-content"
              reverseOrder
            />
          </FlexRow>
          <AppPropertyTextInput
            maxLength={maxLength || SIDE_MENU_MAX}
            placeholder={labelPlaceholder ?? heading}
            property={`Custom${name}Label`}
            disabled={name === 'WebsiteSubscription'}
            $width="100%"
            $marginBottom="16px"
          />
          {!hideUrl && (
            <AppPropertyTextInput
              placeholder={urlPlaceholder ?? 'www.example.com'}
              property={`Sidebar${name}URL`}
              disabled={name === 'WebsiteSubscription'}
              $width="100%"
              $marginBottom="16px"
            />
          )}
        </SettingsGroup>
      ))}
    </>
  );
};
