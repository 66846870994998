import styled from 'styled-components';
import { Input } from 'antd';

import { SearchIcon, XIcon } from 'icons';
import { NEUTRAL_5_COLOUR, NEUTRAL_7_COLOUR, NEUTRAL_9_COLOUR, HIGHLIGHT_PRIMARY_COLOUR } from 'theme';
import { FONT_12PX_MEDIUM } from 'font';

const StyledSearchBar = styled(Input.Search)<{ $width?: string }>`
  #react-app && {
    color: ${NEUTRAL_9_COLOUR};
    border: 1px solid ${NEUTRAL_5_COLOUR};
    border-radius: 6px;
    width: ${({ $width }) => $width || '400px'};
    height: 36px;

    .ant-input-search-button {
      padding-right: 12px;
      padding-left: 8px;
    }

    .ant-input-wrapper,
    .ant-input-affix-wrapper,
    .ant-input-group-addon,
    .ant-input-search-button {
      height: 100%;
    }

    .ant-input-wrapper,
    .ant-input-affix-wrapper {
      border-radius: inherit;
      border: none !important;

      :focus,
      .ant-input-affix-wrapper-focused {
        box-shadow: none !important;
        border: none !important;
      }
    }

    .ant-input-affix-wrapper {
      padding-right: 0;
    }

    input {
      color: ${NEUTRAL_9_COLOUR};

      ::placeholder {
        color: ${NEUTRAL_7_COLOUR};
      }
    }

    .ant-input-prefix {
      color: ${NEUTRAL_7_COLOUR};
      font-size: 16px;
      margin-right: 8px;
    }

    .ant-input-search-button {
      span {
        ${FONT_12PX_MEDIUM};
      }
      color: ${NEUTRAL_9_COLOUR};
      text-shadow: none;
      box-shadow: none;
      background: none;
      border: none;

      :hover {
        color ${HIGHLIGHT_PRIMARY_COLOUR};
      }
    }

    .ant-input-group-addon {
      background: none;
    }

    .ant-input-clear-icon {
      font-size: 19px;
      display: flex;
      align-items: center;
    }
  }
`;

const StyledXIcon = styled(XIcon)`
  #react-app && {
    font-size: 19px;
    color: ${NEUTRAL_7_COLOUR};
    transition: 0.3s color;

    :hover {
      color: ${HIGHLIGHT_PRIMARY_COLOUR};
    }
  }
`;

interface SearchBarProps {
  onSearch: (value: string) => void;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  placeholder?: string;
  value?: string;
  defaultValue?: string;
  $width?: string;
}

export const SearchBar = ({ placeholder, ...props }: SearchBarProps) => {
  return (
    <StyledSearchBar
      placeholder={placeholder ?? 'Search...'}
      prefix={<SearchIcon />}
      enterButton="Search"
      allowClear={{ clearIcon: <StyledXIcon /> }}
      {...props}
    />
  );
};
