import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { PageContainer } from 'components';
import { useContent, useLocalAppProperties } from 'providers';

import { BuildNav } from '..';
import { BuildMyAppMockup, BuildMyAppSaveButton } from '../components';
import { useBuildNav } from '../providers';

export const BuildMyAppInternal = () => {
  const { activeTabs, inactiveTabs } = useBuildNav();
  const { collections, videos } = useContent();
  const { properties } = useLocalAppProperties();
  const history = useHistory();
  const location = useLocation();
  const [tabId, setTabId] = useState<string | number>();

  useEffect(() => {
    const endOfPathname = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);

    if (endOfPathname.includes('TempTab')) {
      setTabId(endOfPathname);
    } else {
      const clickedTabId = parseInt(endOfPathname);

      if (isNaN(clickedTabId) || !collections[clickedTabId]) {
        // Build My App has been navigated to, but no tab has been clicked yet - Navigate to the first available tab
        if (activeTabs && activeTabs.length > 0) {
          history.replace(`/build-my-app/${activeTabs[0].TabId}`, { skipUnsavedChangesPrompt: true });
        } else if (inactiveTabs && inactiveTabs.length > 0) {
          history.replace(`/build-my-app/${inactiveTabs[0].TabId}`, { skipUnsavedChangesPrompt: true });
        }
      } else {
        setTabId(clickedTabId);
      }
    }
  }, [location, activeTabs, inactiveTabs]);

  return (
    <PageContainer
      heading="Build My App"
      headingButton={<BuildMyAppSaveButton />}
      isLoading={!collections || !videos || !properties}
      contentMaxWidth={{ small: '458px', large: '556px' }}
      $headingPadding="32px 32px 0"
      $contentPadding="0"
      mockup={<BuildMyAppMockup tabId={tabId} />}
    >
      <BuildNav />
    </PageContainer>
  );
};
