import { useQuery, UseQueryOptions } from 'react-query';
import { AppProperties, getAppProperties, useAxiosInstance } from 'api';
import { useAppBeingEdited } from 'app-context';

export const useAppProperties = (options?: UseQueryOptions) => {
  const client = useAxiosInstance();
  const appId = useAppBeingEdited();
  return useQuery<AppProperties>(['appProperties', appId], () => getAppProperties(client, appId), {
    staleTime: options?.staleTime ?? 30000,
    refetchOnMount: options?.refetchOnMount ?? true,
    enabled: !!appId, // Don't call fetcher with empty AppId
  });
};
