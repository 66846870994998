import { createContext, Dispatch, ReactNode, SetStateAction, useContext } from 'react';
import { Product, ProductItem } from 'api';

export interface BuilderProduct extends Omit<Product, 'Id'> {
  Id: string | number;
}

export type BuilderProducts = Map<number | string, BuilderProduct>;

interface ContextValue {
  products?: BuilderProducts;
  setProducts: Dispatch<SetStateAction<BuilderProducts>>;
  activeProductId: string | number | undefined;
  setActiveProductId: Dispatch<SetStateAction<string | number | undefined>>;
  setProductValue: (productId: number | string, name: keyof Product, value: string | number) => void;
  setProductItems: (productId: number | string, items: ProductItem[]) => void;
}

const defaultFunction = () => {
  console.warn('Unexpected function call local-products-provider');
};

const LocalProductsContext = createContext<ContextValue>({
  setProducts: defaultFunction,
  products: new Map(),
  activeProductId: undefined,
  setActiveProductId: defaultFunction,
  setProductValue: defaultFunction,
  setProductItems: defaultFunction,
});

interface ProviderProps extends Omit<ContextValue, 'setProductValue' | 'setProductItems'> {
  children: ReactNode;
}

const LocalProductsProvider = ({
  products,
  setProducts,
  activeProductId,
  setActiveProductId,
  children,
}: ProviderProps) => {
  const setProductValue = (productId: number | string, name: keyof Product, value: string | number) => {
    setProducts((prevState) => {
      const newProducts = new Map(prevState);
      const newProduct = newProducts.get(productId);
      if (newProduct) {
        newProducts.set(productId, { ...newProduct, [name]: value });
      }
      return newProducts;
    });
  };

  const setProductItems = (productId: number | string, items: ProductItem[]) => {
    setProducts((prevState) => {
      const newProducts = new Map(prevState);
      const newProduct = newProducts.get(productId);
      if (newProduct) {
        newProducts.set(productId, { ...newProduct, Items: [...items] });
      }
      return newProducts;
    });
  };

  return (
    <LocalProductsContext.Provider
      value={{
        products,
        setProducts,
        activeProductId,
        setActiveProductId,
        setProductValue,
        setProductItems,
      }}
    >
      {children}
    </LocalProductsContext.Provider>
  );
};

const useLocalProducts = () => {
  const context = useContext(LocalProductsContext);
  if (context === undefined) {
    throw new Error('useLocalProducts must be used within a LocalProductsProvider');
  }
  return context;
};

export { LocalProductsProvider, useLocalProducts };
