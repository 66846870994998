import { Form, Input } from 'antd';
import { useMemo, useState } from 'react';
import ReactJson from 'react-json-view';
import styled from 'styled-components';

import { Action } from 'api';
import { CustomButton, CustomCell, CustomTable2, PageContainer, TableColumn, SearchBar } from 'components';
import { useRecordAction } from 'hooks';
import { PAGE_CONTAINER_WIDE_WIDTH } from 'theme';

import { FONT_14PX_MEDIUM, OVERFLOW_ELLIPSIS } from 'font';
import { useActionLog } from './hooks/useActionLog';

const Ellipsis = styled.div`
  ${OVERFLOW_ELLIPSIS};
  ${FONT_14PX_MEDIUM};
`;

const Spacer = styled.div`
  height: 20px;
`;

const StyledForm = styled(Form)`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin-top: 24px;
`;

const StyledFormItem = styled(Form.Item)`
  width: 100%;
  line-height: 18px;
  height: 36px;
`;

const Wrapper = styled.div`
  height: 100%;
  padding: 5px;

  .react-json-view {
    height: 100%;
  }
`;

type FormValues = { Action: string };

export const ActionLog = () => {
  const { data: actions, isLoading } = useActionLog();
  const [searchTerm, setSearchTerm] = useState<string>();
  const recordAction = useRecordAction();
  const [form] = Form.useForm();

  const filteredActions = useMemo(() => {
    if (!searchTerm) {
      return actions;
    }

    return actions?.filter((action) => {
      if (action.Email.toLowerCase().includes(searchTerm.toLowerCase())) {
        return true;
      } else if (action.Action.toLowerCase().includes(searchTerm.toLowerCase())) {
        return true;
      }
      return false;
    });
  }, [actions, searchTerm]);

  const onFinish = (values: FormValues) => {
    if (values.Action) {
      recordAction.mutate({ action: `[ADMIN]: ${values.Action}` });
    }
  };

  const columns: TableColumn<Action & { id: string }>[] = [
    {
      heading: 'Time (NZST)',
      width: 200,
      render: ({ Time }) => (
        <CustomCell>
          <Ellipsis>{Time}</Ellipsis>
        </CustomCell>
      ),
    },
    {
      heading: 'Email',
      width: 250,
      render: ({ Email }) => (
        <CustomCell>
          <Ellipsis>{Email}</Ellipsis>
        </CustomCell>
      ),
    },
    {
      heading: 'Action',
      width: 200,
      render: ({ Action }) => (
        <CustomCell>
          <Ellipsis>{Action}</Ellipsis>
        </CustomCell>
      ),
    },
    {
      heading: 'Details',
      width: 'grow',
      render: function LogDetails(details) {
        return details && Object.keys(details).length ? (
          <Wrapper>
            <ReactJson
              name=""
              collapsed
              src={details}
              theme="paraiso"
              indentWidth={2}
              iconStyle="square"
              style={{ padding: '6px' }}
              displayDataTypes={false}
              quotesOnKeys={false}
              displayObjectSize={false}
            />
          </Wrapper>
        ) : null;
      },
    },
  ];

  return (
    <PageContainer
      heading="Builder Action Log"
      subheading="Recent actions that have occurred in this app"
      isLoading={isLoading}
      contentMaxWidth={PAGE_CONTAINER_WIDE_WIDTH}
    >
      <SearchBar placeholder="Search actions..." onSearch={(term) => setSearchTerm(term)} />
      <Spacer />
      <CustomTable2
        columns={columns}
        data={filteredActions?.map((action) => ({ ...action, id: action.ID }))}
        query={searchTerm}
        emptyTitle="No actions"
        emptyDescription=""
        pageSize={20}
      />
      <StyledForm
        form={form}
        onFinish={(values) => {
          onFinish(values as FormValues);
          form.resetFields();
        }}
      >
        <StyledFormItem name="Action" required>
          <Input type="text" placeholder="Add a custom action to the log" />
        </StyledFormItem>
        <Form.Item>
          <CustomButton medium type="submit">
            Submit
          </CustomButton>
        </Form.Item>
      </StyledForm>
    </PageContainer>
  );
};
