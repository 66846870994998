import { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { DragDropContext, Draggable, DropResult, Droppable } from 'react-beautiful-dnd';

import { CustomButton, EmptyContent, SegmentTitle } from 'components';
import { BuilderCollection, useContent, useSaveContext } from 'providers';
import {
  DataSource,
  ITEM_TYPE_VIDEO,
  SOURCE_TYPE_CIRCUIT,
  SOURCE_TYPE_WORKOUT,
  TEXT_IMAGE_TEMPLATE,
  Video,
  VIDEO_TYPE_POST,
  VIDEO_TYPE_REST,
  VIDEO_TYPE_VIDEO,
  WEBSITE_TEMPLATE,
} from 'api';
import { NEUTRAL_5_COLOUR, NEUTRAL_8_COLOUR } from 'theme';
import { ListAltIcon, ListIcon } from 'icons';
import { calculateCollectionItemPositions, getCollectionName, getVideoName, isSectionHeaderItem } from 'utils';

import { AddCircuitButton, AddContentButton, AddRestButton, TreeCollection, TreeRow } from './components';
import { ContentBreadcrumb, useContentNavigationContext } from '../../providers';
import { CMSModal, CircuitConfigModal, ExerciseConfigModal } from '../cms-modal';
import { useModalContext } from '../cms-modal/providers/modal-provider';

const Wrapper = styled.div`
  margin-bottom: 36px;
`;

const TreeHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 8px 0 6px;
`;

const HeaderButtons = styled.div`
  display: flex;
`;

const CollapseButton = styled(CustomButton)`
  &&&& {
    color: ${NEUTRAL_8_COLOUR};
  }
`;

const RowWrapper = styled.div<{ $isRootLevel?: boolean }>`
  position: relative;
  padding: ${({ $isRootLevel }) => ($isRootLevel ? '24px 0' : '10px 0')};

  .tree-row-title-label {
    font-weight: ${({ $isRootLevel }) => ($isRootLevel ? '600' : '500')};
  }
`;

const BorderBottom = styled.div`
  position: absolute;
  width: calc(100% - 12px);
  height: 1px;
  background-color: ${NEUTRAL_5_COLOUR};
  bottom: 0;
  left: 6px;
`;

export interface TreeItem {
  title: string;
  type: string;
  itemId: string | number; // TabId/VideoId
  tabItemId?: string | number;
  sourceId: string;
  breadcrumbs: ContentBreadcrumb[];
  dataSource?: DataSource;
  sourceType?: string;
  items?: TreeItem[];
}

export const renderItems = (settings: {
  items: TreeItem[];
  activeKeys: (string | number)[];
  setActiveKeys: Dispatch<SetStateAction<(string | number)[]>>;
  parentTabId: string | number;
  parentDataSource: string;
  draggable?: boolean;
  handleDragEnd?: (DropResult: DropResult) => void;
  isRootLevel?: boolean;
}) => {
  const { items, activeKeys, setActiveKeys, parentTabId, parentDataSource, draggable, handleDragEnd, isRootLevel } =
    settings;
  if (draggable) {
    return items.map((item, idx) => {
      const draggableId = `$${item.itemId}|${item.tabItemId}`;
      const showBorderBottom = isRootLevel && idx !== items.length - 1;

      return (
        <Draggable draggableId={draggableId} index={idx} key={draggableId}>
          {(provided, snapshot) => {
            return ['collection', 'circuit'].includes(item.type) ? (
              <RowWrapper ref={provided.innerRef} {...provided.draggableProps} $isRootLevel={isRootLevel}>
                <TreeCollection
                  key={item.itemId}
                  collection={item}
                  activeKeys={activeKeys}
                  setActiveKeys={setActiveKeys}
                  parentTabId={parentTabId}
                  parentDataSource={parentDataSource}
                  dragHandleProps={provided.dragHandleProps}
                  handleDragEnd={handleDragEnd}
                  isDragging={snapshot.isDragging}
                />
                {showBorderBottom && !snapshot.isDragging && <BorderBottom />}
              </RowWrapper>
            ) : (
              <RowWrapper ref={provided.innerRef} {...provided.draggableProps} $isRootLevel={isRootLevel}>
                <TreeRow
                  key={item.itemId}
                  item={item}
                  parentTabId={parentTabId}
                  parentDataSource={parentDataSource}
                  setActiveKeys={setActiveKeys}
                  dragHandleProps={provided.dragHandleProps}
                  isDragging={snapshot.isDragging}
                />
                {showBorderBottom && !snapshot.isDragging && <BorderBottom />}
              </RowWrapper>
            );
          }}
        </Draggable>
      );
    });
  } else {
    return items.map((item, idx) => {
      const showBorderBottom = isRootLevel && idx !== items.length - 1;

      if (item.type === 'collection') {
        return (
          <RowWrapper $isRootLevel={isRootLevel} key={item.itemId}>
            <TreeCollection
              collection={item}
              activeKeys={activeKeys}
              setActiveKeys={setActiveKeys}
              parentTabId={parentTabId}
              parentDataSource={parentDataSource}
            />
            {showBorderBottom && <BorderBottom />}
          </RowWrapper>
        );
      } else {
        return (
          <RowWrapper key={item.itemId}>
            <TreeRow
              item={item}
              parentTabId={parentTabId}
              parentDataSource={parentDataSource}
              setActiveKeys={setActiveKeys}
            />
            {showBorderBottom && <BorderBottom />}
          </RowWrapper>
        );
      }
    });
  }
};

interface ContentCollectionTreeProps {
  collection: BuilderCollection;
}

export const ContentCollectionTree = ({ collection }: ContentCollectionTreeProps) => {
  const { setBlockTreeNavigation, collectionIsEditable } = useContentNavigationContext();
  const { collections, setCollections, videos, getContentForItem } = useContent();
  const { setCollectionItemsToSave } = useSaveContext();
  const [treeKeys, setTreeKeys] = useState<(string | number)[]>([]);
  const [activeKeys, setActiveKeys] = useState<(string | number)[]>([]);
  const { modalCollectionId, modalCircuitId, modalExerciseId } = useModalContext();

  const treeIsCollapsed = activeKeys.length === 0;

  const mapItems: (collection: BuilderCollection, breadcrumbs: ContentBreadcrumb[]) => TreeItem[] = useCallback(
    (collection, breadcrumbs) => {
      if (!collection || !collection.Items) {
        return [];
      }
      return collection.Items.map((item) => {
        const content = getContentForItem(item);

        if (content) {
          if (item.Type === ITEM_TYPE_VIDEO || isSectionHeaderItem(item)) {
            const video = content as Video;
            if (
              [VIDEO_TYPE_VIDEO, VIDEO_TYPE_POST, VIDEO_TYPE_REST].includes(video.Type) ||
              isSectionHeaderItem(item)
            ) {
              const { Type, VideoId, SourceId, DataSource } = video;
              return {
                title: getVideoName(video),
                type: collection.SourceType === SOURCE_TYPE_CIRCUIT ? 'exercise' : Type,
                itemId: VideoId,
                tabItemId: item.TabItemId,
                sourceId: SourceId,
                dataSource: DataSource,
                breadcrumbs: [...breadcrumbs, { id: VideoId, label: getVideoName(video) }],
              };
            }
            return { title: 'null', type: 'null', itemId: 'null', sourceId: 'null', breadcrumbs: [] };
          } else {
            const collection = content as BuilderCollection;
            const { TemplateId, TabId, SourceId, DataSource, SourceType } = collection;

            // Don't set tree keys for workouts/website/textimage as these are treated as items
            if (SourceType !== SOURCE_TYPE_WORKOUT && ![WEBSITE_TEMPLATE, TEXT_IMAGE_TEMPLATE].includes(TemplateId)) {
              setTreeKeys((prevState) => {
                const newState = [...prevState];
                if (!newState.includes(TabId)) {
                  return [...newState, TabId];
                }
                return newState;
              });
            }
            if (typeof collection.TabId === 'string' && collection.TabId.includes('Temp')) {
              setBlockTreeNavigation(true);
            }
            return {
              title: getCollectionName(collection),
              type:
                SourceType === SOURCE_TYPE_WORKOUT
                  ? 'workout'
                  : SourceType === SOURCE_TYPE_CIRCUIT
                  ? 'circuit'
                  : TemplateId === WEBSITE_TEMPLATE
                  ? 'website'
                  : TemplateId === TEXT_IMAGE_TEMPLATE
                  ? 'textimage'
                  : 'collection',
              itemId: TabId,
              tabItemId: item.TabItemId,
              sourceId: SourceId,
              dataSource: DataSource,
              sourceType: SourceType,
              breadcrumbs: [...breadcrumbs, { id: TabId, label: getCollectionName(collection) }],
              items: mapItems(collection, [...breadcrumbs, { id: TabId, label: getCollectionName(collection) }]),
            };
          }
        }
        return { title: 'null', type: 'null', itemId: 'null', sourceId: 'null', breadcrumbs: [] };
      }).filter((item) => item.type !== 'null');
    },
    [getContentForItem, setTreeKeys, setBlockTreeNavigation],
  );

  const handleDragEnd = ({ destination, source, draggableId }: DropResult) => {
    if (destination?.droppableId === source.droppableId && destination?.index === source.index) {
      return; // Item has not moved
    }

    if (destination) {
      // DroppableId is the TabId of the tab being rearranged
      // use suffix separated by | to avoid overlapping droppable ids which may cause issues
      const parentTabId = destination.droppableId.split('|')[1];
      /*
       * DraggableId -> CollectionId|TabItemId
       */
      const tabItemId = draggableId.split('|')[1];
      const newCollections = { ...collections };
      const parsedCurrentTabId = parentTabId.startsWith('Temp') ? parentTabId : Number.parseInt(parentTabId);
      const newCollection = newCollections[parsedCurrentTabId];

      if (!newCollection) {
        console.warn(`Failed to drag and drop couldn't find the Tab with id: ${parentTabId}`);
        return;
      }

      const sourceItem = newCollection.Items.find((item) => item.TabItemId?.toString() === tabItemId);
      newCollection.Items = newCollection.Items.filter((i) => !!collections[i.ChildId] || !!videos[i.ChildId]);
      if (sourceItem) {
        newCollection.Items.splice(source.index, 1);
        newCollection.Items.splice(destination.index, 0, sourceItem);
        console.debug('ContentCollectionTree', 'Rearranging content', {
          TabId: newCollection.TabId,
          Items: newCollection.Items,
        });
      } else {
        console.warn(`Failed to drag and drop couldn't find TabItem with TabItemId: ${tabItemId}`);
        return;
      }
      // Update positions based on new items
      calculateCollectionItemPositions(newCollection.Items);
      newCollection.Items = [...newCollection.Items]; // Trigger dependent updates
      setCollections(newCollections);
      setCollectionItemsToSave(parentTabId);
    }
  };

  const items: TreeItem[] = useMemo(() => mapItems(collection, []), [collection, mapItems]);

  const isEditable = collectionIsEditable(collection.DataSource, collection.SourceType);

  const collectionId = collection.TabId;

  return items.length > 0 || isEditable ? (
    <Wrapper>
      <TreeHeader>
        <SegmentTitle title="Content" $marginBottom="0" />
        <HeaderButtons>
          {treeKeys.length > 0 && (
            <CollapseButton
              icon={treeIsCollapsed ? <ListAltIcon /> : <ListIcon />}
              medium
              tertiary
              onClick={() => setActiveKeys(treeIsCollapsed ? treeKeys : [])}
            >
              {`${treeIsCollapsed ? 'Expand' : 'Collapse'} All`}
            </CollapseButton>
          )}
          {isEditable && (
            <>
              {collection.SourceType === SOURCE_TYPE_WORKOUT ? (
                <>
                  <AddCircuitButton collectionId={collectionId} />
                  <AddRestButton collectionId={collectionId} />
                </>
              ) : (
                <AddContentButton collectionId={collectionId} />
              )}
            </>
          )}
        </HeaderButtons>
      </TreeHeader>
      {items.length === 0 ? (
        <EmptyContent />
      ) : isEditable ? (
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId={`root|${collectionId}`}>
            {(provided) => {
              return (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {renderItems({
                    items,
                    activeKeys,
                    setActiveKeys,
                    parentTabId: collectionId,
                    parentDataSource: collection.DataSource,
                    draggable: isEditable,
                    handleDragEnd,
                    isRootLevel: true,
                  })}
                  {provided.placeholder}
                </div>
              );
            }}
          </Droppable>
        </DragDropContext>
      ) : (
        renderItems({
          items,
          activeKeys,
          setActiveKeys,
          parentTabId: collectionId,
          parentDataSource: collection.DataSource,
          isRootLevel: true,
        })
      )}
      {modalCollectionId && <CMSModal />}
      {modalCircuitId && <CircuitConfigModal />}
      {modalExerciseId && <ExerciseConfigModal />}
    </Wrapper>
  ) : null;
};
