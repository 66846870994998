import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useCollections } from 'hooks';
import styled from 'styled-components';
import {
  DataSourceProvider,
  DefaultAppPropertiesProvider,
  DefaultContentProvider,
  SaveProvider,
  useDataSource,
} from 'providers';

import { MockupContextProvider, PageContext } from './mockup/providers';
import { BuilderInternal } from './BuilderInternal';
import { BuilderContextProvider } from './providers';
import { SOURCE_VHX } from 'api';
import { NEUTRAL_3_COLOUR } from 'theme';

const PageWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: ${NEUTRAL_3_COLOUR};
`;

const BuilderPopupWrapper = styled.div`
  .ant-popover-arrow {
    display: none !important;
  }

  &&&& .ant-popover-inner {
    border-radius: 8px;
    padding: 20px;
  }
`;

export const Builder = () => {
  const { tabId } = useParams<{ tabId: string }>();
  const dataSource = useDataSource();

  // What main tab am I looking at, or contains the page I am looking at
  const [currentTabId, setCurrentTabId] = useState<string | number>('');
  // What page is the mockup looking at, undefined will just display the current tab
  const [currentPage, setCurrentPage] = useState<PageContext | undefined>(undefined);

  // refetchOnMount:true, assume not used in conjunction with Build Advanced
  const { data: collectionData } = useCollections(Infinity, true);

  useEffect(() => {
    if (tabId && tabId !== '0') {
      // Using TabId from route
      setCurrentTabId(tabId);
    } else {
      // Fallback to first Main Tab
      if (collectionData) {
        const collectionArray = Object.values(collectionData.collection_by_id);
        const mainTabs = collectionArray
          .filter((c) => (c.Position || 0) > 0)
          .sort((a, b) => {
            if (a.IsMainTab === b.IsMainTab) {
              return (a.Position || 0) - (b.Position || 0);
            }
            return (b.IsMainTab || 0) - (a.IsMainTab || 0);
          });
        const firstTabId = mainTabs?.find((collection) => {
          if ([30, 22].includes(collection.TemplateId)) {
            if (dataSource === SOURCE_VHX) {
              if (collection?.SourceId !== 'Content1') {
                // Open Mixed/SubNav tabs except the Netflix Template
                return true;
              }
            } else {
              // Open any Mixed/SubNav tab
              return true;
            }
          }
          return false;
        })?.TabId;
        if (firstTabId) {
          setCurrentTabId(firstTabId);
        }
      }
    }
  }, [!!collectionData, tabId]);

  return (
    <PageWrapper>
      <DataSourceProvider>
        <DefaultContentProvider>
          <SaveProvider resetCacheOnSave>
            <DefaultAppPropertiesProvider>
              <MockupContextProvider
                currentTabId={currentTabId}
                setCurrentTabId={setCurrentTabId}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
                navigationEnabled={false}
              >
                <BuilderContextProvider>
                  <BuilderInternal />
                  {/* This wrapper is for popovers to be injected into while still being able to override antd styles*/}
                  <BuilderPopupWrapper id="builder-popups" />
                </BuilderContextProvider>
              </MockupContextProvider>
            </DefaultAppPropertiesProvider>
          </SaveProvider>
        </DefaultContentProvider>
      </DataSourceProvider>
    </PageWrapper>
  );
};
