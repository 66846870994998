import { useMemo, useState } from 'react';
import styled from 'styled-components';
import { Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';

import {
  ClickToCopy,
  CustomButton,
  EmptyContent,
  HighlightText,
  InfoModal,
  SettingsCheckboxInput,
  styledTable,
  Thumbnail,
} from 'components';
import { BuilderCollection } from 'providers';
import { DataSource, SOURCE_KAJABI, SOURCE_TYPE_KAJABI_PRODUCT, SOURCE_VIDAPP, Video } from 'api';
import {
  HIGHLIGHT_PRIMARY_COLOUR,
  NEUTRAL_10_COLOUR,
  NEUTRAL_3_COLOUR,
  NEUTRAL_5_COLOUR,
  NEUTRAL_7_COLOUR,
} from 'theme';
import { FONT_12PX_MEDIUM, FONT_12PX_SEMIBOLD, FONT_14PX_MEDIUM, OVERFLOW_ELLIPSIS } from 'font';
import { UISyncIcon } from 'icons';
import { useSyncKajabiProduct } from 'hooks';
import {
  collectionIsProtected,
  DEFAULT_THUMBNAIL,
  formatNiceDate,
  getCollectionSourceName,
  getContentThumbnailUrl,
  getDataSourceDisplayName,
  getVideoSourceTitle,
} from 'utils';
import { useAppBeingEdited } from 'app-context';

import { DeleteCollectionButton } from '../../components';
import { useContentNavigationContext } from '../../../providers';
import { useBanner } from 'providers/banner-provider';

const defaultStyledTable = styledTable<ContentItem>(Table);

const StyledTable = styled(defaultStyledTable)`
  #react-app && .ant-table {
    border: none;
    border-radius: 8px;

    table {
      position: relative;
      min-width: unset !important;
      margin-right: 32px;
    }

    td {
      transition: none;
    }

    .ant-table-container::after {
      box-shadow: none !important;
    }

    tr:not(.ant-table-placeholder) {
      cursor: pointer;
    }

    tr,
    td {
      height: 56px;
    }

    tr:first-child,
    th {
      height: 40px;
    }

    tr:not(.ant-table-placeholder):hover {
      td {
        background-color: ${NEUTRAL_3_COLOUR} !important;
      }
    }

    /* Delete column */

    td:nth-child(5) {
      padding: 0;
    }

    /* Sync column */

    td:nth-child(6) {
      padding: 0;
    }

    th {
      color: ${NEUTRAL_10_COLOUR};
      background-color: ${NEUTRAL_3_COLOUR} !important;
      ${FONT_12PX_SEMIBOLD};
      padding-left: 12px;
      border-bottom: none;
      cursor: default;
    }
  }
`;

const TableContainer = styled.div`
  position: relative;
  margin-top: 20px;
`;

const TitleCell = styled.span`
  display: flex;
  align-items: center;
  width: 100%;
`;

const TitleWrapper = styled.div`
  span {
    ${FONT_14PX_MEDIUM};
  }

  ${OVERFLOW_ELLIPSIS};
`;

const DeleteWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DateWrapper = styled.div`
  ${FONT_12PX_MEDIUM};
  ${OVERFLOW_ELLIPSIS};
`;

const SourceIdWrapper = styled.div`
  span {
    ${FONT_12PX_MEDIUM};
  }

  ${OVERFLOW_ELLIPSIS};

  :hover {
    color: ${NEUTRAL_7_COLOUR};
  }
`;

const ItemThumbnail = styled(Thumbnail)`
  margin-right: 8px;
`;

const SyncWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SyncButton = styled(CustomButton)`
  #react-app && {
    color: ${HIGHLIGHT_PRIMARY_COLOUR};

    :hover {
      background-color: ${NEUTRAL_5_COLOUR};
    }
  }
`;

const AdminFilters = styled.div`
  display: flex;
  position: absolute;
  top: -115px;
  right: 0;
`;

export interface ContentItem {
  sourceTitle: string;
  title: string;
  sourceId: string;
  itemId: string | number;
  key: string | number;
  thumbnail: string | null;
  dataSource: DataSource;
  sourceType?: string;
  kajabiDataThemeId?: number;
  type: 'collection' | 'video';
  includedInAppData?: boolean;
  createdAt: string;
}

interface ContentScreenTableProps {
  searchValue: string;
  collections?: BuilderCollection[];
  videos?: Video[];
  showSync?: boolean;
  showAdminFilters?: boolean;
}

export const ContentScreenTable = ({
  searchValue,
  collections,
  videos,
  showSync,
  showAdminFilters,
}: ContentScreenTableProps) => {
  const { page, pageSize, setPage, setPageSize, goToItem } = useContentNavigationContext();
  const syncKajabiProduct = useSyncKajabiProduct({ isOnboarding: false, syncToApp: true });
  const appId = useAppBeingEdited();
  const { setRestartBanner } = useBanner();
  const [showIncludedInApp, setShowIncludedInApp] = useState(true);
  const [showNotIncludedInApp, setShowNotIncludedInApp] = useState(true);

  const tableDataSource = collections ? collections[0]?.DataSource : videos ? videos[0]?.DataSource : undefined;
  const showDeleteColumn = !!collections && (tableDataSource === SOURCE_KAJABI || tableDataSource === SOURCE_VIDAPP);

  const columns: ColumnsType<ContentItem> = useMemo(() => {
    const sourceLabel = tableDataSource ? getDataSourceDisplayName(tableDataSource) : 'Source';
    const arr: ColumnsType<ContentItem> = [
      {
        title: `${sourceLabel} Title`,
        dataIndex: 'sourceTitle',
        key: 'sourceTitle',
        width: tableDataSource === SOURCE_VIDAPP ? 0 : undefined,
        ellipsis: true,
        render: (sourceTitle, { thumbnail }) => (
          <TitleCell>
            <ItemThumbnail
              url={thumbnail}
              width="43px"
              height="24px"
              border={thumbnail && thumbnail !== DEFAULT_THUMBNAIL ? 'none' : undefined}
              borderRadius="2px"
            />
            <TitleWrapper>
              <HighlightText text={sourceTitle} highlight={searchValue} />
            </TitleWrapper>
          </TitleCell>
        ),
      },
      {
        title: `VidApp Title`,
        dataIndex: 'title',
        key: 'title',
        ellipsis: true,
        render: (title, { sourceTitle, thumbnail }) => (
          <TitleCell>
            {tableDataSource === SOURCE_VIDAPP && (
              <ItemThumbnail
                url={thumbnail}
                width="43px"
                height="24px"
                border={thumbnail && thumbnail !== DEFAULT_THUMBNAIL ? 'none' : undefined}
                borderRadius="2px"
              />
            )}
            <TitleWrapper>
              <HighlightText text={title !== '' ? title : sourceTitle} highlight={searchValue} />
            </TitleWrapper>
          </TitleCell>
        ),
      },
      {
        title: tableDataSource === SOURCE_VIDAPP ? 'Created At' : 'First Synced',
        dataIndex: 'createdAt',
        key: 'createdAt',
        width: 120,
        render: (createdAt) => <DateWrapper>{formatNiceDate(new Date(createdAt))}</DateWrapper>,
      },
      {
        title: 'Source ID',
        dataIndex: 'sourceId',
        key: 'sourceId',
        width: 100,
        ellipsis: true,
        render: (sourceId) => (
          <ClickToCopy copyValue={sourceId}>
            <SourceIdWrapper>
              <HighlightText text={sourceId} highlight={searchValue} />
            </SourceIdWrapper>
          </ClickToCopy>
        ),
      },
    ];

    if (showDeleteColumn) {
      arr.push({
        dataIndex: 'delete',
        key: 'delete',
        width: 30,
        render: (_, { itemId, dataSource, sourceType, sourceId }) => {
          const showDeleteButton =
            collections &&
            !collectionIsProtected(sourceId) &&
            ((dataSource === SOURCE_KAJABI && sourceType === 'Product') || dataSource === SOURCE_VIDAPP);
          return (
            <DeleteWrapper onClick={(e) => e.stopPropagation()}>
              {showDeleteButton && (
                <DeleteCollectionButton
                  tabId={itemId as number}
                  sourceType={sourceType}
                  dataSource={dataSource}
                  tableStyle
                />
              )}
            </DeleteWrapper>
          );
        },
      });
    }

    if (showSync) {
      arr.push({
        dataIndex: 'sync',
        key: 'sync',
        width: 79,
        ellipsis: true,
        render: (_, { sourceType, sourceId, kajabiDataThemeId }) =>
          sourceType === SOURCE_TYPE_KAJABI_PRODUCT ? (
            <SyncWrapper>
              <SyncButton
                tertiary
                small
                icon={<UISyncIcon />}
                onClick={(e) => {
                  e.stopPropagation();
                  syncKajabiProduct.mutate({ productId: sourceId, themeId: kajabiDataThemeId });
                  setTimeout(() => {
                    setRestartBanner(true);
                  }, 3000);

                  InfoModal(
                    `Your ${SOURCE_KAJABI} course is being synced`,
                    "You'll receive an email once this is complete",
                    'success',
                  );
                }}
              >
                Sync
              </SyncButton>
            </SyncWrapper>
          ) : null,
      });
    }

    return arr;
  }, [videos, collections, showSync, showDeleteColumn, tableDataSource, collectionIsProtected]);

  const dataSource: ContentItem[] = useMemo(() => {
    const collectionsArr: ContentItem[] = collections
      ? collections.map((item) => ({
          sourceTitle: getCollectionSourceName(item),
          title: item.Name,
          sourceId: item.SourceId,
          itemId: item.TabId,
          key: item.TabId,
          thumbnail: getContentThumbnailUrl(appId, item, 'small'),
          dataSource: item.DataSource,
          sourceType: item.SourceType,
          kajabiDataThemeId: item.KajabiDataThemeId,
          type: 'collection',
          includedInAppData: item.IncludedInAppData === 1,
          createdAt: item.CreatedAt ?? '2000-00-00T00:00:00',
        }))
      : [];

    const videosArr: ContentItem[] = videos
      ? videos.map((item) => {
          return {
            sourceTitle: getVideoSourceTitle(item),
            title: item.Title,
            sourceId: item.SourceId,
            itemId: item.VideoId,
            key: item.VideoId,
            thumbnail: getContentThumbnailUrl(appId, item, 'small'),
            dataSource: item.DataSource,
            type: 'video',
            createdAt: item.CreatedAt ?? '2000-00-00T00:00:00',
          };
        })
      : [];

    if (collections && videos) {
      // This is an 'All' tab, so combine collections and videos then sort them alphabetically by sourceTitle
      const joinedArr = collectionsArr.concat(videosArr).sort((a, b) => b.createdAt.localeCompare(a.createdAt));

      // Kajabi 'All' tab displays products first
      if (tableDataSource === SOURCE_KAJABI) {
        const products: ContentItem[] = [];
        const others: ContentItem[] = [];
        joinedArr.forEach((item) => {
          if (item.sourceType === SOURCE_TYPE_KAJABI_PRODUCT) {
            products.push(item);
          } else {
            others.push(item);
          }
        });

        return [...products, ...others];
      }

      return joinedArr;
    }

    return collections ? collectionsArr : videosArr;
  }, [
    collections,
    videos,
    appId,
    tableDataSource,
    getCollectionSourceName,
    getVideoSourceTitle,
    getContentThumbnailUrl,
  ]);

  const inAppData = useMemo(() => {
    const included: ContentItem[] = [];
    const notIncluded: ContentItem[] = [];

    if (showAdminFilters) {
      dataSource.forEach((item) => {
        if (item.includedInAppData) {
          included.push(item);
        } else {
          notIncluded.push(item);
        }
      });
    }

    return { included, notIncluded };
  }, [dataSource]);

  return (
    <TableContainer>
      {showAdminFilters && (
        <AdminFilters>
          <SettingsCheckboxInput
            label={`Included in app (${inAppData.included.length})`}
            checked={showIncludedInApp}
            onChange={(e) => setShowIncludedInApp(e.target.checked)}
            width="fit-content"
            reverseOrder
            margin="0 10px 0 0"
          />
          <SettingsCheckboxInput
            label={`Not included in app (${inAppData.notIncluded.length})`}
            checked={showNotIncludedInApp}
            onChange={(e) => setShowNotIncludedInApp(e.target.checked)}
            width="fit-content"
            reverseOrder
          />
        </AdminFilters>
      )}
      <StyledTable
        dataSource={
          !showAdminFilters || (showIncludedInApp && showNotIncludedInApp)
            ? dataSource
            : showIncludedInApp
            ? inAppData.included
            : showNotIncludedInApp
            ? inAppData.notIncluded
            : []
        }
        onRow={({ type, itemId, sourceTitle }) => ({
          onClick: () => {
            goToItem(type, itemId, {
              breadcrumbs: [{ id: itemId, label: sourceTitle }],
              appendToExisting: true,
            });
          },
        })}
        columns={columns}
        locale={{
          emptyText: (
            <EmptyContent
              title={searchValue !== '' ? `No results found for "${searchValue}"` : undefined}
              description={searchValue !== '' ? 'Check the spelling or try a different search term.' : undefined}
              $width={searchValue !== '' ? '270px' : undefined}
              $height="390px"
            />
          ),
        }}
        pagination={{
          showSizeChanger: true,
          defaultPageSize: pageSize,
          pageSizeOptions: [25, 50, 100],
          defaultCurrent: page,
          onChange: (page, size) => {
            setPage(page);
            setPageSize(size);
          },
        }}
      />
    </TableContainer>
  );
};
