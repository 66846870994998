import { useCallback } from 'react';
import styled from 'styled-components';

import { SettingsTextArea } from 'components';
import { useLocalAppProperties, useSaveContext } from 'providers';

const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 8px;
`;

interface AppPropertyTextAreaInputProps {
  label?: string;
  placeholder?: string;
  property: string;
  rows?: number;
  maxLength?: number;
  onChange?: () => void;
}

export const AppPropertyTextAreaInput = ({
  label,
  placeholder,
  property,
  rows,
  maxLength,
  onChange,
}: AppPropertyTextAreaInputProps) => {
  const { properties, setAppProperty } = useLocalAppProperties();
  const { setAppPropertyToSave } = useSaveContext();

  const handlePropertyChange = useCallback(
    (property: string, value: string) => {
      setAppProperty(property, value);
      setAppPropertyToSave(property, value);
    },
    [property, setAppProperty, setAppPropertyToSave],
  );

  return (
    <Wrapper>
      <SettingsTextArea
        value={properties[property]}
        onChange={(e) => {
          onChange && onChange();
          handlePropertyChange(property, e.target.value);
        }}
        label={label}
        placeholder={placeholder ?? label}
        rows={rows}
        maxLength={maxLength}
      />
    </Wrapper>
  );
};
