import { CollectionTVSettings, Segment, SettingsCheckboxInput } from 'components';
import { BuilderCollection, useLocalAppProperties } from 'providers';

import { DrawerSeparator } from '../TabDrawers/components/DrawerSeparator';

interface DisplayLogicProps {
  tab: BuilderCollection;
  children?: JSX.Element;
  readonly?: boolean;
}

export const DisplayLogic = ({ tab, children, readonly }: DisplayLogicProps) => {
  const { properties } = useLocalAppProperties();

  const displayTV =
    properties.DisplayAppleTV === 'Yes' || properties.DisplayRoku === 'Yes' || properties.DisplayAndroidTV === '1';

  return (
    <>
      <DrawerSeparator />
      <Segment header="Display Logic">
        {children}
        <SettingsCheckboxInput
          label="Lock tab with a sign-in screen if the user is not logged in."
          name="LockedUntilSignIn"
          margin="5px 0 0 0"
          disabled={readonly}
        />
      </Segment>
      {displayTV && <CollectionTVSettings collection={tab} />}
    </>
  );
};
