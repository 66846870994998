import jwtDecode from 'jwt-decode';
import { VidAppJWT } from 'api';

export * from './dispatchCustomEvent';
export * from './FileUtils/file-utils';
export * from './DateUtils/DateUtils';
export * from './ColorUtils/color-utils';
export * from './validation-utils';
export * from './tab-util';
export * from './collection-util';
export * from './content-util';
export * from './video-util';
export * from './html-util';
export * from './style-util';
export * from './community-util';
export * from './route-util';

export const VIDAPP_FILES_DOMAIN = 'https://files.vidapp.com';
export const BUILDER_IMAGES_DOMAIN = `${VIDAPP_FILES_DOMAIN}/images/builder`;
export const DEFAULT_THUMBNAIL = `${BUILDER_IMAGES_DOMAIN}/misc/default_thumbnail.png`;
export const DEFAULT_THUMBNAIL_SMALL = `${BUILDER_IMAGES_DOMAIN}/misc/default_thumbnail_small.png`;
export const DEFAULT_SQUARE_THUMBNAIL = `${BUILDER_IMAGES_DOMAIN}/misc/default_square.png`;
export const DEFAULT_PORTRAIT_THUMBNAIL = `${BUILDER_IMAGES_DOMAIN}/misc/default_portrait.png`;

export const isUserAdmin = (options?: { overrideImpersonation?: boolean }) => {
  try {
    const isImpersonating = localStorage.getItem('ImpersonationMode');
    if (!options?.overrideImpersonation && isImpersonating) {
      return false;
    }

    const jwt = localStorage.getItem('jwt');
    if (jwt) {
      return jwtDecode<VidAppJWT>(jwt)?.admin == true;
    }
  } catch (e) {
    console.error('Error decoding JWT token');
  }
  return false;
};
export const isUserDev = () => {
  // Useful debug for devs that we don't have to explain to anyone
  // Set DevMode in local storage to enable
  const devmode = localStorage.getItem('DevMode');
  if (devmode) {
    return devmode;
  }
  return false;
};

export const getUserEmail = () => {
  return localStorage.getItem('user_email') ?? '';
};

export const getUserName = () => {
  return localStorage.getItem('user_name');
};

export const getAppBeingEdited = () => {
  return localStorage.getItem('appBeingEdited');
};

export const pad2 = (c: string | number) => {
  c = c.toString();
  return c.length == 1 ? '0' + c : '' + c;
};
export const padStart = (c: string | number, desiredLength: number) => {
  c = c.toString();
  while (c.length < desiredLength) {
    c = '0' + c;
  }
  return c;
};

export const isHttps = (string: string) => string.toLowerCase().startsWith('https://');

export const isUrl = (string: string) => string.toLowerCase().startsWith('http://') || isHttps(string);

export const sortByArray = (a: unknown, b: unknown, array: unknown[]) => {
  const indexA = array.indexOf(a);
  const indexB = array.indexOf(b);

  if (indexA === -1 && indexB === -1) {
    return 0;
  } else if (indexA === -1) {
    return 1;
  } else if (indexB === -1) {
    return -1;
  } else {
    return indexA - indexB;
  }
};
