import { useMutation, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';

import { updateVideo, useAxiosInstance, VideoToUpdate } from 'api';
import { useAppBeingEdited } from 'app-context';
import { useRecordAction } from 'hooks';

export const useUpdateVideo = (invalidateQuery = true, refresh = true) => {
  const appId = useAppBeingEdited();
  const client = useAxiosInstance();
  const queryClient = useQueryClient();
  const recordAction = useRecordAction();

  return useMutation<unknown, AxiosError, VideoToUpdate>(
    (videoToUpdate) => {
      return updateVideo(client, appId, videoToUpdate, refresh);
    },
    {
      onSuccess: async (_response, payload) => {
        if (invalidateQuery) {
          await queryClient.invalidateQueries(['videos', appId]);
        }
        recordAction.mutate({ action: 'Update Video', detail: { videoToUpdate: payload } });
      },
    },
  );
};
