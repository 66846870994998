import { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { createPopper } from '@popperjs/core';

import { PopperTooltip, SegmentTitle, SettingsTextInput } from 'components';
import { SearchIcon } from 'icons';
import { NEUTRAL_10_COLOUR, NEUTRAL_3_COLOUR, NEUTRAL_7_COLOUR, NEUTRAL_9_COLOUR } from 'theme';
import { BuilderCollection, BuilderCollectionItem, useContent, useSaveContext } from 'providers';
import {
  CMS_SOURCE_TYPES,
  Collection,
  CollectionItemSubType,
  DEFAULT_COLLECTION_ITEM,
  ITEM_TYPE_COLLECTION,
  SOURCE_VIDAPP,
  TAB_LEVEL_PROPERTIES,
} from 'api';
import {
  calculateCollectionItemPositions,
  getCollectionName,
  getSourceTypeDisplayName,
  groupCollectionsBySourceType,
} from 'utils';
import { FONT_12PX_SEMIBOLD } from 'font';

import { DrawerHeading, DrawerScrollContainer } from 'app/modules/build-dragdrop/Builder/drawer/components';
import { useMockupContext } from 'app/modules/build-dragdrop/Builder/mockup';
import { useBuilderContext } from 'app/modules/build-dragdrop/Builder/providers';
import { ContentCard } from 'app/modules/build-dragdrop/Builder/components';

const DrawerFlex = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const SearchWrapper = styled.div`
  margin-left: 28px;
  margin-right: 24px;
`;
const StyledSearch = styled(SettingsTextInput)`
  &&&.ant-input-affix-wrapper {
    margin-bottom: 8px !important;
  }

  flex-shrink: 0;
`;
const StyledSearchIcon = styled(SearchIcon)<{ active?: boolean }>`
  margin-right: 4px;
  font-size: 16px;
  color: ${({ active }) => (active ? NEUTRAL_9_COLOUR : NEUTRAL_7_COLOUR)} !important;
`;

const CollectionGroupWrapper = styled.div`
  margin-bottom: 40px;
  height: fit-content;
  flex-shrink: 0;
`;
const CollectionWrapper = styled.div`
  padding: 8px;

  width: calc(100% - 4px);
  border-radius: 12px;

  display: flex;
  justify-content: left;
  align-items: center;
  cursor: pointer;

  :hover {
    background-color: ${NEUTRAL_3_COLOUR};
  }
`;

const TitleWrapper = styled.div`
  padding-left: 8px;
`;

const SUBTAB_DEFAULT_ITEM = {
  ...DEFAULT_COLLECTION_ITEM,
  // Currently we only add collection sections
  Type: ITEM_TYPE_COLLECTION,
  SubType: ITEM_TYPE_COLLECTION as CollectionItemSubType,
  // Updated during processing
  ChildId: 0,
  Position: 99,
};

const DrawerSubtitle = styled.div`
  margin: 25px 22px 5px 28px;
  ${FONT_12PX_SEMIBOLD};
  color: ${NEUTRAL_10_COLOUR};
`;

export const CreateLinkedSubTabDrawer = () => {
  const { currentTabId, setCurrentPage, setCurrentTabId } = useMockupContext();
  const {
    getTempId,
    setCollectionItemsToSave,
    setCollectionToDelete,
    setCollectionValueToSave,
    setCollectionPropertyToSave,
  } = useSaveContext();
  const { collections, setCollectionItems, deleteCollection, setCollectionValue, setCollectionProperty } = useContent();
  const { setDrawerContext } = useBuilderContext();

  const [query, setQuery] = useState<string>('');

  // Returns true if the collection is available in the toolbox
  const isValidCollection = (collection: BuilderCollection) => {
    if (collection.DataSource === SOURCE_VIDAPP && !CMS_SOURCE_TYPES.includes(collection.SourceType)) {
      return false;
    } else if (collection.IsMainTab === 1) {
      return false;
    } else if (collection.Published && collection.Published !== 'Published') {
      return false;
    } else if (collection.SourceId.includes('TempTabId')) {
      return false;
    }

    // Filter down by search query (empty query always returns true)
    const lowerQuery = query?.toLowerCase();
    return (
      getCollectionName(collection)?.toLowerCase().includes(lowerQuery) || collection.SourceId.includes(lowerQuery)
    );
  };

  const contentBySourceType = useMemo(() => {
    // Exclude Main Tabs from being available to drag and drop
    // Exclude Draft Content from being available
    const collectionArray = Object.values(collections ?? {})
      .sort((a, b) => a.Name.localeCompare(b.Name))
      .filter((collection: BuilderCollection) => isValidCollection(collection));

    return groupCollectionsBySourceType(collectionArray);
  }, [collections, query]);

  const handleCollection = (childId: string | number) => {
    // Add linked subtab to the current tab
    const newItemId = `TempTabItem${getTempId()}`;

    const newItem = {
      ...SUBTAB_DEFAULT_ITEM,
      TabItemId: newItemId,
      TabId: currentTabId,
      ChildId: childId,
    } as BuilderCollectionItem;

    if (!currentTabId) {
      console.error('CreateLinkedSubTabDrawer', 'Unable to find the current subnav');
      return;
    }

    const collectionItems = collections[currentTabId].Items;
    collectionItems.push(newItem);
    calculateCollectionItemPositions(collectionItems);
    setCollectionItems(currentTabId, collectionItems);
    setCollectionItemsToSave(currentTabId);

    // Clear the drawer
    setDrawerContext(undefined);
    setCurrentPage({ subItemId: childId, subTabItemId: newItemId });
  };

  const removeSubNav = (lastItem: BuilderCollectionItem) => {
    const currentTab = currentTabId ? collections[currentTabId] : undefined;
    const lastSubTabId = lastItem.ChildId;

    if (!currentTab || !currentTabId) {
      console.error('CreateLinkedSubTabDrawer', 'Unable to find the subnav to be removed');
      return;
    }

    // Update the last subtab to be a main tab
    // Position and IsMainTab are expected to have values on the parent tab
    const tabValues = [
      { name: 'Position', value: currentTab.Position ?? 0 },
      { name: 'IsMainTab', value: currentTab.IsMainTab ?? 0 },
      { name: 'Icon', value: currentTab.Icon ?? '' },
    ] as { name: keyof Collection; value: string | number }[];
    for (const { name, value } of tabValues) {
      setCollectionValue(lastSubTabId, name, value);
      setCollectionValueToSave(lastSubTabId, name, value);
    }

    // Copy tab specific properties back to the subtab
    for (const [key, value] of Object.entries(currentTab.Properties || [])) {
      if (TAB_LEVEL_PROPERTIES.includes(key)) {
        setCollectionProperty(lastSubTabId, key, value);
        setCollectionPropertyToSave(lastSubTabId, key, value);
      }
    }

    // Begin removal of the subnav
    deleteCollection(currentTabId);
    setCollectionToDelete(currentTabId);

    setCurrentTabId(lastSubTabId); // Last subtab tab is now the current tab in the simulator
  };

  useEffect(() => {
    // Make the tooltip visible, and pop it up next to the Add Tab
    const target = document.getElementById('DrawerSubtitle') as HTMLElement;
    const tooltip = document.getElementById('LinkedSubTabTooltip') as HTMLElement;
    createPopper(target, tooltip, {
      placement: 'left',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [-2, 37],
          },
        },
      ],
    });

    // Before unmount, if current tab has less than 2 items, remove the subnav (e.g. if drawer is navigated away from before adding a sub tab)
    return () => {
      if (currentTabId && collections[currentTabId].Items.length < 2) {
        removeSubNav(collections[currentTabId].Items[0]);
      }
    };
  }, []);

  return (
    <DrawerFlex>
      <DrawerHeading heading="Linked Sub-Tab" />
      <DrawerSubtitle id="DrawerSubtitle">Select content to link</DrawerSubtitle>
      <SearchWrapper>
        <StyledSearch prefix={<StyledSearchIcon />} placeholder="Search" onChange={(e) => setQuery(e.target.value)} />
      </SearchWrapper>
      <DrawerScrollContainer paddingLeft="20px">
        {contentBySourceType.map(([name, collections]) => {
          return (
            <CollectionGroupWrapper>
              <TitleWrapper>
                <SegmentTitle title={getSourceTypeDisplayName(name, collections[0].DataSource, { pluralize: true })} />
              </TitleWrapper>
              {collections.map((collection) => (
                <CollectionWrapper key={collection.TabId} onClick={() => handleCollection(collection.TabId)}>
                  <ContentCard content={collection} type={ITEM_TYPE_COLLECTION} hideType />
                </CollectionWrapper>
              ))}
            </CollectionGroupWrapper>
          );
        })}
      </DrawerScrollContainer>
      <PopperTooltip id="LinkedSubTabTooltip" show={true} position="left">
        Select the group of content you want to link to your new sub-tab.
      </PopperTooltip>
    </DrawerFlex>
  );
};
