import { useState, useEffect, useMemo } from 'react';
import { customAlphabet } from 'nanoid';
import { Filter, FilterOption, SOURCE_VIDAPP } from 'api';

const nanoid = customAlphabet('123456789', 16);

// Use a timeStamp as a dependency for the data sorting useEffect. Once a save completes, the timestamp will be updated, triggering it to use the latest data from the API
let timestamp = 0;

export const useFilterState = (data?: Filter[]) => {
  const [filters, setFilters] = useState(data);
  const [activeFilterId, setActiveFilterId] = useState<number>(0);
  const [sortedApiData, setSortedApiData] = useState<string>('');
  const [hasExternalSources, setHasExternalSources] = useState<boolean>(false);

  const updateFilterPositions = (filters: Filter[]) => {
    const updatedFilters = [...filters];
    let position = 1;
    for (let i = 0; i < updatedFilters.length; i++) {
      if (updatedFilters[i].Enabled) {
        updatedFilters[i].Position = position;
        position++;
      } else {
        updatedFilters[i].Position = -1;
      }
    }
    return updatedFilters;
  };

  // Sort the initial data by Position value
  useEffect(() => {
    if (data) {
      data.sort((a: Filter, b: Filter) => a.Position - b.Position);
      data.forEach((filter: Filter) => {
        if (filter.FilterOptions.length > 1) {
          filter.FilterOptions.sort((a: FilterOption, b: FilterOption) => a.Position - b.Position);
        }
        if (filter.Source === null) {
          filter.Source = SOURCE_VIDAPP;
        }
      });
      const sourcesArray = data.map((filter: Filter) => filter.Source);
      setHasExternalSources(sourcesArray.some((source) => source !== SOURCE_VIDAPP));
      setFilters(data);
      setSortedApiData(JSON.stringify(updateFilterPositions(data)));
    }
  }, [!!data, timestamp]);

  // Should update position values in filters after the filters have been rearranged or a filter removed
  const dependency = filters?.map((filter: Filter) => filter.Position).join('');

  useEffect(() => {
    if (filters) {
      setFilters(updateFilterPositions(filters));
    }
  }, [dependency]);

  const enabledFilters = useMemo(() => {
    return filters ? filters.filter((filter) => filter.Enabled) : [];
  }, [filters]);

  const disabledFilters = useMemo(() => {
    return filters ? filters.filter((filter) => !filter.Enabled) : [];
  }, [filters]);

  return {
    filters,
    setFilters,
    enabledFilters,
    disabledFilters,
    activeFilterId,
    setActiveFilterId,
    handleDeleteFilter: (filterId: number) => {
      const updatedFilters = filters?.filter((filter: Filter) => filter.Id !== filterId);
      setFilters(updatedFilters);
    },
    addFilter: () => {
      const updatedFilters = filters ? [...filters] : [];
      const tempId = parseInt(nanoid());
      updatedFilters.push({
        Enabled: true,
        Filter: '',
        Source: SOURCE_VIDAPP,
        FilterOptions: [],
        Position: updatedFilters.length + 1,
        Id: tempId,
      });
      setFilters(updatedFilters);
      return tempId;
    },
    addOption: (filterId: number) => {
      const updatedFilters = filters?.map((filter: Filter) => {
        if (filter.Id === filterId) {
          filter.FilterOptions.push({
            Position: filter.FilterOptions.length + 1,
            Enabled: true,
            Id: parseInt(nanoid()),
            IsDefault: false,
            Option: '',
            SourceTag: null,
          });
          return filter;
        }
        return filter;
      });
      setFilters(updatedFilters);
    },
    toggleFilterEnabled: (filterId: number) => {
      if (filters) {
        const updatedFilters = [...filters];
        const toggledFilter = updatedFilters.find((filter) => filter.Id === filterId);
        if (toggledFilter) {
          toggledFilter.Enabled = !toggledFilter?.Enabled;
          updatedFilters.push(updatedFilters.splice(updatedFilters.indexOf(toggledFilter), 1)[0]);
        }
        setFilters(updateFilterPositions(updatedFilters));
      }
    },
    sortedApiData,
    saveTimestamp: () => {
      timestamp = Date.now();
    },
    hasExternalSources,
  };
};
