import { isUserAdmin } from 'utils';

import { ErrorPage } from 'components';
import { DataSourceProvider } from 'providers';
import { Route, Switch } from 'react-router-dom';

import { ActionLog } from 'app/modules/action-log';
import { AddOns } from 'app/modules/add-ons';
import { AdminSettings } from 'app/modules/admin-settings';
import { ProgressTrackingPage } from 'app/modules/analytics';
import { CommunityPostsTable } from 'app/modules/community';
import { CommunityMembersTable } from 'app/modules/community/Members/CommunityMembersTable';
import { CommunityModerationTable } from 'app/modules/community/Moderation/CommunityModerationTable';
import { TagsCsvUploadPage } from 'app/modules/filters';
import { Integrations } from 'app/modules/integrations';
import { PublishDiff } from 'app/modules/publish-diffs';
import { TestingScreen } from 'app/modules/testing-screen';
import { FeatureFlagged } from 'app/modules/feature-flagged';

export const AdminScreens = () => {
  if (!isUserAdmin()) {
    return <ErrorPage code={403} />;
  }

  return (
    <Switch>
      <Route exact path="/admin/csv-upload">
        <FeatureFlagged>
          <TagsCsvUploadPage />
        </FeatureFlagged>
      </Route>
      <Route exact path="/admin/progress-tracking">
        <ProgressTrackingPage />
      </Route>
      <Route exact path="/admin/action-log">
        <ActionLog />
      </Route>
      <Route path="/admin/add-ons">
        <DataSourceProvider>
          <AddOns />
        </DataSourceProvider>
      </Route>
      <Route exact path="/admin/admin-settings">
        <DataSourceProvider>
          <AdminSettings />
        </DataSourceProvider>
      </Route>
      <Route exact path="/admin/integrations">
        <DataSourceProvider>
          <Integrations />
        </DataSourceProvider>
      </Route>
      <Route exact path="/admin/testing">
        <TestingScreen />
      </Route>
      <Route exact path="/admin/publish-diff">
        <PublishDiff />
      </Route>
      <Route exact path="/admin/community/posts">
        <FeatureFlagged>
          <CommunityPostsTable />
        </FeatureFlagged>
      </Route>
      <Route exact path="/admin/community/members">
        <FeatureFlagged>
          <CommunityMembersTable />
        </FeatureFlagged>
      </Route>
      <Route exact path="/admin/community/moderation">
        <FeatureFlagged>
          <CommunityModerationTable />
        </FeatureFlagged>
      </Route>
      <Route path="*">
        <ErrorPage code={404} />
      </Route>
    </Switch>
  );
};
