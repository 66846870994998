import { useState, useEffect } from 'react';

import { useProducts } from 'hooks';
import { BuilderProducts } from 'providers';

export const useOffers = () => {
  const { data: productData, isError: productsIsError } = useProducts();
  const [products, setProducts] = useState<BuilderProducts>();
  const [activeProductId, setActiveProductId] = useState<number | string>();

  useEffect(() => {
    // Setup state once on initial data load
    if (!!productData) {
      // Sort products by DisplayName, with ReadyForSale products at the top of the table
      const sorted = productData.products
        .filter(({ ReadyForSale }) => ReadyForSale !== 'never')
        .sort((a, b) => {
          if (a.ReadyForSale === b.ReadyForSale) {
            return (a.DisplayName ?? '').localeCompare(b.DisplayName ?? '');
          }
          return (b.ReadyForSale ?? '').localeCompare(a.ReadyForSale ?? '');
        });

      const productMap = new Map(sorted.map((obj) => [obj.Id, obj]));

      setProducts(productMap);
    }
  }, [!!productData]);

  useEffect(() => {
    document.getElementById('ScrollContainer--PageContainer')?.scrollTo(0, 0);
  }, [activeProductId]);

  return {
    products,
    setProducts,
    activeProductId,
    setActiveProductId,
    isLoading: !products,
    isError: productsIsError,
  };
};
