import { Dispatch, SetStateAction } from 'react';
import { useParams } from 'react-router-dom';

import { PageContainer } from 'components';
import {
  BuilderCollections,
  BuilderVideos,
  ContentProvider,
  DefaultAppPropertiesProvider,
  DefaultContentProvider,
  SaveProvider,
} from 'providers';

import { GROUP_LABEL, SOURCE_TYPE_WORKOUT } from 'api';
import { useContentScreen } from '../hooks/useContentScreen';
import { useContentNavigationContext } from '../providers';
import { Playlists, Series, SyncedContent, Workouts } from './content-table-screens';
import { ContentCollectionView, ContentVideoView, ContentWorkoutView } from './content-view-screens';
import { ContentHeaderButtons } from './content-table-screens/components';
import { PAGE_CONTAINER_WIDE_WIDTH } from 'theme';

export type Screen = 'synced-content' | 'playlists' | 'series' | 'workouts';

export const screenHeadings = {
  ['synced-content']: {
    heading: 'Synced Content',
    subheading: 'Sync and add content from your existing site or video hosting.',
  },
  playlists: {
    heading: `Playlists & ${GROUP_LABEL}s`,
    subheading: `Create playlists and ${GROUP_LABEL.toLowerCase()}s from your content.`,
  },
  series: {
    heading: 'Series',
    subheading: 'Create challenges, courses and programs from your content.',
  },
  workouts: {
    heading: 'Workouts',
    subheading: 'Create workouts from your content.',
  },
};

const getScreen = (screen?: Screen) => {
  switch (screen) {
    case 'synced-content':
      return <SyncedContent />;
    case 'playlists':
      return <Playlists />;
    case 'series':
      return <Series />;
    case 'workouts':
      return <Workouts />;
    default:
      return <SyncedContent />;
  }
};

export const ContentInternal = () => {
  const { screen } = useParams<{ screen: Screen }>();
  const { collections, videos, setCollections, setVideos, isLoading, isError } = useContentScreen();
  const { activeCollectionId, activeVideoId, newCollectionType } = useContentNavigationContext();

  const isWorkout = activeCollectionId
    ? collections && collections[activeCollectionId].SourceType === SOURCE_TYPE_WORKOUT
    : newCollectionType === SOURCE_TYPE_WORKOUT;

  return !!collections && !!videos && (newCollectionType || activeCollectionId || activeVideoId) ? (
    <ContentProvider
      collections={collections}
      setCollections={setCollections as Dispatch<SetStateAction<BuilderCollections>>}
      videos={videos}
      setVideos={setVideos as Dispatch<SetStateAction<BuilderVideos>>}
    >
      <SaveProvider>
        <DefaultAppPropertiesProvider>
          {isWorkout ? (
            <ContentWorkoutView existingCollectionId={activeCollectionId} key={activeCollectionId} />
          ) : newCollectionType || activeCollectionId ? (
            <ContentCollectionView existingCollectionId={activeCollectionId} key={activeCollectionId} />
          ) : activeVideoId ? (
            <ContentVideoView existingVideoId={activeVideoId} key={activeVideoId} />
          ) : null}
        </DefaultAppPropertiesProvider>
      </SaveProvider>
    </ContentProvider>
  ) : (
    <PageContainer
      heading={screen ? screenHeadings[screen].heading : 'Content'}
      subheading={
        screen ? screenHeadings[screen].subheading : 'Sync and add content from your existing site or video hosting'
      }
      isLoading={isLoading}
      isError={isError}
      headingButton={<ContentHeaderButtons />}
      contentMaxWidth={PAGE_CONTAINER_WIDE_WIDTH}
    >
      <DefaultContentProvider updateOnRefetch>{getScreen(screen)}</DefaultContentProvider>
    </PageContainer>
  );
};
