import { createContext, Dispatch, SetStateAction, useCallback, useContext, useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { Modal } from 'antd';
import { useParams } from 'react-router-dom';

import { useUnsavedChanges } from 'providers';
import { useAppBeingEdited } from 'app-context';
import {
  GROUP_LABEL,
  SOURCE_TYPE_CIRCUIT,
  SOURCE_TYPE_COLLECTION,
  SOURCE_TYPE_COLLECTION_2,
  SOURCE_TYPE_WORKOUT,
  SOURCE_VIDAPP,
} from 'api';

import { Screen } from '../Content/ContentInternal';
import { useAppBasicInfo, useAppProperties } from 'hooks';

const resetScroll = () => document.getElementById('ScrollContainer--PageContainer')?.scrollTo(0, 0);

type NavigationType = 'collection' | 'video' | 'new';

type ActiveId = number | string | undefined;

export interface ContentBreadcrumb {
  id: string | number | undefined;
  label: string;
}

interface BreadcrumbUpdate {
  breadcrumbs: ContentBreadcrumb[];
  appendToExisting: boolean;
}

interface ContextProps {
  activeCollectionId: ActiveId;
  activeVideoId: ActiveId;
  newCollectionType: string | undefined;
  activeTab: string | undefined;
  activeSubTab: string | undefined;
  page: number;
  pageSize: number;
  breadcrumbs: ContentBreadcrumb[] | undefined;
  searchValue: string;
  tempTabId: string;
  blockTreeNavigation: boolean;
  isCMS?: boolean;
  isSimpleCMS?: boolean;
  circuitsEnabled?: boolean;
  collectionIsEditable: (dataSource?: string, sourceType?: string) => boolean;
  goToItem: (type: NavigationType, id: ActiveId, breadcrumbUpdate?: BreadcrumbUpdate, skipSavePrompt?: boolean) => void;
  setSearchValue: Dispatch<SetStateAction<string>>;
  handleSearch: (value: string) => void;
  clearSearch: () => void;
  setActiveTab: Dispatch<SetStateAction<string | undefined>>;
  setActiveSubTab: Dispatch<SetStateAction<string | undefined>>;
  setPage: Dispatch<SetStateAction<number>>;
  setPageSize: Dispatch<SetStateAction<number>>;
  setTempTabId: Dispatch<SetStateAction<string>>;
  setBlockTreeNavigation: Dispatch<SetStateAction<boolean>>;
  goToNewCollection: (newTabId: number, newName: string) => void;
  updateBreadcrumbItem: (tabId: string | number, newName: string) => void;
}

interface ProviderProps {
  children: React.ReactNode;
}

const defaultFunction = () => {
  console.warn('Unexpected function call content-navigation-provider');
};

const ContentNavigationContext = createContext<ContextProps>({
  activeCollectionId: undefined,
  activeVideoId: undefined,
  newCollectionType: undefined,
  activeTab: undefined,
  activeSubTab: undefined,
  page: 1,
  pageSize: 25,
  breadcrumbs: [],
  searchValue: '',
  tempTabId: '',
  blockTreeNavigation: false,
  collectionIsEditable: () => false,
  goToItem: defaultFunction,
  setSearchValue: defaultFunction,
  handleSearch: defaultFunction,
  clearSearch: defaultFunction,
  setActiveTab: defaultFunction,
  setActiveSubTab: defaultFunction,
  setPage: defaultFunction,
  setPageSize: defaultFunction,
  setTempTabId: defaultFunction,
  setBlockTreeNavigation: defaultFunction,
  goToNewCollection: defaultFunction,
  updateBreadcrumbItem: defaultFunction,
});

const ContentNavigationProvider = ({ children }: ProviderProps) => {
  const appId = useAppBeingEdited();
  const queryClient = useQueryClient();
  const { unsavedChanges, setUnsavedChanges } = useUnsavedChanges();
  const { data: appProperties } = useAppProperties();
  const { isMigratedLegacy } = useAppBasicInfo();

  // Control which screen is displayed in ContentInternal
  const [activeCollectionId, setActiveCollectionId] = useState<ActiveId>(); // If set, display a screen for editing a collection's details (ContentCollectionView)
  const [activeVideoId, setActiveVideoId] = useState<ActiveId>(); // If set, display a screen for editing a video's details (ContentVideoView)
  const [newCollectionType, setNewCollectionType] = useState<string>(); // If set, display a screen for creating a new collection (ContentCollectionView without existingCollectionId prop)
  const [tempTabId, setTempTabId] = useState(''); // TempTabId being used while new collection is being drafted
  const [breadcrumbs, setBreadcrumbs] = useState<ContentBreadcrumb[]>(); // Breadcrumb navigation of content being edited
  const [blockTreeNavigation, setBlockTreeNavigation] = useState(false); // Block navigation within Content tree while collections are in draft state

  // If none of the above states are defined, ContentInternal will display tables of collections and videos
  const { screen } = useParams<{ screen: Screen }>(); // Determines which table view screen will display (see getScreen function in ContentInternal)
  const [activeTab, setActiveTab] = useState<string>(); // Controls which DataSource tab is selected
  const [activeSubTab, setActiveSubTab] = useState<string>(); // Controls which collection/video type tab is selected
  const [page, setPage] = useState(1); // Controls table pagination
  const [pageSize, setPageSize] = useState(25); // Controls table pagination
  const [searchValue, setSearchValue] = useState(''); // Controls table search

  // Unblock navigation after save/discard
  useEffect(() => {
    if (blockTreeNavigation && !unsavedChanges) {
      setBlockTreeNavigation(false);
    }
  }, [unsavedChanges, blockTreeNavigation]);

  const handleSearch = useCallback(
    (value: string) => {
      const searchInput = value.toLowerCase();
      setSearchValue(searchInput);
    },
    [setSearchValue],
  );

  const clearSearch = useCallback(() => {
    setSearchValue('');
  }, [setSearchValue]);

  const updateBreadcrumbs = useCallback(
    (breadcrumbUpdate: BreadcrumbUpdate) => {
      if (breadcrumbUpdate.appendToExisting) {
        // If append, add the provided breadcrumb array to the end of the existing breadcrumbs (if there are any)
        setBreadcrumbs((prevState) => {
          if (prevState) {
            return [...prevState, ...breadcrumbUpdate.breadcrumbs];
          } else {
            return [...breadcrumbUpdate.breadcrumbs];
          }
        });
      } else {
        // Else replace the current breadcrumb state with the provided array
        setBreadcrumbs([...breadcrumbUpdate.breadcrumbs]);
      }
    },
    [setBreadcrumbs],
  );

  const navigate = useCallback(
    (type: NavigationType, id: ActiveId, breadcrumbUpdate?: BreadcrumbUpdate) => {
      setActiveCollectionId(type === 'collection' ? id : undefined);
      setActiveVideoId(type === 'video' ? id : undefined);
      setNewCollectionType(type === 'new' ? (id as string) : undefined);

      if (breadcrumbUpdate) {
        // If breadcrumbs have been provided, updated the breadcrumb state
        updateBreadcrumbs(breadcrumbUpdate);
      } else if (type === 'new') {
        updateBreadcrumbs({
          breadcrumbs: [
            {
              id,
              label: `New ${
                [SOURCE_TYPE_COLLECTION, SOURCE_TYPE_COLLECTION_2].includes(id as string) ? GROUP_LABEL : id
              }`,
            },
          ],
          appendToExisting: true,
        });
      }

      setUnsavedChanges(false);
      resetScroll();
    },
    [setActiveCollectionId, setActiveVideoId, updateBreadcrumbs, setUnsavedChanges],
  );

  const goToItem = useCallback(
    (type: NavigationType, id: ActiveId, breadcrumbUpdate?: BreadcrumbUpdate, skipSavePrompt?: boolean) => {
      if (unsavedChanges && !skipSavePrompt) {
        Modal.confirm({
          title: 'Unsaved Changes',
          content: 'You have unsaved changes. If you leave without saving your changes will be lost.',
          getContainer: '#react-app',
          okText: 'Continue Editing',
          cancelText: 'Discard Changes',
          onCancel: () => {
            queryClient.invalidateQueries(['collections', appId]);
            navigate(type, id, breadcrumbUpdate);
            Modal.destroyAll();
          },
          onOk: () => {
            return false;
          },
        });
      } else {
        navigate(type, id, breadcrumbUpdate);
      }
    },
    [unsavedChanges, navigate, queryClient, appId],
  );

  // After a new collection has been saved, navigate to the new collection and update the breadcrumb item to use the permanent tab ID
  const goToNewCollection = useCallback(
    (newTabId: number, newName: string) => {
      goToItem('collection', newTabId, { breadcrumbs: [], appendToExisting: true }, true);

      setBreadcrumbs((prevState) => {
        if (prevState && prevState.length > 0) {
          const updatedState = [...prevState];
          const lastItem = updatedState.pop() as ContentBreadcrumb;
          lastItem.id = newTabId;
          lastItem.label = newName;
          return [...updatedState, lastItem];
        }
        return prevState;
      });
    },
    [goToItem, setBreadcrumbs],
  );

  const updateBreadcrumbItem = useCallback(
    (tabId: string | number, newName: string) => {
      setBreadcrumbs((prevState) => {
        if (prevState) {
          return prevState.map((item) => {
            if (item.id === tabId) {
              return { ...item, label: newName };
            }
            return item;
          });
        }
        return prevState;
      });
    },
    [setBreadcrumbs],
  );

  // Reset when different CMS screen is navigated to
  useEffect(() => {
    clearSearch();
    setPage(1);
    setActiveTab(undefined);
    setActiveSubTab(undefined);
    goToItem('collection', undefined, { breadcrumbs: [], appendToExisting: false });
  }, [screen]);

  const isSimpleCMS = appProperties?.DisplaySimpleCMS === '1' || isMigratedLegacy;
  const isCMS = appProperties?.DisplayCMS === '1' && !isSimpleCMS;

  const collectionIsEditable = (dataSource?: string, sourceType?: string) =>
    (dataSource === SOURCE_VIDAPP && (isCMS || isSimpleCMS)) ||
    sourceType === SOURCE_TYPE_WORKOUT ||
    sourceType === SOURCE_TYPE_CIRCUIT;

  return (
    <ContentNavigationContext.Provider
      value={{
        activeCollectionId,
        activeVideoId,
        newCollectionType,
        activeTab,
        activeSubTab,
        page,
        pageSize,
        breadcrumbs,
        searchValue,
        tempTabId,
        blockTreeNavigation,
        isCMS,
        isSimpleCMS,
        circuitsEnabled: appProperties?.DisplayCircuitWorkouts === '1',
        collectionIsEditable,
        goToItem,
        setSearchValue,
        handleSearch,
        clearSearch,
        setActiveTab,
        setActiveSubTab,
        setPage,
        setPageSize,
        setTempTabId,
        setBlockTreeNavigation,
        goToNewCollection,
        updateBreadcrumbItem,
      }}
    >
      {children}
    </ContentNavigationContext.Provider>
  );
};

const useContentNavigationContext = () => {
  const context = useContext(ContentNavigationContext);
  if (context === undefined) {
    throw new Error('useContentNavigationContext must be used within a ContentNavigationProvider');
  }
  return context;
};

export { ContentNavigationProvider, useContentNavigationContext };
