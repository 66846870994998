import { useMemo, ReactNode } from 'react';
import styled from 'styled-components';

import { Tabs } from 'components';
import {
  GROUP_LABEL,
  SOURCE_TYPE_COLLECTION,
  SOURCE_TYPE_COLLECTION_2,
  SOURCE_TYPE_PLAYLIST,
  SOURCE_VIDAPP,
} from 'api';
import { BuilderCollection, useContent } from 'providers';
import { collectionIsProtected } from 'utils';

import { ContentContainer, TabContent } from './components';
import { useContentNavigationContext } from '../../providers';

const { Root, PillList, PillTrigger, Content } = Tabs;

const StyledRoot = styled(Root)`
  position: relative;
`;

const StyledPillList = styled(PillList)`
  margin-left: auto;
  width: fit-content;
  position: absolute;
  right: 0;
  top: 2px;
`;

export const Playlists = () => {
  const { collections } = useContent();
  const { activeSubTab, setActiveSubTab, setPage, clearSearch } = useContentNavigationContext();

  const sortedCollections: Record<string, BuilderCollection[]> | undefined = useMemo(() => {
    if (collections) {
      const validCollections = Object.values(collections)
        .filter(
          ({ DataSource, SourceId, Position, TemplateId }) =>
            DataSource === SOURCE_VIDAPP &&
            !!SourceId &&
            (!Position || Position === 0) && // Filter out main tabs
            !collectionIsProtected(SourceId) && // Filter out default Self-Onboarding collections
            ![4, 5, 25, 34, 35].includes(TemplateId) && // Filter out Workouts and Circuits, text/image, websites
            !SourceId.includes('TempTabId'), // Filter out temp tabs,
        )
        .sort((a, b) => a.Name.localeCompare(b.Name));

      const collectionsObj: Record<string, BuilderCollection[]> = {
        Playlists: validCollections.filter(({ SourceType }) => SourceType === SOURCE_TYPE_PLAYLIST),
        Groups: validCollections.filter(({ SourceType }) => SourceType === SOURCE_TYPE_COLLECTION_2),
        LegacyGroups: validCollections.filter(({ SourceType }) => SourceType === SOURCE_TYPE_COLLECTION),
      };

      return collectionsObj;
    }
    return undefined;
  }, [collections]);

  interface TabItem {
    key: string;
    children: ReactNode;
  }

  const subTabs = useMemo(() => {
    const subTabArr: TabItem[] = [];
    const allCollections: BuilderCollection[] = [];

    if (!!sortedCollections) {
      ['Playlists', 'Groups'].forEach((type) => {
        const content = sortedCollections[type];

        subTabArr.push({
          key: type,
          children: (
            <TabContent
              searchPlaceholder={`Search ${type} (${content.length})...`}
              sections={[{ key: type, type: 'collections', collections: content }]}
            />
          ),
        });

        allCollections.push(...content);
      });

      if (sortedCollections.LegacyGroups.length > 0) {
        const content = sortedCollections.LegacyGroups;

        subTabArr.push({
          key: `Legacy ${GROUP_LABEL}s`,
          children: (
            <TabContent
              searchPlaceholder={`Search Legacy ${GROUP_LABEL}s (${content.length})...`}
              sections={[{ key: 'LegacyGroups', type: 'collections', collections: content }]}
            />
          ),
        });

        allCollections.push(...content);
      }

      subTabArr.unshift({
        key: 'All',
        children: (
          <TabContent
            searchPlaceholder={`Search All (${allCollections.length})...`}
            sections={[{ key: 'All', type: 'all', collections: allCollections }]}
          />
        ),
      });
    }

    return subTabArr;
  }, [sortedCollections]);

  const isEmpty = subTabs.length === 0;

  return (
    <ContentContainer isEmpty={isEmpty}>
      <StyledRoot
        value={isEmpty ? undefined : activeSubTab ?? subTabs[0].key}
        onValueChange={(activeKey) => {
          setActiveSubTab(activeKey);
          setPage(1);
          clearSearch();
        }}
      >
        <StyledPillList>
          {subTabs.map(({ key }) => (
            <PillTrigger key={key} value={key}>
              {key}
            </PillTrigger>
          ))}
        </StyledPillList>
        {subTabs.map(({ key, children }) => (
          <Content key={key} value={key}>
            {children}
          </Content>
        ))}
      </StyledRoot>
    </ContentContainer>
  );
};
