import { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';

import { CustomButton, InfoModal, PageContainer, SaveFailed, SegmentTitle } from 'components';
import { useUnsavedChanges } from 'providers';
import { Filter, FilterToSave, SOURCE_VIDAPP } from 'api';
import { useDeleteFilter, useFilters, useSaveFilter } from 'hooks';
import { PlusIcon } from 'icons';

import {
  DisabledFilterContainer,
  FilterContainer,
  FiltersDrawer,
  NoFilters,
} from 'app/modules/filters/FiltersPage/components';
import { useFilterState } from './hooks/useFilterState';

// Client terminology is Categories and Tags, but in the code/API Categories = Filters and Tags = FilterOptions

const AddFilterButton = styled(CustomButton)`
  #react-app && {
    margin-bottom: 40px;
  }
`;

const checkForUnsavedChanges = (originalData: string, currentData?: string) => {
  if (currentData) {
    return originalData !== currentData;
  }
  return false;
};

const isTempId = (id: number) => {
  // New Filters/FilterOptions are temporarily assigned a 16 digit numerical nanoid until saved
  return id.toString().length === 16;
};

const updateOptionPositions = (filter: Filter) => {
  for (let i = 0; i < filter.FilterOptions.length; i++) {
    filter.FilterOptions[i].Position = i + 1;
  }
  return filter;
};

const filtersToDelete: number[] = [];

export const FiltersPage = () => {
  const { data, isLoading, isError } = useFilters();
  const {
    filters,
    setFilters,
    enabledFilters,
    disabledFilters,
    activeFilterId,
    setActiveFilterId,
    handleDeleteFilter,
    addFilter,
    addOption,
    toggleFilterEnabled,
    sortedApiData,
    saveTimestamp,
    hasExternalSources,
  } = useFilterState(data?.filters);
  const saveFilter = useSaveFilter();
  const deleteFilter = useDeleteFilter();
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [isValidating, setIsValidating] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const { unsavedChanges, setUnsavedChanges } = useUnsavedChanges();

  useEffect(() => {
    setUnsavedChanges(checkForUnsavedChanges(sortedApiData, JSON.stringify(filters)));
  }, [sortedApiData, filters, setUnsavedChanges]);

  const activeFilter = useMemo(() => {
    return activeFilterId != 0 ? filters?.find((filter: Filter) => filter.Id == activeFilterId) : undefined;
  }, [activeFilterId, filters]);

  const emptyFields = useMemo(() => {
    return (
      activeFilter?.Filter.length === 0 || activeFilter?.FilterOptions.some((option) => option.Option.length === 0)
    );
  }, [filters, activeFilter]);

  const onDragEnd = useCallback(
    (result: DropResult) => {
      const { destination, source, draggableId } = result;
      if (!destination || destination.index === source.index) {
        return;
      }

      if (enabledFilters.length > 0) {
        const updatedEnabledFilters = [...enabledFilters];
        const draggedFilter = updatedEnabledFilters.find((filter: Filter) => filter.Id.toString() == draggableId);
        updatedEnabledFilters.splice(source.index, 1);
        if (draggedFilter) {
          updatedEnabledFilters.splice(destination.index, 0, draggedFilter);
        }
        setFilters(updatedEnabledFilters.concat(disabledFilters));
      }
    },
    [setFilters, enabledFilters, disabledFilters],
  );

  const editFilter = useCallback(
    (id: number) => {
      if (emptyFields) {
        setIsValidating(true);
      } else {
        const updatedFilters = filters?.map((filter: Filter) => {
          if (filter.Id === id) {
            return updateOptionPositions(filter);
          }
          return filter;
        });
        setIsValidating(false);
        setFilters(updatedFilters);
        setActiveFilterId(id);
        setDrawerVisible(true);
      }
    },
    [
      emptyFields,
      isValidating,
      setIsValidating,
      setActiveFilterId,
      updateOptionPositions,
      setFilters,
      filters,
      setDrawerVisible,
    ],
  );

  const onDeleteFilter = useCallback(
    (id: number) => {
      if (!isTempId(id)) {
        filtersToDelete.push(id);
      }
      handleDeleteFilter(id);
      setDrawerVisible(false);
      setActiveFilterId(0);
    },
    [handleDeleteFilter, setDrawerVisible, setActiveFilterId],
  );

  const handleAddFilter = useCallback(async () => {
    if (drawerVisible && activeFilter?.Filter.length === 0 && activeFilter?.FilterOptions.length > 0) {
      setIsValidating(true);
    } else {
      setIsValidating(false);
      const newFilterId = addFilter();
      setActiveFilterId(newFilterId);
      setDrawerVisible(true);
    }
  }, [drawerVisible, activeFilter, setIsValidating, addFilter, setActiveFilterId, setDrawerVisible]);

  const handleAddOption = useCallback(
    (id: number) => {
      addOption(id);
    },
    [addOption],
  );

  const closeDrawer = useCallback(() => {
    if (emptyFields) {
      setIsValidating(true);
    } else {
      setIsValidating(false);
      setDrawerVisible(false);
    }
  }, [emptyFields, setIsValidating, setDrawerVisible]);

  const removeInvalidFilters = useCallback(() => {
    const validFilters: Filter[] = [];
    filters?.forEach((filter: Filter) => {
      if (filter.Filter !== '') {
        validFilters.push(filter);
      }
    });
    setFilters(validFilters);
    return validFilters;
  }, [setFilters, filters]);

  const handleSave = useCallback(async () => {
    if (emptyFields) {
      setIsValidating(true);
    } else {
      setIsValidating(false);
      setDrawerVisible(false);
      setIsSaving(true);
      const validFilters = removeInvalidFilters();

      // Filter out filters that haven't been edited
      const editedFilters = validFilters.filter((filter: Filter) => !sortedApiData.includes(JSON.stringify(filter)));

      const filtersToSave = editedFilters.map((filter) => {
        const filterToSave: FilterToSave = { ...filter, FilterOptions: [...filter.FilterOptions] };

        // New filters have a temporary ID so need to be deleted in order for the DB to assign a new ID
        if (filterToSave.Id && isTempId(filterToSave.Id)) {
          delete filterToSave.Id;
        }
        // Same as above for new FilterOptions
        filterToSave.FilterOptions.forEach((option) => {
          if (option.Id && isTempId(option.Id)) {
            delete option.Id;
          }
        });
        return filterToSave;
      });

      const requests: Promise<unknown>[] = [];

      filtersToSave.forEach((filter) => {
        requests.push(saveFilter.mutateAsync(filter));
      });

      filtersToDelete.forEach((filterId) => {
        requests.push(deleteFilter.mutateAsync(filterId));
      });

      const responses = await Promise.all(requests).catch((error) => {
        SaveFailed(error);
        setIsSaving(false);
      });

      if (typeof responses !== 'undefined') {
        // Save successful - Update the timestamp in useFilterState to trigger the latest data to be used
        saveTimestamp();
        setIsSaving(false);
        filtersToDelete.length = 0;
        InfoModal(
          'Save Successful',
          'Your new updated categories and tags will be applied to your app in 1 hr.',
          'success',
        );
      }
    }
  }, [removeInvalidFilters, saveTimestamp, setIsSaving, saveFilter, deleteFilter, filtersToDelete]);

  return (
    <PageContainer
      heading="Filters"
      subheading="Use this section to add the categories and tags that your users will filter their searches by."
      headingButton={
        <CustomButton medium primary disabled={!unsavedChanges} loading={isSaving} onClick={handleSave}>
          Save
        </CustomButton>
      }
      isError={isError}
      isLoading={isLoading}
      leftAligned
    >
      <SegmentTitle title="Enabled Categories" />
      {enabledFilters && enabledFilters.length > 0 ? (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="filtersDroppable">
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {isSaving
                  ? // If currently saving, display filters with temporary ID of 0 while awaiting new IDs from API
                    enabledFilters?.map((filter: Filter, idx: number) => (
                      <FilterContainer
                        key={filter.Id}
                        filter={filter}
                        idx={idx}
                        hasExternalSources={hasExternalSources}
                        editFilter={editFilter}
                      />
                    ))
                  : // If not currently saving, filter out any filters with an ID of 0 as they will now be displayed with their newly fetched IDs
                    enabledFilters
                      ?.filter((filter: Filter) => filter.Id !== 0)
                      .map((filter: Filter, idx: number) => (
                        <FilterContainer
                          key={filter.Id}
                          filter={filter}
                          idx={idx}
                          hasExternalSources={hasExternalSources}
                          editFilter={editFilter}
                        />
                      ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      ) : (
        <NoFilters />
      )}
      <AddFilterButton tertiaryHighlight medium onClick={handleAddFilter} icon={<PlusIcon />}>
        Add New Category
      </AddFilterButton>
      {disabledFilters && disabledFilters.length > 0 && (
        <>
          <SegmentTitle title="Disabled Categories" />
          {isSaving
            ? // If currently saving, display filters with temporary ID of 0 while awaiting new IDs from API
              disabledFilters?.map((filter: Filter, idx: number) => (
                <DisabledFilterContainer
                  key={filter.Id}
                  filter={filter}
                  idx={idx}
                  hasExternalSources={hasExternalSources}
                  editFilter={editFilter}
                />
              ))
            : // If not currently saving, filter out any filters with an ID of 0 as they will now be displayed with their newly fetched IDs
              disabledFilters
                ?.filter((filter: Filter) => filter.Id !== 0)
                .map((filter: Filter, idx: number) => (
                  <DisabledFilterContainer
                    key={filter.Id}
                    filter={filter}
                    idx={idx}
                    hasExternalSources={hasExternalSources}
                    editFilter={editFilter}
                  />
                ))}
        </>
      )}
      <FiltersDrawer
        title="Edit Category"
        visible={drawerVisible}
        enabled={activeFilter?.Enabled ?? false}
        externalSource={(activeFilter && activeFilter?.Source !== SOURCE_VIDAPP) ?? false}
        toggleFilterEnabled={toggleFilterEnabled}
        closeDrawer={closeDrawer}
        activeFilterId={activeFilterId}
        activeFilter={activeFilter}
        filters={filters}
        setFilters={setFilters}
        deleteFilter={onDeleteFilter}
        addOption={handleAddOption}
        updateOptionPositions={updateOptionPositions}
        isValidating={isValidating}
      />
    </PageContainer>
  );
};
