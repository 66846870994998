import { useCallback } from 'react';
import styled from 'styled-components';

import { SettingsTextInput } from 'components';
import { useLocalAppProperties, useSaveContext } from 'providers';

const Wrapper = styled.div<{ $width?: string; $marginRight?: string; $marginBottom?: string }>`
  width: ${({ $width }) => $width ?? '32%'};
  margin-right: ${({ $marginRight }) => $marginRight};
  margin-bottom: ${({ $marginBottom }) => $marginBottom || '40px'};
`;

interface AppPropertyTextInputProps {
  label?: string;
  placeholder?: string;
  property: string;
  maxLength?: number;
  disabled?: boolean;
  $width?: string;
  $marginRight?: string;
  $marginBottom?: string;
  onChange?: () => void;
}

export const AppPropertyTextInput = ({
  label,
  placeholder,
  property,
  maxLength,
  disabled,
  onChange,
  ...props
}: AppPropertyTextInputProps) => {
  const { properties, setAppProperty } = useLocalAppProperties();
  const { setAppPropertyToSave } = useSaveContext();

  const handlePropertyChange = useCallback(
    (property: string, value: string) => {
      setAppProperty(property, value);
      setAppPropertyToSave(property, value);
    },
    [property, setAppProperty, setAppPropertyToSave],
  );

  return (
    <Wrapper {...props}>
      <SettingsTextInput
        value={properties[property]}
        onChange={(e) => {
          onChange && onChange();
          handlePropertyChange(property, e.target.value);
        }}
        label={label}
        placeholder={placeholder ?? label}
        maxLength={maxLength}
        disabled={disabled}
      />
    </Wrapper>
  );
};
