import { createContext, useCallback, useContext, useState } from 'react';
import { Modal } from 'antd';

import { InfoModal } from 'components';
import { Stage } from 'api';
import { usePublishApp } from 'hooks';

type PublishAvailable = boolean | 'live';

interface ContextProps {
  publishAvailable: PublishAvailable;
  isPublishing: boolean;
  setPublishAvailable: React.Dispatch<React.SetStateAction<PublishAvailable>>;
  handlePublishApp: (stage: Stage) => void;
}

interface ProviderProps {
  children: React.ReactNode;
}

const defaultFunction = () => {
  console.warn('Unexpected function call publish-provider');
};

const PublishContext = createContext<ContextProps>({
  publishAvailable: false,
  isPublishing: false,
  setPublishAvailable: defaultFunction,
  handlePublishApp: defaultFunction,
});

const PublishProvider = ({ children }: ProviderProps) => {
  const [publishAvailable, setPublishAvailable] = useState<PublishAvailable>(false);
  const publishApp = usePublishApp();

  const handlePublishApp = useCallback(
    (stage: Stage) => {
      if (stage === 'Live') {
        Modal.confirm({
          title: 'Are you sure?',
          content: 'Are you sure you want to publish your app live?',
          getContainer: '#react-app',
          okText: 'Yes, publish my app live',
          cancelText: 'Cancel',
          onOk: () => {
            publishApp.mutate(stage, {
              onSuccess: async () => {
                setPublishAvailable(false);
                InfoModal(
                  'Your updates will be live in a few minutes',
                  'Users will receive your updates the next time they launch your app',
                  'success',
                );
              },
              onError: (err) => {
                setPublishAvailable(false);
                InfoModal(
                  'It looks like your publishing task is taking a while!',
                  "Apologies for the delay. You'll receive an email as soon as it's complete",
                  'error',
                );
                console.error(err);
              },
            });
          },
        });
      } else {
        publishApp.mutate(stage, {
          onSuccess: async () => {
            setPublishAvailable('live');
            InfoModal(
              "We're updating the Vidapp preview app",
              'Your updates will be live in a few minutes.',
              'success',
            );
          },
          onError: (err) => {
            setPublishAvailable('live');
            InfoModal(
              'It looks like your publishing task is taking a while!',
              "Apologies for the delay. You'll receive an email as soon as it's complete",
              'error',
            );
            console.error(err);
          },
        });
      }
    },
    [publishApp, setPublishAvailable],
  );

  const isPublishing = publishApp.isLoading;

  return (
    <PublishContext.Provider value={{ publishAvailable, isPublishing, setPublishAvailable, handlePublishApp }}>
      {children}
    </PublishContext.Provider>
  );
};

const usePublishContext = () => {
  const context = useContext(PublishContext);
  if (context === undefined) {
    throw new Error('usePublishContext must be used within an PublishProvider');
  }
  return context;
};

export { PublishProvider, usePublishContext };
