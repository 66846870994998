import { Select } from 'antd';
import { useMemo, useState } from 'react';
import styled from 'styled-components';

import {
  PAYMENT_STATUS_CHURNED,
  PAYMENT_STATUS_DETAILS_SAVED,
  PAYMENT_STATUS_DNE,
  PAYMENT_STATUS_INVOICED,
  PAYMENT_STATUS_TEST,
  PAYMENT_STATUS_UNPAID,
  PAYMENT_STATUS_UNPAID_DEPRECATED,
  PaymentStatus,
  S3_BUCKET,
} from 'api';
import { useAppBeingEdited } from 'app-context';
import { ClickToCopy, Tooltip } from 'components';
import { FONT_12PX_REGULAR, FONT_12PX_SEMIBOLD, OVERFLOW_ELLIPSIS } from 'font';
import { useAppBasicInfo, useAvailableApps } from 'hooks';
import { ChevronDownIcon } from 'icons';
import {
  BREAKPOINT_1,
  DANGER_COLOUR,
  HIGHLIGHT_PRIMARY_COLOUR,
  NEUTRAL_10_COLOUR,
  NEUTRAL_7_COLOUR,
  NEUTRAL_9_COLOUR,
  SUCCESS_COLOUR,
  WARNING_COLOUR,
} from 'theme';
import { isUserAdmin } from 'utils';

import { usePaymentStatuses } from 'hooks/usePaymentStatus';
import { useHistory, useLocation } from 'react-router-dom';

const { Option } = Select;

const AppSelectWrapper = styled.div`
  &&&& {
    display: flex;
    align-items: center;
    .ant-select-focused .ant-select-selection-item {
      opacity: 0.3;
    }
  }
`;

const ActiveAppLabelWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 1;
  overflow: hidden;
`;

const ActiveAppLabel = styled.div`
  ${FONT_12PX_SEMIBOLD};
  color: ${NEUTRAL_10_COLOUR};
  margin-right: 12px;
  flex-shrink: 1;
  ${OVERFLOW_ELLIPSIS};
`;

const StyledSelect = styled(Select)`
  &&&& {
    color: ${NEUTRAL_10_COLOUR};
    border: none;
    border-radius: 6px;
    margin-right: 12px;
    ${FONT_12PX_REGULAR};
    height: 30px;
    display: flex;
    align-items: center;

    &&&& .ant-select-selector {
      padding-left: 0;
    }

    .ant-select-selection-search-input {
      height: 100% !important;
    }

    input {
      border: none;
      outline: none;
      margin-left: -11px;
      padding-top: 0;
      padding-left: 0;
      cursor: pointer;
    }

    .ant-select-selection-item,
    .ant-select-selection-search,
    .ant-select-selection-placeholder {
      display: flex;
      align-items: center;
    }

    &&&& .ant-select-selection-item {
      div {
        font-weight: 600;
        overflow: hidden;
        transition: none;
      }

      span {
        display: none;
      }

      .app-status-icon {
        display: none;
      }
    }

    .ant-select-selection-placeholder {
      color: ${NEUTRAL_10_COLOUR};
    }

    .ant-select-arrow {
      margin-right: -4px;
    }
  }
`;

const AppSelect = styled(StyledSelect)<{ $isOpen?: boolean; $condensedMode?: boolean }>`
  &&&& {
    width: auto;
    min-width: ${({ $isOpen, $condensedMode }) => ($isOpen && !$condensedMode ? '196px' : 'none')};
    max-width: ${({ $condensedMode }) => ($condensedMode ? '22px' : 'none')};

    @media (min-width: ${BREAKPOINT_1.minWidth}) {
      ${({ $condensedMode }) => $condensedMode && 'max-width: 140px'};
    }

    ${({ $condensedMode }) => $condensedMode && 'margin-right: 0px'};

    &&&& .ant-select-selector {
      ${({ $condensedMode }) => $condensedMode && 'padding-right: 5px'};
    }
  }
`;

const AppSelectOption = styled.div`
  color: ${NEUTRAL_9_COLOUR};
  display: flex;
  align-items: center;
`;

const AppIdLabel = styled.span`
  color: ${NEUTRAL_7_COLOUR};
  font-size: 12px;
  margin-left: 5px;
`;

const AppIcon = styled.div<{ $backgroundImage?: string; $cursor?: string }>`
  background-image: ${({ $backgroundImage }) => ($backgroundImage ? `url(${$backgroundImage})` : undefined)};
  background-size: cover;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 4px;
  flex-shrink: 0;
  ${({ $cursor }) => $cursor && `cursor: ${$cursor}`};
`;

const ChevronDown = styled(ChevronDownIcon)`
  &&&& {
    font-size: 16px;
    color: ${NEUTRAL_9_COLOUR};
    pointer-events: none !important; // Make icon clickable (AntD bug workaround)
  }
`;

const OuterPadding = styled.div`
  padding: 4px;
  margin-left: -4px; // Left padding is clickable but doesnt take up space
`;

interface ColorProps {
  $color: string;
}

const ColorCircle = styled.div<ColorProps>`
  border-radius: 50%;
  width: 12px;
  height: 12px;
  background-color: ${({ $color }) => $color};
  margin-right: 4px;
`;

// Padding to make the tooltip easier to trigger
const PaddedCircle = ({ $color, ...props }: ColorProps) => {
  return (
    <OuterPadding {...props}>
      <ColorCircle $color={$color} />
    </OuterPadding>
  );
};

const getAppIcon = (app: { PaymentStatus: number }, statuses: PaymentStatus[] = []) => {
  const name = statuses.find((status) => status.Id === app.PaymentStatus)?.Name || 'Unknown';

  const ICON_COLORS: Record<number, string> = {
    [PAYMENT_STATUS_CHURNED]: DANGER_COLOUR,
    [PAYMENT_STATUS_DNE]: DANGER_COLOUR,
    [PAYMENT_STATUS_TEST]: WARNING_COLOUR,
    [PAYMENT_STATUS_INVOICED]: SUCCESS_COLOUR,
    [PAYMENT_STATUS_DETAILS_SAVED]: SUCCESS_COLOUR,
    [PAYMENT_STATUS_UNPAID]: HIGHLIGHT_PRIMARY_COLOUR,
    [PAYMENT_STATUS_UNPAID_DEPRECATED]: HIGHLIGHT_PRIMARY_COLOUR,
  };

  if (app?.PaymentStatus) {
    return (
      <Tooltip
        title={name}
        icon={<PaddedCircle $color={ICON_COLORS[app.PaymentStatus] ?? WARNING_COLOUR} />}
        placement="left"
        className="app-status-icon"
      />
    );
  }
  return null;
};

export const AppSelector = () => {
  const { data: availableApps } = useAvailableApps();
  const appId = useAppBeingEdited();
  const { data: basicInfo } = useAppBasicInfo();
  const [appSelectOpen, setAppSelectOpen] = useState(false);
  const { data: statuses } = usePaymentStatuses();
  const location = useLocation();
  const history = useHistory();

  const condensedMode = isUserAdmin() && location.pathname.startsWith('/build-my-app');

  const SORT_STATUSES = [
    PAYMENT_STATUS_INVOICED,
    PAYMENT_STATUS_DETAILS_SAVED,
    PAYMENT_STATUS_UNPAID,
    PAYMENT_STATUS_UNPAID_DEPRECATED,
    PAYMENT_STATUS_TEST,
    PAYMENT_STATUS_CHURNED,
    PAYMENT_STATUS_DNE,
  ];
  const sortedApps = useMemo(() => {
    return availableApps?.sort((a, b) => {
      const aStatus = a.PaymentStatus;
      const bStatus = b.PaymentStatus;
      return SORT_STATUSES.indexOf(aStatus) - SORT_STATUSES.indexOf(bStatus);
    });
  }, [availableApps]);

  const iconPath = basicInfo?.AppIconImage ? `${S3_BUCKET}${appId}/images/${basicInfo?.AppIconImage}` : undefined;
  const activeAppTitle = basicInfo?.AppleTitle;

  if (!availableApps || !activeAppTitle) {
    return null;
  }

  if (availableApps.length === 1) {
    return (
      <ActiveAppLabelWrapper>
        <AppIcon $backgroundImage={iconPath} />
        <ActiveAppLabel>{activeAppTitle}</ActiveAppLabel>
      </ActiveAppLabelWrapper>
    );
  }

  return (
    <AppSelectWrapper>
      {isUserAdmin() ? (
        <ClickToCopy copyValue={basicInfo.AppId} showValueInTooltip tooltipPlacement="bottomLeft" displayWordArt>
          <AppIcon $backgroundImage={iconPath} $cursor="pointer" />
        </ClickToCopy>
      ) : (
        <AppIcon $backgroundImage={iconPath} />
      )}
      <AppSelect
        $isOpen={appSelectOpen}
        $condensedMode={condensedMode}
        defaultValue={appId}
        dropdownMatchSelectWidth={false}
        listHeight={400}
        showSearch
        suffixIcon={!appSelectOpen ? <ChevronDown /> : null}
        onDropdownVisibleChange={(open) => setAppSelectOpen(open)}
        filterOption={(input, option) => option?.label.toLowerCase().includes(input.toLowerCase())}
        notFoundContent="No Results"
        onChange={(value) => {
          localStorage.setItem('appBeingEdited', value as string);
          history.push('/');
          window.location.reload();
        }}
        getPopupContainer={() => document.getElementById('react-app') as HTMLElement}
      >
        {sortedApps?.map((app) => {
          return (
            <Option key={app.AppId} value={app.AppId} label={`${app.AppleTitle} ${app.AppId}`}>
              <AppSelectOption>
                {isUserAdmin() && getAppIcon(app, statuses)}
                {app.AppleTitle} <AppIdLabel>({app.AppId})</AppIdLabel>
              </AppSelectOption>
            </Option>
          );
        })}
      </AppSelect>
    </AppSelectWrapper>
  );
};
