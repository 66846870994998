import styled from 'styled-components';

import { S3_BUCKET } from 'api';
import { useAppBeingEdited } from 'app-context';
import {
  APP_FONT_13PX_MEDIUM,
  APP_FONT_14PX_MEDIUM,
  APP_FONT_15PX_MEDIUM,
  APP_FONT_15PX_REGULAR,
  APP_FONT_18PX_SEMIBOLD,
  APP_FONT_20PX_BOLD,
  APP_FONT_22PX_BOLD,
  APP_FONT_26PX_BOLD,
} from 'mockup-font';
import { useLocalAppBasicInfo, useLocalAppProperties } from 'providers';
import { CustomColor, DEFAULT_THUMBNAIL, useCustomColor } from 'utils';

import { useAppTheme } from '../../hooks';
import { useMockupContext } from '../../providers';

const Wrapper = styled.div<{ $backgroundColor: string } & CustomColor>`
  width: 100%;
  min-height: 100%;
  cursor: default;
  color: ${useCustomColor};
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  padding: 0;
`;

const FeatureImageWrapper = styled.div`
  background-image: url(${DEFAULT_THUMBNAIL});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 188px;
`;

interface FeatureImageProps {
  backgroundImage: string;
}

const FeatureImage = styled(FeatureImageWrapper)<FeatureImageProps>`
  background-image: ${(props) => `url(${props.backgroundImage})`};
`;

const ContentWrapper = styled.div`
  padding: 15px 41px;
  text-align: center;
  min-height: 100%;
  display: flex;
  flex-direction: column;
`;

const Heading1 = styled.div`
  ${APP_FONT_26PX_BOLD};
  margin: 20px 0 5px;
  min-height: 32px;
  max-height: 130px;
  overflow: hidden;
  white-space: pre-wrap;
`;

const Heading2 = styled(Heading1)`
  ${APP_FONT_22PX_BOLD};
  margin-top: 77px;
  margin-bottom: 21px;
  max-height: 80px;
`;

interface SubheadingProps {
  marginBottom?: string;
  maxHeight?: string;
}

const Subheading = styled(Heading1)<SubheadingProps>`
  ${APP_FONT_15PX_REGULAR};
  margin-top: 16px;
  margin-bottom: ${(props) => props.marginBottom || '33px'};
  min-height: 13px;
  max-height: ${(props) => props.maxHeight || '52px'};
`;

interface ButtonProps {
  highlightColor: string;
}

const Button = styled.div<ButtonProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 53px;
  ${APP_FONT_18PX_SEMIBOLD};
  color: ${(props) => `#${props.highlightColor}`};
  border: ${(props) => `1.5px solid #${props.highlightColor}`};
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 9px 6px;
  white-space: pre-wrap;
`;

const PrimaryButton = styled(Button)<ButtonProps>`
  color: #fff;
  background-color: ${(props) => `#${props.highlightColor}`};
`;

const LargeButton = styled(PrimaryButton)`
  min-height: 130px;
  justify-content: space-between;
  padding: 13px 0;
`;

const Title = styled.div`
  ${APP_FONT_15PX_MEDIUM};
`;

const Price = styled.div`
  ${APP_FONT_20PX_BOLD};
  margin-bottom: 2px;
`;

const Trial = styled.div`
  ${APP_FONT_15PX_MEDIUM};
`;

const Period = styled.div`
  ${APP_FONT_13PX_MEDIUM};
`;

const Terms1 = styled.div`
  font-size: 20px;
  font-weight: 600;
  line-height: 23px;
  letter-spacing: -0.6px;
`;

const Terms2 = styled.div`
  ${APP_FONT_14PX_MEDIUM};
  margin-top: 35px;
  text-underline-offset: 1px;
`;

const Help = styled(Terms2)`
  margin-top: auto;
  margin-bottom: 22px;
`;

interface Screen1Props extends Screen2Props {
  featureImage: string;
  button1: string;
  button2: string;
  button3?: string;
}

interface Screen2Props {
  heading: string;
  subheading: string;
  highlightColor: string;
  appName?: string;
}

const Screen1 = ({ featureImage, heading, subheading, button1, button2, button3, highlightColor }: Screen1Props) => (
  <>
    <FeatureImageWrapper>{featureImage && <FeatureImage backgroundImage={featureImage} />}</FeatureImageWrapper>
    <ContentWrapper>
      <Heading1>{heading}</Heading1>
      <Subheading>{subheading}</Subheading>
      <PrimaryButton highlightColor={highlightColor}>{button1}</PrimaryButton>
      <Button highlightColor={highlightColor}>{button2}</Button>
      {typeof button3 === 'undefined' ? (
        <Terms2>
          <u>Restore existing iTunes purchases</u>
        </Terms2>
      ) : (
        <Button highlightColor={highlightColor}>{button3}</Button>
      )}
    </ContentWrapper>
  </>
);

const Screen2Subscription = ({ heading, subheading, highlightColor, appName }: Screen2Props) => (
  <ContentWrapper>
    <Heading2>{heading}</Heading2>
    <Subheading maxHeight="54px">{subheading}</Subheading>
    <LargeButton highlightColor={highlightColor}>
      <Title>{`${appName} Monthly`}</Title>
      <div>
        <Price>$XX.XX / month</Price>
        <Trial>7 Day Free Trial</Trial>
      </div>
      <Period>Monthly Subscription</Period>
    </LargeButton>
    <LargeButton highlightColor={highlightColor}>
      <Title>{`${appName} Yearly`}</Title>
      <div>
        <Price>$XX.XX / year</Price>
        <Trial>7 Day Free Trial</Trial>
      </div>
      <Period>Annual Subscription</Period>
    </LargeButton>
    <Terms1>
      This app will automatically renew your subscription to your iTunes account on a recurring basis until you cancel.
      Payments will continue unless you deactivate at least 24- hours prior to the end of the current cycle. Any unused
      portion of a free trial period, if offered, will be forfeited when the user purchases a subscription. You can
      cancel auto-renewal by going to the user’s Account Settings after purchase.
    </Terms1>
  </ContentWrapper>
);

const Screen2Purchase = ({ heading, subheading, highlightColor }: Screen2Props) => (
  <ContentWrapper>
    <Heading2>{heading}</Heading2>
    <Subheading>{subheading}</Subheading>
    <PrimaryButton highlightColor={highlightColor}>{`Product Name\n$XX.XX`}</PrimaryButton>
    <Terms2>
      By subscribing, you agree to our <u>Terms of Service</u> and <u>Privacy Policy</u>
    </Terms2>
    <Help>
      <b>Need Help?</b> <u>Contact Us</u>
    </Help>
  </ContentWrapper>
);

export const PurchaseScreensView = () => {
  const { getDesignProperty } = useAppTheme();
  const PrimaryItemHEX = getDesignProperty('primaryItemHEX') as string;
  const BackgroundHEX = getDesignProperty('backgroundHEX') as string;
  const { purchaseScreenView } = useMockupContext();
  const { properties } = useLocalAppProperties();
  const { basicInfo } = useLocalAppBasicInfo();
  const appId = useAppBeingEdited();

  const appName = basicInfo.AppleTitle;

  const {
    HighlightHEX,
    CustomPurchaseScreen_BrowseButtonText: BrowseText,
    CustomPurchaseScreen1_SubscriptionOnly_Text1: Subscription1Heading,
    CustomPurchaseScreen1_SubscriptionOnly_Text2: Subscription1Subheading,
    CustomPurchaseScreen1_SubscriptionOnly_SubscribeButtonText: Subscription1Subscribe,
    CustomPurchaseScreen1_SubscriptionOnly_LoginButtonText: Subscription1Login,
    CustomPurchaseScreen2_SubscriptionOnly_Text1: Subscription2Heading,
    CustomPurchaseScreen2_SubscriptionOnly_Text2: Subscription2Subheading,
    CustomPurchaseScreen1_BuyToOwnOnly_Text1: Purchase1Heading,
    CustomPurchaseScreen1_BuyToOwnOnly_Text2: Purchase1Subheading,
    CustomPurchaseScreen1_BuyToOwnOnly_BuyToOwnButtonText: Purchase1Buy,
    CustomPurchaseScreen1_BuyToOwnOnly_LoginButtonText: Purchase1Login,
    CustomPurchaseScreen2_BuyToOwnOnly_Text1: Purchase2Heading,
    CustomPurchaseScreen2_BuyToOwnOnly_Text2: Purchase2Subheading,
    AndroidFeatureGraphic,
  } = properties;

  const featureImageURL = `${S3_BUCKET}${appId}/images/${AndroidFeatureGraphic}`;

  return (
    <Wrapper $color={PrimaryItemHEX} $backgroundColor={BackgroundHEX}>
      {purchaseScreenView === 'Subscription2' ? (
        <Screen2Subscription
          heading={Subscription2Heading}
          subheading={Subscription2Subheading}
          highlightColor={HighlightHEX}
          appName={appName}
        />
      ) : purchaseScreenView === 'Purchase1' ? (
        <Screen1
          featureImage={featureImageURL}
          heading={Purchase1Heading}
          subheading={Purchase1Subheading}
          button1={Purchase1Buy || 'Buy-to-own'}
          button2={Purchase1Login || 'Already a member? Sign in'}
          highlightColor={HighlightHEX}
        />
      ) : purchaseScreenView === 'Purchase2' ? (
        <Screen2Purchase heading={Purchase2Heading} subheading={Purchase2Subheading} highlightColor={HighlightHEX} />
      ) : (
        <Screen1
          featureImage={featureImageURL}
          heading={Subscription1Heading}
          subheading={Subscription1Subheading}
          button1={Subscription1Subscribe || 'Join now'}
          button2={BrowseText || 'Explore the app'}
          button3={Subscription1Login || 'Already a member? Sign in'}
          highlightColor={HighlightHEX}
        />
      )}
    </Wrapper>
  );
};
