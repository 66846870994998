import styled from 'styled-components';
import { Typography } from 'antd';
import { Draggable } from 'react-beautiful-dnd';

import { CustomButton, DragHandleDots, Thumbnail } from 'components';
import { FONT_12PX_REGULAR } from 'font';
import { TrashIcon } from 'icons';

import { ItemIcon } from 'app/modules/calendar/CalendarPage/components';
import { ScheduledItemType } from './CalendarModal';
import { getTypeLabel } from '../utils';

const ScheduledItemContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  background-color: #fff;
  border-radius: 8px;
`;

const Spacer = styled.div`
  width: 100%;
  height: 8px;
`;

const Details = styled.div`
  flex: 1 1 auto;
  height: 100%;
  padding: 11px 13px 11px 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Title = styled(Typography.Paragraph)`
  #react-app && {
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    word-break: break-word;
  }
`;

const TypeWrapper = styled.div`
  font-size: 12px;
  display: flex;
  align-items: center;
`;

const Type = styled.div`
  margin-left: 5px;
  ${FONT_12PX_REGULAR};
  text-transform: capitalize;
`;

interface ScheduledItemProps {
  item: ScheduledItemType;
  index: number;
  handleDeleteItem: (id: number) => void;
}

export const ScheduledItem = ({ item, index, handleDeleteItem }: ScheduledItemProps) => {
  const { id, sourceId, title, thumbnail, duration, type, dataSource } = item;

  return (
    <Draggable draggableId={id.toString()} index={index} key={id}>
      {(provided) => (
        <div ref={provided.innerRef} {...provided.draggableProps}>
          <ScheduledItemContainer key={id}>
            <DragHandleDots
              style={{ color: '#bfbfbf', width: '39px', fontSize: '18px' }}
              {...provided.dragHandleProps}
            />
            <Thumbnail url={thumbnail} width="104px" height="58px" duration={duration} />
            <Details>
              <Title ellipsis={{ rows: 2 }}>{title}</Title>
              <TypeWrapper>
                <ItemIcon type={getTypeLabel(type, dataSource)} />
                <Type>
                  {getTypeLabel(type, dataSource)} - {sourceId}
                </Type>
              </TypeWrapper>
            </Details>
            <CustomButton tertiary danger icon={<TrashIcon />} onClick={() => handleDeleteItem(id)} />
          </ScheduledItemContainer>
          <Spacer />
        </div>
      )}
    </Draggable>
  );
};
