import { useEffect, useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { CheckboxValueType } from 'antd/lib/checkbox/Group.d';
import { format, isSameMonth } from 'date-fns';

import { CalendarDay, CalendarType, Collection, GetVideosResponse, ThumbnailSize, Video } from 'api';

import { CollectionCollection, PublishedStatus, VideoCollection } from '../CalendarPage';
import { CalendarModal, ItemIcon } from 'app/modules/calendar/CalendarPage/components';
import { getTypeLabel } from '../utils';
import { FONT_10PX_MEDIUM, FONT_12PX_MEDIUM, OVERFLOW_ELLIPSIS } from 'font';
import { NEUTRAL_8_COLOUR } from 'theme';

const ITEMS_TO_DISPLAY = 3;

const CalendarCellContainer = styled.div`
  height: calc((100vh - 273px) / 6);
  min-height: 106px;
  max-height: 158px;
  padding: 4px;
  text-align: left;
`;

const DateLabel = styled.div`
  ${FONT_12PX_MEDIUM};
  color: ${NEUTRAL_8_COLOUR};
`;

const CellItemsList = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 8px;
`;

const CellItem = styled.div`
  height: 18px;
  display: flex;
  align-items: center;
  margin-bottom: 1px;
`;

const CellItemIcon = styled.span`
  margin-right: 3px;
  font-size: 12px;
`;

const CellItemTitle = styled.span`
  ${FONT_10PX_MEDIUM};
  ${OVERFLOW_ELLIPSIS};
`;

const MoreLabel = styled.div`
  ${FONT_10PX_MEDIUM};
  color: ${NEUTRAL_8_COLOUR};
  height: 19px;
  display: flex;
  align-items: center;
`;

interface CellItem {
  title: string;
  icon: JSX.Element;
}

interface CalendarCellProps {
  date: Date;
  activeDate: Date;
  data?: CalendarType;
  videos?: GetVideosResponse;
  videoCollections: VideoCollection[];
  collectionCollections: CollectionCollection[];
  displayedItems: CheckboxValueType[];
  publishedStatus: PublishedStatus;
  getCalendarDay: (date: Date) => CalendarDay | null;
  getVideoById: (videoId: number) => Video | null;
  getCollectionById: (collectionId: number) => Collection | null;
  getVideoThumbnailUrl: (videoId: number, size: ThumbnailSize) => string | null;
  getCollectionThumbnailUrl: (collectionId: number, size: ThumbnailSize) => string | null;
  isWorkout: (collectionId: number) => boolean;
  setRefetchRequired: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CalendarCell = ({
  date,
  activeDate,
  data,
  videos,
  videoCollections,
  collectionCollections,
  displayedItems,
  publishedStatus,
  getCalendarDay,
  getVideoById,
  getCollectionById,
  getVideoThumbnailUrl,
  getCollectionThumbnailUrl,
  isWorkout,
  setRefetchRequired,
}: CalendarCellProps) => {
  const [cellItems, setCellItems] = useState<CellItem[]>();
  const [calendarDay, setCalendarDay] = useState<CalendarDay>();
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [modalVisible, setModalVisible] = useState(false);
  const [remaining, setRemaining] = useState<number>(0);

  const isInView = useMemo(() => isSameMonth(activeDate, date), [activeDate, date]);

  useEffect(() => {
    setCalendarDay(undefined); // Reset the calendarDay state for the cell
  }, [activeDate]); // When the calendar month changes

  useEffect(() => {
    const day = getCalendarDay(date);
    if (day) {
      setCalendarDay(day); // Set the calendarDay state for the cell with the API data
    }
  }, [data, getCalendarDay(date)]); // When the calendar data is updated from the API

  useEffect(() => {
    const items: CellItem[] = [];
    if (calendarDay && videos) {
      calendarDay.Items.forEach((item) => {
        if (item.Type === 'video' && displayedItems.includes('Videos / Posts')) {
          const video = getVideoById(item.Id);
          video &&
            items.push({
              title: video.Title ?? 'video',
              icon: <ItemIcon type={getTypeLabel(item.Type, video.DataSource)} />,
            });
        } else if (item.Type === 'collection' && isWorkout(item.Id) && displayedItems.includes('Workouts')) {
          const collection = getCollectionById(item.Id);
          collection &&
            items.push({
              title: collection.Name ?? 'workout',
              icon: <ItemIcon type="workout" />,
            });
        }
      });
      calendarDay.Quote &&
        displayedItems.includes('Quotes') &&
        items.push({
          title: calendarDay.Quote,
          icon: <ItemIcon type="quote" />,
        });
    }
    setCellItems(items.slice(0, ITEMS_TO_DISPLAY));
    setRemaining(items.length - ITEMS_TO_DISPLAY);
  }, [calendarDay, videos, displayedItems]); // When calendarDay state is updated, or displayedItems is changed

  const handleDateSelect = useCallback(
    (date: Date, isInView: boolean) => {
      if (isInView) {
        setSelectedDate(date);
        setModalVisible(true);
      }
    },
    [setSelectedDate, setModalVisible],
  );

  return (
    <>
      <CalendarCellContainer onClick={() => handleDateSelect(date, isInView)}>
        <DateLabel className="date-label">{format(date, 'dd')}</DateLabel>
        <CellItemsList>
          {cellItems?.map((item, idx) => (
            <CellItem key={idx}>
              <CellItemIcon>{item.icon}</CellItemIcon>
              <CellItemTitle>{item.title}</CellItemTitle>
            </CellItem>
          ))}
        </CellItemsList>
        {remaining > 0 && <MoreLabel>{remaining} more...</MoreLabel>}
      </CalendarCellContainer>
      <CalendarModal
        modalVisible={modalVisible}
        date={selectedDate}
        videoCollections={videoCollections}
        collectionCollections={collectionCollections}
        publishedStatus={publishedStatus}
        calendarDay={calendarDay}
        setCalendarDay={setCalendarDay}
        setModalVisible={setModalVisible}
        getVideoById={getVideoById}
        getCollectionById={getCollectionById}
        isWorkout={isWorkout}
        getVideoThumbnailUrl={getVideoThumbnailUrl}
        getCollectionThumbnailUrl={getCollectionThumbnailUrl}
        setRefetchRequired={setRefetchRequired}
      />
    </>
  );
};
