import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';

import { useAppBeingEdited } from 'app-context';
import {
  CustomButton,
  CustomCell,
  CustomTable2,
  HighlightText,
  SearchBar,
  SegmentTitle,
  SettingsCheckboxInput,
  TableColumn,
  Thumbnail,
} from 'components';
import { FONT_10PX_MEDIUM, FONT_12PX_MEDIUM, FONT_14PX_MEDIUM, OVERFLOW_ELLIPSIS } from 'font';
import { PenIcon } from 'icons';
import {
  BuilderCollection,
  BuilderProduct,
  BuilderVideo,
  useContent,
  useLocalProducts,
  useSaveContext,
} from 'providers';
import { NEUTRAL_4_COLOUR, NEUTRAL_5_COLOUR } from 'theme';
import { DEFAULT_THUMBNAIL, getContentThumbnailUrl, getDataSourceDisplayName, getSourceTypeDisplayName } from 'utils';

interface OfferContentItem {
  title: string;
  type: string;
  dataSource: string;
  id: string | number;
  sourceId: string;
  thumbnail: string | null;
}

const Ellipsis = styled.div`
  ${OVERFLOW_ELLIPSIS};
  ${FONT_12PX_MEDIUM};
`;

const TitleWrapper = styled.div`
  span {
    ${FONT_14PX_MEDIUM};
  }

  max-width: 300px;
  ${OVERFLOW_ELLIPSIS};
`;

const ItemThumbnail = styled(Thumbnail)`
  margin-right: 8px;
`;

const TypeCell = styled.span`
  padding: 2px 4px;
  background-color: ${NEUTRAL_4_COLOUR};
  border-radius: 3px;
  ${FONT_10PX_MEDIUM};
  text-transform: uppercase;
  user-select: none;
  width: fit-content;
  margin-top: 5px;
`;

const HeaderSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
`;

const Divider = styled.div`
  margin-bottom: 16px;
  height: 1px;
  background-color: ${NEUTRAL_5_COLOUR};
`;

const CheckboxWrapper = styled.div`
  height: 30px;
`;

const StyledCheckbox = styled(SettingsCheckboxInput)`
  &&&& {
    margin-top: 0 !important;
    flex-direction: row-reverse;

    span {
      ${FONT_14PX_MEDIUM};
    }
  }
`;

const Wrapper = styled.div<{ $disabled?: boolean }>`
  opacity: ${({ $disabled }) => ($disabled ? 0.6 : 1)};
`;

interface OfferContentTableProps {
  product: BuilderProduct;
  setModalVisible: Dispatch<SetStateAction<boolean>>;
}

export const OfferContentTable = ({ product, setModalVisible }: OfferContentTableProps) => {
  const { getContentForItem } = useContent();
  const { setProductItems } = useLocalProducts();
  const { setProductValueToSave } = useSaveContext();
  const appId = useAppBeingEdited();
  const [searchValue, setSearchValue] = useState<string>('');

  const { Items, ProductId, Id } = product;

  const allIsChecked = useMemo(() => Items.some((item) => item.Type === 'all'), [Items]);

  const dataSource: OfferContentItem[] = useMemo(() => {
    const contentArr = Items.map((item) => {
      if (item.Type === 'video') {
        const content = getContentForItem(item);
        if (content) {
          const video = content as BuilderVideo;
          return {
            title: video.SourceTitle || video.Title,
            type: video.Type,
            dataSource: getDataSourceDisplayName(video.DataSource),
            id: video.VideoId,
            sourceId: video.SourceId,
            thumbnail: getContentThumbnailUrl(appId, video, 'small'),
          };
        }
      } else if (['tab', 'collection'].includes(item.Type)) {
        const content = getContentForItem(item);
        if (content) {
          const collection = content as BuilderCollection;
          return {
            title: collection.SourceName || collection.Name,
            type: getSourceTypeDisplayName(collection.SourceType, collection.DataSource),
            dataSource: getDataSourceDisplayName(collection.DataSource),
            id: collection.TabId,
            sourceId: collection.SourceId,
            thumbnail: getContentThumbnailUrl(appId, collection, 'small'),
          };
        }
      }

      // If content item can't be found, return invalid object which will be filtered out
      return {
        title: '',
        type: 'Video',
        dataSource: '',
        id: 0,
        sourceId: '',
        thumbnail: null,
      };
    });

    return contentArr.filter((item) => item.id !== 0);
  }, [Items, getContentForItem, appId]);

  const filteredDataSource = useMemo(() => {
    if (searchValue === '') {
      return dataSource;
    }

    const filterFunction = (value: string) => value.toLowerCase().includes(searchValue);

    return dataSource.filter(
      ({ title, id, sourceId }) => filterFunction(title) || filterFunction(id.toString()) || filterFunction(sourceId),
    );
  }, [dataSource, searchValue]);

  const columns: TableColumn<OfferContentItem & { id: string }>[] = useMemo(
    () => [
      {
        heading: 'Title',
        width: 'grow',
        render: ({ title, thumbnail }) => (
          <CustomCell>
            <ItemThumbnail
              url={thumbnail}
              width="43px"
              height="24px"
              border={thumbnail && thumbnail !== DEFAULT_THUMBNAIL ? 'none' : undefined}
              borderRadius="2px"
            />
            <TitleWrapper>
              <HighlightText text={title} highlight={searchValue} />
            </TitleWrapper>
          </CustomCell>
        ),
      },
      {
        heading: 'Type',
        width: 90,
        render: ({ type }) => (
          <CustomCell>
            <TypeCell>{type}</TypeCell>
          </CustomCell>
        ),
      },
      {
        heading: 'Source',
        width: 90,
        render: ({ dataSource }) => (
          <CustomCell>
            <TypeCell>{dataSource}</TypeCell>
          </CustomCell>
        ),
      },
      {
        heading: 'VidApp ID',
        width: 94,
        render: ({ id }) => (
          <CustomCell>
            <Ellipsis>{id}</Ellipsis>
          </CustomCell>
        ),
      },
      {
        heading: 'Source ID',
        width: 100,
        render: ({ sourceId }) => (
          <CustomCell>
            <Ellipsis>{sourceId}</Ellipsis>
          </CustomCell>
        ),
      },
    ],
    [searchValue],
  );

  const handleCheckboxChange = useCallback(
    (e: CheckboxChangeEvent) => {
      const updatedItems = Items.filter((item) => item.Type !== 'all');

      if (e.target.checked) {
        updatedItems.push({
          AppId: appId,
          ProductId,
          ChildId: 0,
          Type: 'all',
          AddedBy: 'builder',
        });
      }

      setProductItems(Id, updatedItems);
      setProductValueToSave(Id, 'Items', updatedItems);
    },
    [Items, ProductId, Id, appId, setProductItems, setProductValueToSave],
  );

  return (
    <>
      <HeaderSection>
        <SegmentTitle title="Included Content" $marginBottom="0" />
        <CheckboxWrapper>
          <StyledCheckbox
            label="Include All Content In Offer"
            defaultChecked={allIsChecked}
            onChange={handleCheckboxChange}
            reverseOrder
          />
        </CheckboxWrapper>
      </HeaderSection>
      <Divider />
      <Wrapper $disabled={allIsChecked}>
        <HeaderSection>
          <SearchBar placeholder="Search..." onSearch={(val) => setSearchValue(val.toLowerCase())} $width="286px" />
          <CustomButton
            medium
            tertiaryHighlight
            icon={<PenIcon />}
            onClick={() => setModalVisible(true)}
            disabled={allIsChecked}
          >
            Edit
          </CustomButton>
        </HeaderSection>
        <CustomTable2
          columns={columns}
          data={filteredDataSource.map((item) => ({ ...item, id: item.id.toString() }))}
          query={searchValue}
          emptyTitle="No Content"
          emptyDescription=""
          pageSize={25}
        />
      </Wrapper>
    </>
  );
};
