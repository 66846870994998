import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import { Heading, PageContainer } from 'components';
import { useAppProperties, useAppBasicInfo } from 'hooks';
import { BREAKPOINT_1, BREAKPOINT_2, BREAKPOINT_3, BREAKPOINT_4, PAGE_CONTAINER_NARROW_WIDTH } from 'theme';
import { SOURCE_KAJABI, SOURCE_VHX } from 'api';
import { useDataSource } from 'providers';

import { useAccountDetails } from 'app/modules/account/AccountDetails/hooks/useAccountDetails';
import { KeyAnalytics } from 'app/modules/analytics';

import { GuidedTour, OnboardingChecklist, TabNavigation } from './components';
import { OnboardingWizard } from 'app/modules/self-onboarding';

const Header = styled.div`
  width: 100%;
  margin-bottom: 10px;

  transition: 0.2s linear margin-top;
  margin-top: 0px;
  @media (min-width: ${BREAKPOINT_1.minWidth}) and (min-height: ${BREAKPOINT_1.minHeight}) {
    margin-top: 25px;
  }
  @media (min-width: ${BREAKPOINT_2.minWidth}) and (min-height: ${BREAKPOINT_2.minHeight}) {
    margin-top: 40px;
  }
  @media (min-width: ${BREAKPOINT_3.minWidth}) and (min-height: ${BREAKPOINT_3.minHeight}) {
    margin-top: 55px;
  }
  @media (min-width: ${BREAKPOINT_4.minWidth}) and (min-height: ${BREAKPOINT_4.minHeight}) {
    margin-top: 75px;
  }
`;

export const Dashboard = ({ forceWizardOpen }: { forceWizardOpen?: boolean }) => {
  const {
    data: appBasicInfo,
    isMigratedLegacy,
    isLoading: basicInfoIsLoading,
    isError: basicInfoIsError,
  } = useAppBasicInfo();
  const { data: properties, isLoading: propertiesIsLoading, isError: propertiesIsError } = useAppProperties();
  const { data: account, isLoading: accountIsLoading, isError: accountIsError } = useAccountDetails();
  const dataSource = useDataSource();
  const [wizardOpen, setWizardOpen] = useState(forceWizardOpen ?? false);

  const showChecklist = properties?.DisplayOnboardingChecklist !== '0'; // Should only be hidden if explicitly turned off
  const welcomeMessage = !showChecklist || isMigratedLegacy ? 'Welcome back' : 'Welcome';

  useEffect(() => {
    if (!!properties && !!appBasicInfo) {
      if (
        [SOURCE_VHX, SOURCE_KAJABI].includes(dataSource) &&
        !isMigratedLegacy &&
        properties?.OnboardingWizardCompleted !== '1'
      ) {
        setWizardOpen(true);
      }
    }
  }, [dataSource, appBasicInfo, isMigratedLegacy, properties]);

  const onWizardCancel = useCallback(() => {
    setWizardOpen(false);
  }, [setWizardOpen]);

  return (
    <>
      <PageContainer
        isLoading={basicInfoIsLoading || propertiesIsLoading || accountIsLoading}
        isError={basicInfoIsError || propertiesIsError || accountIsError}
        contentMaxWidth={{ small: PAGE_CONTAINER_NARROW_WIDTH, large: PAGE_CONTAINER_NARROW_WIDTH }}
      >
        <Header>
          <Heading heading={`${welcomeMessage}, ${account?.FirstName ?? ''}`} />
        </Header>
        <KeyAnalytics />
        <TabNavigation />
        {showChecklist && (
          <GuidedTour>
            <OnboardingChecklist />
          </GuidedTour>
        )}
      </PageContainer>
      {wizardOpen && <OnboardingWizard onCancel={onWizardCancel} />}
    </>
  );
};
