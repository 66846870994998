import { useCallback, ChangeEvent } from 'react';
import styled from 'styled-components';

import { useSaveContext, useLocalProducts, BuilderProduct } from 'providers';
import { SettingsSegment, ClickToCopy, SettingsTextInput } from 'components';
import { SOURCE_KAJABI } from 'api';

const StyledTextInput = styled(SettingsTextInput)`
  // Allow ClickToCopy to work with disabled input
  pointer-events: none;
`;

export const SourceOfferId = ({
  Id,
  SourceProductId,
  DataSource,
}: Pick<BuilderProduct, 'Id' | 'SourceProductId' | 'DataSource'>) => {
  const { setProductValue } = useLocalProducts();
  const { setProductValueToSave } = useSaveContext();

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      setProductValue(Id, 'SourceProductId', value);
      setProductValueToSave(Id, 'SourceProductId', value);
    },
    [setProductValue, setProductValueToSave, Id],
  );

  return (
    <SettingsSegment title="Source Offer ID">
      {DataSource === SOURCE_KAJABI ? (
        <ClickToCopy copyValue={SourceProductId}>
          <StyledTextInput value={SourceProductId} onChange={handleChange} $marginTop="0" disabled />
        </ClickToCopy>
      ) : (
        <SettingsTextInput value={SourceProductId} onChange={handleChange} $marginTop="0" />
      )}
    </SettingsSegment>
  );
};
