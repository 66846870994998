import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';

import { HIGHLIGHT_PRIMARY_COLOUR, NEUTRAL_10_COLOUR, NEUTRAL_5_COLOUR, NEUTRAL_8_COLOUR } from 'theme';
import { FONT_10PX_MEDIUM, FONT_30PX_SEMIBOLD } from 'font';
import { LoadingDots, Tooltip } from 'components';
import { InfoCircleIcon } from 'icons';

import { METRIC_PARAM } from '../const';

const Container = styled(Link)<{ $active?: boolean }>`
  &&&& {
    width: 33%;
    height: 97px;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    border: solid 1px ${NEUTRAL_5_COLOUR};
    outline: solid 1px transparent;
    border-color: ${({ $active }) => $active && HIGHLIGHT_PRIMARY_COLOUR};
    outline-color: ${({ $active }) => $active && HIGHLIGHT_PRIMARY_COLOUR};

    :hover {
      border-color: ${({ $active }) => ($active ? HIGHLIGHT_PRIMARY_COLOUR : NEUTRAL_10_COLOUR)};
    }
  }
`;

const Title = styled.div<{ $active?: boolean }>`
  width: fit-content;
  ${FONT_10PX_MEDIUM};
  color: ${({ $active }) => ($active ? NEUTRAL_10_COLOUR : NEUTRAL_8_COLOUR)};
`;

const StyledTooltip = styled(Tooltip)<{ $active?: boolean }>`
  &&&& {
    color: ${({ $active }) => ($active ? NEUTRAL_10_COLOUR : NEUTRAL_8_COLOUR)};
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 12px;
`;

const ValueRow = styled(Row)`
  height: 31px;
  margin-bottom: 0;
`;

const Value = styled.div`
  color: ${NEUTRAL_10_COLOUR};
  ${FONT_30PX_SEMIBOLD};
`;

const StyledIcon = styled(InfoCircleIcon)`
  font-size: 12px;
  margin-left: 4px;
`;

const LoadingDotsWrapper = styled.div`
  padding-left: 6px;
`;

interface CardProps {
  title: string;
  value?: number;
  isPercentage?: boolean;
  metric: string;
  tooltip?: string;
  active?: boolean;
  isLoading?: boolean;
}

export const Card = ({ title, value, isPercentage, metric, tooltip, active, isLoading, ...props }: CardProps) => {
  const formattedValue = !value ? '--' : isPercentage ? `${Math.round(value)}%` : value.toLocaleString();
  const { pathname } = useLocation();

  return (
    <Container
      to={`/analytics/viewership?${METRIC_PARAM}=${metric}`}
      replace={pathname !== '/'} // Only update history stack if coming from Dashboard
      $active={active}
      {...props}
    >
      <Row>
        <Title $active={active}>{title}</Title>
        {tooltip && <StyledTooltip title={tooltip} icon={<StyledIcon />} $active={active} />}
      </Row>
      <ValueRow>
        {isLoading ? (
          <LoadingDotsWrapper>
            <LoadingDots />
          </LoadingDotsWrapper>
        ) : (
          <Value>{formattedValue}</Value>
        )}
      </ValueRow>
    </Container>
  );
};
