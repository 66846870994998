import { useCallback } from 'react';
import styled from 'styled-components';

import { CustomButton, InfoModal } from 'components';
import { useDataSource, useLocalProducts } from 'providers';
import { SOURCE_KAJABI, SOURCE_USCREEN } from 'api';
import { UISyncIcon } from 'icons';
import { useAppProperties, useSyncProducts } from 'hooks';
import { isUserAdmin } from 'utils';

import { AddOfferButton } from './AddOfferButton';

const Buttons = styled.div`
  margin-bottom: 36px;
  display: flex;
`;

export const OffersHeading = () => {
  const { setActiveProductId } = useLocalProducts();
  const dataSource = useDataSource();
  const syncProducts = useSyncProducts();
  const { data: appProperties } = useAppProperties();

  const isReadOnly = appProperties?.RolloutOffersScreen !== '1' && !isUserAdmin();

  const handleSync = useCallback(() => {
    syncProducts.mutate({});

    InfoModal(`Syncing`, 'Your offers are being synced', 'success');
  }, [syncProducts]);

  return (
    <Buttons>
      {!isReadOnly && <AddOfferButton setActiveProductId={setActiveProductId} />}
      {[SOURCE_KAJABI, SOURCE_USCREEN].includes(dataSource) && (
        <CustomButton secondary icon={<UISyncIcon />} onClick={handleSync}>
          {`Sync ${dataSource} Offers`}
        </CustomButton>
      )}
    </Buttons>
  );
};
