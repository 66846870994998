import { ReactNode, useCallback, useMemo } from 'react';
import styled from 'styled-components';

import { Tabs } from 'components';
import {
  CIRCUIT_TEMPLATE_ID,
  GROUP_LABEL,
  SOURCE_CUSTOMAPI,
  SOURCE_JWPLAYER,
  SOURCE_KAJABI,
  SOURCE_TYPE_ARCHIVED,
  SOURCE_TYPE_BUILDMYAPP,
  SOURCE_TYPE_CALENDAR,
  SOURCE_TYPE_DRAG_DROP,
  SOURCE_TYPE_KAJABI_CAT,
  SOURCE_TYPE_KAJABI_PRODUCT,
  SOURCE_TYPE_KAJABI_SUBCAT,
  SOURCE_TYPE_SUBTAB,
  SOURCE_TYPE_TAG,
  SOURCE_TYPE_VHX_CAT,
  SOURCE_TYPE_VHX_MOVIE,
  SOURCE_TYPE_VHX_PLAYLIST,
  SOURCE_TYPE_VHX_SEAS,
  SOURCE_TYPE_VHX_SERIES,
  SOURCE_TYPE_WORKOUT,
  SOURCE_USCREEN,
  SOURCE_VHX,
  SOURCE_VIDAPP,
  SOURCE_VIMEO,
  SOURCE_WORDPRESS,
  SOURCE_YOUTUBE,
  TEXT_IMAGE_TEMPLATE,
  Video,
  WEBSITE_TEMPLATE,
  WORKOUT_OVERVIEW_ID,
} from 'api';
import { BuilderCollection, useContent, useDataSource } from 'providers';
import { collectionIsProtected, getDataSourceDisplayName, isSectionHeader } from 'utils';

import { ContentContainer, TabContent } from './components';
import { useContentNavigationContext } from '../../providers';

const { Root, List, Trigger, PillList, PillTrigger, Content } = Tabs;

const StyledRoot = styled(Root)`
  position: relative;
`;

const StyledPillList = styled(PillList)`
  margin-left: auto;
  width: fit-content;
  position: absolute;
  right: 0;
  top: 2px;
`;

const StyledList = styled(List)`
  margin-bottom: 24px;
`;
const FALLBACK_CREATE_DATE = '2000-01-01T00:00:00';

export const SyncedContent = () => {
  const { collections, videos } = useContent();
  const {
    activeTab,
    activeSubTab,
    setActiveTab,
    setActiveSubTab,
    setPage,
    clearSearch,
    isCMS,
    isSimpleCMS,
    circuitsEnabled,
  } = useContentNavigationContext();
  const appDataSource = useDataSource();

  const dataSource = useMemo(() => {
    if (appDataSource) {
      return ['MemberPress', 'WooCommerceSubscriptions', 'WishList', 'ActiveMember360', 'MemberMouse'].includes(
        appDataSource,
      )
        ? 'WordPress'
        : appDataSource;
    }
    return undefined;
  }, [appDataSource]);

  const getValidCollections = useCallback(
    (source: string) =>
      Object.values(collections)
        .filter(({ DataSource }) => DataSource === source)
        .filter(({ SourceId }) => !!SourceId)
        .sort((a, b) => (b.CreatedAt ?? FALLBACK_CREATE_DATE).localeCompare(a.CreatedAt ?? FALLBACK_CREATE_DATE)),
    [collections],
  );

  const getValidVideos = useCallback(
    (source: string) =>
      Object.values(videos)
        .filter(({ DataSource }) => DataSource === source)
        .filter(({ SourceId }) => !!SourceId)
        .filter((video) => !isSectionHeader(video))
        .sort((a, b) =>
          (b.CreatedAt ?? FALLBACK_CREATE_DATE).localeCompare(a.CreatedAt ?? FALLBACK_CREATE_DATE),
        ) as Video[],
    [videos],
  );

  const sortedCollections: Record<string, BuilderCollection[]> | undefined = useMemo(() => {
    if (dataSource && collections) {
      const collectionsObj: Record<string, BuilderCollection[]> = {};

      if (!isCMS) {
        collectionsObj[SOURCE_VIDAPP] = [];
        collectionsObj[SOURCE_TYPE_WORKOUT] = [];

        const vidAppCollections = getValidCollections(SOURCE_VIDAPP).filter(
          ({ Position, SourceId, TemplateId, SourceType }) =>
            (!Position || Position === 0) && // Filter out main tabs
            !collectionIsProtected(SourceId) && // Filter out default Self-Onboarding collections
            !SourceId.includes('TempTabId') && // Filter out temp tabs
            ![TEXT_IMAGE_TEMPLATE, WEBSITE_TEMPLATE, CIRCUIT_TEMPLATE_ID, WORKOUT_OVERVIEW_ID].includes(TemplateId) && // Filter out text/image, websites, circuits, workout overview
            ![
              SOURCE_TYPE_DRAG_DROP,
              SOURCE_TYPE_BUILDMYAPP,
              SOURCE_TYPE_CALENDAR,
              SOURCE_TYPE_SUBTAB,
              SOURCE_TYPE_ARCHIVED,
            ].includes(SourceType), // Filter D&D/BMA/Calendar collections,
        );

        vidAppCollections.forEach((collection) => {
          if (collection.SourceType === SOURCE_TYPE_WORKOUT) {
            collectionsObj[SOURCE_TYPE_WORKOUT].push(collection);
          } else {
            collectionsObj[SOURCE_VIDAPP].push(collection);
          }
        });
      }

      if (dataSource === SOURCE_KAJABI) {
        collectionsObj[SOURCE_TYPE_KAJABI_PRODUCT] = [];
        collectionsObj[SOURCE_TYPE_KAJABI_CAT] = [];
        collectionsObj[SOURCE_TYPE_KAJABI_SUBCAT] = [];
      } else if (dataSource === SOURCE_VHX) {
        collectionsObj[SOURCE_TYPE_VHX_CAT] = [];
        collectionsObj[SOURCE_TYPE_VHX_PLAYLIST] = [];
        collectionsObj[SOURCE_TYPE_VHX_SERIES] = [];
        collectionsObj[SOURCE_TYPE_VHX_MOVIE] = [];
        collectionsObj[SOURCE_TYPE_VHX_SEAS] = [];
      } else if (dataSource === SOURCE_USCREEN) {
        collectionsObj[SOURCE_TYPE_TAG] = [];
        collectionsObj[SOURCE_USCREEN] = [];
      }

      if (dataSource === SOURCE_KAJABI || dataSource === SOURCE_VHX) {
        getValidCollections(dataSource).forEach((collection) => {
          if (collectionsObj[collection.SourceType]) {
            collectionsObj[collection.SourceType].push(collection);
          }
        });
      } else if (dataSource === SOURCE_USCREEN) {
        getValidCollections(dataSource).forEach((collection) => {
          if (collectionsObj[collection.SourceType]) {
            collectionsObj[collection.SourceType].push(collection);
          } else {
            collectionsObj[dataSource].push(collection);
          }
        });
      } else {
        collectionsObj[dataSource] = getValidCollections(dataSource);
      }

      // Tabs for these data sources will only be displayed if the app has at least one collection for that data source
      const otherSources = [
        {
          dataSource: SOURCE_WORDPRESS,
          collections: getValidCollections(SOURCE_WORDPRESS),
        },
        {
          dataSource: SOURCE_VIMEO,
          collections: getValidCollections(SOURCE_VIMEO),
        },
        {
          dataSource: SOURCE_JWPLAYER,
          collections: getValidCollections(SOURCE_JWPLAYER),
        },
        {
          dataSource: SOURCE_YOUTUBE,
          collections: getValidCollections(SOURCE_YOUTUBE),
        },
      ];

      if (dataSource !== SOURCE_CUSTOMAPI) {
        otherSources.push({
          dataSource: SOURCE_CUSTOMAPI,
          collections: getValidCollections(SOURCE_CUSTOMAPI),
        });
      }

      otherSources.forEach(({ dataSource, collections }) => {
        if (collections.length > 0) {
          collectionsObj[dataSource] = collections;
        }
      });

      return collectionsObj;
    }
    return undefined;
  }, [dataSource, collections, isCMS, getValidCollections]);

  const sortedVideos: Record<string, Video[]> | undefined = useMemo(() => {
    if (dataSource && videos) {
      const videosObj: Record<string, Video[]> = {};

      if (dataSource !== SOURCE_WORDPRESS) {
        videosObj[dataSource] = getValidVideos(dataSource);
      }

      // Tabs for these data sources will only be displayed if the app has at least one video for that data source
      const otherSources = [
        {
          dataSource: SOURCE_WORDPRESS,
          videos: getValidVideos(SOURCE_WORDPRESS),
        },
        {
          dataSource: SOURCE_VIMEO,
          videos: getValidVideos(SOURCE_VIMEO),
        },
        {
          dataSource: SOURCE_JWPLAYER,
          videos: getValidVideos(SOURCE_JWPLAYER),
        },
        {
          dataSource: SOURCE_YOUTUBE,
          videos: getValidVideos(SOURCE_YOUTUBE),
        },
      ];

      if (dataSource !== SOURCE_CUSTOMAPI) {
        otherSources.push({
          dataSource: SOURCE_CUSTOMAPI,
          videos: getValidVideos(SOURCE_CUSTOMAPI),
        });
      }

      otherSources.forEach(({ dataSource, videos }) => {
        if (videos.length > 0) {
          videosObj[dataSource] = videos;
        }
      });

      return videosObj;
    }
    return undefined;
  }, [dataSource, videos, getValidVideos]);

  interface SubTabGroup {
    key: string;
    type: 'collections' | 'videos';
    label: string;
    dataSource?: string;
  }

  interface TabItem {
    key: string;
    label: string;
    children: ReactNode;
  }

  const getSubTabs = useCallback(
    (subTabsGroups: SubTabGroup[], hideAllTab?: boolean) => {
      const subTabArr: TabItem[] = [];
      const allCollections: BuilderCollection[] = [];
      const allVideos: Video[] = [];

      if (!!sortedCollections && !!sortedVideos) {
        subTabsGroups.forEach(({ key, type, label }) => {
          if (type === 'collections') {
            const content = sortedCollections[key];

            if (
              content?.length > 0 ||
              (isSimpleCMS && key === SOURCE_VIDAPP) ||
              (!isCMS && circuitsEnabled && key === SOURCE_TYPE_WORKOUT)
            ) {
              subTabArr.push({
                key: `${key}${type}`,
                label,
                children: (
                  <TabContent
                    searchPlaceholder={`Search ${label} (${content ? content.length : 0})...`}
                    sections={[{ key: label, type, collections: content ?? [] }]}
                    detailedView
                  />
                ),
              });

              content && allCollections.push(...content);
            }
          } else {
            const content = sortedVideos[key];

            if (content?.length > 0) {
              subTabArr.push({
                key: `${key}${type}`,
                label,
                children: (
                  <TabContent
                    searchPlaceholder={`Search ${label} (${content.length})...`}
                    sections={[{ key: label, type, videos: content }]}
                    detailedView
                  />
                ),
              });

              allVideos.push(...content);
            }
          }
        });

        if (!hideAllTab) {
          const key = subTabsGroups[0].key;

          // These groups aren't displayed as their own tab, but should be included in 'All' tab
          if (subTabsGroups[0].dataSource === SOURCE_KAJABI) {
            allCollections.push(
              ...sortedCollections[SOURCE_TYPE_KAJABI_CAT],
              ...sortedCollections[SOURCE_TYPE_KAJABI_SUBCAT],
            );
          } else if (subTabsGroups[0].dataSource === SOURCE_VHX) {
            allCollections.push(...sortedCollections[SOURCE_TYPE_VHX_SEAS]);
          }

          if (allCollections.length > 0 && allVideos.length > 0) {
            subTabArr.unshift({
              key: `${key}All`,
              label: 'All',
              children: (
                <TabContent
                  searchPlaceholder={`Search All (${allCollections.length + allVideos.length})...`}
                  sections={[{ key: 'All', type: 'all', collections: allCollections, videos: allVideos }]}
                  detailedView
                />
              ),
            });
          }
        }
      }

      return subTabArr;
    },
    [sortedCollections, sortedVideos, isSimpleCMS, circuitsEnabled],
  );

  const dataSourceTabs = useMemo(() => {
    if (dataSource) {
      const arr: TabItem[] = [];

      const tabs = [
        {
          dataSource: SOURCE_WORDPRESS,
          subTabs: getSubTabs([
            { key: SOURCE_WORDPRESS, type: 'collections', label: 'Playlists' },
            { key: SOURCE_WORDPRESS, type: 'videos', label: 'Posts' },
          ]),
        },
        {
          dataSource: SOURCE_VIMEO,
          subTabs: getSubTabs([
            { key: SOURCE_VIMEO, type: 'collections', label: 'Albums' },
            { key: SOURCE_VIMEO, type: 'videos', label: 'Videos' },
          ]),
        },
        {
          dataSource: SOURCE_JWPLAYER,
          subTabs: getSubTabs([
            { key: SOURCE_JWPLAYER, type: 'collections', label: 'Playlists' },
            { key: SOURCE_JWPLAYER, type: 'videos', label: 'Videos' },
          ]),
        },
        {
          dataSource: SOURCE_YOUTUBE,
          subTabs: getSubTabs([
            { key: SOURCE_YOUTUBE, type: 'collections', label: 'Playlists' },
            { key: SOURCE_YOUTUBE, type: 'videos', label: 'Videos' },
          ]),
        },
        {
          dataSource: SOURCE_VIDAPP,
          subTabs: circuitsEnabled
            ? getSubTabs(
                [
                  { key: SOURCE_VIDAPP, type: 'collections', label: `${GROUP_LABEL}s` },
                  { key: SOURCE_TYPE_WORKOUT, type: 'collections', label: 'Workouts' },
                ],
                true,
              )
            : getSubTabs([{ key: SOURCE_VIDAPP, type: 'collections', label: `${GROUP_LABEL}s` }], true),
        },
      ];

      if (dataSource !== SOURCE_CUSTOMAPI) {
        tabs.unshift({
          dataSource: SOURCE_CUSTOMAPI,
          subTabs: getSubTabs([
            { key: SOURCE_CUSTOMAPI, type: 'collections', label: 'Groups' },
            { key: SOURCE_CUSTOMAPI, type: 'videos', label: 'Videos' },
          ]),
        });
      }

      if (dataSource !== SOURCE_WORDPRESS) {
        if (dataSource === SOURCE_KAJABI) {
          tabs.unshift({
            dataSource: SOURCE_KAJABI,
            subTabs: getSubTabs([
              { key: SOURCE_TYPE_KAJABI_PRODUCT, type: 'collections', label: 'Courses', dataSource: SOURCE_KAJABI },
              { key: SOURCE_KAJABI, type: 'videos', label: 'Lessons', dataSource: SOURCE_KAJABI },
            ]),
          });
        } else if (dataSource === SOURCE_VHX) {
          tabs.unshift({
            dataSource: SOURCE_VHX,
            subTabs: getSubTabs([
              { key: SOURCE_TYPE_VHX_CAT, type: 'collections', label: 'Categories', dataSource: SOURCE_VHX },
              { key: SOURCE_TYPE_VHX_PLAYLIST, type: 'collections', label: 'Playlists', dataSource: SOURCE_VHX },
              { key: SOURCE_TYPE_VHX_SERIES, type: 'collections', label: 'Series', dataSource: SOURCE_VHX },
              { key: SOURCE_TYPE_VHX_MOVIE, type: 'collections', label: 'Movies', dataSource: SOURCE_VHX },
              { key: SOURCE_VHX, type: 'videos', label: 'Videos', dataSource: SOURCE_VHX },
            ]),
          });
        } else if (dataSource === SOURCE_USCREEN) {
          tabs.unshift({
            dataSource: SOURCE_USCREEN,
            subTabs: getSubTabs([
              { key: SOURCE_USCREEN, type: 'collections', label: 'Collections', dataSource: SOURCE_USCREEN },
              { key: SOURCE_TYPE_TAG, type: 'collections', label: 'Playlists (Tags)', dataSource: SOURCE_USCREEN },
              { key: SOURCE_USCREEN, type: 'videos', label: 'Videos', dataSource: SOURCE_USCREEN },
            ]),
          });
        } else {
          tabs.unshift({
            dataSource: dataSource,
            subTabs: getSubTabs([
              { key: dataSource, type: 'collections', label: 'Collections' },
              { key: dataSource, type: 'videos', label: 'Videos' },
            ]),
          });
        }
      }

      tabs.forEach(({ dataSource, subTabs }) => {
        if (subTabs?.length > 0) {
          arr.push({
            key: dataSource,
            label: getDataSourceDisplayName(dataSource),
            children: (
              <StyledRoot
                value={activeSubTab ?? subTabs[0].key}
                onValueChange={(activeKey) => {
                  setActiveSubTab(activeKey);
                  setPage(1);
                  clearSearch();
                }}
              >
                <StyledPillList>
                  {subTabs.map(({ key, label }) => (
                    <PillTrigger key={key} value={key}>
                      {label}
                    </PillTrigger>
                  ))}
                </StyledPillList>
                {subTabs.map(({ key, children }) => (
                  <Content key={key} value={key}>
                    {children}
                  </Content>
                ))}
              </StyledRoot>
            ),
          });
        }
      });

      return arr;
    }
    return [];
  }, [
    sortedCollections,
    sortedVideos,
    dataSource,
    activeSubTab,
    circuitsEnabled,
    setActiveSubTab,
    getSubTabs,
    setPage,
  ]);

  const isEmpty = dataSourceTabs.length === 0;

  return (
    <ContentContainer isEmpty={isEmpty}>
      <Root
        value={isEmpty ? undefined : activeTab ?? dataSourceTabs[0].key}
        onValueChange={(activeKey) => {
          setActiveTab(activeKey);
          setActiveSubTab(undefined);
          setPage(1);
          clearSearch();
        }}
      >
        <StyledList>
          {dataSourceTabs.map(({ key, label }) => (
            <Trigger key={key} value={key}>
              {label}
            </Trigger>
          ))}
        </StyledList>
        {dataSourceTabs.map(({ key, children }) => (
          <Content key={key} value={key}>
            {children}
          </Content>
        ))}
      </Root>
    </ContentContainer>
  );
};
