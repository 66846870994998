import { Link } from 'react-router-dom';
import styled from 'styled-components';
import * as amplitude from '@amplitude/analytics-browser';

import { BuilderCollection, useMainTabs } from 'providers';
import { HIGHLIGHT_PRIMARY_COLOUR, NEUTRAL_10_COLOUR, NEUTRAL_5_COLOUR } from 'theme';
import { BUILDER_IMAGES_DOMAIN, TintImage, getTabIconFromCollection, isContent1Tab } from 'utils';
import { FONT_12PX_MEDIUM, FONT_16PX_MEDIUM, OVERFLOW_ELLIPSIS } from 'font';
import { SegmentTitle, SettingsGroup } from 'components';
import { useAppBeingEdited } from 'app-context';

import { getPageName } from 'app/modules/build-screens/utils';

export const TAB_HEIGHT = '137px';
const GRID_GAP = '16px';

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-column-gap: ${GRID_GAP};
  grid-row-gap: ${GRID_GAP};
`;

const Tab = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 24px 12px 12px;
  width: 140px;
  height: ${TAB_HEIGHT};
  border-radius: 8px;
  border: 1px solid ${NEUTRAL_5_COLOUR};
  cursor: pointer;

  :hover {
    border-color: ${NEUTRAL_10_COLOUR};
  }
`;

const Details = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TabIcon = styled.div`
  height: 24px;
  margin-bottom: 8px;
`;

const TabName = styled.div`
  ${FONT_16PX_MEDIUM};
  ${OVERFLOW_ELLIPSIS};
  color: ${NEUTRAL_10_COLOUR};
  width: 100%;
  text-align: center;
`;

const Customize = styled.div`
  ${FONT_12PX_MEDIUM};
  color: ${HIGHLIGHT_PRIMARY_COLOUR};
`;

export const TabNavigationInner = () => {
  const appId = useAppBeingEdited();
  const { activeTabs } = useMainTabs();

  if (!activeTabs || activeTabs.length === 0) {
    return null;
  }

  const navigateToTab = (tab: BuilderCollection) => {
    const tabType = isContent1Tab(tab)
      ? 'Content1'
      : getPageName(tab) === 'Content'
      ? 'ContentOther'
      : getPageName(tab);
    amplitude.track('Tab opened via Dashboard', { appId, tabType });
  };

  return (
    <SettingsGroup>
      <SegmentTitle title="Tab Navigation" $marginBottom="12px" />
      <Grid>
        {activeTabs.map((tab) => {
          const { TabId, Name } = tab;
          const icon = getTabIconFromCollection(tab) || `${BUILDER_IMAGES_DOMAIN}/icons/blocked.png`;

          return (
            <Link to={{ pathname: `/builder/${tab.TabId}`, state: { viaDashboard: true } }}>
              <Tab key={TabId} onClick={() => navigateToTab(tab)}>
                <Details>
                  <TabIcon>
                    <TintImage src={icon} color={NEUTRAL_10_COLOUR} dimensions={{ width: 20, height: 20 }} />
                  </TabIcon>
                  <TabName>{Name}</TabName>
                </Details>
                <Customize>Customize</Customize>
              </Tab>
            </Link>
          );
        })}
      </Grid>
    </SettingsGroup>
  );
};
