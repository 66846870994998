import { useCallback, useState } from 'react';

import { ProgressTracking } from 'api';
import { LoadingSpinner, styledTable } from 'components';
import { ColumnHeader, ColumnSortIcon } from '../../components';
import { Table } from 'antd';

export interface TableRow extends ProgressTracking {
  key: unknown;
  VideoId: number;
  Title: string;
}

interface ProgressTrackingTableProps {
  data?: TableRow[];
}

interface SortType {
  dataIndex: 'Title' | 'SourceUserId' | 'StartDate' | 'MaxPercentage' | 'SourceId';
  order: 'descend' | 'ascend';
}

const StyledTable = styledTable<TableRow>(Table);

export const ProgressTrackingTable = ({ data }: ProgressTrackingTableProps) => {
  const [activeSort, setActiveSort] = useState<SortType>({ dataIndex: 'Title', order: 'descend' });

  const handleTableChange = useCallback(
    (pagination, filters, sorter) => {
      setActiveSort({ dataIndex: sorter.columnKey, order: sorter.order });
    },
    [setActiveSort],
  );

  return (
    <StyledTable
      dataSource={data}
      columns={[
        {
          title: (
            <ColumnHeader>
              Title
              <ColumnSortIcon active={activeSort.dataIndex === 'Title'} order={activeSort.order} />
            </ColumnHeader>
          ),
          dataIndex: 'Title',
          key: 'Title',
          width: 300,
          sorter: (a, b) => a.Title.toLowerCase().localeCompare(b.Title.toLowerCase()),
          showSorterTooltip: false,
        },
        {
          title: (
            <ColumnHeader>
              Source Id
              <ColumnSortIcon active={activeSort.dataIndex === 'SourceId'} order={activeSort.order} />
            </ColumnHeader>
          ),
          dataIndex: 'SourceId',
          key: 'SourceId',
          sorter: (a, b) => a.SourceId.toLowerCase().localeCompare(b.SourceId.toLowerCase()),
          showSorterTooltip: false,
        },
        {
          title: (
            <ColumnHeader>
              User Id
              <ColumnSortIcon active={activeSort.dataIndex === 'SourceUserId'} order={activeSort.order} />
            </ColumnHeader>
          ),
          dataIndex: 'SourceUserId',
          key: 'SourceUserId',
          sorter: (a, b) => a.SourceUserId.toLowerCase().localeCompare(b.SourceUserId.toLowerCase()),
          showSorterTooltip: false,
        },
        {
          title: (
            <ColumnHeader>
              Date
              <ColumnSortIcon active={activeSort.dataIndex === 'StartDate'} order={activeSort.order} />
            </ColumnHeader>
          ),
          dataIndex: 'StartDate',
          key: 'StartDate',
          sorter: (a, b) => a.StartDate.toLowerCase().localeCompare(b.StartDate.toLowerCase()),
          showSorterTooltip: {
            title: 'The date and time the user started watching the video',
            getPopupContainer: () => document.getElementById('react-app') as HTMLElement,
          },
        },
        {
          title: (
            <ColumnHeader>
              Percent Complete
              <ColumnSortIcon active={activeSort.dataIndex === 'MaxPercentage'} order={activeSort.order} />
            </ColumnHeader>
          ),
          dataIndex: 'MaxPercentage',
          key: 'MaxPercentage',
          sorter: (a, b) => Number.parseFloat(a.MaxPercentage) - Number.parseFloat(b.MaxPercentage),
          showSorterTooltip: {
            title: 'The percentage a video has been watched by the user.',
            getPopupContainer: () => document.getElementById('react-app') as HTMLElement,
          },
        },
      ]}
      sortDirections={['descend', 'ascend', 'descend']}
      pagination={{ pageSize: 20, showSizeChanger: false }}
      locale={{ emptyText: ' ' }}
      onChange={handleTableChange}
      rowKey={(record: TableRow) => `${record.SourceUserId}|${record.SourceId}`}
      loading={
        !data && {
          indicator: (
            <div style={{ marginTop: '13px' }}>
              <LoadingSpinner />
            </div>
          ),
        }
      }
    />
  );
};
