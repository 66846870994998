import { MutableRefObject, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useDraggable } from 'react-use-draggable-scroll';

import { BuilderCollection, useContent } from 'providers';
import {
  CALENDAR_TEMPLATE,
  COMMUNITY_TEMPLATE,
  FILTER_TEMPLATE,
  ITEM_TYPE_COLLECTION,
  ITEM_TYPE_VIDEO,
  MIXED_TEMPLATE,
  MY_LISTS_TEMPLATE,
  MY_PLAYLISTS_TEMPLATE,
  PROFILE_TEMPLATE,
  SUBNAV_TEMPLATE,
  WEBSITE_TEMPLATE,
} from 'api';

import { getScrollHistoryKey, useMockupContext } from 'app/modules/build-dragdrop/Builder/mockup/providers';
import {
  CalendarView,
  CollectionDetails,
  CommunityView,
  FilterView,
  LegacyView,
  MixedView,
  MyListsView,
  MyPlaylistsView,
  OnboardingScreensView,
  ProfileView,
  PurchaseScreensView,
  SubNavView,
  VideoDetails,
  WebsiteView,
} from 'app/modules/build-dragdrop/Builder/mockup/components';
import { CONTENT_TEMPLATES } from 'app/modules/build-dragdrop/Builder/mockup/const';

const ContentWrapper = styled.div<{ tabBarVisible?: boolean; navBarVisible?: boolean }>`
  display: flex;
  flex-direction: column;

  min-height: 500px;
  height: fit-content;
  border-radius: ${({ tabBarVisible, navBarVisible }) =>
    tabBarVisible && navBarVisible ? '0' : navBarVisible ? '0 0 40px 40px' : tabBarVisible ? '40px 40px 0 0' : '40px'};

  width: 100%;
  max-width: 410px;
  overflow-y: scroll;
  overflow-x: clip;
  flex-grow: 1;

  z-index: 1; // Above the content hover-zone for DragDrop

  ::-webkit-scrollbar {
    display: none;
  }
`;

const getTabComponent = (currentTab: BuilderCollection) => {
  if (CONTENT_TEMPLATES.includes(currentTab.TemplateId)) {
    return <CollectionDetails collection={currentTab} />;
  }

  switch (currentTab.TemplateId) {
    case SUBNAV_TEMPLATE:
      return <SubNavView />;
    case MIXED_TEMPLATE:
      return <MixedView collection={currentTab} />;
    case CALENDAR_TEMPLATE:
      return <CalendarView />;
    case FILTER_TEMPLATE:
      return <FilterView />;
    case MY_LISTS_TEMPLATE:
      return <MyListsView />;
    case MY_PLAYLISTS_TEMPLATE:
      return <MyPlaylistsView />;
    case PROFILE_TEMPLATE:
      return <ProfileView />;
    case WEBSITE_TEMPLATE:
      return <WebsiteView tab={currentTab} />;
    case COMMUNITY_TEMPLATE:
      return <CommunityView tab={currentTab} />;
    default:
      return <LegacyView tab={currentTab} />;
  }
};

const getTemplateComponent = (currentTemplateId: BuilderCollection['TemplateId']) => {
  switch (currentTemplateId) {
    case CALENDAR_TEMPLATE:
      return <CalendarView />;
    case FILTER_TEMPLATE:
      return <FilterView />;
    case MY_LISTS_TEMPLATE:
      return <MyListsView />;
    case MY_PLAYLISTS_TEMPLATE:
      return <MyPlaylistsView />;
    case PROFILE_TEMPLATE:
    default:
      return <ProfileView />;
  }
};

export const AppContent = () => {
  const { collections, videos } = useContent();
  const { currentTabId, currentTemplateId, currentPage, scrollPositionHistory, navBarHidden } = useMockupContext();
  const currentTab = currentTabId ? collections[currentTabId] : undefined;
  const ref = useRef<HTMLDivElement>() as MutableRefObject<HTMLInputElement>;
  const { events } = useDraggable(ref);

  useEffect(() => {
    ref.current.scrollTo(0, scrollPositionHistory[getScrollHistoryKey(currentPage)] || 0);
  }, [currentPage]);

  return (
    <ContentWrapper
      id="Builder__Content"
      {...events}
      ref={ref}
      tabBarVisible={!!currentTabId}
      navBarVisible={!navBarHidden}
    >
      {currentPage?.itemType === 'OnboardingScreens' ? (
        <OnboardingScreensView />
      ) : currentPage?.itemType === 'PurchaseScreens' ? (
        <PurchaseScreensView />
      ) : currentPage?.itemId && currentPage?.itemType === ITEM_TYPE_VIDEO ? (
        /* Render the CurrentPage as Video Details */
        <VideoDetails
          key={currentPage.tabItemId}
          video={videos[currentPage.itemId]}
          parentCollection={currentPage?.parentCollectionId ? collections[currentPage?.parentCollectionId] : undefined}
        />
      ) : currentPage?.itemId && currentPage.itemType === ITEM_TYPE_COLLECTION ? (
        /* Render the CurrentPage as Collection Details */
        <CollectionDetails collection={collections[currentPage.itemId]} />
      ) : currentTab ? (
        /* Render the CurrentTab */
        getTabComponent(currentTab)
      ) : currentTemplateId ? (
        /* Render the current template ID */
        getTemplateComponent(currentTemplateId)
      ) : null}
    </ContentWrapper>
  );
};
