import { useEffect, useMemo, useRef, useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { Editor } from '@tinymce/tinymce-react';

import { getCollectionDescription, getCollectionHTMLDescription } from 'utils';
import { FONT_12PX_MEDIUM } from 'font';
import { DANGER_COLOUR } from 'theme';
import { useAppBeingEdited } from 'app-context';
import { CustomButton, Segment, SegmentProps, SegmentTitle } from 'components';
import { RevertIcon } from 'icons';
import { useUploadFile } from 'hooks';

const StyledSegment = styled(Segment)`
  position: relative;
  margin-top: 0;
  margin-bottom: 0;
`;

const BottomButton = styled(CustomButton)`
  position: absolute;
  bottom: -58px;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;
const ErrorText = styled.div`
  color: ${DANGER_COLOUR};
  ${FONT_12PX_MEDIUM};
`;

const ALLOWED_IMAGE_TYPES = ['image/jpeg', 'image/png', 'image/gif'];

interface ContentDescriptionProps extends SegmentProps {
  description?: string;
  sourceDescription?: string;
  controlledDesc?: string;
  onDescChange: (description: string) => void;
  revertText?: string;
  hideTitle?: boolean;
  bottomRevertButton?: boolean;
  height?: number;
  maxHeight?: number;
}

export const ContentDescription = ({
  description,
  sourceDescription,
  controlledDesc,
  onDescChange,
  revertText,
  hideTitle,
  bottomRevertButton,
  height,
  maxHeight,
  ...props
}: ContentDescriptionProps) => {
  const appId = useAppBeingEdited();
  const uploadFile = useUploadFile();

  const URL_PREFIX = `https://vidapp-bucket.s3.amazonaws.com/${appId}/images/`;

  const processedSourceDesc = useMemo(() => {
    if (sourceDescription) {
      return getCollectionHTMLDescription({ SourceDescription: sourceDescription, Description: '' });
    }
    return undefined;
  }, [sourceDescription]);

  const [desc, setDesc] = useState(
    description
      ? getCollectionDescription({
          Description: description,
          SourceDescription: processedSourceDesc ?? '',
        })
      : '',
  );

  // Used for controlled input
  // controlledDesc is the source of truth
  useEffect(() => {
    if (controlledDesc != undefined) {
      setDesc(controlledDesc);
    }
  }, [controlledDesc]);

  const initRef = useRef<boolean>(false); // Ignore first change on mount
  const [error, setError] = useState<string>('');

  const handleRevert = () => {
    // There is only revert if there is a source description
    if (!!processedSourceDesc && !!sourceDescription) {
      setDesc(processedSourceDesc);
      initRef.current = false; //Ignore the editorChange call caused by this
      onDescChange(sourceDescription);
    }
  };

  // Hide errors after a delay
  useEffect(() => {
    if (error) {
      setTimeout(() => setError(''), 5000);
    }
  }, [error]);

  const handleImageUploadAndUpdateDesc = async (file: File) => {
    if (!ALLOWED_IMAGE_TYPES.includes(file.type)) {
      const err = `Unsupported image type ${file.type}`;
      setError(err);
      throw err;
    }

    switch (file.name.split('.').pop()) {
      case 'webp':
        const err = 'Unsupported image type';
        setError(err);
        throw err;
      default:
        const prefix = `${appId}/images/`;
        // Given a File object, upload it to the images bucket
        uploadFile.mutate(
          { file: file, options: { filePath: prefix } },
          {
            onSuccess: (filename) => {
              const url = `${URL_PREFIX}${filename}`;
              const imgTag = `<img src="${url}" style="display: block;margin: 0 auto;text-align: center; max-width:100%;" />`;

              // TinyMCE wraps its content
              setDesc((currentDesc) => {
                const lastParagraph = currentDesc.lastIndexOf('</p>');
                let newDesc = '';
                if (lastParagraph) {
                  newDesc = currentDesc.substring(0, lastParagraph) + imgTag + currentDesc.substring(lastParagraph);
                } else {
                  newDesc = currentDesc + imgTag;
                }
                onDescChange(newDesc);
                return newDesc;
              });
            },
          },
        );
    }
  };

  // Remove an img tag with the specific source from the description(value)
  const handleImageTagRemove = (value: string, source: string) => {
    const imgTag = value.match(new RegExp(`<img\\s+[^>]*src="(${source})"[^>]*>`, 'i'));
    if (imgTag) {
      const updatedDesc = value.replace(imgTag[0], '');
      setDesc(updatedDesc);
      onDescChange(updatedDesc);
    }
  };

  return (
    <StyledSegment
      header={
        !hideTitle && (
          <Row>
            <SegmentTitle title="Description" />
            {!!processedSourceDesc &&
              description &&
              description != processedSourceDesc &&
              (revertText ? (
                <CustomButton tertiary medium icon={<RevertIcon />} onClick={handleRevert}>
                  {revertText}
                </CustomButton>
              ) : (
                <CustomButton tertiaryHighlight medium onClick={handleRevert}>
                  Revert to Source
                </CustomButton>
              ))}
          </Row>
        )
      }
      {...props}
    >
      <Editor
        apiKey="9rhe2jkork6bw4cbffogwf7qtuc9g6pkvvblgm1bra8k316x" // This is a free key that can be generated from any signup, its locked to specific domains
        value={desc}
        plugins={['lists', 'link', 'image', 'code', 'AwsS3Upload', 'textcolor', 'colorpicker']}
        toolbar="bold italic link code forecolor | alignleft aligncenter alignright alignjustify bullist numlist |  outdent indent removeformat AwsS3UploadButton"
        init={{
          placeholder: 'Enter your description',
          menubar: false,
          height: height ?? 250,
          max_height: maxHeight,
          toolbar_mode: 'scrolling',
          paste_as_text: true,
          paste_data_images: true,
          Awss3UploadSettings: {
            buttonText: 'Upload image', // optional
            uploadFn: async (editor: Editor, file: File, setProgress: (bool: boolean) => void) => {
              setProgress(true);
              try {
                await handleImageUploadAndUpdateDesc(file);
              } catch (e) {
                console.error(e);
                setError((error) => {
                  return error ? error : 'Unexpected error during upload';
                });
              } finally {
                setProgress(false);
              }
            },
            secondFileSelectedBeforeFirstUpload: {
              callback: () => {
                alert('You cannot upload because first upload is progressing');
              },
            },
          },
        }}
        onEditorChange={async (value) => {
          if (!initRef.current) {
            initRef.current = true;
            if (!!desc && !desc.startsWith('<p>')) {
              // In most cases, when we feed TinyMCE non-HTML text, it will automatically make this change on opening the editor
              // We don't want to save this change unless the user actually has continued editing, skip first change in these cases
              return;
            }
          }
          if (value === '') {
            setDesc('');
            onDescChange('');
          } else {
            const matches = value.matchAll(/src="([^"]*)"/gi);

            for (const match of matches) {
              // Handle the img pointing to local data
              const source = match[1];
              try {
                if (source.startsWith('data')) {
                  const response = await axios.get(source, { responseType: 'blob' });
                  if (!ALLOWED_IMAGE_TYPES.includes(response.data.type)) {
                    console.error('Unexpected image type cannot be pasted');
                    handleImageTagRemove(value, source);
                  } else {
                    const file = new File([response.data], `pasted_image`);
                    switch (file.name.split('.').pop()) {
                      case 'webp':
                        const err = 'Unsupported image type';
                        setError(err);
                        throw err;
                      default:
                        const prefix = `${appId}/images/`;
                        uploadFile.mutate(
                          { file: file, options: { filePath: prefix } },
                          {
                            onSuccess: (filename) => {
                              const url = `${URL_PREFIX}${filename}`;
                              const updatedDesc = value
                                .replace(source, url)
                                .replace(
                                  `"${url}"`,
                                  `"${url}" style="display: block;margin: 0 auto;text-align: center; max-width:100%;"`,
                                );
                              setDesc(updatedDesc);
                              onDescChange(updatedDesc);
                            },
                          },
                        );
                    }
                  }

                  return;
                } else if (source.startsWith('file')) {
                  // Gifs seem to upload with file urls instead of data, they are unsupported
                  handleImageTagRemove(value, source);
                }
              } catch (e) {
                console.error('Failed to upload image', e);
                handleImageTagRemove(value, source);
              }
            }
            if (desc != value) {
              setDesc(value);
              onDescChange(value);
            }
          }
        }}
      />
      {error && <ErrorText>{error}</ErrorText>}
      {bottomRevertButton &&
        !!processedSourceDesc &&
        desc != processedSourceDesc &&
        (revertText ? (
          <BottomButton tertiary medium icon={<RevertIcon />} onClick={handleRevert}>
            {revertText}
          </BottomButton>
        ) : (
          <BottomButton tertiaryHighlight medium onClick={handleRevert}>
            Revert to Source
          </BottomButton>
        ))}
    </StyledSegment>
  );
};
