const PARENT_SUBDIRECTORIES = ['content', 'design', 'community', 'app-store', 'analytics', 'admin'];

export const getScreenFromPathname = (pathname: string) => {
  // These are unique cases
  if (pathname === '/content') {
    return 'content';
  } else if (['/', '/onboarding'].includes(pathname)) {
    return 'dashboard';
  }

  const subdirectories = pathname.substring(1).split('/');
  // find the first subdirectory that isn't in PARENT_SUBDIRECTORIES
  return subdirectories.find((subdirectory) => !PARENT_SUBDIRECTORIES.includes(subdirectory));
};
