import { useEffect, useState } from 'react';
import styled from 'styled-components';

import {
  CollectionDescriptionPreview,
  CollectionTitle,
  ContentAdvancedSettings,
  ContentSourceTitle,
  SettingsCollectionThumbnail,
} from 'components';
import { useContent, useSaveContext } from 'providers';
import {
  DEFAULT_COLLECTION,
  GROUP_LABEL,
  SOURCE_TYPE_CHALLENGE,
  SOURCE_TYPE_COLLECTION,
  SOURCE_TYPE_COLLECTION_2,
  SOURCE_TYPE_COURSE,
  SOURCE_TYPE_OTHER,
  SOURCE_TYPE_PROGRAM,
  SOURCE_VIDAPP,
} from 'api';
import { getCollectionValuesFromNewCollection, getDataSourceDisplayName } from 'utils';
import { useAppBeingEdited } from 'app-context';
import { useTemplates } from 'hooks';

import {
  CollectionResources,
  CollectionSourceTypeSelect,
  CollectionTemplateSelect,
  ContentViewContainer,
} from './components';
import { ContentCollectionTree } from '../content-tree';
import { useContentNavigationContext } from '../../providers';
import { ModalContextProvider } from '../cms-modal/providers/modal-provider';

const SEGMENT_MARGIN = { $marginTop: '0px', $marginBottom: '40px' };

const SeriesTypes = [SOURCE_TYPE_CHALLENGE, SOURCE_TYPE_COURSE, SOURCE_TYPE_PROGRAM, SOURCE_TYPE_OTHER];

const SOURCE_TYPE_UNSELECTED = 'unselected';

const PaddedContainer = styled.div`
  // All children of a ContentCollectionView except for the content tree need to be given extra padding, so tree rows appear to bleed into the margins
  padding: 0 4px 0 6px;
`;

interface ContentCollectionViewProps {
  existingCollectionId?: string | number;
}

export const ContentCollectionView = ({ existingCollectionId }: ContentCollectionViewProps) => {
  const { newCollectionType, tempTabId, setTempTabId } = useContentNavigationContext();
  const { setNewCollection, collections } = useContent();
  const { setCollectionToSave, getTempId } = useSaveContext();
  const appId = useAppBeingEdited();
  const { data: templatesData } = useTemplates();

  const [errorVisible, setErrorVisible] = useState(false);

  const collection = collections[existingCollectionId ?? tempTabId];

  useEffect(() => {
    if (!existingCollectionId) {
      const tempId = `TempTab${getTempId()}`;
      setTempTabId(tempId);
      const newCollection = {
        AppId: appId,
        ...DEFAULT_COLLECTION,
        TabId: tempId,
        TemplateId: 10,
        Position: 0,
        Name: `New ${
          newCollectionType && [SOURCE_TYPE_COLLECTION, SOURCE_TYPE_COLLECTION_2].includes(newCollectionType)
            ? GROUP_LABEL
            : newCollectionType
        }`,
        Thumbnails: {},
        SourceType: newCollectionType === 'Series' ? SOURCE_TYPE_UNSELECTED : (newCollectionType as string),
        Items: [],
        Resources: [],
        DisplayInTVApp: 1 as const,
        IncludedInAppData: 0 as const,
      };
      setNewCollection(tempId, newCollection);
      setCollectionToSave(tempId, getCollectionValuesFromNewCollection(newCollection));
    }
  }, [existingCollectionId, newCollectionType]);

  const displaySourceType =
    newCollectionType === 'Series' ||
    (collection && collection.DataSource === SOURCE_VIDAPP && SeriesTypes.includes(collection.SourceType));

  return (
    <ContentViewContainer
      collection={collection}
      key={existingCollectionId}
      blockSave={
        displaySourceType && collection?.SourceType === SOURCE_TYPE_UNSELECTED ? () => setErrorVisible(true) : undefined
      }
      isLoading={!templatesData}
    >
      {collection && (
        <>
          <PaddedContainer>
            {collection.DataSource !== SOURCE_VIDAPP && (
              <ContentSourceTitle
                size="large"
                DataSource={collection.DataSource}
                SourceName={collection.SourceName}
                showDeepLink
                itemId={collection.TabId}
                itemType="collection"
                {...SEGMENT_MARGIN}
              />
            )}
            <CollectionTitle
              TabId={collection.TabId}
              Name={collection.Name}
              placeholder={collection.SourceName}
              label="VidApp Title"
              size="large"
              {...SEGMENT_MARGIN}
            />
            {displaySourceType && (
              <CollectionSourceTypeSelect
                collection={collection}
                errorVisible={errorVisible}
                setErrorVisible={setErrorVisible}
              />
            )}
            <CollectionTemplateSelect collection={collection} />
          </PaddedContainer>
          <ModalContextProvider treeCollectionId={collection.TabId}>
            <ContentCollectionTree collection={collection} />
          </ModalContextProvider>
          <PaddedContainer>
            <SettingsCollectionThumbnail collection={collection} {...SEGMENT_MARGIN} />
            <CollectionDescriptionPreview
              collection={collection}
              revertText={`Use description from ${getDataSourceDisplayName(collection.DataSource)}`}
            />
            <CollectionResources collection={collection} {...SEGMENT_MARGIN} />
            <ContentAdvancedSettings collection={collection} />
          </PaddedContainer>
        </>
      )}
    </ContentViewContainer>
  );
};
