import { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { startOfDay, endOfDay, subDays } from 'date-fns';

import { CustomButton, Dropdown, SegmentTitle } from 'components';
import { ChevronDownIcon } from 'icons';
import { DateIncrement } from 'api';

import { Card } from 'app/modules/analytics/components/';
import {
  METRIC_KEY_AVERAGE_WATCHED,
  METRIC_KEY_COMPLETIONS,
  METRIC_KEY_TOTAL_PLAYS,
  METRIC_LABELS,
  MetricKey,
} from '../const';
import { useAnalyticsContext } from '../providers/AnalyticsProvider';

interface MetricDetails {
  key: MetricKey;
  tooltip: string;
  isPercentage?: boolean;
}

const METRICS: MetricDetails[] = [
  { key: METRIC_KEY_TOTAL_PLAYS, tooltip: 'Number of times your videos have been played.' },
  {
    key: METRIC_KEY_COMPLETIONS,
    tooltip: 'Number of times your videos have been completed (over 90% progress or marked as complete).',
  },
  {
    key: METRIC_KEY_AVERAGE_WATCHED,
    tooltip: 'The average watched percentage of your videos.',
    isPercentage: true,
  },
];

const RANGE_OPTIONS = [7, 30, 90, 365];

const getRangeLabel = (range: number) => `Last ${range} Days`;

const Row = styled.div`
  display: flex;
  width: 100%;
`;

const HeaderRow = styled(Row)`
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;

  div {
    width: auto;
  }
`;

const CardRow = styled(Row)`
  margin-bottom: 40px;
`;

const StyledCard = styled(Card)`
  :not(:last-child) {
    margin-right: 16px;
  }
`;

const DropdownWrapper = styled.div`
  display: flex;
`;

const StyledButton = styled(CustomButton)`
  #react-app && {
    margin-left: 8px;
    text-transform: capitalize;
  }
`;

export const KeyAnalytics = () => {
  const {
    activeMetric,
    activeRange,
    keyAnalytics,
    setActiveRange,
    setStartDate,
    setEndDate,
    hideIncrementSelect,
    activeIncrement,
    setActiveIncrement,
  } = useAnalyticsContext();
  const today: Date = new Date();

  const handleRangeChange = useCallback(
    (value: number) => {
      // Make sure a valid increment is selected when range changes
      if (value === 7 && activeIncrement !== 'daily') {
        setActiveIncrement('daily');
      } else if (value === 30 && activeIncrement === 'monthly') {
        setActiveIncrement('weekly');
      }

      setActiveRange(value);

      const startDate = subDays(today, value);
      const endDate = subDays(today, 1);

      setStartDate(startOfDay(startDate));
      setEndDate(endOfDay(endDate));
    },
    [setStartDate, setEndDate, setActiveRange, activeIncrement, setActiveIncrement],
  );

  const incrementOptions = useMemo(() => {
    const options = [{ label: 'Daily', value: 'daily' }];

    if (activeRange > 7) {
      options.push({ label: 'Weekly', value: 'weekly' });
      if (activeRange > 30) {
        options.push({ label: 'Monthly', value: 'monthly' });
      }
    }

    return options;
  }, [activeRange]);

  return (
    <>
      <HeaderRow>
        <SegmentTitle title="Key App Analytics" $marginBottom="0" />
        <DropdownWrapper>
          {!hideIncrementSelect && (
            <Dropdown
              trigger={
                <StyledButton small secondary iconPosition="right" icon={<ChevronDownIcon />}>
                  {activeIncrement}
                </StyledButton>
              }
              options={incrementOptions.map(({ label, value }) => ({
                label,
                onClick: () => setActiveIncrement(value as DateIncrement),
                isActive: value === activeIncrement,
              }))}
            />
          )}
          <Dropdown
            trigger={
              <StyledButton small secondary iconPosition="right" icon={<ChevronDownIcon />}>
                {getRangeLabel(activeRange)}
              </StyledButton>
            }
            options={RANGE_OPTIONS.map((range) => ({
              label: getRangeLabel(range),
              onClick: () => handleRangeChange(range),
              isActive: range === activeRange,
            }))}
          />
        </DropdownWrapper>
      </HeaderRow>
      <CardRow>
        {METRICS.map(({ key, tooltip, isPercentage }) => (
          <StyledCard
            key={key}
            metric={key}
            title={METRIC_LABELS[key]}
            active={activeMetric === key}
            value={keyAnalytics && keyAnalytics[key]}
            isPercentage={isPercentage}
            tooltip={tooltip}
            isLoading={!keyAnalytics}
          />
        ))}
      </CardRow>
    </>
  );
};
