import styled from 'styled-components';
import { NEUTRAL_1_COLOUR, NEUTRAL_5_COLOUR } from 'theme';

export const TableFrame = styled.div`
  padding: 32px;
  overflow: hidden;
  background-color: ${NEUTRAL_1_COLOUR};
  border-radius: 12px;
  border: 1px solid ${NEUTRAL_5_COLOUR};
`;
