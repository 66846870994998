export const METRIC_PARAM = 'metric'; // Query param used in analytics path, e.g. /analytics/viewership?metric=total-plays

export const METRIC_KEY_TOTAL_PLAYS = 'plays';
export const METRIC_KEY_COMPLETIONS = 'completions';
export const METRIC_KEY_AVERAGE_WATCHED = 'average_completion';

export const METRIC_KEYS = [METRIC_KEY_TOTAL_PLAYS, METRIC_KEY_COMPLETIONS, METRIC_KEY_AVERAGE_WATCHED] as const;

export type MetricKey = (typeof METRIC_KEYS)[number];

export const METRIC_LABELS: Record<MetricKey, string> = {
  [METRIC_KEY_TOTAL_PLAYS]: 'Total Plays',
  [METRIC_KEY_COMPLETIONS]: 'Completions',
  [METRIC_KEY_AVERAGE_WATCHED]: 'Average Watched',
};
