import { CATALOG_SOURCE_ID, FEATURED_SOURCE_ID, IN_PROGRESS_SOURCE_ID, MY_PRODUCTS_SOURCE_ID } from 'api';

export const NAV_HEADER_MAX = 20;
export const TAB_ICON_WIDTH = 400;
export const TAB_ICON_HEIGHT = 400;
export const TAB_TITLE_MAX = 12;
export const SIDE_MENU_MAX = 22;
export const PLAYLIST_MAX = 20;
export const CORE_STATS_ICON_WIDTH = 60;
export const CORE_STATS_ICON_HEIGHT = 60;
export const CORE_STATS_NAME_MAX = 30;
export const DEFAULT_MOCKUP_HEIGHT = 620;
export const DEFAULT_MOCKUP_HEIGHT_2 = 891;

export const CONTENT_1_SORT = [IN_PROGRESS_SOURCE_ID, FEATURED_SOURCE_ID, MY_PRODUCTS_SOURCE_ID, CATALOG_SOURCE_ID];
