import { useMutation, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';

import { AppBasicInfoToSave, AvailableApp, saveAppBasicInfo, useAxiosInstance } from 'api';
import { useAppBeingEdited } from 'app-context';
import { useRecordAction } from 'hooks';

export const useSaveAppBasicInfo = () => {
  const appId = useAppBeingEdited();
  const client = useAxiosInstance();
  const queryClient = useQueryClient();
  const recordAction = useRecordAction();

  return useMutation<unknown, AxiosError, AppBasicInfoToSave>(
    (appBasicInfo) => {
      return saveAppBasicInfo(client, appId, appBasicInfo);
    },
    {
      onSuccess: async (response, payload) => {
        await queryClient.invalidateQueries(['appBasicInfo', appId]);
        if (payload.PaymentStatus) {
          // Update the local cache for this app in the search box
          const availableApps = queryClient.getQueryData('availableApps') as AvailableApp[];
          if (availableApps) {
            const app = availableApps.find((app) => app.AppId === appId);
            if (app) {
              app.PaymentStatus = payload.PaymentStatus as number;
            }
            queryClient.setQueryData('availableApps', availableApps);
          }
        }

        recordAction.mutate({ action: 'Save App Basic Info', detail: { infoToSave: payload } });
      },
    },
  );
};
