import styled from 'styled-components';
import { Result } from 'antd';
import { DANGER_COLOUR } from 'theme';
import { VIDAPP_HELP_URL } from 'api';

const ErrorContainer = styled.div`
  width: 1048px;
  margin-top: 20px;
  display: flex;
  height: calc(100vh - 240px);
  justify-content: center;
  align-items: center;
`;

const ErrorResult = styled(Result)`
  #react-app && {
    width: 500px;
    & .ant-result-icon {
      color: ${DANGER_COLOUR};
    }
    & .ant-result-title {
      line-height: 32px;
    }
    & .ant-result-extra {
      margin-top: 8px;
    }
    & p,
    & a {
      color: rgba(0, 0, 0, 0.45);
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
    }
    & a {
      text-decoration: underline;
    }
  }
`;

export const PageLoadError = () => (
  <ErrorContainer>
    <ErrorResult
      status="error"
      title="Something went wrong."
      extra={
        <p>
          Looks like your page failed to load. Please try again or click{' '}
          <a href={VIDAPP_HELP_URL} target="_blank" rel="noopener noreferrer">
            here
          </a>{' '}
          to get in contact with the VidApp support team.
        </p>
      }
    />
  </ErrorContainer>
);
