import styled from 'styled-components';

import { BuilderProduct } from 'providers';
import { ClickToCopy, SettingsSegment, SettingsTextInput } from 'components';

const StyledTextInput = styled(SettingsTextInput)`
  // Allow ClickToCopy to work with disabled input
  pointer-events: none;
`;

export const OfferId = ({ ProductId }: Pick<BuilderProduct, 'Id' | 'ProductId'>) => {
  return (
    <SettingsSegment title="Offer ID">
      <ClickToCopy copyValue={ProductId}>
        <StyledTextInput value={ProductId} $marginTop="0" disabled />
      </ClickToCopy>
    </SettingsSegment>
  );
};
