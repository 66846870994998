import { useMutation, useQueryClient } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';

import { useAppBeingEdited } from 'app-context';
import { Product, deleteProduct, useAxiosInstance } from 'api';
import { useRecordAction } from 'hooks';

export const useDeleteProduct = () => {
  const appId = useAppBeingEdited();
  const client = useAxiosInstance();
  const queryClient = useQueryClient();
  const recordAction = useRecordAction();

  return useMutation<AxiosResponse, AxiosError, Product['Id']>(
    (productId) => {
      return deleteProduct(client, appId, productId);
    },
    {
      onSuccess: async (_response, productId) => {
        await queryClient.invalidateQueries(['products', appId]);
        recordAction.mutate({ action: 'Delete Product', detail: { ProductId: productId } });
      },
    },
  );
};
