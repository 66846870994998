import { Tooltip as AntdTooltip } from 'antd';

import { CustomButton } from 'components';
import { usePublishContext } from 'providers';

import { useAdminActions } from '../hooks/useAdminActions';
import { PublishIcon } from 'icons';

export const PublishButton = () => {
  const { livePublishingDisabled, handlePublishApp } = useAdminActions();
  const { publishAvailable, isPublishing } = usePublishContext();

  if (publishAvailable === 'live') {
    return (
      <AntdTooltip
        getPopupContainer={() => document.getElementById('react-app') as HTMLElement}
        title={
          livePublishingDisabled
            ? 'Disabled by VidApp Admin, please contact support for more information'
            : `Publish content updates to users of your live apps`
        }
      >
        <span>
          <CustomButton
            medium
            success
            onClick={() => handlePublishApp('Live')}
            loading={isPublishing}
            disabled={livePublishingDisabled || isPublishing}
            icon={<PublishIcon />}
          >
            Publish To Live Apps
          </CustomButton>
        </span>
      </AntdTooltip>
    );
  }
  return (
    <AntdTooltip
      getPopupContainer={() => document.getElementById('react-app') as HTMLElement}
      title={publishAvailable ? 'Publish content updates to the VidApp preview app' : ''}
    >
      <span>
        <CustomButton
          medium
          onClick={() => handlePublishApp('Preview')}
          loading={isPublishing}
          disabled={isPublishing || !publishAvailable}
          icon={<PublishIcon />}
        >
          Publish To Preview
        </CustomButton>
      </span>
    </AntdTooltip>
  );
};
