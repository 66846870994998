import { useQuery } from 'react-query';
import { VideoAnalyticsTotals, getVideoAnalyticsTotals, useAxiosInstance } from 'api';
import { useAppBeingEdited } from 'app-context';

export const useVideoAnalyticsTotals = (startDate: Date, endDate: Date) => {
  const client = useAxiosInstance();
  const appId = useAppBeingEdited();
  return useQuery<VideoAnalyticsTotals>(
    ['videoAnalyticsTotals', appId, startDate, endDate],
    () => getVideoAnalyticsTotals(client, appId, startDate, endDate),
    {
      enabled: !!appId, // Don't call fetcher with empty AppId
    },
  );
};
