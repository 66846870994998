import { ReactNode, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { SideNav } from 'app/modules/side-nav';
import { SyncBanner } from 'app/modules/sync-tracking/SyncBanner/SyncBanner';
import { BannerProvider } from 'providers/banner-provider';
import { DataSourceProvider } from 'providers';

const Container = styled.div`
  display: flex;
  height: 100%;
`;

const PageWrapper = styled.div`
  min-width: 960px;
  height: 100%;
  overflow: auto;
  flex: 1 1 auto;
`;

interface MainContainerProps {
  children: ReactNode;
}

export const MainContainer = ({ children }: MainContainerProps) => {
  const { pathname, state } = useLocation();

  useEffect(() => {
    document.getElementById('ScrollContainer--PageContainer')?.scrollTo(0, 0); // Reset scroll when new page is navigated to

    const pathSegments = pathname.split('/');
    if (window.hj && pathSegments.length >= 2) {
      // Send the first segment of the pathname as a hotjar event
      // Avoid any dynamic parameters in the path
      // Allows segmentation of recordings
      const output = pathSegments.filter((s) => isNaN(s as unknown as number)).join('/');
      const event = output ? output : 'legacy';
      console.debug(`Sending event:${event} to hotjar`);
      window.hj('event', event);
    }
  }, [pathname]);

  const isErrorScreen = state ? !!(state as Record<string, string>).isError : false;

  const isSideNavHidden = useMemo(() => {
    return isErrorScreen || ['/builder'].some((prefix) => pathname.startsWith(prefix));
  }, [isErrorScreen, pathname]);

  return (
    <Container>
      <DataSourceProvider>
        <SideNav visible={!isSideNavHidden} />
      </DataSourceProvider>
      <BannerProvider>
        <PageWrapper>{children}</PageWrapper>
        <SyncBanner />
      </BannerProvider>
      <div id="modal-portal" />
    </Container>
  );
};
