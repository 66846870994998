import { ReactNode, useState } from 'react';
import styled, { keyframes } from 'styled-components';

import { Tooltip } from 'components';
import { TooltipPlacement } from 'antd/lib/tooltip';
import { isUserAdmin } from 'utils';

const move = keyframes`
 0% { transform: translate(0, 0)}
 100% { transform: translate(calc(50vw - 260px), 50vh)}
`;

const spin = keyframes`
 0% { transform: rotate(0deg)}
 100% { transform: rotate(360deg)}
`;

const grow = keyframes`
 0% { transform: scale(0)}
 100% { transform: rotate(1)}
`;

const WordArt = styled.div`
  height: 10px;
  width: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 80px;
  text-shadow: 5px -5px 1px #1e08ad;
  color: #43cbfe;
  position: fixed;
  top: 0px;
  left: 260px;
  z-index: 100;
  animation: ${move} 4.5s;
`;

const Grow = styled.div`
  animation: ${grow} 4s;
`;
const Spin = styled.div`
  animation: ${spin} 0.5s linear 3;
  transform-origin: center;
  font-weight: 700;
`;

interface ClickToCopyProps {
  copyValue: string;
  showValueInTooltip?: boolean;
  tooltipPlacement?: TooltipPlacement;
  displayWordArt?: boolean; // Admin-only easter-egg
  children: ReactNode;
}

export const ClickToCopy = ({
  copyValue,
  showValueInTooltip,
  tooltipPlacement,
  displayWordArt,
  children,
}: ClickToCopyProps) => {
  const tooltip = showValueInTooltip ? `Click to copy: ${copyValue}` : 'Click to copy';
  const [tooltipText, setTooltipText] = useState(tooltip);
  const [wordArtVisible, setWordArtVisible] = useState(false);

  const triggerWordArt = () => {
    if (displayWordArt && isUserAdmin() && !wordArtVisible) {
      setWordArtVisible(true);
      setTimeout(() => {
        setWordArtVisible(false);
      }, 4400);
    }
  };

  return (
    <div>
      <Tooltip
        title={tooltipText}
        placement={tooltipPlacement ?? 'left'}
        icon={
          <div
            onMouseEnter={() => setTooltipText(tooltip)}
            onClick={(e) => {
              e.stopPropagation();
              setTooltipText('Copied');
              navigator.clipboard.writeText(copyValue);
              triggerWordArt();
            }}
          >
            {children}
          </div>
        }
      />
      {wordArtVisible && (
        <WordArt>
          <Grow>
            <Spin>{copyValue}</Spin>
          </Grow>
        </WordArt>
      )}
    </div>
  );
};
