import { useCallback, useMemo, useState } from 'react';
import { endOfDay, format, startOfDay, subDays } from 'date-fns';

import { DateRangePicker, PageContainer } from 'components';
import { useVideos } from 'hooks';

import { CsvButton } from '../components';
import { useProgressTracking } from './hooks/useProgressTracking';
import { ProgressTrackingTable, TableRow } from './components/ProgressTrackingTable';
import { ExportIcon } from '../../../../icons';
import { PAGE_CONTAINER_WIDE_WIDTH } from 'theme';

export const ProgressTrackingPage = () => {
  const today: Date = new Date();
  const [startDate, setStartDate] = useState<Date>(startOfDay(subDays(today, 7)));
  const [endDate, setEndDate] = useState<Date>(endOfDay(subDays(today, 1)));
  const { data: videos, isError: isVideosError, isLoading: isVideosLoading } = useVideos();
  const {
    data: analytics,
    isError: isAnalyticsError,
    isLoading: isAnalyticsLoading,
  } = useProgressTracking(startDate, endDate);

  const isError = isAnalyticsError || isVideosError;
  const isLoading = isVideosLoading || isAnalyticsLoading;

  // Combine the analytics data and the video data
  const data = useMemo(() => {
    if (analytics && videos) {
      const tableData: TableRow[] = [];
      for (const analytic of analytics) {
        const video = videos.video_by_source_id[analytic.SourceId];
        if (video) {
          tableData.push({ ...analytic, Title: video.Title, VideoId: video.VideoId });
        }
      }
      return tableData;
    }
    return undefined;
  }, [analytics, videos]);

  const handleDateChange = useCallback(
    (dates) => {
      const returnedStartDate = startOfDay(dates[0]);
      const returnedEndDate = endOfDay(dates[1]);
      if (
        startDate.toString() !== returnedStartDate.toString() ||
        endDate.toString() !== returnedStartDate.toString()
      ) {
        setStartDate(returnedStartDate);
        setEndDate(returnedEndDate);
      }
    },
    [startDate, endDate, setStartDate, setEndDate],
  );

  return (
    <PageContainer
      heading="Progress Tracking"
      subheading="View a summary of viewing progress for users within your app."
      isError={isError}
      headingButton={
        data && (
          <CsvButton
            filename={`ProgressTracking ${format(startDate, 'yyyy-MM-dd')} - ${format(endDate, 'yyyy-MM-dd')}`}
            headers={[
              { label: 'Title', key: 'Title' },
              { label: 'Source Id', key: 'SourceId' },
              { label: 'User Id', key: 'SourceUserId' },
              { label: 'Percent Complete', key: 'MaxPercentage' },
              { label: 'Date', key: 'StartDate' },
            ]}
            data={data}
          >
            <ExportIcon />
            Export CSV File
          </CsvButton>
        )
      }
      contentMaxWidth={PAGE_CONTAINER_WIDE_WIDTH}
    >
      <DateRangePicker
        today={today}
        onChange={handleDateChange}
        value={[startDate, endDate]}
        disabled={isLoading}
        allowClear={false}
      />
      <ProgressTrackingTable data={data} />
    </PageContainer>
  );
};
