import { useMutation, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';

import { CollectionPropertiesToSave, saveCollectionProperties, useAxiosInstance } from 'api';
import { useAppBeingEdited } from 'app-context';
import { useRecordAction } from 'hooks';

export const useSaveCollectionProperties = (invalidateQuery = true, refresh = true) => {
  const appId = useAppBeingEdited();
  const client = useAxiosInstance();
  const queryClient = useQueryClient();
  const recordAction = useRecordAction();

  return useMutation<unknown, AxiosError, CollectionPropertiesToSave>(
    (collectionPropertiesToSave) => {
      return saveCollectionProperties(client, appId, collectionPropertiesToSave, refresh);
    },
    {
      onSuccess: async (_response, payload) => {
        if (invalidateQuery) {
          await queryClient.invalidateQueries(['collections', appId]);
        }
        recordAction.mutate({ action: 'Save Collection Properties', detail: { collectionPropertiesToSave: payload } });
      },
    },
  );
};
