import { useQuery } from 'react-query';
import { AxiosResponse } from 'axios';

import { getProgressTrackingAnalytics, useAxiosInstance } from 'api';
import { useAppBeingEdited } from '../../../../../app-context';

export const useProgressTracking = (startDate: Date, endDate: Date) => {
  const client = useAxiosInstance();
  const appId = useAppBeingEdited();
  const query = useQuery<AxiosResponse>(['progresstracking', appId, startDate, endDate], () => {
    return getProgressTrackingAnalytics(client, appId, startDate, endDate);
  });
  return {
    ...query,
    data: query.data?.data.ProgressTracking, // Get data from AxiosResponse
  };
};
