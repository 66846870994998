import { useMemo } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { getTabIconFilename, TintImage } from 'utils';

import { useContent } from '../../providers';
import { useMockupContext } from '../providers';
import { useLocalAppProperties } from 'providers';

const TabBar = styled.div<{ backgroundColor: string }>`
  display: flex;
  width: 100%;
  height: 60px;
  padding: 8px 8px 20px 8px;
  background-color: ${(props) => props.backgroundColor};
  justify-content: space-around;
  align-items: center;
  z-index: 12;
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  box-shadow: 0px 2px 19px rgba(0, 0, 0, 0.07);
`;

const TabContainer = styled(Link)<{ $enabled: boolean; disableTabNavigation?: boolean }>`
  display: ${({ $enabled }) => ($enabled ? 'flex' : 'none')};
  pointer-events: ${({ disableTabNavigation }) => disableTabNavigation && 'none'};
  height: 100%;
  width: 55px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const TabIcon = styled.div`
  height: 18px;
  width: 18px;
  display: flex;
  flex-direction: column;
`;

const TabTitle = styled.div<{ color: string }>`
  color: ${(props) => props.color};
  font-size: 7px;
  font-weight: 500;
  line-height: 7px;

  width: 100%;
  text-align: center;
  margin-top: 6px;
`;

export const AppTabBar = () => {
  const { collections } = useContent();
  const { currentTabId, disableTabNavigation } = useMockupContext();
  const { properties } = useLocalAppProperties();
  const tabs = useMemo(() => {
    const mainTabs = Object.values(collections)
      .filter((c) => c.IsMainTab === 1)
      .sort((a, b) => (a.Position ?? 99) - (b.Position ?? 99));
    return mainTabs.map((t) => {
      return {
        active: t.TabId === currentTabId,
        title: t.Name,
        enabled: true,
        icon: t.Icon,
        id: t.TabId,
      };
    });
  }, [collections, currentTabId]);

  const { TabBarTintHEX, TabIconNotSelectedHEX, TabBarBackgroundHEX } = properties ?? {};

  return properties ? (
    <TabBar backgroundColor={`#${TabBarBackgroundHEX}`}>
      {tabs?.slice(0, 5).map((tab, idx) => (
        <TabContainer
          key={idx}
          $enabled={true}
          to={`/build-my-app/${tab.id}`}
          disableTabNavigation={disableTabNavigation}
        >
          <TabIcon>
            {getTabIconFilename(tab) && (
              <TintImage
                src={getTabIconFilename(tab) as string}
                color={tab.active ? `#${TabBarTintHEX}` : `#${TabIconNotSelectedHEX}`}
                dimensions={{ width: 18, height: 18 }}
              />
            )}
          </TabIcon>
          <TabTitle color={tab.active ? `#${TabBarTintHEX}` : `#${TabIconNotSelectedHEX}`}>{tab.title}</TabTitle>
        </TabContainer>
      ))}
    </TabBar>
  ) : null;
};
