import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import { Collapse, LoadingSpinner, SegmentTitle } from 'components';
import { CUSTOM_SCROLLBAR, NEUTRAL_10_COLOUR } from 'theme';
import { FONT_14PX_SEMIBOLD } from 'font';
import { useAppBasicInfo } from 'hooks';

import { useBuildNav } from '../providers';
import { AddTabButton } from 'app/modules/build-screens/BuildNav/AddTabButton';
import { BuildNavProfileTab, BuildNavTab } from '../components';
import { useEffect, useMemo, useState } from 'react';
import { useDataSource } from 'providers';
import {
  CONTENT_1_SOURCE_ID,
  CONTENT_2_SOURCE_ID,
  KAJABI_LIBRARY_SOURCE_ID,
  MIXED_TEMPLATE,
  SOURCE_VHX,
  SUBNAV_TEMPLATE,
} from 'api';

const LoadingSpinnerWrapper = styled.div`
  width: 100%;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
`;

const TabList = styled.div`
  width: 100%;
  min-height: 1px;
  padding-left: 32px;
`;

const TitleWrapper = styled.div`
  margin: 0 0 12px 32px;
`;

const StyledCollapse = styled(Collapse)`
  .radix-collapse-row {
    ${FONT_14PX_SEMIBOLD};
    color: ${NEUTRAL_10_COLOUR};
    margin: 15px 0 12px 32px;
    height: 30px;
  }
`;

const EmptyCollapseHeading = styled.div`
  height: 25px;
  display: flex;
  align-items: center;
`;

const TabLists = styled.div`
  overflow: auto;
  ${CUSTOM_SCROLLBAR};
  max-height: calc(100vh - 202px);
  margin-right: 8px;
  padding: 0 24px 0 0;
`;

export const BuildNav = () => {
  const location = useLocation();
  const { isMigratedLegacy } = useAppBasicInfo();
  const dataSource = useDataSource();
  const endOfPathname = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);
  const activeTabId = endOfPathname.includes('TempTab') ? endOfPathname : parseInt(endOfPathname);
  const { activeTabs, inactiveTabs, onDragEnd, profileTab } = useBuildNav();
  const [forceOpen, setForceOpen] = useState(false); // Used to open Inactive section when new tab is created

  const allTabs = [...(inactiveTabs ?? []), ...(activeTabs ?? [])];
  if (profileTab) {
    allTabs.push(profileTab);
  }

  const inactiveIsEmpty = !inactiveTabs || inactiveTabs.length === 0;

  useEffect(() => {
    if (forceOpen) {
      // If Inactive section has been forced open, set it back to false afterwards to change collapse back to manual control
      setForceOpen(false);

      // Scroll to bottom of BuildNav's tab lists container so new tab is in view (setTimeout to allow collapse to expand and height to update first)
      setTimeout(() => {
        const tabLists = document.getElementById('build-nav-tab-lists');
        tabLists?.scrollTo(0, tabLists.scrollHeight);
      }, 300);
    }
  }, [forceOpen]);

  // Identify which tab to highlight in the guided tour
  const guidedTourTabId = useMemo(() => {
    const allContentTabs = allTabs.filter(({ TemplateId }) => [MIXED_TEMPLATE, SUBNAV_TEMPLATE].includes(TemplateId));

    // For VHX apps, try to find Content2, for other data sources try to find Content1/KajabiLibrary
    const targetSourceIds =
      dataSource === SOURCE_VHX ? [CONTENT_2_SOURCE_ID] : [CONTENT_1_SOURCE_ID, KAJABI_LIBRARY_SOURCE_ID];

    const contentTab = allContentTabs.find(({ SourceId }) => targetSourceIds.includes(SourceId));

    if (contentTab) {
      return contentTab.TabId;
    }

    // If Content1/Content2 can't be found
    const fallbackTab =
      allContentTabs.length > 0
        ? allContentTabs[0] // Use first Content Tab if there are any
        : activeTabs && activeTabs.length > 0
        ? activeTabs[0] // Otherwise use first active tab
        : undefined;

    return fallbackTab?.TabId;
  }, [allTabs, activeTabs, dataSource]);

  if (!activeTabs || !inactiveTabs) {
    return (
      <LoadingSpinnerWrapper>
        <LoadingSpinner />
      </LoadingSpinnerWrapper>
    );
  }

  return (
    <>
      <TabLists id="build-nav-tab-lists">
        <DragDropContext onDragEnd={onDragEnd}>
          <TitleWrapper>
            <SegmentTitle title={`Bottom Tab Bar ${!isMigratedLegacy ? '(5 Max)' : ''}`} />
          </TitleWrapper>
          <Droppable droppableId="activeDroppable">
            {(provided, { isDraggingOver }) => (
              <TabList ref={provided.innerRef} {...provided.droppableProps}>
                {activeTabs?.map(({ TabId }, idx) => (
                  <BuildNavTab
                    key={TabId}
                    tabId={TabId}
                    idx={idx}
                    isActive={TabId === activeTabId}
                    isGuidedTourTab={TabId === guidedTourTabId}
                    isDraggingOver={isDraggingOver}
                    showActiveLabel
                  />
                ))}
                {provided.placeholder}
              </TabList>
            )}
          </Droppable>
          {profileTab && (
            // For Templated apps, Profile tab is locked to 5th position of Active Tabs, so is listed separately outside of Droppable
            <TabList>
              <BuildNavProfileTab tab={profileTab} isActive={profileTab.TabId === activeTabId} />
            </TabList>
          )}
          <StyledCollapse
            iconAtEnd
            triggerContent="Not Active"
            defaultOpen
            isOpen={forceOpen || inactiveIsEmpty ? true : undefined}
            hideTrigger={inactiveIsEmpty ? true : undefined}
            rowContent={inactiveIsEmpty ? <EmptyCollapseHeading>Not Active</EmptyCollapseHeading> : undefined}
          >
            <Droppable droppableId="inactiveDroppable">
              {(provided, { isDraggingOver }) => (
                <TabList ref={provided.innerRef} {...provided.droppableProps}>
                  {inactiveTabs?.map(({ TabId }, idx) => (
                    <BuildNavTab
                      key={TabId}
                      tabId={TabId}
                      idx={idx}
                      isActive={TabId === activeTabId}
                      isGuidedTourTab={TabId === guidedTourTabId}
                      isDraggingOver={isDraggingOver}
                    />
                  ))}
                  {provided.placeholder}
                </TabList>
              )}
            </Droppable>
          </StyledCollapse>
        </DragDropContext>
      </TabLists>
      <AddTabButton existingTabs={allTabs} setForceOpen={setForceOpen} />
    </>
  );
};
