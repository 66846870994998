import { Dispatch, ReactNode, SetStateAction, useEffect, useState } from 'react';
import { UseQueryOptions } from 'react-query';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { LoadingSpinner } from 'components';
import { useAppProperties } from 'hooks';
import { AppProperties } from 'api';

import { LocalAppPropertiesProvider } from './local-app-properties-provider';

const LoadingContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface DefaultAppPropertiesProviderProps {
  staleTime?: UseQueryOptions['staleTime'];
  refetchOnMount?: UseQueryOptions['refetchOnMount'];
  customLoader?: ReactNode;
  children: ReactNode | ReactNode[];
}

export const DefaultAppPropertiesProvider = ({
  staleTime,
  refetchOnMount,
  customLoader,
  children,
}: DefaultAppPropertiesProviderProps) => {
  const [properties, setProperties] = useState<AppProperties>();
  const history = useHistory();

  const { data: propertiesData } = useAppProperties({
    staleTime: staleTime,
    refetchOnMount: refetchOnMount,
  });

  const revertLocalChanges = () => {
    console.debug('DefaultAppPropertiesProvider', 'Reverting local changes');
    setProperties(propertiesData);
  };

  // When the user navigates to a new page the local changes will be reverted
  history.listen((location, action) => {
    if (action === 'PUSH' || action === 'POP') {
      revertLocalChanges();
    }
  });

  useEffect(() => {
    // Setup state once on initial data load
    if (properties === undefined && !!propertiesData) {
      setProperties(propertiesData);
    }
  }, [!!propertiesData]);

  return !properties ? (
    <>
      {!!customLoader ? (
        customLoader
      ) : (
        <LoadingContainer>
          <LoadingSpinner />
        </LoadingContainer>
      )}
    </>
  ) : (
    <LocalAppPropertiesProvider
      properties={properties}
      setProperties={setProperties as Dispatch<SetStateAction<AppProperties>>}
    >
      {children}
    </LocalAppPropertiesProvider>
  );
};
