import { VideoIcon, PostIcon, PenIcon, DumbbellIcon } from 'icons';

interface CellItemIconMap {
  video: JSX.Element;
  workout: JSX.Element;
  quote: JSX.Element;
  [key: string]: JSX.Element;
}

export interface ItemIconProps {
  type: string;
}

export const ItemIcon = ({ type }: ItemIconProps) => {
  const cellItemIconMap: CellItemIconMap = {
    lesson: <PostIcon />,
    post: <PostIcon />,
    video: <VideoIcon />,
    workout: <DumbbellIcon />,
    quote: <PenIcon />,
  };

  return cellItemIconMap[type] ?? <VideoIcon />;
};
