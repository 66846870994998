import { useMutation, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';

import { CollectionToUpdate, updateCollection, useAxiosInstance } from 'api';
import { useAppBeingEdited } from 'app-context';
import { useRecordAction } from 'hooks';

export const useUpdateCollection = (invalidateQuery = true, refresh = true) => {
  const appId = useAppBeingEdited();
  const client = useAxiosInstance();
  const queryClient = useQueryClient();
  const recordAction = useRecordAction();

  return useMutation<unknown, AxiosError, CollectionToUpdate>(
    (collectionToUpdate) => {
      return updateCollection(client, appId, collectionToUpdate, refresh);
    },
    {
      onSuccess: async (_response, payload) => {
        if (invalidateQuery) {
          await queryClient.invalidateQueries(['collections', appId]);
        }
        recordAction.mutate({ action: 'Update Collection', detail: { collectionToUpdate: payload } });
      },
    },
  );
};
