import { Modal as AntModal, Typography } from 'antd';
import { format } from 'date-fns';
import { useMemo, useState } from 'react';
import styled from 'styled-components';

import { OneSignalNotification } from 'api';
import { CustomButton, CustomTable2, InfoModal, Modal, TableColumn } from 'components';
import { FONT_10PX_MEDIUM, FONT_14PX_MEDIUM, FONT_14PX_REGULAR, FONT_18PX_MEDIUM } from 'font';
import { useCancelOneSignalNotification } from 'hooks';
import { PenIcon, TrashIcon } from 'icons';
import {
  HIGHLIGHT_PRIMARY_COLOUR,
  HIGHLIGHT_SECONDARY_COLOUR,
  NEUTRAL_10_COLOUR,
  NEUTRAL_7_COLOUR,
  SECONDARY_SUCCESS_COLOUR,
  SUCCESS_COLOUR,
} from 'theme';

import { PushNotificationPreview, PushNotificationPreviewProps } from './PushNotificationPreview';

const Cell = styled.div<{ centered?: boolean }>`
  height: 100%;
  display: flex;
  ${({ centered }) => centered && 'justify-content: center'};
  align-items: center;
  padding: 16px 12px;
`;

const MessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 12px;
  color: ${NEUTRAL_10_COLOUR};
`;

const TitleWrapper = styled.div`
  ${FONT_14PX_MEDIUM};
  margin-bottom: 3px;
`;

const ContentWrapper = styled(Typography.Paragraph)`
  &&&& {
    ${FONT_14PX_REGULAR};
    white-space: pre-wrap;
  }
`;

const StatusTag = styled.div<{ status: string }>`
  background-color: ${({ status }) =>
    status === 'Scheduled'
      ? HIGHLIGHT_SECONDARY_COLOUR
      : status === 'Delivered'
      ? SECONDARY_SUCCESS_COLOUR
      : '#ffefb5'};
  color: ${({ status }) =>
    status === 'Scheduled' ? HIGHLIGHT_PRIMARY_COLOUR : status === 'Delivered' ? SUCCESS_COLOUR : '#c49001'};
  ${FONT_10PX_MEDIUM};
  padding: 2px 4px;
  border-radius: 3px;
  width: fit-content;
  display: flex;
  align-items: center;
`;

const DateWrapper = styled.span`
  font-size: 11px;
  font-weight: 400;
`;

const DeleteButton = styled(CustomButton)`
  &&&& {
    margin-left: 4px;
  }
`;

const Disclaimer = styled.div`
  width: 100%;
  padding: 30px;
  text-align: center;
  color: ${NEUTRAL_7_COLOUR};
  font-size: 11px;
`;

const ModalContent = styled.div`
  width: 465px;
  height: 507px;
  padding: 50px 35px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${NEUTRAL_10_COLOUR};
  overflow: hidden;
`;

const ModalHeading = styled.div`
  ${FONT_18PX_MEDIUM};
  margin-bottom: 24px;
`;

const ModalDetails = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 75px;
`;

const ModalDate = styled.div`
  margin-left: 7px;
`;

const ScalingWrapper = styled.div`
  transform: scale(0.6);
  transform-origin: top;
`;

const getNotificationStatus = ({ send_after, completed_at }: OneSignalNotification) => {
  const dateNow = Math.floor(Date.now() / 1000);
  return send_after > dateNow ? 'Scheduled' : completed_at ? 'Delivered' : 'Push In Progress';
};

interface Message {
  title: string;
  content: string;
}

interface Notification {
  id: string;
  message: Message;
  status: string;
  date: string;
}

interface PushNotificationLogProps {
  oneSignalNotifications: OneSignalNotification[] | undefined;
  setIsComposing: React.Dispatch<React.SetStateAction<boolean>>;
  selectedNotification: OneSignalNotification | undefined;
  setSelectedNotification: React.Dispatch<React.SetStateAction<OneSignalNotification | undefined>>;
  previewValues: PushNotificationPreviewProps;
  readOnly?: boolean;
}

export const PushNotificationLog = ({
  oneSignalNotifications,
  setIsComposing,
  selectedNotification,
  setSelectedNotification,
  previewValues,
  readOnly,
}: PushNotificationLogProps) => {
  const cancelOneSignalNotification = useCancelOneSignalNotification();
  const [modalOpen, setModalOpen] = useState(false);

  const dataSource = useMemo(() => {
    if (oneSignalNotifications) {
      const notifications = oneSignalNotifications
        .filter((n) => !n.canceled)
        .map((n) => {
          const { id, headings, contents, send_after, delayed_option, delivery_time_of_day } = n;
          const message = { title: headings.en, content: contents.en };
          const status = getNotificationStatus(n);
          const date = `${format(send_after * 1000, 'MM/dd/yy p')}${
            delayed_option === 'last-active'
              ? ' - Intelligent delivery'
              : delayed_option === 'timezone'
              ? ` - Based off users timezone (${delivery_time_of_day})`
              : ''
          }`;

          return {
            id,
            message,
            status,
            date,
          };
        });
      return notifications;
    }
    return [];
  }, [oneSignalNotifications]);

  const columns: TableColumn<Notification>[] = [
    {
      heading: 'Message',
      width: 'grow',
      render: ({ message }) => (
        <MessageWrapper>
          <TitleWrapper>{message.title}</TitleWrapper>
          <ContentWrapper ellipsis={{ rows: 2 }}>{message.content}</ContentWrapper>
        </MessageWrapper>
      ),
    },
    {
      heading: 'Status',
      width: 113,
      centered: true,
      render: ({ status }) => (
        <Cell centered>
          <StatusTag status={status}>{status}</StatusTag>
        </Cell>
      ),
    },
    {
      heading: 'Delivery Date',
      width: 340,
      render: ({ date }) => (
        <Cell>
          <DateWrapper>{date}</DateWrapper>
        </Cell>
      ),
    },
    {
      heading: 'Actions',
      width: 150,
      centered: true,
      render: ({ status, id }) => (
        <Cell centered>
          {status === 'Scheduled' ? (
            <>
              <CustomButton
                tertiaryHighlight
                small
                icon={<PenIcon />}
                onClick={() => {
                  setSelectedNotification(oneSignalNotifications?.find((n) => n.id === id));
                  setIsComposing(true);
                }}
              >
                Edit
              </CustomButton>
              <DeleteButton
                tertiary
                small
                icon={<TrashIcon />}
                onClick={() => {
                  AntModal.confirm({
                    title: '',
                    content: 'Are you sure you want to delete this push notification?',
                    getContainer: '#react-app',
                    okText: 'Yes',
                    cancelText: 'No',
                    onOk: () => {
                      cancelOneSignalNotification.mutate(id, {
                        onError: () => {
                          InfoModal('Something went wrong!', 'Please try again or contact support for help', 'error');
                        },
                      });
                    },
                  });
                }}
              />
            </>
          ) : (
            <CustomButton
              tertiaryHighlight
              small
              onClick={() => {
                setSelectedNotification(oneSignalNotifications?.find((n) => n.id === id));
                setModalOpen(true);
              }}
            >
              Preview
            </CustomButton>
          )}
        </Cell>
      ),
    },
  ];

  return (
    <>
      <CustomTable2
        pageSize={30}
        columns={columns}
        data={dataSource}
        emptyTitle="No notifications"
        emptyDescription="Looks like you haven’t created any scheduled push notifications yet."
      />
      {!readOnly && <Disclaimer>Notifications records older than 30 days are periodically deleted.</Disclaimer>}
      <Modal
        open={modalOpen}
        onCancel={() => {
          setSelectedNotification(undefined);
          setModalOpen(false);
        }}
        $height="fit-content"
        $padding="0"
        width="fit-content"
        closable={true}
      >
        <ModalContent>
          {selectedNotification && (
            <>
              <ModalHeading>Push Notification Preview</ModalHeading>
              <ModalDetails>
                <StatusTag status={getNotificationStatus(selectedNotification)}>
                  {getNotificationStatus(selectedNotification)}
                </StatusTag>
                <ModalDate>{format(selectedNotification.send_after * 1000, 'MM/dd/yy p')}</ModalDate>
              </ModalDetails>
              <ScalingWrapper>
                <PushNotificationPreview
                  scheduledTime={previewValues.scheduledTime}
                  scheduledDate={previewValues.scheduledDate}
                  title={previewValues.title}
                  message={previewValues.message}
                />
              </ScalingWrapper>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};
