export const SOURCE_VIDAPP = 'VidApp';
export const SOURCE_USCREEN = 'Uscreen';
export const SOURCE_VIMEO = 'Vimeo';
export const SOURCE_WORDPRESS = 'WordPress';
export const SOURCE_KAJABI = 'Kajabi';
export const SOURCE_VHX = 'VHX';
export const SOURCE_YOUTUBE = 'YouTube';
export const SOURCE_JWPLAYER = 'JWPlayer';
export const SOURCE_CUSTOMAPI = 'CustomAPI';
export const SOURCE_MEMBERPRESS = 'MemberPress';
export const SOURCE_MEMBERMOUSE = 'MemberMouse';
export const SOURCE_WOOCOMMERCESUBSCRIPTIONS = 'WooCommerceSubscriptions';
export const SOURCE_WOOCOMMERCEMEMBERSHIPS = 'WooCommerceMemberships';
export const SOURCE_WISHLIST = 'WishList';
export const SOURCE_ACTIVEMEMBER360 = 'ActiveMember360';
export const SOURCE_PAIDMEMBERSHIPSPRO = 'PaidMembershipsPro';
export const SOURCE_PREVIEW = 'Preview';
export const SOURCE_SHOPIFY = 'Shopify';
export const SOURCE_TEACHABLE = 'Teachable';
export const SOURCE_THINKIFIC = 'Thinkific';
export const ALL_DATA_SOURCES = [
  SOURCE_VIDAPP,
  SOURCE_USCREEN,
  SOURCE_VIMEO,
  SOURCE_WORDPRESS,
  SOURCE_KAJABI,
  SOURCE_VHX,
  SOURCE_YOUTUBE,
  SOURCE_JWPLAYER,
  SOURCE_CUSTOMAPI,
  SOURCE_MEMBERPRESS,
  SOURCE_MEMBERMOUSE,
  SOURCE_WOOCOMMERCESUBSCRIPTIONS,
  SOURCE_WOOCOMMERCEMEMBERSHIPS,
  SOURCE_WISHLIST,
  SOURCE_ACTIVEMEMBER360,
  SOURCE_PAIDMEMBERSHIPSPRO,
  SOURCE_TEACHABLE,
  SOURCE_THINKIFIC,
  SOURCE_PREVIEW,
] as const;

export type DataSource = (typeof ALL_DATA_SOURCES)[number];

export const WORKOUT_TEMPLATE_ID = 34;
export const CIRCUIT_TEMPLATE_ID = 35;
export const WORKOUT_OVERVIEW_ID = 25; // Deprecated Circuits V1 template
export const SOURCE_TYPE_WORKOUT = 'Workout';
export const SOURCE_TYPE_CIRCUIT = 'Circuit';
export const CICUIT_TYPE_TIME_BASED = 'time-based';
export const CICUIT_TYPE_REP_BASED = 'rep-based';

// Source IDs for default tabs created by Onboarding Wizard
export const CONTENT_1_SOURCE_ID = 'Content1';
export const CONTENT_2_SOURCE_ID = 'Content2';
export const CONTENT_3_SOURCE_ID = 'Content3';
export const KAJABI_LIBRARY_SOURCE_ID = 'KajabiLibrary';
export const SEARCH_SOURCE_ID = 'Search';
export const CALENDAR_SOURCE_ID = 'Calendar';
export const MYLISTS_SOURCE_ID = 'MyLists';
export const PROFILE_SOURCE_ID = 'Profile';
export const WEBSITE_1_SOURCE_ID = 'Website1';
export const WEBSITE_2_SOURCE_ID = 'Website2';
export const CATALOG_SOURCE_ID = 'Catalog';
export const MY_PRODUCTS_SOURCE_ID = 'MyProducts';
export const FEATURED_SOURCE_ID = 'Featured';
export const IN_PROGRESS_SOURCE_ID = 'InProgress';

export const DEFAULT_TAB_SOURCE_IDS = [
  CONTENT_1_SOURCE_ID,
  KAJABI_LIBRARY_SOURCE_ID,
  SEARCH_SOURCE_ID,
  CALENDAR_SOURCE_ID,
  MYLISTS_SOURCE_ID,
  PROFILE_SOURCE_ID,
  WEBSITE_1_SOURCE_ID,
  WEBSITE_2_SOURCE_ID,
];

export const S3_BUCKET = 'https://vidapp-bucket.s3.amazonaws.com/';
export const TAB_ICON_URL = `${S3_BUCKET}images/icons/`;
export const ETL_URL = 'https://etl.vidapp.com';
export const VIDAPP_HELP_URL = 'https://wearevidapp.zendesk.com/hc/requests/new';

export const ITEM_TYPE_COLLECTION = 'collection';
export const ITEM_TYPE_TAB = 'tab';
export const ITEM_TYPE_VIDEO = 'video';
export const ITEM_TYPE_SECTIONHEADER = 'sectionHeader';

export const COLLECTION_TYPE_TAB = 'tab';
export const COLLECTION_TYPE_COLLECTION = 'collection';

export const VIDEO_TYPE_SECTIONHEADER = 'sectionHeader';
export const VIDEO_TYPE_VIDEO = 'video';
export const VIDEO_TYPE_POST = 'post';
export const VIDEO_TYPE_REST = 'rest';

export const SOURCE_TYPE_TAB = 'Tab';
export const SOURCE_TYPE_COLLECTION = 'Collection';

export const GROUP_LABEL = 'Group';

//Collection types created through CMS
export const SOURCE_TYPE_CHALLENGE = 'Challenge';
export const SOURCE_TYPE_COURSE = 'Course';
export const SOURCE_TYPE_PROGRAM = 'Program';
export const SOURCE_TYPE_OTHER = 'Other';
export const SOURCE_TYPE_PLAYLIST = 'Playlist';
export const SOURCE_TYPE_COLLECTION_2 = 'Collection2';

export const CMS_SOURCE_TYPES = [
  SOURCE_TYPE_CHALLENGE,
  SOURCE_TYPE_COURSE,
  SOURCE_TYPE_PROGRAM,
  SOURCE_TYPE_OTHER,
  SOURCE_TYPE_PLAYLIST,
  SOURCE_TYPE_COLLECTION_2,
  SOURCE_TYPE_COLLECTION,
];

export type CMSSourceType = (typeof CMS_SOURCE_TYPES)[number];

// Tag Collections generated by VidApp
export const SOURCE_TYPE_TAG = 'Tag';
// Collections created in the DragDrop Builder aka Batman Collections
export const SOURCE_TYPE_BUILDMYAPP = 'BuildMyApp';
export const SOURCE_TYPE_SUBTAB = 'SubTab';
export const SOURCE_TYPE_ARCHIVED = 'Archived';
export const SOURCE_TYPE_DRAG_DROP = 'DragDrop';
export const SOURCE_TYPE_KAJABI_PRODUCT = 'Product';
export const SOURCE_TYPE_KAJABI_CAT = 'Category';
export const SOURCE_TYPE_KAJABI_SUBCAT = 'Sub-Category';
export const SOURCE_TYPE_VHX_CAT = 'category';
export const SOURCE_TYPE_USCREEN_CAT = 'Category';
export const SOURCE_TYPE_USCREEN_COL = 'collection';
export const SOURCE_TYPE_VHX_SEAS = 'season';
export const SOURCE_TYPE_VHX_PLAYLIST = 'playlist';
export const SOURCE_TYPE_VHX_SERIES = 'series';
export const SOURCE_TYPE_VHX_MOVIE = 'movie';
export const SOURCE_TYPE_VIMEO_ALBUM = 'Album';
export const SOURCE_TYPE_WEBSITE = 'Website';
export const SOURCE_TYPE_TEXT = 'Text';
export const SOURCE_TYPE_CALENDAR = 'Calendar';

export const APP_TYPE_ONBOARDING = 1;
export const APP_TYPE_MIGRATED = 2;
export const APP_TYPE_CUSTOM = 3;

export const TEMPLATE_TYPE_MAIN = 'main';
export const TEMPLATE_TYPE_COLLECTION = 'collection';

export const WEBSITE_TEMPLATE = 5;
export const TEXT_IMAGE_TEMPLATE = 4;
export const SUBNAV_TEMPLATE = 22;
export const MIXED_TEMPLATE = 30;
export const CALENDAR_TEMPLATE = 17;
export const MY_PLAYLISTS_TEMPLATE = 33;
export const MY_LISTS_TEMPLATE = 21;
export const IN_PROGRESS_TEMPLATE = 44;
export const PROFILE_TEMPLATE = 31;
export const FILTER_TEMPLATE = 18;
export const COMMUNITY_TEMPLATE = 47;

export const hasHeroImage = (templateId: number) => {
  return [49, 48, 16, 10, 12].includes(templateId);
};
export const isHorizontalScroll = (templateId: number) => {
  return [3, 16].includes(templateId);
};

export const TAB_LEVEL_PROPERTIES = [
  'NavBarTitleImage',
  'NavBarNotificationsView',
  'NavBarFilterView',
  'NavBarSearchView',
  'NavBarTitleText',
  'LockedUntilSignIn',
];
export const NEW_TAB_OPTIONS = [
  CALENDAR_TEMPLATE,
  MIXED_TEMPLATE,
  MY_LISTS_TEMPLATE,
  MY_PLAYLISTS_TEMPLATE,
  PROFILE_TEMPLATE,
  FILTER_TEMPLATE,
  WEBSITE_TEMPLATE,
];
export const DEFAULT_TAB_ICONS: Record<number, string> = {
  [MIXED_TEMPLATE]: 'icon_content_secondary_2022.png',
  [WEBSITE_TEMPLATE]: 'icon_website_2022.png',
  [MY_PLAYLISTS_TEMPLATE]: 'icon_playlist.png',
  [MY_LISTS_TEMPLATE]: 'icon_star_2022.png',
  [CALENDAR_TEMPLATE]: 'icon_calendar_2022.png',
  [FILTER_TEMPLATE]: 'icon_search_2022.png',
  [PROFILE_TEMPLATE]: 'icon_profile.png',
  [COMMUNITY_TEMPLATE]: 'icon_community_2023.png',
};
export const DEFAULT_TAB_NAMES: Record<number, string> = {
  [MIXED_TEMPLATE]: 'Content',
  [WEBSITE_TEMPLATE]: 'Website',
  [MY_PLAYLISTS_TEMPLATE]: 'My Playlists',
  [MY_LISTS_TEMPLATE]: 'My Lists',
  [CALENDAR_TEMPLATE]: 'Calendar',
  [FILTER_TEMPLATE]: 'Search',
  [PROFILE_TEMPLATE]: 'Profile',
  [COMMUNITY_TEMPLATE]: 'Community',
};

export const VIDEO_DETAILS_DEFAULT = 'ButtonsBelowPlayerSegment';
export const VIDEO_DETAILS_EXPANDABLE_DESC = 'ButtonsBelowPlayerDropdown';
export const VIDEO_DETAILS_RESOURCES = 'ButtonsBelowPlayerResources';

export const PAYMENT_STATUS_UNPAID_DEPRECATED = 2;
export const PAYMENT_STATUS_UNPAID = 10;
export const PAYMENT_STATUS_DETAILS_SAVED = 50;
export const PAYMENT_STATUS_INVOICED = 60;
export const PAYMENT_STATUS_CHURNED = 90;
export const PAYMENT_STATUS_DNE = 91;
export const PAYMENT_STATUS_TEST = 99;

export const VIDEO_STATUS_LOCKED = '-1';
export const VIDEO_STATUS_FREE = '0';
