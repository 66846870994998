import styled, { css } from 'styled-components';
import { Table, TableProps } from 'antd';
import { NEUTRAL_5_COLOUR, NEUTRAL_9_COLOUR } from 'theme';
import { FONT_14PX_REGULAR, FONT_14PX_MEDIUM } from 'font';

const styles = css`
  #react-app && {
    table {
      border-collapse: collapse;
      color: ${NEUTRAL_9_COLOUR};
      margin-bottom: 0;
    }

    & th,
    & td {
      border: none;
      padding: 5px 2px 5px 12px;
    }

    & th {
      border-bottom: 1px solid ${NEUTRAL_5_COLOUR};
      background-color: #fff !important;
    }

    & th,
    .ant-table-column-title {
      ${FONT_14PX_MEDIUM};
    }

    & td {
      ${FONT_14PX_REGULAR};
    }

    & th,
    & tr {
      height: 56px;
      background-color: #fff;
    }

    & tr:not(:first-child) {
      border-top: 1px solid ${NEUTRAL_5_COLOUR};
    }

    & .ant-table-tbody > tr.ant-table-row:hover > td {
      background-color: inherit;
    }

    & .ant-table-column-sort {
      background-color: inherit;
    }

    & .ant-table-cell-fix-left {
      background-color: inherit;
    }

    & .ant-table {
      border-radius: 8px;
      border: 1px solid ${NEUTRAL_5_COLOUR};
      margin-bottom: 8px;
      overflow: hidden; // Instead of fixing border radius of internal components
    }

    & th:first-child {
      border-top-left-radius: 8px !important;
    }

    & th:last-child {
      border-top-right-radius: 8px !important;
    }

    & .ant-table-column-sorter {
      visibility: collapse;
    }

    & .ant-pagination {
      margin: 24px 0;
    }

    & .ant-pagination-item {
      margin: 0 4px;
    }

    & .ant-pagination-prev,
    & .ant-pagination-next {
      margin: 0 8px;
    }

    & .ant-pagination-jump-prev,
    & .ant-pagination-jump-next {
      margin: 0 !important;
    }
  }
`;

// Weird workaround for StyledComponents not being able to pass through generic props to components
// eslint-disable-next-line
export const styledTable = <RecordType extends object = any>(T: typeof Table) => styled(
  (props: TableProps<RecordType> & { className?: string }) => {
    return <T {...props} />;
  },
)`
  ${styles}
`;

export const CustomTable = styled(Table)`
  ${styles}
`;
