import { useMemo } from 'react';
import { linearGradientDef } from '@nivo/core';
import { Datum, ResponsiveLine, Serie } from '@nivo/line';
import styled from 'styled-components';

import {
  HIGHLIGHT_PRIMARY_COLOUR,
  NEUTRAL_10_COLOUR,
  NEUTRAL_5_COLOUR,
  NEUTRAL_7_COLOUR,
  NEUTRAL_8_COLOUR,
} from 'theme';
import { FONT_10PX_MEDIUM, FONT_12PX_MEDIUM } from 'font';

import { LoadingDots } from 'components/Loading/LoadingDots/LoadingDots';
import { SegmentTitle } from 'components/Text/SegmentTitle';

const ChartContainer = styled.div`
  height: 489px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SliceTooltip = styled.div`
  color: ${NEUTRAL_10_COLOUR};
  background: #fff;
  border: 1px solid ${NEUTRAL_5_COLOUR};
  border-radius: 4px;
  padding: 6px 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TooltipDate = styled.div`
  ${FONT_10PX_MEDIUM};
`;

const TooltipValue = styled.div`
  ${FONT_12PX_MEDIUM};
`;

interface LineChartProps {
  header: string;
  data?: Serie[];
  yMax?: number;
  xLabelsToShow?: string[];
  getFormattedDate: (value: string, fullDate?: boolean) => string;
  axisLeftFormat?: (name: string) => string;
}

export const LineChart = ({ header, data, yMax, xLabelsToShow, getFormattedDate, axisLeftFormat }: LineChartProps) => {
  const yScale = useMemo(() => {
    if (yMax) {
      return { min: 0, max: yMax };
    }
    if (!data || data[0].data.length === 0) {
      return { min: 0, max: 100 };
    }

    // Workaround to make yMax higher than the highest Y value in the data
    // See https://github.com/plouc/nivo/issues/671
    const allData: Datum[] = [];
    data.forEach((series) => allData.push(...series.data));
    const allYValues = allData.map(({ y }) => y as number);
    const maxYValue = Math.max(...allYValues);

    return {
      min: 0,
      max: maxYValue * 1.15,
    };
  }, [data, yMax]);

  return (
    <>
      <SegmentTitle title={header} $marginBottom="0" />
      <ChartContainer>
        {data ? (
          <ResponsiveLine
            data={data}
            margin={{ left: 55, right: 20, bottom: 58, top: 19 }}
            colors={[HIGHLIGHT_PRIMARY_COLOUR]}
            enableArea
            areaOpacity={0.11}
            xScale={{ type: 'point' }}
            yScale={{
              type: 'linear',
              ...yScale,
            }}
            axisLeft={{ tickSize: 0, tickPadding: 12, format: axisLeftFormat }}
            axisBottom={{
              tickSize: 0,
              tickPadding: 11,
              format: xLabelsToShow
                ? (v) => (xLabelsToShow.find((label) => label === v) ? getFormattedDate(v) : '')
                : (v) => getFormattedDate(v),
            }}
            markers={[
              {
                axis: 'y',
                value: 0,
                lineStyle: {
                  stroke: NEUTRAL_7_COLOUR,
                  strokeWidth: 1,
                },
              },
            ]}
            defs={[
              linearGradientDef('gradientA', [
                { offset: 20, color: HIGHLIGHT_PRIMARY_COLOUR },
                { offset: 100, color: 'white' },
              ]),
            ]}
            fill={[{ match: { id: data[0].id }, id: 'gradientA' }]}
            theme={{
              textColor: NEUTRAL_8_COLOUR,
              fontSize: 10,
              fontFamily: 'Inter',
              grid: { line: { stroke: NEUTRAL_5_COLOUR, width: 1 } },
            }}
            pointSize={4}
            pointColor="#fff"
            pointBorderWidth={2}
            pointBorderColor={{ from: 'serieColor', modifiers: [] }}
            enableGridX={false}
            enableSlices="x"
            yFormat=" >-,.2~f"
            sliceTooltip={({ slice }) => (
              <>
                {slice.points.map(({ data }) => (
                  <SliceTooltip>
                    <TooltipDate>{getFormattedDate(data.x.toString(), true)}</TooltipDate>
                    <TooltipValue>
                      {axisLeftFormat ? axisLeftFormat(data.yFormatted.toString()) : data.yFormatted}
                    </TooltipValue>
                  </SliceTooltip>
                ))}
              </>
            )}
            motionConfig={{
              mass: 1,
              tension: 500,
              friction: 42,
              clamp: false,
              precision: 1,
              velocity: 0,
            }}
          />
        ) : (
          <LoadingDots />
        )}
      </ChartContainer>
    </>
  );
};
