import { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { VariableSizeList } from 'react-window';

import { BuilderCollection } from 'providers';
import {
  SOURCE_TYPE_KAJABI_CAT,
  SOURCE_TYPE_KAJABI_SUBCAT,
  SOURCE_TYPE_SUBTAB,
  SOURCE_TYPE_TAB,
  SOURCE_TYPE_TAG,
  SOURCE_TYPE_USCREEN_COL,
  SOURCE_TYPE_VHX_MOVIE,
  SOURCE_TYPE_VHX_PLAYLIST,
  SOURCE_TYPE_VHX_SERIES,
} from 'api';
import { HIGHLIGHT_PRIMARY_COLOUR, NEUTRAL_10_COLOUR } from 'theme';
import { FONT_14PX_SEMIBOLD } from 'font';
import { CheckIcon } from 'icons';
import { getSourceTypeDisplayName, sortByArray } from 'utils';
import { ModalSearchBox } from 'components';

import { ContentCard } from 'app/modules/build-dragdrop/Builder/components';

interface CollectionOptionsProps {
  availableCollections: BuilderCollection[];
  includedCollections: BuilderCollection['TabId'][];
  onCollectionClick: (collectionId: BuilderCollection['TabId']) => void;
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 25px 0 17px 0;
`;
const Heading = styled.div`
  color: ${NEUTRAL_10_COLOUR};
  ${FONT_14PX_SEMIBOLD};
  margin-bottom: 13px;
`;

const CollectionWrapper = styled.div`
  padding: 0 20px;
  height: 74px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

const BlueCheck = styled.div`
  color: ${HIGHLIGHT_PRIMARY_COLOUR};
  font-size: 16px;
`;
const Results = styled.div`
  padding-top: 16px;
`;
const RowSpacing = styled.div`
  padding: 0 20px;
`;
const SourceType = styled.div`
  ${FONT_14PX_SEMIBOLD};
  padding-left: 20px;
  margin: 10px 0 2px;
`;

interface ResultsItem {
  heading?: string;
  collection?: BuilderCollection;
}

export const ModalCollectionOptions = ({
  availableCollections,
  includedCollections,
  onCollectionClick,
}: CollectionOptionsProps) => {
  const [query, setQuery] = useState('');
  const listRef = useRef<HTMLDivElement>();

  const collectionOptions = useMemo(() => {
    // Filter options based on search query
    const lowerQuery = query.toLowerCase();

    const options = availableCollections?.filter(
      (collection) =>
        collection.SourceName.toLowerCase().includes(lowerQuery) ||
        collection.Name.toLowerCase().includes(lowerQuery) ||
        collection.SourceId.toLowerCase().includes(lowerQuery),
    );

    // Group options by source type
    const groupedOptions = options.reduce((acc, cur) => {
      const sourceType = cur.SourceType.toLowerCase() || 'collection';
      return {
        ...acc,
        [sourceType]: [...(acc[sourceType] || []), cur],
      };
    }, {} as Record<string, BuilderCollection[]>);

    // Sort groups into defined order, then push all groups into an array (group heading first, then collections)
    // This is so the entire list of results can be virtualised using react-window
    const arr: ResultsItem[] = [];
    const order = [
      SOURCE_TYPE_VHX_PLAYLIST,
      SOURCE_TYPE_VHX_SERIES,
      SOURCE_TYPE_USCREEN_COL,
      SOURCE_TYPE_VHX_MOVIE,
      SOURCE_TYPE_KAJABI_CAT,
      SOURCE_TYPE_KAJABI_SUBCAT,
      SOURCE_TYPE_TAG,
      SOURCE_TYPE_TAB,
      SOURCE_TYPE_SUBTAB,
    ].map((s) => s.toLowerCase());

    Object.entries(groupedOptions)
      .sort(([sourceTypeA], [sourceTypeB]) => {
        return sortByArray(sourceTypeA, sourceTypeB, order);
      })
      .map(([sourceType, collections]) => {
        arr.push({
          heading: getSourceTypeDisplayName(sourceType, collections[0].DataSource, { pluralize: true }),
        });
        collections.forEach((c) => arr.push({ collection: c }));
      });

    return arr;
  }, [availableCollections, query]);

  useEffect(() => {
    // eslint-disable-next-line
    // @ts-ignore
    listRef?.current?.resetAfterIndex(0);
  }, [collectionOptions]);

  const getItemSize = (index: number) => (collectionOptions[index].collection ? 74 : 32);

  return (
    <Container>
      <RowSpacing>
        <Heading>All</Heading>
        <ModalSearchBox onChange={setQuery} />
      </RowSpacing>
      <Results>
        <VariableSizeList
          // eslint-disable-next-line
          // @ts-ignore
          ref={listRef}
          height={397}
          width={377}
          itemSize={getItemSize}
          itemCount={collectionOptions.length}
        >
          {({ index, style }) => {
            const item = collectionOptions[index];
            const collection = item.collection;
            return collection ? (
              <CollectionWrapper
                key={collection.TabId}
                style={style}
                onClick={() => onCollectionClick(collection.TabId)}
              >
                <ContentCard content={collection} type="collection" />
                {includedCollections.includes(collection.TabId) && (
                  <BlueCheck>
                    <CheckIcon />
                  </BlueCheck>
                )}
              </CollectionWrapper>
            ) : (
              <SourceType style={style}>{item.heading}</SourceType>
            );
          }}
        </VariableSizeList>
      </Results>
    </Container>
  );
};
