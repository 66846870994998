import { useCallback, Dispatch, SetStateAction, useState } from 'react';
import styled, { css } from 'styled-components';
import { Select } from 'antd';

import { Filter, FilterOption } from 'api';
import { NEUTRAL_6_COLOUR, NEUTRAL_9_COLOUR, HIGHLIGHT_SECONDARY_HOVER_COLOUR } from 'theme';
import { FONT_10PX_REGULAR } from 'font';
import { PlusIcon } from 'icons';

import { ContentItem } from '../ContentTagging';
import { ItemToSave } from '../hooks/useContentTagging';

const LABEL_STYLE = css`
  background-color: ${HIGHLIGHT_SECONDARY_HOVER_COLOUR};
  border-radius: 13px;
  border: none;
  color: ${NEUTRAL_9_COLOUR};
  ${FONT_10PX_REGULAR};
  line-height: 18px;
  padding: 2px 8px;
`;

const SelectInput = styled(Select)`
  width: 100%;
  height: 100%;
  border: 1px solid transparent;

  :hover {
    border-color: ${NEUTRAL_6_COLOUR};
  }

  #react-app && .ant-select-selector {
    height: 100%;
    display: flex;
    align-items: flex-start;
    padding: 5px 0 4px 8px;
    border: none;
    background: none !important;
  }

  input {
    border: none;
  }

  #react-app && .ant-select-selection-item {
    ${LABEL_STYLE};
    height: 18px;
    margin: 4px;
    display: flex;
    align-items: center;
  }

  #react-app && .ant-select-selection-search-input {
    background: none;
  }
`;

const OptionLabel = styled.div`
  ${LABEL_STYLE};
  display: inline-block;
`;

const CreateNewTag = styled.div`
  ${LABEL_STYLE};
  padding-left: 2px;
  background: none;
`;

const StyledPlusIcon = styled(PlusIcon)`
  margin-right: 4px;
`;

interface TagSelectInputProps {
  contentItem: ContentItem;
  filter: Filter;
  existingTags: string[];
  tagSelectState: Record<string, string[]>;
  filterToSave?: Filter;
  selectedRows: ContentItem[];
  disabled: boolean;
  setTagSelectState: Dispatch<SetStateAction<Record<string, string[]>>>;
  setItemsToSave: Dispatch<SetStateAction<Record<number, ItemToSave>>>;
  setFiltersToSave: Dispatch<SetStateAction<Record<string, Filter>>>;
}

export const TagSelectInput = ({
  contentItem,
  filter,
  existingTags,
  tagSelectState,
  filterToSave,
  selectedRows,
  disabled,
  setTagSelectState,
  setItemsToSave,
  setFiltersToSave,
}: TagSelectInputProps) => {
  const { Filter: FilterTitle, Id: FilterId, FilterOptions } = filter;
  const stateKey = `${contentItem.Id}${FilterId}`; // SelectInput's value is stored in tagSelectState object, indexed by VideoId/TabId + FilterId
  const selectedStateKeys = selectedRows.length > 0 ? selectedRows.map((item) => `${item.Id}${FilterId}`) : [stateKey]; // Array of all selected rows' stateKeys
  const inputValue = tagSelectState[stateKey] ?? existingTags; // If changes have been made, SelectInput's value will use tagSelectState, otherwise it will use tags from Video/Collection's Tag property, matching this Filterw
  const availableOptions = filterToSave ? filterToSave.FilterOptions : FilterOptions; // If any new tags have been created, these need to be included in the options for all inputs in this Filter's column
  const filteredOptions = availableOptions.filter((option) => !inputValue.includes(option.Option));
  const [searchValue, setSearchValue] = useState<string>('');

  const updateTagSelectState = useCallback(
    (newValue: string[]) => {
      const updatedKeys: Record<string, string[]> = {};
      selectedStateKeys.forEach((key) => (updatedKeys[key] = newValue));
      setTagSelectState((oldState) => ({
        ...oldState,
        ...updatedKeys,
      }));
    },
    [selectedStateKeys, setTagSelectState],
  );

  const updateItemsToSave = useCallback(
    (filterTitle: string, tags: string[]) => {
      setItemsToSave((oldState) => {
        const updatedItems: Record<number, ItemToSave> = {};

        if (selectedRows.length === 0) {
          const oldFiltersState = oldState[contentItem.Id] ? oldState[contentItem.Id].filters : contentItem.Tags;
          updatedItems[contentItem.Id] = {
            type: contentItem.Type === 'lesson' ? 'video' : 'collection',
            filters: { ...oldFiltersState, [filterTitle]: tags },
          };
        } else {
          selectedRows.forEach((item) => {
            const oldFiltersState = oldState[item.Id] ? oldState[item.Id].filters : item.Tags;
            updatedItems[item.Id] = {
              type: item.Type === 'lesson' ? 'video' : 'collection',
              filters: { ...oldFiltersState, [filterTitle]: tags },
            };
          });
        }

        return {
          ...oldState,
          ...updatedItems,
        };
      });
    },
    [contentItem, selectedRows, setItemsToSave],
  );

  return (
    <SelectInput
      mode="multiple"
      value={inputValue}
      optionLabelProp="value"
      filterOption={(input, option) => {
        return (
          (option?.label !== 'ADD_NEW_TAG' &&
            option?.value.toLowerCase().trim().includes(input.toLowerCase().trim())) ||
          (option?.label === 'ADD_NEW_TAG' &&
            !availableOptions.find((option) => option.Option.toLowerCase() === input.toLowerCase().trim()))
        );
      }}
      onSearch={(value) => {
        setSearchValue(value);
      }}
      onChange={(value) => {
        setSearchValue('');
        const onChangeValue = value as string[];

        if (onChangeValue.length === 0 || onChangeValue.length < inputValue.length) {
          // Tag is being removed
          updateTagSelectState(onChangeValue);
          updateItemsToSave(FilterTitle, onChangeValue);
        } else {
          const trimmedValueArr = onChangeValue.map((tag) => tag.trim()); // Trim leading/trailing whitespace from all tags in array
          const latestTag = [...trimmedValueArr].pop() as string; // Get the tag that has just been typed/selected
          const latestTagLowerCase = latestTag.toLowerCase(); // toLowerCase for comparison to existing tags
          const prevStateLowerCase = inputValue.map((tag) => tag.toLowerCase()); // value prior to this onChange event, convert toLowerCase for comparison
          if (prevStateLowerCase.includes(latestTagLowerCase)) {
            // Entered tag is already selected, so will be deselected
            const filteredArr = trimmedValueArr.filter((tag) => tag.toLowerCase() !== latestTagLowerCase);
            updateTagSelectState(filteredArr);
            updateItemsToSave(FilterTitle, filteredArr);
          } else {
            // Tag is being added, check if it already exists in the available FilterOptions (in any case variation)
            const existingFilterOption: FilterOption | undefined = availableOptions.find(
              ({ Option }) => Option.toLowerCase().trim() === latestTagLowerCase,
            );
            if (existingFilterOption) {
              // If it already exists, remove the most recently entered tag, and add the existing tag instead, to avoid duplicate tags with different cases
              trimmedValueArr.pop();
              trimmedValueArr.push(existingFilterOption.Option);
              updateTagSelectState(trimmedValueArr);
              updateItemsToSave(FilterTitle, trimmedValueArr);
            } else {
              // This is a new tag, add it as entered (minus leading/trailing whitespace), and add it as a new FilterOption in the current Filter
              const updatedFilter = filterToSave || filter;
              updatedFilter.FilterOptions.push({
                Option: latestTag,
                Position: updatedFilter.FilterOptions.length + 1,
                Enabled: true,
                Id: 0,
                IsDefault: false,
                SourceTag: null,
              });
              updateTagSelectState(trimmedValueArr);
              updateItemsToSave(FilterTitle, trimmedValueArr);
              setFiltersToSave((oldVal) => ({ ...oldVal, [FilterId]: updatedFilter }));
            }
          }
        }
      }}
      notFoundContent=""
      dropdownMatchSelectWidth={295}
      getPopupContainer={() => document.getElementById('react-app') as HTMLElement}
      disabled={disabled}
    >
      {filteredOptions.map(({ Option }) => (
        <Select.Option key={Option} value={Option}>
          <OptionLabel>{Option}</OptionLabel>
        </Select.Option>
      ))}
      {searchValue && (
        <Select.Option key="ADD_NEW_TAG" label="ADD_NEW_TAG" value={searchValue}>
          <CreateNewTag>
            <StyledPlusIcon />
            {`Create new tag '${searchValue}'`}
          </CreateNewTag>
        </Select.Option>
      )}
    </SelectInput>
  );
};
