import { useState } from 'react';

import { PageContainer } from 'components';
import { useContent, useLocalAppProperties } from 'providers';

import {
  DesignMockup,
  ColorSchemeSettings,
  GeneralSettings,
  SideMenuSettings,
  PurchaseScreensSettings,
  OnboardingScreensSettings,
} from 'app/modules/design-screens';
import { PurchaseScreenView } from 'app/modules/build-dragdrop';

export const DESIGN_SCREEN_KEYS = [
  'general',
  'color-scheme',
  'side-menu',
  'purchase-screens',
  'onboarding-screens',
] as const;

export type DesignScreenKey = (typeof DESIGN_SCREEN_KEYS)[number];

interface Screen {
  heading: string;
  subheading: string;
}

const SCREEN_HEADINGS: Record<DesignScreenKey, Screen> = {
  general: {
    heading: 'General',
    subheading: 'Customize text, layout and design elements across your app.',
  },
  'color-scheme': {
    heading: 'Color Scheme',
    subheading: "Edit your app's brand color and color scheme.",
  },
  'side-menu': {
    heading: 'Side Menu',
    subheading: "Configure the labels and links on your app's side menu.",
  },
  'purchase-screens': {
    heading: 'Purchase Screens',
    subheading: 'Configure the text on the purchase screens.',
  },
  'onboarding-screens': {
    heading: 'Onboarding Screens',
    subheading:
      'Onboarding Screens are displayed the first time a user opens your app and/or if the user is not signed in. You can change your Onboarding Screens at any time while your Splash Screen will require a new app submission.',
  },
};

export const DesignScreensInternal = ({ screenKey }: { screenKey: DesignScreenKey }) => {
  const { properties } = useLocalAppProperties();
  const { collections } = useContent();
  const [purchaseScreenView, setPurchaseScreenView] = useState<PurchaseScreenView>('Subscription1');

  const { heading, subheading } = SCREEN_HEADINGS[screenKey];

  const getComponent = (key: DesignScreenKey) => {
    switch (key) {
      case 'color-scheme':
        return <ColorSchemeSettings />;
      case 'general':
        return <GeneralSettings />;
      case 'side-menu':
        return <SideMenuSettings />;
      case 'purchase-screens':
        return <PurchaseScreensSettings setPurchaseScreenView={setPurchaseScreenView} />;
      case 'onboarding-screens':
        return <OnboardingScreensSettings />;
    }
  };

  return (
    <PageContainer
      heading={heading}
      subheading={subheading}
      isLoading={!properties || !collections}
      mockup={screenKey !== 'general' && <DesignMockup screenKey={screenKey} purchaseScreenView={purchaseScreenView} />}
      headingButton="save"
    >
      {getComponent(screenKey)}
    </PageContainer>
  );
};
