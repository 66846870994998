import { endOfDay, format, startOfDay } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';

import { METRIC_KEYS, Metric, Video, VideoByMetric } from 'api';
import { VideoAnalyticsTable, VideoWithAnalytics } from 'app/modules/analytics/Viewership/components';
import { DateRangePicker, PageContainer } from 'components';
import { useVideos } from 'hooks';
import { ExportIcon } from 'icons';
import { secondsToTime } from 'utils/DateUtils';

import { CsvButton, HeadingWrapper } from '../components';
import { useVideoAnalyticsContext } from '../providers/VideoAnalyticsProvider';
import { PAGE_CONTAINER_WIDE_WIDTH } from 'theme';

const PAGE_NAME = 'Video Analytics';

interface IndexedAnalytics {
  [key: string]: VideoByMetric;
}

const removeInvalidVideos = (videos: Video[]) =>
  videos.filter(
    (video: Video) =>
      video.Type === 'video' && !video.OriginalFilename.includes('.mp3') && !video.OriginalFilename.includes('.pdf'),
  );

const getAnalyticById = (id: string, analytics: IndexedAnalytics) => {
  const indexedAnalytic = analytics[id];
  return indexedAnalytic ? indexedAnalytic : null;
};

const getTotalWatched = (
  durationSeconds: string,
  videoPlays: number | undefined,
  averageCompletions: string | undefined,
) => {
  const duration = !isNaN(parseInt(durationSeconds)) ? parseInt(durationSeconds) : undefined;
  const completions =
    averageCompletions && !isNaN(parseInt(averageCompletions)) ? parseInt(averageCompletions) / 100 : undefined;
  if (duration && videoPlays && completions) {
    return Math.round(duration * videoPlays * completions);
  }
  return 0;
};

export const VideoAnalyticsPage = () => {
  const today: Date = new Date();
  const { data: videoData, isError: videosIsError } = useVideos();
  const { videoAnalyticsData, videoAnalyticsIsError, startDate, endDate, setStartDate, setEndDate } =
    useVideoAnalyticsContext();
  const [videos, setVideos] = useState<VideoWithAnalytics[] | undefined>(undefined);
  const [tableData, setTableData] = useState<Partial<VideoWithAnalytics>[] | undefined>(undefined);
  const [dataLoaded, setDataLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (videoData && videoAnalyticsData && !dataLoaded) {
      setVideos(removeInvalidVideos(videoData.videos));
      setDataLoaded(true);
    }
  }, [videoData, videoAnalyticsData]);

  useEffect(() => {
    if (dataLoaded && videoAnalyticsData && videos) {
      let updatedVideos: VideoWithAnalytics[] = videos;
      METRIC_KEYS.forEach((metric: Metric) => {
        const ANALYTIC_BY_ID: IndexedAnalytics = {};
        const metricArr = videoAnalyticsData[metric];
        if (metricArr.length > 0) {
          for (const analytic of metricArr) {
            if ('ItemId' in analytic) {
              ANALYTIC_BY_ID[analytic.ItemId] = analytic;
            } else if (analytic.SourceId) {
              ANALYTIC_BY_ID[analytic.SourceId] = analytic;
            }
          }
          const idKey = 'ItemId' in metricArr[0] ? 'ItemId' : 'SourceId';
          const metricKey =
            'AverageCompletion' in metricArr[0]
              ? 'AverageCompletion'
              : 'Completions' in metricArr[0]
              ? 'Completions'
              : 'Plays';
          updatedVideos = updatedVideos.map((video: VideoWithAnalytics) => {
            const videoId = idKey === 'ItemId' ? video.VideoId.toString() : video.SourceId;
            const analyticsObj = getAnalyticById(videoId, ANALYTIC_BY_ID);
            if (analyticsObj) {
              const value =
                metric === 'AverageCompletions' && analyticsObj.AverageCompletion
                  ? `${Math.round(parseInt(analyticsObj.AverageCompletion))}%`
                  : analyticsObj[metricKey];
              return { ...video, [metric]: value };
            }
            return { ...video, [metric]: metric === 'AverageCompletions' ? '0%' : 0 };
          });
        }
      });
      setTableData(
        updatedVideos.map(
          ({
            Title,
            SourceId,
            DurationSeconds,
            AverageCompletions,
            UniqueVideoPlays,
            UniqueVideoCompletions,
            VideoPlays,
            VideoCompletions,
          }) => ({
            Title,
            SourceId,
            Duration:
              DurationSeconds === '' ? DurationSeconds : secondsToTime(parseInt(DurationSeconds), { hideHours: true }),
            AverageCompletions,
            UniqueVideoPlays,
            UniqueVideoCompletions,
            VideoPlays,
            VideoCompletions,
            TotalWatched: getTotalWatched(DurationSeconds, VideoPlays, AverageCompletions),
          }),
        ),
      );
    }
  }, [dataLoaded, setTableData]);

  const handleDateChange = useCallback(
    (dates) => {
      const returnedStartDate = startOfDay(dates[0]);
      const returnedEndDate = endOfDay(dates[1]);
      if (
        startDate.toString() !== returnedStartDate.toString() ||
        endDate.toString() !== returnedStartDate.toString()
      ) {
        setStartDate(returnedStartDate);
        setEndDate(returnedEndDate);
        setVideos(undefined);
        setDataLoaded(false);
      }
    },
    [startDate, endDate, setStartDate, setEndDate, setVideos, setDataLoaded],
  );

  return (
    <PageContainer
      heading={PAGE_NAME}
      isError={videosIsError || videoAnalyticsIsError}
      breadcrumbs={{
        items: [{ label: 'Viewership', location: { path: '/analytics/viewership', key: 'Analytics' } }],
        currentScreen: PAGE_NAME,
      }}
      contentMaxWidth={PAGE_CONTAINER_WIDE_WIDTH}
    >
      <HeadingWrapper>
        <DateRangePicker
          today={today}
          onChange={handleDateChange}
          value={[startDate, endDate]}
          disabled={!dataLoaded}
          allowClear={false}
        />
        {tableData && (
          <CsvButton
            filename={`Video ${format(startDate, 'yyyy-MM-dd')} - ${format(endDate, 'yyyy-MM-dd')}`}
            headers={[
              { label: 'Title', key: 'Title' },
              { label: 'Source ID', key: 'SourceId' },
              { label: 'Video Duration', key: 'Duration' },
              { label: 'Total Plays', key: 'VideoPlays' },
              { label: 'Unique Plays', key: 'UniqueVideoPlays' },
              { label: 'Completions', key: 'VideoCompletions' },
              { label: 'Unique Completions', key: 'UniqueVideoCompletions' },
              { label: 'Ave. Watched', key: 'AverageCompletions' },
              { label: 'Est. Total Watched', key: 'TotalWatched' },
            ]}
            data={tableData}
          >
            <ExportIcon />
            Export CSV File
          </CsvButton>
        )}
      </HeadingWrapper>
      <VideoAnalyticsTable dataSource={tableData} isLoading={!dataLoaded} />
    </PageContainer>
  );
};
