import { useCallback, useState } from 'react';
import { ColumnType } from 'antd/lib/table/interface.d';

import { CustomTable, LoadingSpinner } from 'components';
import { Video, Metric } from 'api';
import { secondsToTime } from 'utils/DateUtils';

import { ColumnHeader, ColumnSortIcon } from 'app/modules/analytics/components';

interface SortType {
  dataIndex: Metric | 'Title' | 'Duration' | 'SourceId' | 'TotalWatched';
  order: 'descend' | 'ascend';
}

export interface VideoWithAnalytics extends Video {
  Duration?: string;
  AverageCompletions?: string;
  UniqueVideoPlays?: number;
  UniqueVideoCompletions?: number;
  VideoPlays?: number;
  VideoCompletions?: number;
  TotalWatched?: number;
}

export interface VideoAnalyticsTableProps {
  dataSource?: Partial<VideoWithAnalytics>[];
  isLoading?: boolean;
}

export const VideoAnalyticsTable = ({ dataSource, isLoading }: VideoAnalyticsTableProps) => {
  const [activeSort, setActiveSort] = useState<SortType>({ dataIndex: 'VideoPlays', order: 'descend' });

  // eslint-disable-next-line
  const columns: ColumnType<any>[] = [
    {
      title: (
        <ColumnHeader>
          Title
          <ColumnSortIcon active={activeSort.dataIndex === 'Title'} order={activeSort.order} />
        </ColumnHeader>
      ),
      dataIndex: 'Title',
      key: 'Title',
      width: 300,
      sorter: (a, b) => a.Title.toLowerCase().localeCompare(b.Title.toLowerCase()),
      sortDirections: ['ascend', 'descend', 'ascend'],
      showSorterTooltip: false,
      fixed: 'left',
    },
    {
      title: (
        <ColumnHeader>
          Source ID
          <ColumnSortIcon active={activeSort.dataIndex === 'SourceId'} order={activeSort.order} />
        </ColumnHeader>
      ),
      dataIndex: 'SourceId',
      key: 'SourceId',
      sorter: (a, b) => a.SourceId - b.SourceId,
      sortDirections: ['ascend', 'descend', 'ascend'],
      showSorterTooltip: {
        title: 'Source ID of the video',
        getPopupContainer: () => document.getElementById('react-app') as HTMLElement,
      },
    },
    {
      title: (
        <ColumnHeader>
          Video Duration
          <ColumnSortIcon active={activeSort.dataIndex === 'Duration'} order={activeSort.order} />
        </ColumnHeader>
      ),
      dataIndex: 'Duration',
      key: 'Duration',
      sorter: (a, b) => a.Duration.replaceAll(':', '') - b.Duration.replaceAll(':', ''),
      sortDirections: ['descend', 'ascend', 'descend'],
      showSorterTooltip: {
        title: 'Duration of the video',
        getPopupContainer: () => document.getElementById('react-app') as HTMLElement,
      },
    },
    {
      title: (
        <ColumnHeader>
          Total Plays
          <ColumnSortIcon active={activeSort.dataIndex === 'VideoPlays'} order={activeSort.order} />
        </ColumnHeader>
      ),
      dataIndex: 'VideoPlays',
      key: 'VideoPlays',
      sorter: (a, b) => a.VideoPlays - b.VideoPlays,
      sortDirections: ['descend', 'ascend', 'descend'],
      defaultSortOrder: 'descend',
      showSorterTooltip: {
        title: 'Number of times a video has been played.',
        getPopupContainer: () => document.getElementById('react-app') as HTMLElement,
      },
    },
    {
      title: (
        <ColumnHeader>
          Unique Plays
          <ColumnSortIcon active={activeSort.dataIndex === 'UniqueVideoPlays'} order={activeSort.order} />
        </ColumnHeader>
      ),
      dataIndex: 'UniqueVideoPlays',
      key: 'UniqueVideoPlays',
      sorter: (a, b) => a.UniqueVideoPlays - b.UniqueVideoPlays,
      sortDirections: ['descend', 'ascend', 'descend'],
      showSorterTooltip: {
        title: 'Number of people who have played a video.',
        getPopupContainer: () => document.getElementById('react-app') as HTMLElement,
      },
    },
    {
      title: (
        <ColumnHeader>
          Completions
          <ColumnSortIcon active={activeSort.dataIndex === 'VideoCompletions'} order={activeSort.order} />
        </ColumnHeader>
      ),
      dataIndex: 'VideoCompletions',
      key: 'VideoCompletions',
      sorter: (a, b) => a.VideoCompletions - b.VideoCompletions,
      sortDirections: ['descend', 'ascend', 'descend'],
      showSorterTooltip: {
        title: 'Number of times a video has been completed (over 90% progress or marked as complete).',
        getPopupContainer: () => document.getElementById('react-app') as HTMLElement,
      },
    },
    {
      title: (
        <ColumnHeader>
          Unique Completions
          <ColumnSortIcon active={activeSort.dataIndex === 'UniqueVideoCompletions'} order={activeSort.order} />
        </ColumnHeader>
      ),
      dataIndex: 'UniqueVideoCompletions',
      key: 'UniqueVideoCompletions',
      sorter: (a, b) => a.UniqueVideoCompletions - b.UniqueVideoCompletions,
      sortDirections: ['descend', 'ascend', 'descend'],
      showSorterTooltip: {
        title: 'Number of people who have completed a video (over 90% progress or marked as complete).',
        getPopupContainer: () => document.getElementById('react-app') as HTMLElement,
      },
    },
    {
      title: (
        <ColumnHeader>
          Ave. Watched
          <ColumnSortIcon active={activeSort.dataIndex === 'AverageCompletions'} order={activeSort.order} />
        </ColumnHeader>
      ),
      dataIndex: 'AverageCompletions',
      key: 'AverageCompletions',
      sorter: (a, b) => parseFloat(a.AverageCompletions) - parseFloat(b.AverageCompletions),
      sortDirections: ['descend', 'ascend', 'descend'],
      showSorterTooltip: {
        title: 'The percentage a video has been watched.',
        getPopupContainer: () => document.getElementById('react-app') as HTMLElement,
      },
    },
    {
      title: (
        <ColumnHeader>
          Est. Total Watched
          <ColumnSortIcon active={activeSort.dataIndex === 'TotalWatched'} order={activeSort.order} />
        </ColumnHeader>
      ),
      dataIndex: 'TotalWatched',
      key: 'TotalWatched',
      render: (TotalWatched) => secondsToTime(TotalWatched, { format: 'h_min' }),
      sorter: (a, b) => a.TotalWatched - b.TotalWatched,
      sortDirections: ['descend', 'ascend', 'descend'],
      showSorterTooltip: {
        title: 'The cumulative watch time of a video, estimated through its Ave. Watched and Total Plays data.',
        getPopupContainer: () => document.getElementById('react-app') as HTMLElement,
      },
    },
  ];

  const handleTableChange = useCallback(
    (pagination, filters, sorter) => {
      setActiveSort({ dataIndex: sorter.columnKey, order: sorter.order });
    },
    [setActiveSort],
  );

  return (
    <CustomTable
      dataSource={dataSource}
      columns={columns}
      pagination={{ pageSize: 20, showSizeChanger: false }}
      locale={{ emptyText: ' ' }}
      onChange={handleTableChange}
      rowKey="VideoId"
      scroll={{ x: 'max-content' }}
      loading={
        isLoading && {
          indicator: (
            <div style={{ marginTop: '13px' }}>
              <LoadingSpinner />
            </div>
          ),
        }
      }
    />
  );
};
