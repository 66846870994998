import { useMemo, useState } from 'react';
import styled from 'styled-components';

import { SOURCE_KAJABI } from 'api';
import { CustomCell, CustomTable2, SearchBar, StyledToggle, TableColumn, ToggleWrapper } from 'components';
import { FONT_14PX_MEDIUM, OVERFLOW_ELLIPSIS } from 'font';
import { useAppProperties } from 'hooks';
import { BuilderProduct, useLocalProducts, useSaveContext } from 'providers';
import { isUserAdmin } from 'utils';

import { DeleteOfferButton } from './DeleteOfferButton';
import { OffersHeading } from './OffersHeading';

const Ellipsis = styled.div`
  ${OVERFLOW_ELLIPSIS};
  ${FONT_14PX_MEDIUM};
`;

const Spacer = styled.div`
  height: 20px;
`;

const DeleteWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const OffersTable = () => {
  const [query, setQuery] = useState<string>('');

  const { products, setProductValue, setActiveProductId } = useLocalProducts();
  const { setProductValueToSave } = useSaveContext();
  const { data: appProperties } = useAppProperties();

  const isReadOnly = appProperties?.RolloutOffersScreen !== '1' && !isUserAdmin();

  const dataSource = useMemo(() => {
    if (!products) {
      return [];
    }

    const productArr = [...products.values()];

    if (query === '') {
      return productArr;
    }

    const filterFunction = (value: string) => value.toLowerCase().includes(query);

    return productArr.filter(
      ({ DisplayName, CustomDisplayName, SourceProductId }) =>
        filterFunction(DisplayName) || filterFunction(CustomDisplayName) || filterFunction(SourceProductId),
    );
  }, [products, query]);

  const columns: TableColumn<BuilderProduct & { id: string }>[] = useMemo(() => {
    const arr: TableColumn<BuilderProduct & { id: string }>[] = [
      {
        heading: 'Internal Title',
        width: 'grow',
        render: ({ DisplayName }) => (
          <CustomCell>
            <Ellipsis>{DisplayName}</Ellipsis>
          </CustomCell>
        ),
      },
      {
        heading: 'External Title',
        width: 'grow',
        render: ({ CustomDisplayName }) => (
          <CustomCell>
            <Ellipsis>{CustomDisplayName}</Ellipsis>
          </CustomCell>
        ),
      },
      {
        heading: 'Data Source',
        width: 125,
        render: ({ DataSource }) => (
          <CustomCell>
            <Ellipsis>{DataSource}</Ellipsis>
          </CustomCell>
        ),
      },
      {
        heading: 'Source Offer ID',
        width: 125,
        render: ({ SourceProductId }) => (
          <CustomCell>
            <Ellipsis>{SourceProductId}</Ellipsis>
          </CustomCell>
        ),
      },
      {
        heading: 'Logs In',
        width: 72,
        render: ({ AvailableForLogin, Id, ReadyForSale }) => (
          <ToggleWrapper onClick={(e) => e.stopPropagation()}>
            <CustomCell>
              <StyledToggle
                checked={AvailableForLogin === 'yes'}
                disabled={ReadyForSale === 'yes' || isReadOnly}
                checkedChildren="ON"
                onChange={(checked) => {
                  const newValue = checked ? 'yes' : 'no';
                  setProductValue(Id, 'AvailableForLogin', newValue);
                  setProductValueToSave(Id, 'AvailableForLogin', newValue);
                }}
              />
            </CustomCell>
          </ToggleWrapper>
        ),
      },
      {
        heading: 'For Sale',
        width: 72,
        render: ({ ReadyForSale, Id }) => (
          <ToggleWrapper onClick={(e) => e.stopPropagation()}>
            <CustomCell>
              <StyledToggle
                checked={ReadyForSale === 'yes'}
                disabled={isReadOnly}
                checkedChildren="ON"
                onChange={(checked) => {
                  const newValue = checked ? 'yes' : 'no';
                  setProductValue(Id, 'ReadyForSale', newValue);
                  setProductValueToSave(Id, 'ReadyForSale', newValue);

                  // If ReadyForSale is 'yes', AvailableForLogin is forced to be 'yes' and toggle is disabled
                  if (checked) {
                    setProductValue(Id, 'AvailableForLogin', 'yes');
                    setProductValueToSave(Id, 'AvailableForLogin', 'yes');
                  }
                }}
              />
            </CustomCell>
          </ToggleWrapper>
        ),
      },
    ];

    if (!isReadOnly) {
      arr.push({
        heading: '',
        id: 'delete',
        width: 30,
        render: ({ Id, DataSource }) => (
          <DeleteWrapper onClick={(e) => e.stopPropagation()}>
            <CustomCell>{DataSource !== SOURCE_KAJABI && <DeleteOfferButton productId={Id} />}</CustomCell>
          </DeleteWrapper>
        ),
      });
    }

    return arr;
  }, [setProductValue, setProductValueToSave]);

  const onRowClick = (data: BuilderProduct) => {
    setActiveProductId(data.Id);
  };

  return (
    <>
      <OffersHeading />
      <SearchBar placeholder="Search..." onSearch={(val) => setQuery(val.toLowerCase())} />
      <Spacer />
      <CustomTable2
        pageSize={25}
        columns={columns}
        data={dataSource.map((offer) => ({ ...offer, id: offer.Id.toString() }))}
        onRowClick={isReadOnly ? undefined : onRowClick}
        query={query}
        emptyTitle="No Offers"
        emptyDescription=""
      />
    </>
  );
};
