import { useState } from 'react';
import styled from 'styled-components';
import { Popover } from 'antd';

import { NEUTRAL_10_COLOUR, NEUTRAL_3_COLOUR, NEUTRAL_8_COLOUR, NEUTRAL_9_COLOUR } from 'theme';
import { FONT_12PX_MEDIUM, FONT_14PX_MEDIUM, FONT_14PX_REGULAR } from 'font';

import { CustomSubTabIcon, LinkIcon, PlusIcon } from 'icons';
import { BuilderCollection, BuilderCollectionItem, useContent, useSaveContext } from 'providers';
import {
  Collection,
  COLLECTION_TYPE_COLLECTION,
  COLLECTION_TYPE_TAB,
  CollectionItemSubType,
  DEFAULT_COLLECTION,
  DEFAULT_COLLECTION_ITEM,
  ITEM_TYPE_COLLECTION,
  MIXED_TEMPLATE,
  SOURCE_KAJABI,
  SOURCE_TYPE_SUBTAB,
  SOURCE_TYPE_TAB,
  SOURCE_VHX,
  SUBNAV_TEMPLATE,
  TAB_LEVEL_PROPERTIES,
} from 'api';
import { calculateCollectionItemPositions, getCollectionValuesFromNewCollection } from 'utils';
import { CustomButton } from 'components';
import { useDataSource } from 'providers';
import { useAppProperties } from 'hooks';

import { useMockupContext } from 'app/modules/build-dragdrop/Builder/mockup';
import { useBuilderContext } from 'app/modules/build-dragdrop/Builder/providers';
import { STANDALONE_PROPERTY } from 'app/modules/build-dragdrop/Builder/const';

const Wrapper = styled.div`
  margin-top: 12px;
  margin-left: 16px;
`;

const PopHead = styled.div`
  ${FONT_12PX_MEDIUM};
  color: ${NEUTRAL_8_COLOUR};
`;
const OptionWrapper = styled.div`
  padding: 12px;
  cursor: pointer;
  border-radius: 8px;

  :hover {
    background-color: ${NEUTRAL_3_COLOUR};
  }
`;
const Row = styled.div`
  display: flex;
  margin-bottom: 4px;
`;
const OptionIcon = styled.div`
  color: ${NEUTRAL_9_COLOUR};
  font-size: 16px;
  margin-right: 5px;
`;
const OptionTitle = styled.div`
  ${FONT_14PX_MEDIUM};
  color: ${NEUTRAL_10_COLOUR};
`;
const OptionBodyText = styled.div`
  ${FONT_14PX_REGULAR};
  ${NEUTRAL_8_COLOUR};
  margin-left: 21px;
`;

const SUBTAB_DEFAULT_COLLECTION = {
  ...DEFAULT_COLLECTION,
  Name: 'New Sub-Tab',
  SourceType: SOURCE_TYPE_SUBTAB,
  HideFromSearchResults: 1 as const,
};
const SUBTAB_DEFAULT_ITEM = {
  ...DEFAULT_COLLECTION_ITEM,
  // Currently we only add collection sections
  Type: ITEM_TYPE_COLLECTION,
  SubType: ITEM_TYPE_COLLECTION as CollectionItemSubType,
  // Updated during processing
  ChildId: 0,
  Position: 99,
};

const getLinkedBodyText = (dataSource: string) => {
  const textMap: Record<string, JSX.Element> = {
    [SOURCE_KAJABI]: (
      <>
        Create a Sub-Tab that's linked to a <br /> single Kajabi Course, Module or <br /> Sub-Module.
      </>
    ),
    [SOURCE_VHX]: (
      <>
        Create a Sub-Tab that's linked to a <br /> single Vimeo OTT Category or <br /> Collection
      </>
    ),
    default: (
      <>
        Link this sub-tab that will display a <br /> single group content.
      </>
    ),
  };

  return textMap[dataSource] ?? textMap.default;
};

export const TreeAddSubTab = () => {
  const dataSource = useDataSource();
  const { collections, setCollectionItems, setNewCollection, setCollectionProperty, setCollectionValue } = useContent();
  const {
    getTempId,
    setCollectionItemsToSave,
    setCollectionToSave,
    setCollectionPropertyToSave,
    setCollectionValueToSave,
  } = useSaveContext();
  const { currentTabId, setCurrentPage, setCurrentTabId } = useMockupContext();
  const { setDrawerContext } = useBuilderContext();
  const [open, setOpen] = useState<boolean>(false);
  const { data } = useAppProperties();

  const convertToSubNav = (currentTab: BuilderCollection) => {
    const newTabId = `TempTab${getTempId()}`;

    // Need to convert tab to a subnav and move all its items into a subtab
    // Create a new parent main tab
    const newCollection = {
      ...DEFAULT_COLLECTION,
      Position: currentTab.Position,
      Name: currentTab.Name,
      NavBarTitleText: currentTab.NavBarTitleText,
      TabId: newTabId,
      TemplateId: SUBNAV_TEMPLATE,
      Type: COLLECTION_TYPE_TAB,
      SourceType: SOURCE_TYPE_TAB,
      SourceId: '', // Avoid clash with existing SourceId
      AppId: currentTab.AppId,
      Items: [],
      Thumbnails: {},
      IsMainTab: currentTab.IsMainTab,
      Icon: currentTab.Icon,
    };
    const newItemId = `TempTabItem${getTempId()}`;
    const newCollectionItems = [
      {
        ...SUBTAB_DEFAULT_ITEM,
        TabItemId: newItemId,
        TabId: newTabId,
        ChildId: currentTabId,
        Position: 1,
      },
    ] as BuilderCollectionItem[];
    setNewCollection(newTabId, newCollection, newCollectionItems);
    setCollectionToSave(newTabId, getCollectionValuesFromNewCollection(newCollection));

    // Update existing tab collection to behave like a collection instead of a tab
    const defaultValues = [
      { name: 'Position', value: 0 },
      { name: 'IsMainTab', value: 0 },
      { name: 'Type', value: COLLECTION_TYPE_COLLECTION },
      { name: 'SourceType', value: SOURCE_TYPE_SUBTAB },
    ] as { name: keyof Collection; value: string | number }[];
    for (const { name, value } of defaultValues) {
      if (currentTabId) {
        setCollectionValue(currentTabId, name, value);
        setCollectionValueToSave(currentTabId, name, value);
      }
    }

    setCurrentTabId(newTabId); // Newly created tab is now the current tab in the simulator

    // Copy TabLevel properties back up to the main tab
    // e.g) Filter View, Search View, Notifications icon etc
    for (const [key, value] of Object.entries(currentTab.Properties || [])) {
      if (TAB_LEVEL_PROPERTIES.includes(key)) {
        setCollectionProperty(newTabId, key, value);
        setCollectionPropertyToSave(newTabId, key, value);
      }
    }
    return { newTabId, newItems: newCollectionItems };
  };

  const handleCreateCustomSubTab = () => {
    if (!currentTabId) {
      console.error('TreeAddSubTab', 'Unable to create subtab without currentTabId');
      return;
    }
    const currentTab = collections[currentTabId];
    const currentTemplate = currentTab.TemplateId;
    let parentTabId = currentTabId;
    let parentTabItems = currentTab.Items;

    if (currentTemplate !== SUBNAV_TEMPLATE) {
      const { newTabId, newItems } = convertToSubNav(currentTab);
      parentTabId = newTabId;
      parentTabItems = newItems;
    }

    // Current tab is a subnav, add a new collection to it
    // Create a new subtab
    const newId = `TempTab${getTempId()}`;
    const newCollection = {
      ...SUBTAB_DEFAULT_COLLECTION,
      TabId: newId,
      TemplateId: MIXED_TEMPLATE,
      AppId: currentTab.AppId,
      Items: [],
      Thumbnails: {},
    };
    setNewCollection(newId, newCollection);
    const newItemId = `TempTabItem${getTempId()}`;
    parentTabItems.push({
      ...SUBTAB_DEFAULT_ITEM,
      TabItemId: newItemId,
      TabId: parentTabId,
      ChildId: newId,
    } as BuilderCollectionItem);
    calculateCollectionItemPositions(parentTabItems);

    setCollectionItems(parentTabId, parentTabItems);
    setCollectionItemsToSave(parentTabId);
    setCollectionToSave(newId, getCollectionValuesFromNewCollection(newCollection));
    setCurrentPage({ subItemId: newId, subTabItemId: newItemId }, { resetScrollPosition: true });

    // Automatically close the add subtab popup after creating a subtab
    setOpen(false);
  };
  const handleCreateLinkedSubTab = () => {
    if (!currentTabId) {
      console.error('TreeAddSubTab', 'Unable to create subtab without currentTabId');
      return;
    }
    const currentTab = collections[currentTabId];
    const currentTemplate = currentTab.TemplateId;

    if (currentTemplate !== SUBNAV_TEMPLATE) {
      convertToSubNav(currentTab);
    }

    // The drawer will now be responsible for creating the linked sub-tab
    // The drawer will identify the newly created tab via currentTabId in MockupContext
    setTimeout(() => {
      // Set after a delay to avoid drawer resets during the subnav conversion
      setDrawerContext({ drawerType: 'linkSubTab' });
    }, 100);
    setOpen(false);
  };

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  return (
    <Wrapper>
      {data?.[STANDALONE_PROPERTY] !== '1' ? (
        <Popover
          placement="bottomLeft"
          open={open}
          content={
            <>
              <PopHead>Select your sub-tab type:</PopHead>
              <OptionWrapper onClick={handleCreateCustomSubTab}>
                <Row>
                  <OptionIcon>
                    <CustomSubTabIcon />
                  </OptionIcon>
                  <OptionTitle>Custom Sub-Tab</OptionTitle>
                </Row>
                <OptionBodyText>
                  Create a Sub-Tab that combines <br /> multiple different content types all <br /> together.
                </OptionBodyText>
              </OptionWrapper>
              <OptionWrapper onClick={handleCreateLinkedSubTab}>
                <Row>
                  <OptionIcon>
                    <LinkIcon />
                  </OptionIcon>
                  <OptionTitle>Linked Sub-Tab</OptionTitle>
                </Row>
                <OptionBodyText>{getLinkedBodyText(dataSource)}</OptionBodyText>
              </OptionWrapper>
            </>
          }
          trigger="click"
          getPopupContainer={() => document.getElementById('builder-popups') as HTMLElement}
          onOpenChange={handleOpenChange}
        >
          <CustomButton medium tertiaryHighlight icon={<PlusIcon />}>
            Add Sub-Tab
          </CustomButton>
        </Popover>
      ) : (
        <CustomButton medium tertiaryHighlight icon={<PlusIcon />} onClick={handleCreateCustomSubTab}>
          Add Sub-Tab
        </CustomButton>
      )}
    </Wrapper>
  );
};
