import { ReactNode } from 'react';
import styled from 'styled-components';
import { Radio, RadioChangeEvent } from 'antd';

import { SettingsInputWrapper, Tooltip } from 'components';
import { NEUTRAL_7_COLOUR } from 'theme';
import { FONT_11PX_REGULAR_DEPRECATED } from 'font';

import { CustomRadioButtonsGroup, CustomRadioGroup } from './CustomRadioButtonsGroup';

interface RadioImageGroupProps {
  centered?: boolean;
}

const RadioImageGroup = styled(CustomRadioButtonsGroup)<RadioImageGroupProps>`
  #react-app && {
    height: auto;
    display: ${(props) => (props.centered ? 'flex' : 'block')};
    justify-content: center;
  }
`;

const RadioButton = styled(Radio.Button)<{ itemWidth?: string }>`
  #react-app && {
    width: ${(props) => props.itemWidth || '153px'};
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding: 8px !important;
  }
`;

const RadioButtonLabel = styled.div`
  #react-app && {
    ${FONT_11PX_REGULAR_DEPRECATED};
    color: ${NEUTRAL_7_COLOUR};
    text-align: center;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

interface ImageWrapperProps {
  image: Image;
  margin?: string;
}

const ImageWrapper = styled.div<ImageWrapperProps>`
  width: ${(props) => props.image.width};
  height: ${(props) => props.image.height};
  margin: ${(props) => props.margin || '18px 18px 14px'};
`;

const Description = styled.div`
  color: ${NEUTRAL_7_COLOUR};
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
  text-align: center;
  width: 222px;
  margin-top: 20px;
`;

export interface SettingsRadioInputProps {
  label?: string;
  defaultVal?: string | number;
  options: Option[];
  width?: string;
  value?: string | boolean;
  centered?: boolean;
  disabled?: boolean;
  onChange?: (event: RadioChangeEvent) => void;
}

interface Option {
  name: string;
  value: string | number;
  tooltip?: string;
}

interface SettingsRadioImageInputProps extends SettingsRadioInputProps {
  options: ImageOption[];
}

interface ImageOption extends Option {
  image: Image;
  description?: string;
}

interface CustomOption extends Option {
  node: ReactNode | ReactNode[];
}

interface Image {
  url: string;
  width: string;
  height: string;
}

export interface SettingsRadioButtonsProps extends SettingsRadioInputProps {
  groupHeight?: string;
  itemWidth?: string;
  options: (CustomOption | ImageOption)[];
  column?: boolean;
}

export const SettingsRadioInput = ({ label, defaultVal, options, width, ...props }: SettingsRadioInputProps) => (
  <SettingsInputWrapper label={label} width={width}>
    <CustomRadioGroup defaultValue={defaultVal ?? options[0].value} {...props}>
      {options.map((option) => (
        <Radio key={option.name} value={option.value}>
          {option.name}
          {option.tooltip && <Tooltip title={option.tooltip} $fontSize="11px" $marginLeft="5px" placement="right" />}
        </Radio>
      ))}
    </CustomRadioGroup>
  </SettingsInputWrapper>
);

export const SettingsRadioImageInput = ({ label, defaultVal, options, ...props }: SettingsRadioImageInputProps) => (
  <SettingsInputWrapper label={label}>
    <RadioImageGroup defaultValue={defaultVal ?? options[0].value} {...props}>
      {options.map((opt) => {
        const option = opt as ImageOption;
        return (
          <Wrapper key={option.name}>
            <ImageWrapper image={option.image}>
              <img src={option.image.url} width="100%" alt={option.name} />
            </ImageWrapper>
            <Radio value={option.value}>{option.name}</Radio>
            <Description>{option.description}</Description>
          </Wrapper>
        );
      })}
    </RadioImageGroup>
  </SettingsInputWrapper>
);

export const SettingsRadioButtons = ({ options, groupHeight, itemWidth, ...props }: SettingsRadioButtonsProps) => (
  <SettingsInputWrapper>
    <CustomRadioButtonsGroup defaultValue={options[0].value} height={groupHeight} {...props}>
      {options.map((option) => (
        <RadioButton key={option.name} value={option.value} itemWidth={itemWidth}>
          {'node' in option ? (
            <>{option.node}</>
          ) : 'image' in option ? (
            <ImageWrapper image={option.image} margin="8px auto">
              <img src={option.image.url} width="100%" alt={option.name} />
            </ImageWrapper>
          ) : null}
          <RadioButtonLabel>{option.name}</RadioButtonLabel>
        </RadioButton>
      ))}
    </CustomRadioButtonsGroup>
  </SettingsInputWrapper>
);
