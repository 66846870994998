import { BuilderCollection, useContent, useSaveContext } from 'providers';
import { SegmentProps, ContentDescription } from 'components';

interface CollectionDescriptionProps extends SegmentProps {
  revertText?: string;
  height?: number;
  maxHeight?: number;
  hideTitle?: boolean;
  bottomRevertButton?: boolean;
}

export const CollectionDescription = ({
  TabId,
  Description,
  SourceDescription,
  ...props
}: Pick<BuilderCollection, 'TabId' | 'SourceDescription' | 'Description'> & CollectionDescriptionProps) => {
  const { setCollectionValue } = useContent();
  const { setCollectionValueToSave } = useSaveContext();

  const handleDescChange = (description: string) => {
    setCollectionValue(TabId, 'Description', description);
    setCollectionValueToSave(TabId, 'Description', description);
  };

  return (
    <ContentDescription
      description={Description}
      sourceDescription={SourceDescription}
      onDescChange={handleDescChange}
      {...props}
    />
  );
};
