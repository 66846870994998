import { MetricKey } from 'app/modules/analytics/const';
import { AxiosInstance, AxiosResponse } from 'axios';
import { format } from 'date-fns';

const metrics = ['average_completion', 'unique_plays', 'unique_completions', 'plays', 'completions'];

export const METRIC_KEYS = [
  'AverageCompletions',
  'UniqueVideoPlays',
  'UniqueVideoCompletions',
  'VideoPlays',
  'VideoCompletions',
] as const;

export type Metric = (typeof METRIC_KEYS)[number];

export interface VideoByMetric {
  ItemId?: string;
  SourceId?: string;
  Plays?: number;
  Completions?: number;
  AverageCompletion?: string;
}

export interface AverageCompletions {
  SourceId: string;
  AverageCompletion: string; // Unrounded percentage string, e.g. "92.192307"
}

export interface UniqueVideoPlays {
  SourceId: string;
  Plays: number;
}

export interface UniqueVideoCompletions {
  ItemId: string;
  Completions: number;
}

export interface VideoPlays {
  SourceId: string;
  Plays: number;
}

export interface VideoCompletions {
  ItemId: string;
  Completions: number;
}

export interface VideoAnalytics {
  AverageCompletions: AverageCompletions[];
  UniqueVideoPlays: UniqueVideoPlays[];
  UniqueVideoCompletions: UniqueVideoCompletions[];
  VideoPlays: VideoPlays[];
  VideoCompletions: VideoCompletions[];
}

export const getVideoAnalytics = async (
  client: AxiosInstance,
  appBeingEdited: string,
  startDate: Date,
  endDate: Date,
) => {
  const formattedStart = format(startDate, 'yyyy-MM-dd HH:mm:ss');
  const formattedEnd = format(endDate, 'yyyy-MM-dd HH:mm:ss');
  const requests: Promise<AxiosResponse>[] = [];

  metrics.forEach((metric: string) => {
    requests.push(
      client
        .get(`/video_analytics/${appBeingEdited}/${metric}?start_date=${formattedStart}&end_date=${formattedEnd}`)
        .then(({ data }) => data),
    );
  });

  const responses = await Promise.all(requests);
  const analytics: VideoAnalytics = {
    AverageCompletions: [],
    UniqueVideoPlays: [],
    UniqueVideoCompletions: [],
    VideoPlays: [],
    VideoCompletions: [],
  };
  responses.forEach((response) => {
    for (const [key, value] of Object.entries(response)) {
      analytics[key as keyof VideoAnalytics] = value;
    }
  });
  return analytics;
};

export interface ProgressTracking {
  SourceUserId: string;
  SourceId: string;
  MaxPercentage: string;
  StartDate: string;
}

export interface ProgressTrackingResponse {
  ProgressTracking: ProgressTracking[];
}

export const getProgressTrackingAnalytics = (
  client: AxiosInstance,
  appBeingEdited: string,
  startDate: Date,
  endDate: Date,
) => {
  return client.get<ProgressTrackingResponse>(`/video_analytics/${appBeingEdited}/progresstracking`, {
    params: {
      start_date: format(startDate, 'yyyy-MM-dd HH:mm:ss'),
      end_date: format(endDate, 'yyyy-MM-dd HH:mm:ss'),
    },
  });
};

export interface AverageCompletionsTotal {
  SourceId: 'all' | null;
  AverageCompletion: string; // Unrounded percentage string, e.g. "92.192307"
  Date: Date | null;
}

export interface VideoPlaysTotal {
  SourceId: 'all' | null;
  Plays: number;
  Date: Date | null;
}

export interface VideoCompletionsTotal {
  ItemId: 'all' | null;
  Completions: number;
  Date: Date | null;
}

export interface VideoAnalyticsTotals {
  AverageCompletions: AverageCompletionsTotal | undefined; // Unrounded percentage string, e.g. "92.192307"
  VideoPlays: VideoPlaysTotal | undefined;
  VideoCompletions: VideoCompletionsTotal | undefined;
}

export type DateIncrement = 'daily' | 'weekly' | 'monthly';

export const getVideoAnalyticsTotals = async (
  client: AxiosInstance,
  appBeingEdited: string,
  startDate: Date,
  endDate: Date,
) => {
  const formattedStart = format(startDate, 'yyyy-MM-dd HH:mm:ss');
  const formattedEnd = format(endDate, 'yyyy-MM-dd HH:mm:ss');
  const requests: Promise<AxiosResponse>[] = [];

  ['plays', 'completions', 'average_completion'].forEach((metric: string) => {
    requests.push(
      client
        .get(
          `/video_analytics/${appBeingEdited}/${metric}?start_date=${formattedStart}&end_date=${formattedEnd}&total_only=true`,
        )
        .then(({ data }) => data),
    );
  });

  const responses = await Promise.all(requests);
  const analytics: VideoAnalyticsTotals = {
    AverageCompletions: undefined,
    VideoPlays: undefined,
    VideoCompletions: undefined,
  };
  responses.forEach((response) => {
    for (const [key, value] of Object.entries(response)) {
      analytics[key as keyof VideoAnalyticsTotals] = value[0];
    }
  });
  return analytics;
};

export const getVideoAnalyticsMetricByDate = async (
  client: AxiosInstance,
  appBeingEdited: string,
  metric: MetricKey,
  startDate: Date,
  endDate: Date,
  byDate: DateIncrement,
) => {
  const formattedStart = format(startDate, 'yyyy-MM-dd HH:mm:ss');
  const formattedEnd = format(endDate, 'yyyy-MM-dd HH:mm:ss');

  return client
    .get(
      `/video_analytics/${appBeingEdited}/${metric}?start_date=${formattedStart}&end_date=${formattedEnd}&total_only=true&by_date=${byDate}`,
    )
    .then(({ data }) => {
      if (metric === 'completions') {
        const arr = data.VideoCompletions as VideoCompletionsTotal[];
        return arr.map(({ Date, Completions }) => ({ date: Date as Date, value: Completions }));
      } else if (metric === 'plays') {
        const arr = data.VideoPlays as VideoPlaysTotal[];
        return arr.map(({ Date, Plays }) => ({ date: Date as Date, value: Plays }));
      } else {
        const arr = data.AverageCompletions as AverageCompletionsTotal[];
        return arr.map(({ Date, AverageCompletion }) => ({ date: Date as Date, value: parseInt(AverageCompletion) }));
      }
    });
};
