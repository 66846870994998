import { BuilderVideo, useContent, useSaveContext } from 'providers';
import { SegmentProps, ContentDescription } from 'components';

interface VideoDescriptionProps extends SegmentProps {
  revertText?: string;
  height?: number;
  maxHeight?: number;
  hideTitle?: boolean;
  bottomRevertButton?: boolean;
}

export const VideoDescription = ({
  VideoId,
  Description,
  SourceDescription,
  ...props
}: Pick<BuilderVideo, 'VideoId' | 'SourceDescription' | 'Description'> & VideoDescriptionProps) => {
  const { setVideoValue } = useContent();
  const { setVideoValueToSave } = useSaveContext();

  const handleDescChange = (description: string) => {
    setVideoValue(VideoId, 'Description', description);
    setVideoValueToSave(VideoId, 'Description', description);
  };

  return (
    <ContentDescription
      description={Description}
      sourceDescription={SourceDescription}
      onDescChange={handleDescChange}
      {...props}
    />
  );
};
