import { useCallback } from 'react';
import styled from 'styled-components';
import { Form } from 'antd';

import { ContentAdvancedSettings, SegmentTitle, SettingsCheckboxInput, SettingsImageInput } from 'components';
import { useContent, useDataSource } from 'providers';
import { NEUTRAL_7_COLOUR } from 'theme';
import { BellAltIcon } from 'icons';
import { FONT_14PX_SEMIBOLD } from 'font';

import { useTabSettings } from 'app/modules/build-screens/hooks';

import { NavigationHeader, TabBar } from '../components';
import { getPageName } from '../utils';
import { DrawerSeparator } from './components/DrawerSeparator';
import { useAppProperties } from 'hooks';
import { TabDrawer } from '../components/Common/TabDrawer';

const Divider = styled.div`
  color: ${NEUTRAL_7_COLOUR};
  ${FONT_14PX_SEMIBOLD};
  display: flex;
  align-items: center;
  margin: 8px 0 12px;
  height: 30px;
`;

const HeaderLogoWrapper = styled.div`
  margin-bottom: 40px;
`;

const StyledBellIcon = styled(BellAltIcon)`
  font-size: 14px;
  color: #484848;
  stroke: #484848;
  stroke-width: 2;
`;

interface LibraryTabDrawerProps {
  tabId: number;
}

export const LibraryTabDrawer = ({ tabId }: LibraryTabDrawerProps) => {
  const [form] = Form.useForm();
  const dataSource = useDataSource();

  const { collections } = useContent();
  const tab = collections[tabId];

  // The declared variables are managed in useTabSettings
  const { onValuesChange } = useTabSettings(
    {
      NavBarTitleText: { type: 'collection', valueType: 'string' },
      Name: { type: 'collection', valueType: 'string' },
      LockedUntilSignIn: { type: 'collectionProperty', valueType: 'binary' },
      NavBarNotificationsView: { type: 'collectionProperty', valueType: 'binary' },
      NavBarSearchView: { type: 'collectionProperty', valueType: 'binary' },
      NavBarFilterView: { type: 'collectionProperty', valueType: 'binary' },
      OnlyDisplayPurchasedContent: { type: 'collectionProperty', valueType: 'binary' },
      Icon: { type: 'collection', valueType: 'string' },
      NavBarTitleImage: { type: 'collectionProperty', valueType: 'string' },
    },
    tabId,
    form,
  );

  const { data: properties } = useAppProperties();

  const onTabIconFilenameChange = useCallback(
    (_filename) => {
      onValuesChange({ Icon: _filename }, {}, true);
    },
    [onValuesChange],
  );
  const onHeaderLogoFilenameChange = useCallback(
    (_filename) => {
      onValuesChange({ NavBarTitleImage: _filename }, {}, true);
    },
    [onValuesChange],
  );

  return (
    <TabDrawer tabId={tabId}>
      <Form name="settings-form" onValuesChange={onValuesChange} autoComplete="off" form={form}>
        <NavigationHeader
          tabId={tabId}
          description="The header of this tab can have a Title or a Logo."
          inputLabel="Title"
          placeholder={getPageName(tab, dataSource)}
        >
          <Divider>OR</Divider>
          <SegmentTitle title="Logo" />
          <HeaderLogoWrapper>
            <SettingsImageInput
              filename={tab.Properties?.NavBarTitleImage}
              backgroundColor={
                tab.Properties?.NavBarTitleImage &&
                properties?.NavBarBackgroundHEX &&
                `#${properties?.NavBarBackgroundHEX}`
              }
              requirementsLabel="File must be PNG with transparent background. 1400px by 300px."
              requirementsLabelPosition="bottom"
              width="160px"
              height="33px"
              requiredDimensions={{ requiredWidth: 1400, requiredHeight: 300 }}
              acceptedFileTypes="image/png"
              onFilenameChange={onHeaderLogoFilenameChange}
            />
          </HeaderLogoWrapper>
          <SegmentTitle title="Header Properties" />
          <SettingsCheckboxInput
            label={
              <div>
                Show notifications bell in header <StyledBellIcon />
              </div>
            }
            name="NavBarNotificationsView"
            margin="5px 0 0 0"
          />
          <SettingsCheckboxInput
            label="Show search button in header"
            name="NavBarSearchView"
            disabled={tab.Properties?.NavBarFilterView === '1'}
            margin="5px 0 0 0"
          />
          <SettingsCheckboxInput
            label="Show search and filter button in header"
            name="NavBarFilterView"
            onChange={(e) => {
              // Uncheck NavBarSearchView if NavBarFilterView is checked
              if (e.target.checked) {
                form.setFields([{ name: 'NavBarSearchView', value: false }]);
                onValuesChange({ NavBarSearchView: false });
              }
            }}
            margin="5px 0 0 0"
          />
        </NavigationHeader>
        <DrawerSeparator />
        <ContentAdvancedSettings collection={tab} isDrawer isTab />
        <TabBar
          filename={tab.Icon}
          placeholder={getPageName(tab, dataSource)}
          onFilenameChange={onTabIconFilenameChange}
        />
      </Form>
    </TabDrawer>
  );
};
