import { useMutation, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';

import { CollectionItemsToSave, saveCollectionItems, useAxiosInstance } from 'api';
import { useAppBeingEdited } from 'app-context';
import { useRecordAction } from 'hooks';

export const useSaveCollectionItems = (invalidateQuery = true, refresh = true) => {
  const appId = useAppBeingEdited();
  const client = useAxiosInstance();
  const queryClient = useQueryClient();
  const recordAction = useRecordAction();

  return useMutation<unknown, AxiosError, CollectionItemsToSave>(
    (collectionItemsToSave) => {
      return saveCollectionItems(client, appId, collectionItemsToSave, refresh);
    },
    {
      onSuccess: async (_response, payload) => {
        if (invalidateQuery) {
          await queryClient.invalidateQueries(['collections', appId]);
        }
        recordAction.mutate({ action: 'Save Collection Items', detail: { collectionItemsToSave: payload } });
      },
    },
  );
};
