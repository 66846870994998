import { useState, useCallback, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { Input, message, Typography } from 'antd';
import { DropResult } from 'react-beautiful-dnd';
import { format } from 'date-fns';

import { Modal, CustomButton } from 'components';
import {
  CalendarDay,
  CalendarItem,
  CalendarMonth,
  Collection,
  saveCalendar,
  ThumbnailSize,
  Video,
  DataSource,
  SOURCE_VIDAPP,
  useAxiosInstance,
} from 'api';
import { NEUTRAL_10_COLOUR, NEUTRAL_3_COLOUR, NEUTRAL_5_COLOUR } from 'theme';

import { AddItems, ScheduledContent } from 'app/modules/calendar/CalendarPage/components';
import { CollectionCollection, VideoCollection, PublishedStatus } from '../CalendarPage';
import { useAppBeingEdited } from 'app-context';
import { FONT_14PX_REGULAR } from 'font';

const StyledModal = styled(Modal)`
  #react-app && .ant-modal-content,
  #react-app && .ant-modal-body {
    width: 100%;
  }
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
`;

const Header = styled.div`
  width: 100%;
  padding: 16px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${NEUTRAL_5_COLOUR};
`;

const Title = styled(Typography.Text)`
  #react-app && {
    margin: 0;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }
`;

const Body = styled.div`
  display: flex;
`;

const Section = styled.div`
  width: 50%;
  height: 435px;
  padding: 28px;
  overflow-y: scroll;
`;

const Heading = styled(Typography.Text)`
  #react-app && {
    display: block;
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
`;

const CancelButton = styled(CustomButton)`
  #react-app && {
    margin-right: 17px;
  }
`;

const QuoteInput = styled(Input.TextArea)<{ onChange: (e: React.ChangeEvent<HTMLInputElement>) => void }>`
  #react-app && {
    width: 100%;
    margin-bottom: 28px;
    padding: 12px;
    border-radius: 7px;
  }
`;

const Info = styled.div`
  padding: 12px;
  margin-bottom: 20px;
  ${FONT_14PX_REGULAR};
  color: ${NEUTRAL_10_COLOUR};
  background-color: ${NEUTRAL_3_COLOUR};
  border: 1px solid ${NEUTRAL_5_COLOUR};
  border-radius: 6px;
`;

export interface ScheduledItemType {
  title: string;
  type: string;
  id: number;
  sourceId: string | null;
  duration: number | null;
  thumbnail: string | null;
  dataSource: DataSource;
}

interface CalendarModalProps {
  modalVisible: boolean;
  date: Date;
  videoCollections: VideoCollection[];
  collectionCollections: CollectionCollection[];
  publishedStatus: PublishedStatus;
  calendarDay: CalendarDay | undefined;
  setCalendarDay: React.Dispatch<React.SetStateAction<CalendarDay | undefined>>;
  setModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  getVideoById: (videoId: number) => Video | null;
  getCollectionById: (collectionId: number) => Collection | null;
  isWorkout: (collectionId: number) => boolean;
  getVideoThumbnailUrl: (videoId: number, size: ThumbnailSize) => string | null;
  getCollectionThumbnailUrl: (collectionId: number, size: ThumbnailSize) => string | null;
  setRefetchRequired: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CalendarModal = ({
  modalVisible,
  date,
  videoCollections,
  collectionCollections,
  publishedStatus,
  calendarDay,
  setCalendarDay,
  setModalVisible,
  getVideoById,
  getCollectionById,
  isWorkout,
  getVideoThumbnailUrl,
  getCollectionThumbnailUrl,
  setRefetchRequired,
}: CalendarModalProps) => {
  const appBeingEdited = useAppBeingEdited();
  const axiosInstance = useAxiosInstance();

  const [originalQuote, setOriginalQuote] = useState<string | undefined>(undefined);
  const [quote, setQuote] = useState<string | undefined>(undefined);
  const [originalItems, setOriginalItems] = useState<ScheduledItemType[]>([]);
  const [items, setItems] = useState<ScheduledItemType[]>([]);
  const [availableVideoCollections, setAvailableVideoCollections] = useState<VideoCollection[]>(videoCollections);
  const [availableCollectionCollections, setAvailableCollectionCollections] =
    useState<CollectionCollection[]>(collectionCollections);
  const [selectedItems, setSelectedItems] = useState<Record<string, number[]>>({});
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const unsavedChanges = useMemo(() => {
    if (items) {
      return JSON.stringify(originalItems) !== JSON.stringify(items) || originalQuote !== quote;
    }
    return false;
  }, [originalItems, items, originalQuote, quote]);

  useEffect(() => {
    const dayItems: ScheduledItemType[] = [];
    if (calendarDay?.Items && calendarDay.Items.length > 0) {
      calendarDay.Items.forEach((item) => {
        if (item.Type === 'video') {
          const video = getVideoById(item.Id);
          dayItems.push({
            title: video?.Title ?? 'Video',
            type: item.Type,
            id: item.Id,
            sourceId: video?.SourceId ?? null,
            duration: video?.DurationSeconds ? parseInt(video.DurationSeconds) : null,
            thumbnail: getVideoThumbnailUrl(item.Id, 'small'),
            dataSource: video?.DataSource ?? SOURCE_VIDAPP,
          });
        } else if (item.Type === 'collection' && isWorkout(item.Id)) {
          const collection = getCollectionById(item.Id);
          dayItems.push({
            title: collection?.Name ?? 'Workout',
            type: 'workout',
            id: item.Id,
            sourceId: collection?.SourceId ?? null,
            duration: null,
            thumbnail: getCollectionThumbnailUrl(item.Id, 'small'),
            dataSource: collection?.DataSource ?? SOURCE_VIDAPP,
          });
        }
      });
    }
    setOriginalItems(dayItems); // Store original items to check for unsaved changes
    setOriginalQuote(calendarDay?.Quote ?? undefined); // Store original quote to check for unsaved changes
    setItems(dayItems); // Display the list of scheduled items
    setQuote(calendarDay?.Quote ?? undefined); // Display the scheduled quote (or empty input if undefined)
    setSelectedItems({}); // Reset SelectedItems so the Select inputs don't have any items selected
  }, [modalVisible]); // When the modal opens

  useEffect(() => {
    const itemIds: number[] = [];
    items.forEach((item) => {
      itemIds.push(item.id);
    });
    const scheduledVideosRemoved: VideoCollection[] = [];
    const scheduledCollectionsRemoved: CollectionCollection[] = [];

    videoCollections.forEach((collection) => {
      scheduledVideosRemoved.push({
        ...collection,
        options: collection.options.filter((option) => !itemIds.includes(option.VideoId)),
      });
    });

    collectionCollections.forEach((collection) => {
      scheduledCollectionsRemoved.push({
        ...collection,
        options: collection.options.filter((option) => !itemIds.includes(option.TabId)),
      });
    });

    setAvailableVideoCollections(scheduledVideosRemoved);
    setAvailableCollectionCollections(scheduledCollectionsRemoved);
  }, [items, videoCollections, collectionCollections]);

  const handleSave = useCallback(async () => {
    setIsSaving(true);
    const formattedItems: CalendarItem[] = items.map((item) => ({
      Type: item.type === 'workout' ? 'collection' : item.type,
      Id: item.id,
    }));
    const dataToSave: CalendarMonth = {
      [format(date, 'yyyy-MM-dd')]: {
        Published: publishedStatus[format(date, 'yyyy-MM')] ?? 0,
        Quote: quote ?? '',
        Items: formattedItems,
      },
    };
    await saveCalendar(axiosInstance, appBeingEdited, dataToSave).then((response) => {
      if (response.status === 201) {
        setModalVisible(false);
        setRefetchRequired(true);
        setCalendarDay(dataToSave[format(date, 'yyyy-MM-dd')]);
      } else {
        message.config({
          getContainer: () => document.getElementById('react-app') as HTMLElement,
        });
        message.error('An error occurred while saving. Please try again.');
      }
      setIsSaving(false);
    });
  }, [
    date,
    quote,
    items,
    axiosInstance,
    appBeingEdited,
    saveCalendar,
    setModalVisible,
    setRefetchRequired,
    setIsSaving,
    setCalendarDay,
  ]);

  const handleCancel = useCallback(() => {
    setModalVisible(false);
  }, [setModalVisible]);

  const handleQuoteChange = useCallback(
    (e) => {
      if (e.target.value.length > 0) {
        setQuote(e.target.value);
      } else {
        setQuote(undefined);
      }
    },
    [setQuote],
  );

  const onDragEnd = useCallback(
    (result: DropResult) => {
      const { destination, source, draggableId } = result;
      if (!destination || destination.index === source.index) {
        return;
      }

      const updatedItems = [...items];
      const draggedItem = updatedItems.find((item: ScheduledItemType) => item.id.toString() === draggableId);
      updatedItems.splice(source.index, 1);
      if (draggedItem) {
        updatedItems.splice(destination.index, 0, draggedItem);
      }
      setItems(updatedItems);
    },
    [items, setItems],
  );

  const handleAddItem = useCallback(
    (dataSource: string, type: 'video' | 'workout') => {
      const updatedItems = [...items];
      selectedItems[dataSource] &&
        selectedItems[dataSource].forEach((item) => {
          if (type === 'video') {
            const video = getVideoById(item);
            updatedItems.push({
              title: video?.Title ?? 'video',
              type: type,
              id: item,
              sourceId: video?.SourceId ?? null,
              duration: video?.DurationSeconds ? parseInt(video.DurationSeconds) : null,
              thumbnail: getVideoThumbnailUrl(item, 'small'),
              dataSource: video?.DataSource ?? SOURCE_VIDAPP,
            });
          } else if (type === 'workout') {
            const collection = getCollectionById(item);
            updatedItems.push({
              title: collection?.Name ?? 'workout',
              type: type,
              id: item,
              sourceId: collection?.SourceId ?? null,
              duration: null,
              thumbnail: getCollectionThumbnailUrl(item, 'small'),
              dataSource: collection?.DataSource ?? SOURCE_VIDAPP,
            });
          }
        });
      setItems(updatedItems);
      const updatedSelectedItems = { ...selectedItems, [dataSource]: [] };
      setSelectedItems(updatedSelectedItems);
    },
    [
      selectedItems,
      setSelectedItems,
      items,
      setItems,
      getVideoById,
      getCollectionById,
      getVideoThumbnailUrl,
      getCollectionThumbnailUrl,
    ],
  );

  const handleDeleteItem = useCallback(
    (id: number) => {
      const updatedItems = items.filter((item) => item.id !== id);
      setItems(updatedItems);
    },
    [items, setItems],
  );

  return (
    <StyledModal open={modalVisible} onCancel={handleCancel} $height={'100%'}>
      <Content>
        <Header>
          <Title>{format(date, 'EEEE do MMMM')}</Title>
          <ButtonWrapper>
            <CancelButton tertiary medium onClick={handleCancel} data-testid="cancel-calendar-modal">
              Cancel
            </CancelButton>
            <CustomButton
              medium
              onClick={handleSave}
              loading={isSaving}
              disabled={!unsavedChanges}
              data-testid="save-calendar-modal"
            >
              Save
            </CustomButton>
          </ButtonWrapper>
        </Header>
        <Body>
          <Section>
            <Info>
              Content that has been synced to the Builder, but has not been Published into your Live app, can not be
              added to the Calendar Scheduler. Publish content to the app to view it here.
            </Info>
            <AddItems
              videoCollections={availableVideoCollections}
              collectionCollections={availableCollectionCollections}
              selectedItems={selectedItems}
              setSelectedItems={setSelectedItems}
              handleAddItem={handleAddItem}
            />
          </Section>
          <Section style={{ backgroundColor: '#f7f8f9' }}>
            <Heading>Scheduled Quote (optional)</Heading>
            <QuoteInput
              value={quote}
              rows={3}
              placeholder="Write your quote here..."
              autoSize={{ minRows: 3, maxRows: 3 }}
              onChange={handleQuoteChange}
              onKeyDown={(e) => e.stopPropagation()}
            />
            <Heading>Scheduled Content</Heading>
            <ScheduledContent items={items} onDragEnd={onDragEnd} handleDeleteItem={handleDeleteItem} />
          </Section>
        </Body>
      </Content>
    </StyledModal>
  );
};
