import { useCallback } from 'react';
import styled from 'styled-components';
import { CheckboxValueType } from 'antd/lib/checkbox/Group.d';
import generateCalendar from 'antd/es/calendar/generateCalendar';
import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns';
import { CalendarCell } from 'app/modules/calendar/CalendarPage/components';
import { CollectionCollection, PublishedStatus, VideoCollection } from '../CalendarPage';
import { CalendarDay, CalendarType, Collection, GetVideosResponse, ThumbnailSize, Video } from 'api';
import {
  HIGHLIGHT_PRIMARY_COLOUR,
  HIGHLIGHT_SECONDARY_COLOUR,
  NEUTRAL_10_COLOUR,
  NEUTRAL_3_COLOUR,
  NEUTRAL_4_COLOUR,
  NEUTRAL_5_COLOUR,
  NEUTRAL_8_COLOUR,
} from 'theme';
import { FONT_12PX_SEMIBOLD } from 'font';

const CustomCalendar = styled(generateCalendar<Date>(dateFnsGenerateConfig))`
  #react-app && {
    background: none;
    width: 100%;

    .ant-picker-panel {
      background: none;
    }

    table {
      margin-bottom: 0;
    }

    th {
      display: none;
    }

    tr,
    td {
      border: none; // Reset default
    }

    tr:not(:last-child) {
      border-bottom: 1px solid ${NEUTRAL_5_COLOUR};
    }

    td {
      transition: 0.3s background-color;
      background-color: #fff;
      color: ${NEUTRAL_10_COLOUR};

      :not(.ant-picker-cell-in-view) {
        background-color: ${NEUTRAL_3_COLOUR};
      }

      &.ant-picker-cell-today {
        background-color: ${HIGHLIGHT_SECONDARY_COLOUR};
        color: ${HIGHLIGHT_PRIMARY_COLOUR};
        .date-label {
          color: ${HIGHLIGHT_PRIMARY_COLOUR};
        }
      }

      :hover {
        background-color: ${NEUTRAL_4_COLOUR};
      }

      :not(:last-child) {
        border-right: 1px solid ${NEUTRAL_5_COLOUR};
      }
    }

    .ant-picker-body {
      border: 1px solid ${NEUTRAL_5_COLOUR};
      border-radius: 8px;
      padding: 0;
      overflow: hidden;
    }
  }
`;

const CustomHeader = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 4px;
`;

const HeaderDay = styled.div`
  width: calc(100% / 7);
  color: ${NEUTRAL_8_COLOUR};
  ${FONT_12PX_SEMIBOLD};
  margin-left: 1px;
`;

interface CalendarProps {
  activeDate: Date;
  startDate: Date;
  endDate: Date;
  displayedItems: CheckboxValueType[];
  data?: CalendarType;
  videos?: GetVideosResponse;
  videoCollections: VideoCollection[];
  collectionCollections: CollectionCollection[];
  publishedStatus: PublishedStatus;
  getCalendarDay: (date: Date) => CalendarDay | null;
  setActiveDate: React.Dispatch<React.SetStateAction<Date>>;
  getVideoById: (videoId: number) => Video | null;
  getCollectionById: (collectionId: number) => Collection | null;
  getVideoThumbnailUrl: (videoId: number, size: ThumbnailSize) => string | null;
  getCollectionThumbnailUrl: (collectionId: number, size: ThumbnailSize) => string | null;
  isWorkout: (collectionId: number) => boolean;
  refetchCalendar: () => void;
  setRefetchRequired: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Calendar = ({
  activeDate,
  startDate,
  endDate,
  displayedItems,
  data,
  videos,
  videoCollections,
  collectionCollections,
  publishedStatus,
  getCalendarDay,
  setActiveDate,
  getVideoById,
  getCollectionById,
  getVideoThumbnailUrl,
  getCollectionThumbnailUrl,
  isWorkout,
  refetchCalendar,
  setRefetchRequired,
}: CalendarProps) => {
  const handlePanelChange = useCallback(
    (date) => {
      setActiveDate(date);
      refetchCalendar();
    },
    [setActiveDate, refetchCalendar],
  );

  return (
    <>
      <CustomHeader>
        {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map((day) => (
          <HeaderDay key={day}>{day}</HeaderDay>
        ))}
      </CustomHeader>
      <CustomCalendar
        mode="month"
        validRange={[startDate, endDate]}
        value={activeDate}
        onPanelChange={handlePanelChange}
        dateFullCellRender={(date) => (
          <CalendarCell
            date={date}
            activeDate={activeDate}
            data={data}
            videos={videos}
            videoCollections={videoCollections}
            collectionCollections={collectionCollections}
            displayedItems={displayedItems}
            publishedStatus={publishedStatus}
            getCalendarDay={getCalendarDay}
            getVideoById={getVideoById}
            getCollectionById={getCollectionById}
            getVideoThumbnailUrl={getVideoThumbnailUrl}
            getCollectionThumbnailUrl={getCollectionThumbnailUrl}
            isWorkout={isWorkout}
            setRefetchRequired={setRefetchRequired}
          />
        )}
        headerRender={() => null}
      />
    </>
  );
};
