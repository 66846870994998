import { useMemo, useState } from 'react';
import styled from 'styled-components';
import { Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';

import { EmptyContent, HighlightText, SettingsCheckboxInput, styledTable, Thumbnail } from 'components';
import { BuilderCollection } from 'providers';
import { DataSource, SOURCE_KAJABI, SOURCE_VIDAPP, Video } from 'api';
import { NEUTRAL_3_COLOUR } from 'theme';
import { FONT_12PX_MEDIUM, FONT_16PX_MEDIUM, OVERFLOW_ELLIPSIS } from 'font';
import {
  collectionIsProtected,
  DEFAULT_THUMBNAIL,
  formatNiceDate,
  getCollectionSourceName,
  getContentThumbnailUrl,
  getVideoSourceTitle,
} from 'utils';
import { useAppBeingEdited } from 'app-context';

import { DeleteCollectionButton } from '../../components';
import { useContentNavigationContext } from '../../../providers';

const defaultStyledTable = styledTable<ContentItem>(Table);

const StyledTable = styled(defaultStyledTable)`
  #react-app && .ant-table {
    border: none;
    border-radius: 8px;

    table {
      position: relative;
      min-width: unset !important;
      margin-right: 32px;
    }

    tr {
      border-bottom: none;
    }

    td {
      transition: none;
    }

    .ant-table-container::after {
      box-shadow: none !important;
    }

    tr:not(.ant-table-placeholder) {
      cursor: pointer;
    }

    tr,
    td {
      height: 84px;
    }

    tr:not(.ant-table-placeholder):hover {
      td {
        background-color: ${NEUTRAL_3_COLOUR} !important;
      }
    }
  }
`;

const TableContainer = styled.div`
  position: relative;
  margin-top: 20px;
`;

const TitleCell = styled.span`
  display: flex;
  align-items: center;
  width: 100%;
`;

const ItemThumbnail = styled(Thumbnail)`
  margin-right: 8px;
`;

const TitleWrapper = styled.div`
  span {
    ${FONT_16PX_MEDIUM};
  }

  ${OVERFLOW_ELLIPSIS};
`;
const DateWrapper = styled.div`
  ${FONT_12PX_MEDIUM};
`;
const DeleteWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const AdminFilters = styled.div`
  display: flex;
  position: absolute;
  top: -90px;
  right: 0;
`;

interface ContentItem {
  title: string;
  sourceId: string;
  itemId: string | number;
  key: string | number;
  thumbnail: string | null;
  dataSource: DataSource;
  sourceType?: string;
  kajabiDataThemeId?: number;
  type: 'collection' | 'video';
  includedInAppData?: boolean;
  createdAt: string;
}

interface CMSTableProps {
  searchValue: string;
  collections?: BuilderCollection[];
  videos?: Video[];
  showAdminFilters?: boolean;
}

export const CMSTable = ({ searchValue, collections, videos, showAdminFilters }: CMSTableProps) => {
  const { page, pageSize, setPage, setPageSize, goToItem } = useContentNavigationContext();
  const appId = useAppBeingEdited();
  const [showIncludedInApp, setShowIncludedInApp] = useState(true);
  const [showNotIncludedInApp, setShowNotIncludedInApp] = useState(true);

  const tableDataSource = collections ? collections[0]?.DataSource : videos ? videos[0]?.DataSource : undefined;
  const showDeleteColumn = !!collections && (tableDataSource === SOURCE_KAJABI || tableDataSource === SOURCE_VIDAPP);

  const columns: ColumnsType<ContentItem> = useMemo(() => {
    const arr: ColumnsType<ContentItem> = [
      {
        title: `VidApp Title`,
        dataIndex: 'title',
        key: 'title',
        ellipsis: true,
        render: (title, { thumbnail }) => (
          <TitleCell>
            <ItemThumbnail
              url={thumbnail}
              width="78px"
              height="44px"
              border={thumbnail && thumbnail !== DEFAULT_THUMBNAIL ? 'none' : undefined}
              borderRadius="4px"
            />
            <TitleWrapper>
              <HighlightText text={title} highlight={searchValue} />
            </TitleWrapper>
          </TitleCell>
        ),
      },
      {
        title: 'Created At',
        dataIndex: 'createdAt',
        key: 'createdAt',
        width: 100,
        render: (createdAt) => <DateWrapper>{formatNiceDate(new Date(createdAt))}</DateWrapper>,
      },
      {
        dataIndex: 'delete',
        key: 'delete',
        width: 50,
        render: (_, { itemId, dataSource, sourceType }) => (
          <DeleteWrapper onClick={(e) => e.stopPropagation()}>
            <DeleteCollectionButton
              tabId={itemId as number}
              sourceType={sourceType}
              dataSource={dataSource}
              tableStyle
            />
          </DeleteWrapper>
        ),
      },
    ];

    return arr;
  }, [videos, collections, showDeleteColumn, tableDataSource, collectionIsProtected]);

  const dataSource: ContentItem[] = useMemo(() => {
    const collectionsArr: ContentItem[] = collections
      ? collections.map((item) => ({
          title: item.Name,
          sourceId: item.SourceId,
          itemId: item.TabId,
          key: item.TabId,
          thumbnail: getContentThumbnailUrl(appId, item, 'small'),
          dataSource: item.DataSource,
          sourceType: item.SourceType,
          kajabiDataThemeId: item.KajabiDataThemeId,
          type: 'collection',
          includedInAppData: item.IncludedInAppData === 1,
          createdAt: item.CreatedAt ?? '2000-00-00T00:00:00',
        }))
      : [];

    const videosArr: ContentItem[] = videos
      ? videos.map((item) => {
          return {
            title: item.Title,
            sourceId: item.SourceId,
            itemId: item.VideoId,
            key: item.VideoId,
            thumbnail: getContentThumbnailUrl(appId, item, 'small'),
            dataSource: item.DataSource,
            type: 'video',
            createdAt: item.CreatedAt ?? '2000-00-00T00:00:00',
          };
        })
      : [];

    if (collections && videos) {
      // This is an 'All' tab, so combine collections and videos then sort them alphabetically by title
      const joinedArr = collectionsArr.concat(videosArr).sort((a, b) => b.createdAt.localeCompare(a.createdAt));
      return joinedArr;
    }

    return collections ? collectionsArr : videosArr;
  }, [collections, videos, appId, getCollectionSourceName, getVideoSourceTitle, getContentThumbnailUrl]);

  const inAppData = useMemo(() => {
    const included: ContentItem[] = [];
    const notIncluded: ContentItem[] = [];

    if (showAdminFilters) {
      dataSource.forEach((item) => {
        if (item.includedInAppData) {
          included.push(item);
        } else {
          notIncluded.push(item);
        }
      });
    }

    return { included, notIncluded };
  }, [dataSource]);

  return (
    <TableContainer>
      {showAdminFilters && (
        <AdminFilters>
          <SettingsCheckboxInput
            label={`Included in app (${inAppData.included.length})`}
            checked={showIncludedInApp}
            onChange={(e) => setShowIncludedInApp(e.target.checked)}
            width="fit-content"
            reverseOrder
            margin="0 10px 0 0"
          />
          <SettingsCheckboxInput
            label={`Not included in app (${inAppData.notIncluded.length})`}
            checked={showNotIncludedInApp}
            onChange={(e) => setShowNotIncludedInApp(e.target.checked)}
            width="fit-content"
            reverseOrder
          />
        </AdminFilters>
      )}
      <StyledTable
        showHeader={false}
        dataSource={
          !showAdminFilters || (showIncludedInApp && showNotIncludedInApp)
            ? dataSource
            : showIncludedInApp
            ? inAppData.included
            : showNotIncludedInApp
            ? inAppData.notIncluded
            : []
        }
        onRow={({ type, itemId, title }) => ({
          onClick: () => {
            goToItem(type, itemId, {
              breadcrumbs: [{ id: itemId, label: title }],
              appendToExisting: true,
            });
          },
        })}
        columns={columns}
        locale={{
          emptyText: (
            <EmptyContent
              title={searchValue !== '' ? `No results found for "${searchValue}"` : undefined}
              description={searchValue !== '' ? 'Check the spelling or try a different search term.' : undefined}
              $width={searchValue !== '' ? '270px' : undefined}
              $height="390px"
            />
          ),
        }}
        pagination={{
          showSizeChanger: true,
          defaultPageSize: pageSize,
          pageSizeOptions: [25, 50, 100],
          defaultCurrent: page,
          onChange: (page, size) => {
            setPage(page);
            setPageSize(size);
          },
        }}
      />
    </TableContainer>
  );
};
