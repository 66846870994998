import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useMemo, useState } from 'react';
import { startOfDay, endOfDay, subDays } from 'date-fns';
import { useLocation } from 'react-router-dom';
import { Serie } from '@nivo/line';

import { DateIncrement } from 'api';

import {
  METRIC_PARAM,
  MetricKey,
  METRIC_KEY_TOTAL_PLAYS,
  METRIC_KEY_COMPLETIONS,
  METRIC_KEY_AVERAGE_WATCHED,
  METRIC_LABELS,
} from '../const';
import { getKeyAnalytics } from '../util';
import { useVideoAnalyticsTotals } from '../hooks/useVideoAnalyticsTotals';
import { useVideoAnalyticsMetricByDate } from '../hooks/useVideoAnalyticsMetricByDate';

interface KeyAnalytics {
  [METRIC_KEY_TOTAL_PLAYS]: number | undefined;
  [METRIC_KEY_COMPLETIONS]: number | undefined;
  [METRIC_KEY_AVERAGE_WATCHED]: number | undefined;
}

interface ProviderProps {
  children: ReactNode;
}

interface ContextValue {
  activeMetric?: MetricKey;
  keyAnalytics?: KeyAnalytics;
  chartData?: Serie[];
  setStartDate: Dispatch<SetStateAction<Date>>;
  setEndDate: Dispatch<SetStateAction<Date>>;
  activeRange: number;
  setActiveRange: Dispatch<SetStateAction<number>>;
  activeIncrement: DateIncrement;
  setActiveIncrement: Dispatch<SetStateAction<DateIncrement>>;
  isError?: boolean;
  hideIncrementSelect: boolean;
}

const AnalyticsContext = createContext<ContextValue | undefined>(undefined);

const AnalyticsProvider = ({ children }: ProviderProps) => {
  const today: Date = new Date();
  const [activeRange, setActiveRange] = useState(7);
  const [startDate, setStartDate] = useState(startOfDay(subDays(today, 7)));
  const [endDate, setEndDate] = useState(endOfDay(subDays(today, 1)));
  const [activeIncrement, setActiveIncrement] = useState<DateIncrement>('daily');

  const { data: videoAnalyticsTotalsData, isError: videoAnalyticsTotalsIsError } = useVideoAnalyticsTotals(
    startDate,
    endDate,
  );

  const { pathname, search } = useLocation();
  const defaultMetric = pathname === '/' ? undefined : METRIC_KEY_TOTAL_PLAYS;
  const query = new URLSearchParams(search);
  const activeMetric = query.get(METRIC_PARAM) ? (query.get(METRIC_PARAM) as MetricKey) : defaultMetric;

  const keyAnalytics = useMemo(() => getKeyAnalytics(videoAnalyticsTotalsData), [videoAnalyticsTotalsData]);

  const { data: metricByDateData, isError: metricByDateDataIsError } = useVideoAnalyticsMetricByDate(
    activeMetric ?? METRIC_KEY_TOTAL_PLAYS,
    startDate,
    endDate,
    activeIncrement,
  );

  const chartData: Serie[] | undefined = metricByDateData
    ? [
        {
          id: METRIC_LABELS[activeMetric ?? METRIC_KEY_TOTAL_PLAYS],
          data: metricByDateData.map(({ date, value }) => ({ x: date, y: value })),
        },
      ]
    : undefined;

  return (
    <AnalyticsContext.Provider
      value={{
        activeMetric,
        keyAnalytics,
        chartData,
        setStartDate,
        setEndDate,
        activeRange,
        setActiveRange,
        activeIncrement,
        setActiveIncrement,
        isError: videoAnalyticsTotalsIsError || metricByDateDataIsError,
        hideIncrementSelect: pathname === '/',
      }}
    >
      {children}
    </AnalyticsContext.Provider>
  );
};

const useAnalyticsContext = () => {
  const context = useContext(AnalyticsContext);
  if (context === undefined) {
    throw new Error('useAnalyticsContext must be used with an AnalyticsProvider');
  }
  return context;
};

export { AnalyticsProvider, useAnalyticsContext };
