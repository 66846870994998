import styled from 'styled-components';

import { Diff } from 'api';
import { FONT_12PX_MEDIUM, FONT_12PX_SEMIBOLD, FONT_14PX_SEMIBOLD, OVERFLOW_ELLIPSIS } from 'font';
import { CustomTable } from 'components';

import { usePropertyDiff } from 'app/modules/publish-diffs/PublishDiff/hooks/usePublishDiff';
import { Heading } from './Heading';

const TruncatedText = styled.div`
  ${OVERFLOW_ELLIPSIS};
  max-width: 400px;
  ${FONT_12PX_MEDIUM};
`;

const PropertyTitle = styled.div`
  ${FONT_14PX_SEMIBOLD};
`;

const Semibold = styled.div`
  ${FONT_12PX_SEMIBOLD};
`;
const KeyDiff = styled.div`
  margin-bottom: 4px;
`;

const StyledCustomTable = styled(CustomTable)`
  width: 100%;

  & th {
    height: 36px !important;
    font-weight: bold !important;
  }

  & tr {
    height: 36px !important;
    font-size: 12px !important;
  }
`;

const renderValue = (value: string | undefined | null) => {
  if (typeof value === 'undefined') {
    return 'UNDEFINED';
  } else if (typeof value === 'string') {
    if (value.length === 0) {
      return 'EMPTY_STRING';
    }
    return `"${value}"`;
  }
  return '';
};

const getColumns = (stage: string) => {
  const afterStage = stage === 'Preview' ? 'Builder' : 'Preview';
  return [
    {
      title: 'Property',
      dataIndex: 'Name',
      width: 200,
      render: function Property(property: string) {
        return <PropertyTitle>{property}</PropertyTitle>;
      },
    },
    {
      title: `Current (${stage})`,
      dataIndex: 'Before',
      render: function Before(before?: string) {
        return <TruncatedText>{renderValue(before)}</TruncatedText>;
      },
      width: 400,
    },
    {
      title: `New (${afterStage})`,
      dataIndex: 'After',
      render: function After(after?: string) {
        return <TruncatedText>{renderValue(after)}</TruncatedText>;
      },
      width: 400,
    },
    {
      title: 'Differences on Keys inside the property',
      dataIndex: 'KeyDiffs',
      render: function KeyDiffs(key_diffs?: Diff[]) {
        return (
          <TruncatedText>
            {key_diffs
              ? key_diffs?.map((diff) => (
                  <KeyDiff>
                    <Semibold>{diff.Name}</Semibold>
                    <div> {`${renderValue(diff.Before)} -> ${renderValue(diff.After)}`}</div>
                  </KeyDiff>
                ))
              : 'N/A'}
          </TruncatedText>
        );
      },
      width: 400,
    },
  ];
};

interface PropertyDiffTableProps {
  stage: 'Preview' | 'Live';
}

export const PropertyDiffTable = ({ stage }: PropertyDiffTableProps) => {
  const { data } = usePropertyDiff(stage);

  return (
    <>
      <Heading>App Property Changes</Heading>
      <StyledCustomTable dataSource={data?.PropertyDifferences} columns={getColumns(stage)} />
    </>
  );
};
