import styled from 'styled-components';
import { Result } from 'antd';
import { HIGHLIGHT_PRIMARY_COLOUR } from 'theme';

const NoFiltersContainer = styled.div`
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  margin: 30px auto;
`;

const EmptyResult = styled(Result)`
  #react-app && {
    width: 240px;
    margin: auto;
    padding: 35px 0;
    & .ant-result-icon {
      color: ${HIGHLIGHT_PRIMARY_COLOUR};
      margin-bottom: 19px;
      & .anticon {
        font-size: 47px;
      }
    }
    & .ant-result-title {
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
    }
    & .ant-result-extra {
      margin-top: 8px;
    }
    & p,
    & a {
      color: rgba(0, 0, 0, 0.45);
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
    }
    & a {
      text-decoration: underline;
    }
  }
`;

export const NoFilters = () => (
  <NoFiltersContainer>
    <EmptyResult title="Add a new category by tapping the Add New Category button." />
  </NoFiltersContainer>
);
