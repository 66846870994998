import { useState } from 'react';

import { CustomButton, ConfirmModal } from 'components';

import { useRemoveAccount } from '../hooks/useRemoveAccount';
import { useAppAccounts } from '../hooks/useAppAccounts';

interface DeleteProps {
  userId: number;
}

export const Delete = ({ userId }: DeleteProps) => {
  const { getAccountById } = useAppAccounts();
  const removeAccount = useRemoveAccount();

  const [open, setOpen] = useState(false); // Modal is open

  const handleDelete = () => {
    removeAccount.mutate(userId, { onSuccess: () => setOpen(false) });
  };
  const account = getAccountById(userId);
  return (
    <ConfirmModal
      heading={`Delete ${account?.Name}?`}
      type="info"
      triggerButton={
        <CustomButton danger medium>
          Delete
        </CustomButton>
      }
      content="Are you sure you want to delete this staff member? You will not be able to reverse this action."
      danger
      primaryText="Delete"
      onConfirm={handleDelete}
      open={open}
      setOpen={setOpen}
    />
  );
};
