import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { CheckboxValueType } from 'antd/lib/checkbox/Group.d';
import { addMonths, endOfMonth, startOfMonth, subMonths } from 'date-fns';

import { PageContainer } from 'components';
import { ALL_DATA_SOURCES, Collection, PublishedType, SOURCE_VIDAPP, Video } from 'api';
import { useCollections, useVideos } from 'hooks';

import { Calendar, CalendarHeader, DisplayPanel } from 'app/modules/calendar/CalendarPage/components';
import { useCalendar } from './hooks/useCalendar';
import { PAGE_CONTAINER_WIDE_WIDTH } from 'theme';

const CalendarContainer = styled.div`
  width: 100%;
  display: flex;
`;

const displayOptions = ['Videos / Posts', 'Workouts', 'Quotes'];

export interface VideoCollection {
  dataSource: string;
  options: Video[];
}

export interface CollectionCollection {
  dataSource: string;
  options: Collection[];
}

export interface PublishedStatus {
  [key: string]: PublishedType;
}

export const CalendarPage = () => {
  const { data, isError, refetch, getCalendarDay, getCalendarMonthPublished } = useCalendar();
  const {
    data: videos,
    isError: videosIsError,
    getVideoById,
    getVideosByDataSource,
    getVideoThumbnailUrl,
  } = useVideos();
  const {
    data: collections,
    isError: collectionsIsError,
    getCollectionById,
    getCollectionsByDataSource,
    getCollectionThumbnailUrl,
    isWorkout,
  } = useCollections(Infinity, 'always');

  const [activeDate, setActiveDate] = useState<Date>(new Date());
  const [videoCollections, setVideoCollections] = useState<VideoCollection[]>([]);
  const [collectionCollections, setCollectionCollections] = useState<CollectionCollection[]>([]);
  const [displayedItems, setDisplayedItems] = useState<CheckboxValueType[]>(displayOptions);
  const [publishedStatus, setPublishedStatus] = useState<PublishedStatus>({});
  const [refetchRequired, setRefetchRequired] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const dateNow: Date = new Date();
  const startDate: Date = startOfMonth(subMonths(dateNow, 12));
  const endDate: Date = endOfMonth(addMonths(dateNow, 3));

  useEffect(() => {
    if (videos && collections) {
      const schedulableVideoCollections: VideoCollection[] = [];
      const schedulableCollectionCollections: CollectionCollection[] = [];

      for (const dataSource of ALL_DATA_SOURCES) {
        const videosBySource = getVideosByDataSource(dataSource).filter(
          (video) => ['video', 'post'].includes(video.Type) && video.IncludedInAppData === 1,
        );
        if (videosBySource.length > 0) {
          schedulableVideoCollections.push({ options: videosBySource, dataSource: dataSource });
        }
      }
      const schedulableVidAppCollections = getCollectionsByDataSource(SOURCE_VIDAPP).filter(
        (collection) => collection.IncludedInAppData === 1,
      );
      const schedulableWorkoutCollections = schedulableVidAppCollections.filter(
        (collection) =>
          isWorkout(collection.TabId) && collection.Name.length > 0 && !collection.SourceId.includes('TempTabId'),
      ); // Currently only allowing Workout collections to be scheduled, also filter out invalid workouts
      if (schedulableWorkoutCollections.length > 0) {
        schedulableCollectionCollections.push({ options: schedulableWorkoutCollections, dataSource: SOURCE_VIDAPP });
      }

      setVideoCollections(schedulableVideoCollections);
      setCollectionCollections(schedulableCollectionCollections);
    }
  }, [videos, collections]);

  useEffect(() => {
    const statusFromApi: PublishedStatus = {};
    if (data) {
      Object.keys(data).forEach((month) => {
        statusFromApi[month] = getCalendarMonthPublished(month) ? 1 : 0;
      });
    }
    setPublishedStatus(statusFromApi);
  }, [data]);

  useEffect(() => {
    data && videos && collections && setIsLoading(false);
  }, [data, videos, collections]);

  const refetchCalendar = useCallback(() => {
    if (refetchRequired) {
      refetch();
    }
    setRefetchRequired(false);
  }, [refetchRequired, setRefetchRequired, refetch]);

  return (
    <PageContainer
      heading="Calendar Scheduler"
      subheading="Use this section to schedule videos and quotes to your users calendar."
      isError={isError || videosIsError || collectionsIsError}
      isLoading={isLoading}
      contentMaxWidth={PAGE_CONTAINER_WIDE_WIDTH}
    >
      <CalendarContainer>
        <div>
          <CalendarHeader
            activeDate={activeDate}
            startDate={startDate}
            endDate={endDate}
            publishedStatus={publishedStatus}
            setPublishedStatus={setPublishedStatus}
            setActiveDate={setActiveDate}
            refetchCalendar={refetchCalendar}
          />
          <Calendar
            activeDate={activeDate}
            startDate={startDate}
            endDate={endDate}
            displayedItems={displayedItems}
            data={data}
            videos={videos}
            videoCollections={videoCollections}
            collectionCollections={collectionCollections}
            publishedStatus={publishedStatus}
            setActiveDate={setActiveDate}
            getCalendarDay={getCalendarDay}
            getVideoById={getVideoById}
            getCollectionById={getCollectionById}
            getVideoThumbnailUrl={getVideoThumbnailUrl}
            getCollectionThumbnailUrl={getCollectionThumbnailUrl}
            isWorkout={isWorkout}
            refetchCalendar={refetchCalendar}
            setRefetchRequired={setRefetchRequired}
          />
        </div>
        <DisplayPanel
          displayOptions={displayOptions}
          displayedItems={displayedItems}
          setDisplayedItems={setDisplayedItems}
        />
      </CalendarContainer>
    </PageContainer>
  );
};
