import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Draggable } from 'react-beautiful-dnd';
import { Tooltip as AntdTooltip } from 'antd';
import * as amplitude from '@amplitude/analytics-browser';

import { CustomButton, DragHandleDots, DragHandleLocked, Dropdown } from 'components';
import { CONTENT_1_SOURCE_ID, KAJABI_LIBRARY_SOURCE_ID, TAB_ICON_URL } from 'api';
import { BUILDER_IMAGES_DOMAIN, TintImage } from 'utils';
import {
  NEUTRAL_10_COLOUR,
  NEUTRAL_5_COLOUR,
  NEUTRAL_7_COLOUR,
  NEUTRAL_9_COLOUR,
  PRIMARY_ORANGE_COLOUR,
  SECONDARY_ORANGE_COLOUR,
  SECONDARY_SUCCESS_COLOUR,
  SUCCESS_COLOUR,
} from 'theme';
import { FONT_10PX_MEDIUM, FONT_18PX_MEDIUM } from 'font';
import { BuilderCollection, useContent, useDataSource, useLocalAppProperties, useSaveContext } from 'providers';
import { PenIcon, TrashIcon, VericalEllipsisIcon } from 'icons';
import { useAppBeingEdited } from 'app-context';

import { getPageName, tabIsProtected } from '../../utils';
import { useBuildNav } from '../../providers';

interface TabProps {
  $isActive: boolean;
  $isDraggingOver?: boolean;
  $isDragging?: boolean;
  $padding?: string;
}

const Tab = styled.div<TabProps>`
  position: relative;
  width: 100%;
  padding: ${({ $padding }) => $padding || '12px'};
  border-width: 1px;
  border-style: solid;
  border-color: ${({ $isActive, $isDragging }) =>
    $isActive ? PRIMARY_ORANGE_COLOUR : $isDragging ? NEUTRAL_10_COLOUR : NEUTRAL_5_COLOUR};
  border-radius: 8px;
  background-color: #fff;
  color: ${NEUTRAL_10_COLOUR};
  user-select: none;
  ${FONT_18PX_MEDIUM};
  cursor: pointer;

  .anticon {
    opacity: ${({ $isDragging }) => ($isDragging ? 1 : 0)};
  }

  .customize-button-wrapper {
    display: none;
  }

  :hover {
    border-color: ${({ $isActive, $isDraggingOver }) =>
      $isActive ? PRIMARY_ORANGE_COLOUR : $isDraggingOver ? NEUTRAL_5_COLOUR : NEUTRAL_10_COLOUR};

    .customize-button-wrapper {
      display: ${({ $isDraggingOver }) => ($isDraggingOver ? 'none' : 'block')};
    }

    .anticon {
      opacity: ${({ $isDraggingOver }) => ($isDraggingOver ? 0 : 1)};
    }
  }
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const TopRow = styled(Row)`
  height: 30px;
  margin-bottom: 12px;
`;

const BottomRow = styled(Row)`
  height: 18px;
`;

const TypeLabel = styled.div`
  ${FONT_10PX_MEDIUM};
  text-transform: uppercase;
  padding: 2px 4px;
  border-radius: 3px;
  white-space: nowrap;
  color: ${PRIMARY_ORANGE_COLOUR};
  background-color: ${SECONDARY_ORANGE_COLOUR};
`;

const ActiveLabel = styled(TypeLabel)`
  color: ${SUCCESS_COLOUR};
  background-color: ${SECONDARY_SUCCESS_COLOUR};
  margin-left: 8px;
`;

const StyledDragHandleDots = styled(DragHandleDots)`
  position: absolute;
  top: -1px;
  left: -28px;
`;

const StyledDragHandleLocked = styled(DragHandleLocked)`
  position: absolute;
  top: -1px;
  left: -28px;
`;

const TabIcon = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  margin-right: 8px;
`;
const TabName = styled.div`
  width: 100%;
  flex-grow: 1;
`;

const Spacer = styled.div`
  height: 20px;
`;

const EllipsisButton = styled(CustomButton)`
  &&&& {
    background: none;
    color: ${NEUTRAL_7_COLOUR};

    .anticon {
      font-size: 24px;
    }
    :hover:not(:active) {
      color: ${NEUTRAL_9_COLOUR};
    }
  }
`;

const CustomizeButton = styled(CustomButton)`
  &&&& {
    margin-bottom: 2px;
  }
`;

interface BuildNavTabProps {
  tabId: string | number;
  idx: number;
  isActive: boolean;
  isGuidedTourTab: boolean;
  isDraggingOver?: boolean;
  showActiveLabel?: boolean;
}

export const BuildNavTab = ({
  tabId,
  idx,
  isActive,
  isGuidedTourTab,
  isDraggingOver,
  showActiveLabel,
}: BuildNavTabProps) => {
  const { collections, deleteCollection } = useContent();
  const { setCollectionToDelete } = useSaveContext();
  const { properties } = useLocalAppProperties();
  const { saveTimestamp } = useBuildNav();
  const appId = useAppBeingEdited();
  const dataSource = useDataSource();
  const history = useHistory();

  const handleDelete = useCallback(() => {
    setCollectionToDelete(tabId);
    deleteCollection(tabId);
    saveTimestamp();
    history.replace('/build-my-app/', { skipUnsavedChangesPrompt: true });
  }, [tabId, setCollectionToDelete, deleteCollection, saveTimestamp]);

  const tab = collections[tabId];

  const showEllipsisButton = tab && !tabIsProtected(tab, dataSource, properties.OptionalProfileTab !== '1');

  return tab ? (
    <Draggable draggableId={tabId.toString()} index={idx} key={tabId}>
      {(provided, { isDragging }) => (
        <div ref={provided.innerRef} {...provided.draggableProps}>
          <Tab
            onClick={() => history.replace(`/build-my-app/${tabId}`, { skipUnsavedChangesPrompt: true })}
            $isActive={isActive}
            $isDraggingOver={isDraggingOver}
            $isDragging={isDragging}
            $padding={showEllipsisButton ? '12px 4px 12px 12px' : undefined}
            id={isGuidedTourTab ? 'BuildNavTab--GuidedTour' : undefined}
            data-testid={`build-nav-tab-${idx}`}
          >
            <StyledDragHandleDots $color={NEUTRAL_7_COLOUR} {...provided.dragHandleProps} />
            <TopRow>
              <TabIcon>
                <TintImage
                  src={tab.Icon ? `${TAB_ICON_URL}${tab.Icon}` : `${BUILDER_IMAGES_DOMAIN}/icons/blocked.png`}
                  color={NEUTRAL_10_COLOUR}
                  dimensions={{ width: 20, height: 20 }}
                />
              </TabIcon>
              <TabName>{tab.Name}</TabName>
              <div className="customize-button-wrapper">
                {isNaN(tabId as number) ? (
                  <AntdTooltip
                    getPopupContainer={() => document.getElementById('react-app') as HTMLElement}
                    arrowPointAtCenter
                    title="You need to click save before you customize this Tab"
                    trigger="hover"
                  >
                    <div>
                      <CustomizeButton tertiaryHighlight medium icon={<PenIcon />} disabled>
                        Customize
                      </CustomizeButton>
                    </div>
                  </AntdTooltip>
                ) : (
                  <CustomizeButton
                    id={isGuidedTourTab ? 'CustomizeButton--BuildNavTab' : undefined}
                    tertiaryHighlight
                    medium
                    icon={<PenIcon />}
                    link={{ to: `/builder/${tabId}` }}
                    onClick={(e) => {
                      e.stopPropagation();
                      const isContent1Tab = [CONTENT_1_SOURCE_ID, KAJABI_LIBRARY_SOURCE_ID].includes(tab.SourceId);
                      const tabType = isContent1Tab
                        ? 'Content1'
                        : getPageName(tab) === 'Content'
                        ? 'ContentOther'
                        : getPageName(tab);
                      amplitude.track('Tab opened via BMA Side Menu', { appId, tabType });
                    }}
                  >
                    Customize
                  </CustomizeButton>
                )}
              </div>
              {showEllipsisButton && (
                <Dropdown
                  trigger={<EllipsisButton icon={<VericalEllipsisIcon />} tertiary />}
                  options={[
                    {
                      label: 'Delete Tab',
                      icon: <TrashIcon />,
                      onClick: handleDelete,
                      danger: true,
                    },
                  ]}
                />
              )}
            </TopRow>
            <BottomRow>
              <TypeLabel>{getPageName(tab, undefined, 'Content')}</TypeLabel>
              {showActiveLabel && <ActiveLabel>ACTIVE</ActiveLabel>}
            </BottomRow>
          </Tab>
          <Spacer />
        </div>
      )}
    </Draggable>
  ) : null;
};

interface BuildNavProfileTabProps {
  tab: BuilderCollection;
  isActive: boolean;
}

export const BuildNavProfileTab = ({ tab, isActive }: BuildNavProfileTabProps) => {
  const history = useHistory();

  return (
    <>
      <Tab
        onClick={() => history.replace(`/build-my-app/${tab.TabId}`, { skipUnsavedChangesPrompt: true })}
        $isActive={isActive}
      >
        <StyledDragHandleLocked />
        <TopRow>
          <TabIcon>
            <TintImage
              src={tab.Icon ? `${TAB_ICON_URL}${tab.Icon}` : `${BUILDER_IMAGES_DOMAIN}/icons/blocked.png`}
              color={NEUTRAL_10_COLOUR}
              dimensions={{ width: 20, height: 20 }}
            />
          </TabIcon>
          <TabName>{tab.Name}</TabName>
          <div className="customize-button-wrapper">
            <CustomizeButton
              tertiaryHighlight
              medium
              icon={<PenIcon />}
              link={{ to: `/builder/${tab.TabId}` }}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              Customize
            </CustomizeButton>
          </div>
        </TopRow>
        <BottomRow>
          <TypeLabel>Profile</TypeLabel>
          <ActiveLabel>ACTIVE</ActiveLabel>
        </BottomRow>
      </Tab>
      <Spacer />
    </>
  );
};
