// This contains a lot of duplicate code, but can be deleted once all apps have been migrated to the new builder

import { useCallback, useEffect, useState } from 'react';
import { Form } from 'antd';

import { PageContainer, SettingsGroup } from 'components';
import { useAppBeingEdited } from 'app-context';
import { useLocalAppProperties, useSaveContext } from 'providers';
import { isUrl } from 'utils';

import { CoreStatsSettings } from 'app/modules/build-screens/components';
import { setInitialFormField } from 'app/modules/build-screens/utils';
import { DesignMockup } from 'app/modules/design-screens';

export const CoreStatsInternal = () => {
  const [form] = Form.useForm();

  const { properties, setAppProperty } = useLocalAppProperties();
  const { setAppPropertyToSave } = useSaveContext();

  const [coreStatsFilenames, setCoreStatsFilenames] = useState<Record<string, string | undefined>>({});
  const [initialized, setInitialized] = useState(false);
  const appId = useAppBeingEdited();

  const filePath = `https://storage.vidapp.com/${appId}/icons/`;

  // Initialize core stats fields to match local state
  useEffect(() => {
    if (properties.DisplayCoreStats === '1' && properties.CoreStats) {
      const coreStats = JSON.parse(properties.CoreStats);
      setInitialFormField(form, 'CoreStatsEnabled', coreStats.Enabled === '1');
      setInitialFormField(form, 'CoreStat1Title', coreStats.Stats[0].Title);
      setInitialFormField(form, 'CoreStat2Title', coreStats.Stats[1].Title);
      setInitialFormField(form, 'CoreStat3Title', coreStats.Stats[2].Title);

      setCoreStatsFilenames({
        CoreStat1Icon: coreStats.Stats[0].Icon,
        CoreStat2Icon: coreStats.Stats[1].Icon,
        CoreStat3Icon: coreStats.Stats[2].Icon,
      });
    }
  }, []);

  // TEMPORARY
  // App is currently expecting a full URL for core stats icons
  const getIconUrlToSave = (icon: string | undefined) => {
    if (icon) {
      return isUrl(icon) ? icon : `${filePath}${icon}`;
    }

    return '';
  };

  const updateCoreStats = useCallback(() => {
    const value = JSON.stringify({
      Enabled: form.getFieldValue('CoreStatsEnabled') ? '1' : '0',
      Stats: [
        { Title: form.getFieldValue('CoreStat1Title') ?? '', Icon: getIconUrlToSave(coreStatsFilenames.CoreStat1Icon) },
        { Title: form.getFieldValue('CoreStat2Title') ?? '', Icon: getIconUrlToSave(coreStatsFilenames.CoreStat2Icon) },
        { Title: form.getFieldValue('CoreStat3Title') ?? '', Icon: getIconUrlToSave(coreStatsFilenames.CoreStat3Icon) },
      ],
    });

    setAppProperty('CoreStats', value);
    if (initialized) {
      setAppPropertyToSave('CoreStats', value);
    }
  }, [form, initialized, coreStatsFilenames, setAppProperty, setAppPropertyToSave]);

  useEffect(() => {
    if (Object.keys(coreStatsFilenames).length > 0) {
      updateCoreStats();
      setInitialized(true);
    }
  }, [coreStatsFilenames]);

  return (
    <PageContainer
      heading="Three Core Stats"
      subheading="This feature will provide your members with key metrics around their content consumption in the app. This will display in their Profile page."
      isLoading={!properties}
      headingButton="save"
      mockup={<DesignMockup screenKey="profile" />}
    >
      <Form name="settings-form" autoComplete="off" form={form}>
        <SettingsGroup wrapRow>
          <CoreStatsSettings
            filenames={coreStatsFilenames}
            onToggleChange={updateCoreStats}
            onInputChange={updateCoreStats}
            onFilenameChange={(key: string, filename: string) =>
              setCoreStatsFilenames((oldState) => ({ ...oldState, [key]: filename }))
            }
          />
        </SettingsGroup>
      </Form>
    </PageContainer>
  );
};
