import { useLocation } from 'react-router-dom';

import {
  DataSourceProvider,
  DefaultAppBasicInfoProvider,
  DefaultAppPropertiesProvider,
  DefaultContentProvider,
  SaveProvider,
} from 'providers';

import { DESIGN_SCREEN_KEYS, DesignScreenKey, DesignScreensInternal } from '../';
import { ErrorPage } from 'components';

export const DesignScreens = () => {
  const location = useLocation();

  const screenKey = location.pathname.substring(location.pathname.lastIndexOf('/') + 1) as DesignScreenKey;

  if (![...DESIGN_SCREEN_KEYS].includes(screenKey)) {
    return <ErrorPage code={404} />;
  }

  return (
    <DataSourceProvider>
      <DefaultContentProvider>
        <SaveProvider>
          <DefaultAppPropertiesProvider>
            <DefaultAppBasicInfoProvider>
              <DesignScreensInternal screenKey={screenKey} />
            </DefaultAppBasicInfoProvider>
          </DefaultAppPropertiesProvider>
        </SaveProvider>
      </DefaultContentProvider>
    </DataSourceProvider>
  );
};
