import { Dispatch, ReactNode, SetStateAction, useCallback, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';

import {
  SOURCE_TYPE_COLLECTION,
  SOURCE_TYPE_COLLECTION_2,
  SOURCE_TYPE_COURSE,
  SOURCE_TYPE_PLAYLIST,
  SOURCE_VIDAPP,
} from 'api';
import { ImageUploader } from 'components';
import { FONT_10PX_MEDIUM, FONT_14PX_MEDIUM, OVERFLOW_ELLIPSIS } from 'font';
import {
  AppVideoIcon,
  CollectionIcon,
  CubeIcon,
  DumbbellIcon,
  FileBlankIcon,
  FilledRightArrow,
  FolderIcon,
  GlobeIcon,
  LinkedCollectionIcon,
  LinkedCubeIcon,
  LinkedFolderIcon,
  PostIcon,
  SectionHeaderIcon,
  TextImageIcon,
  UISyncIcon,
  UploadIcon,
} from 'icons';
import { useContent, useSaveContext } from 'providers';
import { INPUT_STYLES_SMALL, NEUTRAL_10_COLOUR, NEUTRAL_5_COLOUR, NEUTRAL_7_COLOUR } from 'theme';
import {
  DEFAULT_THUMBNAIL,
  getCollectionSourceTypeEquivalent,
  getContentThumbnailUrl,
  getSourceTypeDisplayName,
} from 'utils';

import { useModalContext } from 'app/modules/content/Content/cms-modal/providers/modal-provider';
import { RestRowConfig } from '.';
import { useContentNavigationContext } from '../../../providers';
import { TreeItem } from '../ContentCollectionTree';
import { useAppBeingEdited } from 'app-context';

const THUMBNAIL_BORDER_RADIUS = '4px';

const IconCss = css`
  font-size: 12px;
  margin-right: 2px;
`;

const StyledCubeIcon = styled(CubeIcon)`
  ${IconCss};
`;
const StyledLinkedCubeIcon = styled(LinkedCubeIcon)`
  ${IconCss};
`;
const StyledCollectionIcon = styled(CollectionIcon)`
  ${IconCss};

  svg {
    fill: none;
  }
`;
const StyledLinkCollectionIcon = styled(LinkedCollectionIcon)`
  ${IconCss}
`;

const StyledFolderIcon = styled(FolderIcon)`
  ${IconCss};
`;
const StyledLinkedFolderIcon = styled(LinkedFolderIcon)`
  ${IconCss};
`;

const StyledVideoIcon = styled(AppVideoIcon)`
  ${IconCss};

  svg {
    fill: none;
  }
`;

const StyledGlobeIcon = styled(GlobeIcon)`
  ${IconCss};
`;

const StyledFileBlankIcon = styled(FileBlankIcon)`
  ${IconCss};
`;

const StyledPostIcon = styled(PostIcon)`
  ${IconCss};
`;

const StyledDumbbellIcon = styled(DumbbellIcon)`
  ${IconCss};
`;

const StyledSyncIcon = styled(UISyncIcon)`
  ${IconCss};
`;

const StyledTextImageIcon = styled(TextImageIcon)`
  ${IconCss};
`;

const StyledSectionHeaderIcon = styled(SectionHeaderIcon)`
  ${IconCss};
`;

const Details = styled.div`
  width: 100%;
`;

const TitleRow = styled.div<{ $marginBottom: string }>`
  width: 100%;
  text-wrap: wrap;
  margin-bottom: ${({ $marginBottom }) => $marginBottom};
  max-height: 40px;
  overflow: hidden;
`;

const TypeRow = styled.div`
  display: flex;
  ${FONT_10PX_MEDIUM};
  color: ${NEUTRAL_7_COLOUR};
`;

const TypeLabel = styled.div`
  margin-right: 8px;
  text-transform: capitalize;
`;

const TitleLabel = styled.div`
  ${FONT_14PX_MEDIUM};
  min-height: 20px;
`;

const TitleInput = styled.input`
  &&&& {
    ${INPUT_STYLES_SMALL};
    ${FONT_14PX_MEDIUM};
    height: 24px;
    width: 100%;
    outline: none;
    padding-left: 2px;
  }
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  ${OVERFLOW_ELLIPSIS};
`;

const Spacer = styled.div`
  width: 30px;
  height: 50px;
  flex-grow: 0;
  flex-shrink: 0;
`;

const ArrowWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 50px;
  margin-right: 2px;
  flex-grow: 0;
  flex-shrink: 0;
  cursor: pointer;
`;

interface StyledArrowProps {
  $rotate?: boolean;
}

const StyledArrow = styled(FilledRightArrow)<StyledArrowProps>`
  &&&& {
    color: ${NEUTRAL_7_COLOUR};
    font-size: 16px;
    line-height: 16px;
    transform: ${({ $rotate }) => ($rotate ? 'rotate(90deg) translate(1px, 0px)' : 'rotate(0deg) translate(1px, 2px)')};
    transition: transform 0.1s ease;
  }
`;

const ThumbnailWrapper = styled.div<{ $disableThumbnailUpload?: boolean }>`
  margin-right: 12px;
  cursor: ${({ $disableThumbnailUpload }) => ($disableThumbnailUpload ? 'unset' : 'pointer')};
  position: relative;

  :hover {
    .thumbnail-overlay {
      opacity: ${({ $disableThumbnailUpload }) => ($disableThumbnailUpload ? 0 : 1)};
    }
  }
`;

const ThumbnailOverlay = styled.div`
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: ${THUMBNAIL_BORDER_RADIUS};
  transition: 0.2s ease opacity;
`;

const StyledUploadIcon = styled(UploadIcon)`
  &&&& {
    color: ${NEUTRAL_10_COLOUR};
    color: #fff;
  }
`;

const getIcon = (type: string, linked?: boolean) => {
  const iconMap: Record<string, ReactNode> = {
    [SOURCE_TYPE_COURSE]: <StyledCubeIcon />,
    [SOURCE_TYPE_COLLECTION]: <StyledCollectionIcon />,
    [SOURCE_TYPE_COLLECTION_2]: <StyledCollectionIcon />,
    [SOURCE_TYPE_PLAYLIST]: <StyledFolderIcon />,
    video: <StyledVideoIcon />,
    post: <StyledPostIcon />,
    workout: <StyledDumbbellIcon />,
    circuit: <StyledSyncIcon />,
    exercise: <StyledVideoIcon />,
    textimage: <StyledTextImageIcon />,
    website: <StyledGlobeIcon />,
    sectionHeader: <StyledSectionHeaderIcon />,
  };
  const linkedIconMap: Record<string, ReactNode> = {
    [SOURCE_TYPE_COURSE]: <StyledLinkedCubeIcon />,
    [SOURCE_TYPE_COLLECTION]: <StyledLinkCollectionIcon />,
    [SOURCE_TYPE_COLLECTION_2]: <StyledLinkCollectionIcon />,
    [SOURCE_TYPE_PLAYLIST]: <StyledLinkedFolderIcon />,
  };

  // These collection types can be linked or unlinked
  if ([SOURCE_TYPE_COURSE, SOURCE_TYPE_COLLECTION, SOURCE_TYPE_COLLECTION_2, SOURCE_TYPE_PLAYLIST].includes(type)) {
    return linked ? linkedIconMap[type] : iconMap[type];
  }

  // Unexpected type use file icon
  return iconMap[type] ?? <StyledFileBlankIcon />;
};

interface TreeRowDetailsProps {
  item: TreeItem;
  isEmpty?: boolean;
  isOpen?: boolean;
  handleTriggerClick?: () => void;
  isEditingTitle: boolean;
  setIsEditingTitle: Dispatch<SetStateAction<boolean>>;
}

export const TreeRowDetails = ({
  item,
  isEmpty,
  isOpen,
  isEditingTitle,
  setIsEditingTitle,
  handleTriggerClick,
}: TreeRowDetailsProps) => {
  const { setCollectionValue, setVideoValue, collections, videos } = useContent();
  const { setCollectionValueToSave, setVideoValueToSave } = useSaveContext();
  const { collectionIsEditable } = useContentNavigationContext();
  const { type, itemId, title, dataSource, sourceType, sourceId } = item;
  const { treeCollectionId } = useModalContext();
  const appId = useAppBeingEdited();

  const titleInput = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isEditingTitle) {
      titleInput.current?.select();
    }
  }, [isEditingTitle]);

  const changeTitle = useCallback(
    (value: string) => {
      setCollectionValue(item.itemId, 'Name', value);
      setCollectionValueToSave(item.itemId, 'Name', value);
    },
    [setCollectionValue, setCollectionValueToSave],
  );

  useEffect(() => {
    if (isEditingTitle) {
      titleInput.current?.addEventListener('keyup', ({ key }) => {
        if (key === 'Enter') {
          setIsEditingTitle(false);
        }
      });
    }
    return () =>
      titleInput.current?.removeEventListener('keyup', ({ key }) => {
        if (key === 'Enter') {
          setIsEditingTitle(false);
        }
      });
  }, [isEditingTitle]);

  const handleThumbnailChange = useCallback(
    (f: string) => {
      if (collections[itemId]) {
        setCollectionValue(itemId, 'ThumbnailSource', f);
        setCollectionValue(itemId, 'Thumbnails', { source: f });
        setCollectionValueToSave(itemId, 'ThumbnailSource', f);
      } else if (videos[itemId]) {
        setVideoValue(itemId, 'ThumbnailSource', f);
        setVideoValue(itemId, 'Thumbnails', { source: f });
        setVideoValueToSave(itemId, 'ThumbnailSource', f);
      }
    },
    [collections, videos, itemId, setCollectionValue, setVideoValue, setCollectionValueToSave, setVideoValueToSave],
  );

  const treeCollection = collections[treeCollectionId]; // The page being viewed is for this collection
  const isEditable = collectionIsEditable(treeCollection.DataSource, treeCollection.SourceType);
  const hideThumbnail = ['circuit', 'rest'].includes(type);
  const disableThumbnailUpload = ['sectionHeader'].includes(type);
  const thumbnail = collections[itemId]
    ? getContentThumbnailUrl(appId, collections[itemId], 'small')
    : videos[itemId]
    ? getContentThumbnailUrl(appId, videos[itemId], 'small')
    : undefined;

  const overrideRef = useRef<HTMLInputElement>(null);

  const handleUploadClick = useCallback(() => {
    overrideRef.current?.click(); //Forward click to the file input
  }, [overrideRef]);

  return (
    <Container>
      {type === 'rest' ? (
        <RestRowConfig videoId={itemId} />
      ) : (
        <>
          {!isEmpty && handleTriggerClick ? (
            <ArrowWrapper
              onClick={(e) => {
                e.stopPropagation();
                handleTriggerClick();
              }}
            >
              <StyledArrow $rotate={isOpen} />
            </ArrowWrapper>
          ) : (
            <Spacer />
          )}
          {!hideThumbnail && (
            <ThumbnailWrapper
              $disableThumbnailUpload={disableThumbnailUpload}
              onClick={(e) => {
                e.stopPropagation();
                !disableThumbnailUpload && handleUploadClick();
              }}
            >
              <ImageUploader
                overrideRef={overrideRef}
                backgroundImage={DEFAULT_THUMBNAIL}
                borderRadius={THUMBNAIL_BORDER_RADIUS}
                width="66px"
                height="38px"
                filename={thumbnail}
                onFilenameChange={handleThumbnailChange}
                hideUploadButton
                borderColor={!thumbnail || thumbnail === DEFAULT_THUMBNAIL ? NEUTRAL_5_COLOUR : undefined}
                scaleSpinner={75}
              />
              <ThumbnailOverlay className="thumbnail-overlay">
                <StyledUploadIcon />
              </ThumbnailOverlay>
            </ThumbnailWrapper>
          )}
          <Details>
            <TitleRow $marginBottom={isEditingTitle ? '4px' : '8px'}>
              {isEditingTitle ? (
                <TitleInput
                  ref={titleInput}
                  value={title}
                  onChange={(e) => changeTitle(e.target.value)}
                  onBlur={() => setIsEditingTitle(false)}
                  onClick={(e) => e.stopPropagation()}
                />
              ) : (
                <TitleLabel className="tree-row-title-label">{title}</TitleLabel>
              )}
            </TitleRow>
            <TypeRow>
              {getIcon(
                type === 'collection' ? getCollectionSourceTypeEquivalent(dataSource, sourceType) : type,
                dataSource !== SOURCE_VIDAPP && isEditable,
              )}
              <TypeLabel>{sourceType ? getSourceTypeDisplayName(sourceType, dataSource) : type}</TypeLabel>
              {sourceId}
            </TypeRow>
          </Details>
        </>
      )}
    </Container>
  );
};
