import { CSSProperties, useEffect } from 'react';
import { Drawer, DrawerProps } from 'antd';

import { NAV_BAR_OFFSET, NEUTRAL_10_COLOUR } from 'theme';
import {
  CALENDAR_TEMPLATE,
  COMMUNITY_TEMPLATE,
  ITEM_TYPE_VIDEO,
  MIXED_TEMPLATE,
  MY_LISTS_TEMPLATE,
  MY_PLAYLISTS_TEMPLATE,
  PROFILE_TEMPLATE,
  SUBNAV_TEMPLATE,
  WEBSITE_TEMPLATE,
} from 'api';
import { isUserAdmin } from 'utils';

import {
  AddDrawer,
  CollectionDetailsDrawer,
  CreateLinkedSubTabDrawer,
  SectionDetailsDrawer,
  SectionHeaderDrawer,
  SubTabDrawer,
  VideoDetailsDrawer,
} from 'app/modules/build-dragdrop/Builder/drawer/components';
import { useMockupContext } from 'app/modules/build-dragdrop/Builder/mockup';
import { BuilderCollection, useBuilderContext, useContent } from '../providers';
import {
  BUILDER_DRAWER_WIDTH,
  DRAWER_TYPE_ADD,
  DRAWER_TYPE_BMA,
  DRAWER_TYPE_LINKED,
  DRAWER_TYPE_SECTION,
  DRAWER_TYPE_SECTION_HEADER,
} from '../const';
import {
  CalendarTabDrawer,
  LibraryTabDrawer,
  MyListsTabDrawer,
  ProfileTabDrawer,
  WebsiteTabDrawer,
} from 'app/modules/build-screens';
import { CONTENT_TEMPLATES } from '../mockup/const';
import { DefaultTabWrapper } from 'app/modules/build-screens/components';
import {
  COMMUNITY_EXTENSION_PROPERTIES,
  CommunityExtension,
} from 'app/modules/build-screens/TabDrawers/components/CommunityExtension';

const drawerStyles: CSSProperties = {
  top: NAV_BAR_OFFSET,
};
const drawerBodyStyles: CSSProperties = {
  height: '100%',
  maxWidth: BUILDER_DRAWER_WIDTH,
  maxHeight: `calc(100vh - ${NAV_BAR_OFFSET})`,
  padding: '0px',
  color: NEUTRAL_10_COLOUR,
  display: 'flex',
  flexDirection: 'column',
};

const getMainTab = (tab: BuilderCollection) => {
  const defaultProps = { tabId: tab.TabId as number, key: tab.TabId };

  if (CONTENT_TEMPLATES.includes(tab.TemplateId)) {
    // All homogeneous content tabs should open to D&D
    return <LibraryTabDrawer {...defaultProps} />;
  }

  switch (tab.TemplateId) {
    case MIXED_TEMPLATE:
    case SUBNAV_TEMPLATE:
      return <LibraryTabDrawer {...defaultProps} />;
    case CALENDAR_TEMPLATE:
      return <CalendarTabDrawer {...defaultProps} />;
    case MY_LISTS_TEMPLATE:
    case MY_PLAYLISTS_TEMPLATE:
      return <MyListsTabDrawer {...defaultProps} />;
    case PROFILE_TEMPLATE:
      return <ProfileTabDrawer {...defaultProps} />;
    case WEBSITE_TEMPLATE:
      return <WebsiteTabDrawer {...defaultProps} />;
    case COMMUNITY_TEMPLATE:
      return (
        <DefaultTabWrapper
          {...defaultProps}
          drawerExtension={isUserAdmin() && <CommunityExtension />}
          drawerExtensionProperties={isUserAdmin() ? COMMUNITY_EXTENSION_PROPERTIES : undefined}
        />
      );
    default:
      return <DefaultTabWrapper {...defaultProps} />;
  }
};

/**
 * The Right Drawer Menu in the Drag and Drop Builder
 * This drawer is used for configuring an item that is selected in the mockup
 * Currently supports editing a Collection
 */
export const RightDrawer = ({ ...props }: DrawerProps) => {
  const { visiblePageId, drawerContext, setDrawerContext, isDrawerOpen } = useBuilderContext();
  const { currentPage, currentTabId } = useMockupContext();
  const { collections } = useContent();

  // Which Drawer is displayed in order of precedence
  // If no drawer item context is set show the drawer for the current visible page
  // Either
  // a) SubTabDrawer for the currently displayed subtab
  // b) CollectionDetailsDrawer for the currently displayed CollectionDetails screen

  // Render section based on selection in DrawerContext
  // SectionDetailsDrawer

  useEffect(() => {
    if (isDrawerOpen) {
      console.debug('RightDrawer', 'Render with context', drawerContext);
    }
  }, [drawerContext]);

  // We want to reset the drawer context when we change the page that is visible
  // Exclusion: The tree menu will set the context to a specific subtab
  useEffect(() => {
    if (drawerContext && drawerContext.drawerType !== DRAWER_TYPE_BMA) {
      setDrawerContext(undefined);
    }
  }, [visiblePageId]);

  const renderDrawer = ({ drawerType }: { drawerType?: string; isSubTab?: boolean }) => {
    if (drawerType === DRAWER_TYPE_BMA) {
      return getMainTab(collections[currentTabId as number]);
    }
    if (drawerType === DRAWER_TYPE_LINKED) {
      return <CreateLinkedSubTabDrawer />;
    }
    if (drawerType === DRAWER_TYPE_SECTION) {
      return <SectionDetailsDrawer key={drawerContext?.tabItemId} />;
    }
    if (drawerType === DRAWER_TYPE_ADD) {
      return <AddDrawer />;
    }
    if (drawerType === DRAWER_TYPE_SECTION_HEADER) {
      return <SectionHeaderDrawer />;
    }
    if (currentPage?.itemId) {
      return currentPage?.itemType === ITEM_TYPE_VIDEO ? (
        <VideoDetailsDrawer key={currentPage?.itemId} />
      ) : (
        <CollectionDetailsDrawer key={currentPage?.itemId} />
      );
    }
    return <SubTabDrawer key={visiblePageId} />;
  };

  return (
    <Drawer
      style={{ ...drawerStyles, pointerEvents: isDrawerOpen ? undefined : 'none' }}
      bodyStyle={drawerBodyStyles}
      placement="right"
      width={BUILDER_DRAWER_WIDTH}
      mask={false}
      closable={false}
      getContainer={false}
      open={isDrawerOpen}
      onClose={() => setDrawerContext(undefined)}
      {...props}
    >
      <>{isDrawerOpen && renderDrawer({ drawerType: drawerContext?.drawerType })}</>
    </Drawer>
  );
};
