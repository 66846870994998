import styled from 'styled-components';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import { ScheduledItem } from 'app/modules/calendar/CalendarPage/components';
import { ScheduledItemType } from './CalendarModal';

const ScheduledContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const NoContent = styled.div`
  height: 168px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 77px;
  color: #8c8c8c;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
`;

interface ScheduledContentProps {
  items: ScheduledItemType[];
  onDragEnd: (result: DropResult) => void;
  handleDeleteItem: (id: number) => void;
}

export const ScheduledContent = ({ items, onDragEnd, handleDeleteItem }: ScheduledContentProps) => {
  return (
    <ScheduledContentContainer>
      {items.length > 0 ? (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="scheduledDroppable">
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {items.map((item, idx) => (
                  <ScheduledItem key={item.id} index={idx} item={item} handleDeleteItem={handleDeleteItem} />
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      ) : (
        <NoContent>You have not scheduled any content on this day</NoContent>
      )}
    </ScheduledContentContainer>
  );
};
