import { AxiosInstance } from 'axios';

export const createCommunityTab = (client: AxiosInstance, appId: string) => {
  return client.post(`/community/create-tab/${appId}`);
};

interface GetCommunityPostOptions {
  flagged?: boolean; // Default get all, True get flagged, False get not flagged
  page_token?: string; // Pagination token to get subsequent pages
}

interface GetCommunityMembersOptions {
  banned?: boolean;
  page_token?: string; // Pagination token to get subsequent pages
  query?: string;
}

interface GetPostsResponse {
  posts: AmityPost[];
  postChildren: AmityChildPost[];
  comments: AmityComment[];
  users: AmityUser[];
  files: AmityFile[];
  communities: AmityCommunity[];
  paging: { next: string; previous: string };
}

interface GetMembersResponse {
  communityUsers: AmityCommunityUser[];
  users: AmityUser[];
  communities: AmityCommunity[];
  paging: { next: string; previous: string };
}

export interface AmityPost {
  _id: string;
  dataType: string; // usually text/image
  commentsCount: number;
  createdAt: string; // ISO string like 2023-05-25T00:19:12.690Z
  updatedAt: string;
  isDeleted: boolean;
  hasFlaggedComment: boolean;
  hasFlaggedChildren: boolean;
  data: { text: string; fileId: string };
  postId: string;
  postedUserId: string;
  targetId: string;
  targetType: string; // usually community
  flagCount: number;
  reactionsCount: number;
  reactions: { like: number };
  comments: string[]; // IDs of comments
  children: string[]; // IDs of child posts
}

export interface AmityChildPost extends AmityPost {
  parentPostId: string;
}

export interface AmityComment {
  _id: string;
  commentId: string;
  referenceType: string; //post
  referenceId: string;
  dataType: string;
  dataTypes: string[]; // Why are there multiple sometimes?
  data: { text: string };
  childrenNumber: number;
  userId: string;
  flagCount: number;
  children: string[]; // IDs of comments
  reactions: { like: number };
  reactionsCount: number;
  createdAt: string;
  updatedAt: string;
  isDeleted: boolean;
  attachments: { type: string; fileId: string }[];
}

export interface AmityUser {
  _id: string;
  createdAt: string;
  updatedAt: string;
  isDeleted: boolean;
  displayName: string;
  userId: string;
  userInternalId: string;
  userPublicId: string;
  metadata: Record<string, unknown>;
  roles: string[];
  flagCount: number;
  avatarFileId: string | null;
  isGlobalBan: boolean;
}

export interface AmityCommunityUser {
  communityMembership: string;
  lastActivity: string;
  updatedAt: string;
  createdAt: string;
  isBanned: boolean;
  userId: string;
  communityId: string;
  channelId: string;
  roles: string[];
  permissions: string[];
}

export interface AmityFile {
  fileId: string;
  fileUrl: string;
  type: string; // image
  createdAt: string;
  updatedAt: string;
  attributes: {
    name: string;
    extension: string; // jpg
    size: number; // bytes
    mimeType: string; // image/jpeg
    metadata: {
      height?: number;
      width?: number;
      orientation?: number;
    };
  };
}

export interface AmityCommunity {
  _id: string;
  description: string;
  isOfficial: boolean;
  isPublic: boolean;
  onlyAdminCanPost: boolean;
  postsCount: number;
  membersCount: number;
  moderatorMembersCount: number;
  createdAt: string;
  isDeleted: boolean;
  needApprovalOnPostCreation?: boolean;
  displayName: string;
  tags: string[];
  hasFlaggedComment: boolean;
  hasFlaggedPost: boolean;
  communityId: string;
  avatarFileId: string | null;
  categoryIds: string[];
}

/*
 * Retrieve Content
 */

export const getCommunityPosts = (
  client: AxiosInstance,
  appId: string,
  communityId: string,
  options?: GetCommunityPostOptions,
) => {
  const params: Record<string, string | boolean> = {};
  if (options?.page_token) {
    params['page_token'] = options.page_token;
  }
  if (options?.flagged) {
    params['flagged'] = options.flagged;
  }

  return client.get<GetPostsResponse>(`/community/posts/${appId}/${communityId}`, { params });
};

/*
 * Delete Content
 */

export const deleteCommunityPost = (client: AxiosInstance, appId: string, postId: string) => {
  return client.delete(`/community/posts/${appId}/${postId}`);
};
export const deleteCommunityComment = (client: AxiosInstance, appId: string, commentId: string) => {
  return client.delete(`/community/comments/${appId}/${commentId}`);
};

/*
 * Clear Flags
 */

export const clearCommunityPost = (client: AxiosInstance, appId: string, postId: string) => {
  return client.post(`/community/posts/${appId}/${postId}/clear`);
};
export const clearCommunityComment = (client: AxiosInstance, appId: string, commentId: string) => {
  return client.post(`/community/comments/${appId}/${commentId}/clear`);
};
/*
 * Member Management
 */
export const getCommunityMembers = (
  client: AxiosInstance,
  appId: string,
  communityId: string,
  options?: GetCommunityMembersOptions,
) => {
  const params: Record<string, unknown> = {};
  if (options?.banned) {
    params['banned'] = options.banned;
  }
  if (options?.query) {
    params['query'] = options.query;
  }
  return client.get<GetMembersResponse>(`/community/users/${appId}/${communityId}`, { params });
};
export const banCommunityMember = (client: AxiosInstance, appId: string, communityId: string, userId: string) => {
  return client.post(`/community/users/${appId}/${communityId}/${userId}/ban`);
};
export const unbanCommunityMember = (client: AxiosInstance, appId: string, communityId: string, userId: string) => {
  return client.post(`/community/users/${appId}/${communityId}/${userId}/unban`);
};
