// The API directory should hold all requests to external APIs
// This should also include interfaces representing the shape of the payloads received
import React, { createContext, useContext } from 'react';
import { AxiosInstance } from 'axios';
import { axiosReact } from './axios';

export * from './app-basic-info';
export * from './apple-categories';
export * from './apple-price-tiers';
export * from './calendar';
export * from './collections';
export * from './const';
export * from './content-cache';
export * from './content';
export * from './filters';
export * from './videos';
export * from './kajabi-onboarding';
export * from './onboarding';
export * from './video-analytics';
export * from './app-properties';
export * from './secure-app-property';
export * from './uploads';
export * from './login';
export * from './action-log';
export * from './pricing-plan';
export * from './publish-app';
export * from './account';
export * from './template';
export * from './products';
export * from './item-tags';
export * from './build-app';
export * from './in-app-products';
export * from './one-signal';
export * from './wordpress';
export * from './axios';
export * from './sync-tracing';
export * from './community';
export * from './comments';
export * from './code-push';

const AxiosContext = createContext<AxiosInstance | undefined>(undefined);

interface AxiosProviderProps {
  axiosInstance?: AxiosInstance;
  children: React.ReactNode;
}

const AxiosProvider = ({ children, axiosInstance: override }: AxiosProviderProps) => {
  return <AxiosContext.Provider value={override ?? axiosReact}>{children}</AxiosContext.Provider>;
};

const useAxiosInstance = () => {
  const context = useContext(AxiosContext);
  if (context === undefined) {
    throw new Error('useAxiosInstance muster be used within an AxiosProvider');
  }
  return context;
};
export { AxiosProvider, useAxiosInstance };
