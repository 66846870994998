import { ReactNode, useState } from 'react';
import styled, { css } from 'styled-components';

import {
  HIGHLIGHT_PRIMARY_COLOUR,
  NEUTRAL_10_COLOUR,
  NEUTRAL_1_COLOUR,
  NEUTRAL_2_COLOUR,
  NEUTRAL_3_COLOUR,
  NEUTRAL_5_COLOUR,
} from 'theme';
import { FONT_12PX_SEMIBOLD, FONT_14PX_MEDIUM } from 'font';
import { ChevronLeftIcon, ChevronRightIcon } from 'icons';
import { OnClick } from 'utils';
import { LoadingSpinner } from 'components';

import { Empty } from 'app/modules/community/Shared/Empty';

const StyledTable = styled.div<{ $width?: string; $minWidth?: string }>`
  background-color: ${NEUTRAL_1_COLOUR};
  border-radius: 8px 8px 0 0;
  overflow: hidden;
  width: ${({ $width }) => $width || '100%'};
  ${({ $minWidth }) => $minWidth && `min-width: ${$minWidth}`};
`;

const StyledHeader = styled.div`
  background-color: ${NEUTRAL_3_COLOUR};
  height: 40px;
`;
const Row = styled.div`
  display: flex;
`;
const StyledRow = styled(Row)<OnClick>`
  border-bottom: 1px solid ${NEUTRAL_5_COLOUR};
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'unset')};

  :hover {
    background-color: ${({ onClick }) => !!onClick && NEUTRAL_2_COLOUR};
  }
`;
const Cell = styled.div<{ $width: TableColumn<unknown>['width']; $minWidth: TableColumn<unknown>['minWidth'] }>`
  &&&& {
    overflow: hidden;
    color: ${NEUTRAL_10_COLOUR};
    ${FONT_14PX_MEDIUM};
    ${({ $width }) => getCellWidth($width)};
    ${({ $minWidth }) => $minWidth && `min-width: ${$minWidth}px`};
  }
`;
const HeadCell = styled(Cell)<{ $centered: TableColumn<unknown>['centered'] }>`
  &&&& {
    ${FONT_12PX_SEMIBOLD};
    padding: 12px;
    ${({ $centered }) => $centered && 'text-align: center'};
  }
`;
const PaginationRow = styled.div`
  width: 100%;
  margin-top: 24px;
  margin-bottom: 24px;
  display: flex;
  justify-content: right;
`;
const PaginationButton = styled.div<{ $selected: boolean; $disabled: boolean }>`
  height: 30px;
  width: 30px;
  margin-left: 4px;
  margin-right: 4px;

  border: 1px solid ${NEUTRAL_5_COLOUR};
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;

  :hover {
    border-color: ${HIGHLIGHT_PRIMARY_COLOUR};
    color: ${HIGHLIGHT_PRIMARY_COLOUR};
  }

  cursor: pointer;

  ${({ $selected }) =>
    $selected &&
    css`
      border-color: ${HIGHLIGHT_PRIMARY_COLOUR};
      color: ${HIGHLIGHT_PRIMARY_COLOUR};
    `}
  ${({ $disabled }) =>
    $disabled &&
    css`
      border-color: ${NEUTRAL_5_COLOUR};
      color: ${NEUTRAL_5_COLOUR};
      cursor: not-allowed;
    `}
`;

const LoadingContainer = styled.div`
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const getCellWidth = (width: string | number) => {
  if (width === 'grow') {
    return css`
      width: 100%;
      flex-grow: 1;
    `;
  }
  return css`
    min-width: ${width}px;
    width: ${width}px;
    max-width: ${width}px;
    flex-shrink: 0;
    flex-grow: 0;
  `;
};

export interface TableColumn<T> {
  render: (data: T) => ReactNode | string;
  id?: number | string;
  heading: string;
  width: number | 'grow';
  minWidth?: number;
  centered?: boolean;
}

interface TableProps<T> {
  data?: T[];
  columns: TableColumn<T>[];
  pageSize?: number;
  query?: string;
  emptyTitle?: string;
  emptyDescription?: string;
  onRowClick?: (data: T) => void;
  overridePage?: number;
  onPageChange?: (page: number) => void;
  tableWidth?: string;
  tableMinWidth?: string;
}

// Data can be anything, but must have an id value
interface RowData {
  id: string;
}

const MAX_VISIBLE_PAGES = 10;

/**
 * This is the DIY Table
 * Supports custom column widths
 * Custom cell rendering
 * Pagination
 */

export const CustomTable2 = <T extends RowData>({
  data,
  columns,
  pageSize = 15,
  query,
  emptyTitle,
  emptyDescription,
  onRowClick,
  overridePage,
  onPageChange,
  tableWidth,
  tableMinWidth,
}: TableProps<T>) => {
  const [page, setPage] = useState(1);

  const activePageNumber = overridePage ?? page;

  const totalPages = data ? Math.ceil(data.length / pageSize) : 0;
  const startIndex = (activePageNumber - 1) * pageSize;
  const endIndex = startIndex + pageSize;

  const currentPage = data?.slice(startIndex, endIndex);

  const generatePageNumbers = () => {
    const visiblePages = [];
    const halfMaxVisiblePages = Math.floor(MAX_VISIBLE_PAGES / 2);

    let startPage = activePageNumber - halfMaxVisiblePages;
    if (startPage < 1) {
      startPage = 1;
    }

    let endPage = startPage + MAX_VISIBLE_PAGES - 1;
    if (endPage > totalPages) {
      endPage = totalPages;
      startPage = Math.max(endPage - MAX_VISIBLE_PAGES + 1, 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      visiblePages.push(i);
    }

    return visiblePages;
  };

  const handlePageChange = (pageNumber: number) => {
    onPageChange ? onPageChange(pageNumber) : setPage(pageNumber);
  };

  return (
    <div>
      <StyledTable $width={tableWidth} $minWidth={tableMinWidth}>
        <StyledHeader>
          <Row>
            {columns.map((column) => (
              <HeadCell
                key={column.id ?? column.heading}
                $width={column.width}
                $minWidth={column.minWidth}
                $centered={column.centered}
              >
                {column.heading}
              </HeadCell>
            ))}
          </Row>
        </StyledHeader>
        {data ? (
          <div>
            {!currentPage ||
              (currentPage?.length === 0 && (
                <Empty
                  title={!!query ? `No results found for "${query}"` : emptyTitle}
                  description={!!query ? 'Check the spelling or try a different search term.' : emptyDescription}
                  $width="50%!"
                />
              ))}
            {currentPage?.map((row) => (
              <StyledRow key={row.id} onClick={onRowClick ? () => onRowClick(row) : undefined} className="StyledRow">
                {columns.map((column) => (
                  <Cell key={row.id + (column.id ?? column.heading)} $width={column.width} $minWidth={column.minWidth}>
                    {column.render(row)}
                  </Cell>
                ))}
              </StyledRow>
            ))}
          </div>
        ) : (
          <LoadingContainer>
            <LoadingSpinner />
          </LoadingContainer>
        )}
      </StyledTable>
      {totalPages > 1 && (
        <PaginationRow>
          <PaginationButton
            $selected={false}
            $disabled={activePageNumber === 1}
            onClick={() => activePageNumber > 1 && handlePageChange(activePageNumber - 1)}
          >
            <ChevronLeftIcon />
          </PaginationButton>
          {generatePageNumbers().map((pageNumber) => (
            <PaginationButton
              key={pageNumber}
              $disabled={false}
              $selected={pageNumber === activePageNumber}
              onClick={() => handlePageChange(pageNumber)}
            >
              {pageNumber}
            </PaginationButton>
          ))}
          <PaginationButton
            $selected={false}
            $disabled={activePageNumber === totalPages}
            onClick={() => activePageNumber < totalPages && handlePageChange(activePageNumber + 1)}
          >
            <ChevronRightIcon />
          </PaginationButton>
        </PaginationRow>
      )}
    </div>
  );
};
