import { SearchBar } from 'components';
import { BuilderCollection } from 'providers';
import { SOURCE_KAJABI, SOURCE_VIDAPP, Video } from 'api';

import { useContentNavigationContext } from '../../../providers';
import { getFilteredCollections, getFilteredVideos } from '../../../utils';
import { ContentScreenTable } from './ContentScreenTable';
import { CMSTable } from './';
import { isUserAdmin } from 'utils';

interface TabContentSection {
  key: string;
  type: 'collections' | 'videos' | 'all';
  collections?: BuilderCollection[];
  videos?: Video[];
  heading?: string;
}

interface TabContentProps {
  searchPlaceholder: string;
  sections: TabContentSection[];
  detailedView?: boolean;
}

export const TabContent = ({ searchPlaceholder, sections, detailedView }: TabContentProps) => {
  const { searchValue, handleSearch } = useContentNavigationContext();
  return (
    <>
      <SearchBar
        defaultValue={searchValue}
        placeholder={searchPlaceholder}
        onSearch={(value) => {
          handleSearch(value);
        }}
      />
      {sections.map(({ key, type, collections, videos }) => {
        const filteredCollections = ['collections', 'all'].includes(type)
          ? getFilteredCollections(collections ?? [], searchValue)
          : undefined;
        const filteredVideos = ['videos', 'all'].includes(type)
          ? getFilteredVideos(videos ?? [], searchValue)
          : undefined;

        const props = {
          key,
          searchValue,
          collections: filteredCollections,
          videos: filteredVideos,
          showSync: detailedView
            ? filteredCollections &&
              filteredCollections.length > 0 &&
              filteredCollections[0].DataSource === SOURCE_KAJABI
            : undefined,
          showAdminFilters:
            isUserAdmin() &&
            collections &&
            collections.length > 0 &&
            collections[0].DataSource === SOURCE_VIDAPP &&
            key.includes('Groups'),
        };

        return detailedView ? <ContentScreenTable {...props} /> : <CMSTable {...props} />;
      })}
    </>
  );
};
