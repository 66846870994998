import { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';

import { CustomButton, Modal, ModalBodyNavigationGroup, ModalCollectionOptions, ModalVideoOptions } from 'components';
import {
  BuilderCollection,
  BuilderProduct,
  BuilderVideo,
  useContent,
  useDataSource,
  useLocalProducts,
  useSaveContext,
} from 'providers';
import { FONT_16PX_MEDIUM } from 'font';
import { INPUT_BORDER_RADIUS, NEUTRAL_10_COLOUR, NEUTRAL_5_COLOUR, NEUTRAL_9_COLOUR } from 'theme';
import { CubeIcon, FolderIcon, MultiFolderIcon, VideoIcon } from 'icons';
import { checkVideoType, getDataSourceDisplayName } from 'utils';
import {
  CATALOG_SOURCE_ID,
  FEATURED_SOURCE_ID,
  ITEM_TYPE_COLLECTION,
  ITEM_TYPE_VIDEO,
  MY_PRODUCTS_SOURCE_ID,
  ProductItem,
  SOURCE_JWPLAYER,
  SOURCE_KAJABI,
  SOURCE_TYPE_COLLECTION,
  SOURCE_TYPE_KAJABI_CAT,
  SOURCE_TYPE_KAJABI_PRODUCT,
  SOURCE_TYPE_KAJABI_SUBCAT,
  SOURCE_TYPE_SUBTAB,
  SOURCE_TYPE_TAB,
  SOURCE_TYPE_VHX_CAT,
  SOURCE_TYPE_VHX_SEAS,
  SOURCE_VHX,
  SOURCE_VIDAPP,
  SOURCE_VIMEO,
  SOURCE_WORDPRESS,
  SOURCE_YOUTUBE,
} from 'api';
import { useAppBeingEdited } from 'app-context';

import { useModalContext } from './providers/modal-provider';
import { OfferContentPreview } from './components';

const ModalHeading = styled.div`
  ${FONT_16PX_MEDIUM};
  color: ${NEUTRAL_9_COLOUR};
  padding: 20px;
  border-bottom: 1px solid ${NEUTRAL_5_COLOUR};
`;
const ModalBody = styled.div`
  display: flex;
  color: ${NEUTRAL_10_COLOUR};
  height: 516px;
`;
const ModalActions = styled.div`
  display: flex;
  justify-content: right;
  padding: 20px 24px;
  border-top: 1px solid ${NEUTRAL_5_COLOUR};
`;

const CancelButton = styled(CustomButton)`
  &&&& {
    margin-right: 8px;
  }
`;

const SaveButton = styled(CustomButton)`
  &&&& {
    font-weight: 500;
    border-radius: ${INPUT_BORDER_RADIUS};
  }
`;

const BodyNavigation = styled.div`
  padding: 20px;
  width: 186px;
  height: 100%;
  border-right: 1px solid ${NEUTRAL_5_COLOUR};
  overflow-y: auto;
`;

const LeftPanel = styled.div`
  width: 379px;
  height: 100%;
`;

const DUMMY_SOURCE = {
  Title: '',
  Icon: <FolderIcon />,
  Options: [],
}; // Will be hidden

interface OfferContentModalProps {
  // The product the items are being customised in
  product: BuilderProduct;
  // Control of the visibility of this modal is injected
  modalVisible: boolean;
  setModalVisible: Dispatch<SetStateAction<boolean>>;
}

export const OfferContentModal = ({ product, modalVisible, setModalVisible }: OfferContentModalProps) => {
  const appId = useAppBeingEdited();
  const datasource = useDataSource();
  const { videos, collections } = useContent();
  const [editedItems, setEditedItems] = useState<ProductItem[]>(product.Items || []);
  const [activeKey, setActiveKey] = useState<string>('1');
  const { setProductItems } = useLocalProducts();
  const { setProductValueToSave } = useSaveContext();
  const { confirmDisabled } = useModalContext();

  // The content that already exists in the current product
  const includedVideos = useMemo(() => {
    return editedItems.filter((item) => item.Type === ITEM_TYPE_VIDEO).map((item) => item.ChildId);
  }, [editedItems]);
  const includedCollections = useMemo(() => {
    return editedItems.filter((item) => item.Type === ITEM_TYPE_COLLECTION).map((item) => item.ChildId);
  }, [editedItems]);

  // Save the working state of the product
  const handleSave = useCallback(async () => {
    setProductItems(product.Id, editedItems);
    setProductValueToSave(product.Id, 'Items', editedItems);
    setModalVisible(false);
  }, [editedItems, setModalVisible, setProductItems, setProductValueToSave]);

  // The following determines the 1 or 2 available collection tabs for the main integration of the app,
  // As well as the WordPress/JWPlayer/Vimeo/YouTube/VidApp collection tabs
  // and the filtered down collection content in each
  const {
    Collections1,
    Collections2,
    CollectionsWordPress,
    CollectionsJWPlayer,
    CollectionsVimeo,
    CollectionsYouTube,
    CollectionsVidApp,
    TabsVidApp,
  } = useMemo(() => {
    const collectionsBySource: Record<string, BuilderCollection[]> = {
      [datasource]: [],
      [SOURCE_WORDPRESS]: [],
      [SOURCE_JWPLAYER]: [],
      [SOURCE_VIMEO]: [],
      [SOURCE_YOUTUBE]: [],
      [SOURCE_VIDAPP]: [],
    };
    Object.values(collections).forEach((collection) => {
      if (collectionsBySource[collection.DataSource]) {
        collectionsBySource[collection.DataSource].push(collection);
      }
    });

    const otherSources = {
      CollectionsWordPress: {
        Title: 'Collections',
        Icon: <FolderIcon />,
        Options: collectionsBySource[SOURCE_WORDPRESS],
      },
      CollectionsJWPlayer: {
        Title: 'Collections',
        Icon: <FolderIcon />,
        Options: collectionsBySource[SOURCE_JWPLAYER],
      },
      CollectionsVimeo: {
        Title: 'Albums',
        Icon: <FolderIcon />,
        Options: collectionsBySource[SOURCE_VIMEO],
      },
      CollectionsYouTube: {
        Title: 'Collections',
        Icon: <FolderIcon />,
        Options: collectionsBySource[SOURCE_YOUTUBE],
      },
      CollectionsVidApp: {
        Title: 'Collections',
        Icon: <FolderIcon />,
        Options: collectionsBySource[SOURCE_VIDAPP].filter((c) => [SOURCE_TYPE_COLLECTION].includes(c.SourceType)),
      },
      TabsVidApp: {
        Title: 'Tabs',
        Icon: <FolderIcon />,
        Options: collectionsBySource[SOURCE_VIDAPP].filter((c) =>
          [SOURCE_TYPE_TAB, SOURCE_TYPE_SUBTAB].includes(c.SourceType),
        ),
      },
    };

    if (datasource == SOURCE_KAJABI) {
      return {
        Collections1: {
          Title: 'Modules',
          Icon: <FolderIcon />,
          Options: collectionsBySource[datasource].filter(
            ({ SourceType }) => SourceType === SOURCE_TYPE_KAJABI_CAT || SourceType === SOURCE_TYPE_KAJABI_SUBCAT,
          ),
        },
        Collections2: {
          Title: 'Courses',
          Icon: <CubeIcon />,
          Options: collectionsBySource[datasource].filter(
            ({ SourceType }) => SourceType === SOURCE_TYPE_KAJABI_PRODUCT,
          ),
        },
        ...otherSources,
      };
    } else if (datasource === SOURCE_VHX) {
      return {
        Collections1: {
          Title: 'Collections',
          Icon: <MultiFolderIcon />,
          Options: collectionsBySource[datasource].filter(
            ({ SourceType, SourceId }) =>
              SourceType !== SOURCE_TYPE_VHX_CAT &&
              SourceType !== SOURCE_TYPE_VHX_SEAS &&
              SourceId !== CATALOG_SOURCE_ID &&
              SourceId !== FEATURED_SOURCE_ID &&
              SourceId !== MY_PRODUCTS_SOURCE_ID,
          ),
        },
        Collections2: {
          Title: 'Categories',
          Icon: <FolderIcon />,
          Options: collectionsBySource[datasource].filter(({ SourceType }) => SourceType === SOURCE_TYPE_VHX_CAT),
        },
        ...otherSources,
      };
    }
    return {
      Collections1: {
        Title: 'Collections',
        Icon: <MultiFolderIcon />,
        Options: collectionsBySource[datasource].filter(
          ({ SourceId }) =>
            SourceId !== CATALOG_SOURCE_ID && SourceId !== FEATURED_SOURCE_ID && SourceId !== MY_PRODUCTS_SOURCE_ID,
        ),
      },
      Collections2: DUMMY_SOURCE,
      ...otherSources,
    };
  }, [collections, datasource]);

  const videosBySource = useMemo(() => {
    const obj: Record<string, BuilderVideo[]> = {
      [datasource]: [],
      [SOURCE_WORDPRESS]: [],
      [SOURCE_JWPLAYER]: [],
      [SOURCE_VIMEO]: [],
      [SOURCE_YOUTUBE]: [],
      [SOURCE_VIDAPP]: [],
    };

    Object.values(videos).forEach((video) => {
      if (obj[video.DataSource] && checkVideoType(video, { audio: true, text: true })) {
        obj[video.DataSource].push(video);
      }
    });
    return obj;
  }, [videos, datasource]);

  // Build the list of navigation options on the left side of the modal
  const sourceOptions = useMemo(() => {
    const output = [];
    if (videosBySource[datasource].length > 0) {
      output.push({ id: '1', name: datasource === SOURCE_KAJABI ? 'Lessons' : 'Videos', icon: <VideoIcon /> });
    }
    if (Collections1.Options.length > 0) {
      output.push({ id: '2', name: Collections1.Title, icon: Collections1.Icon });
    }
    if (Collections2.Options.length > 0) {
      output.push({ id: '3', name: Collections2.Title, icon: Collections2.Icon });
    }

    return output;
  }, [videosBySource, Collections1, Collections2]);

  const wordPressOptions = useMemo(() => {
    const output = [];
    if (videosBySource[SOURCE_WORDPRESS].length > 0) {
      output.push({ id: '4', name: 'Posts', icon: <VideoIcon /> });
    }

    if (CollectionsWordPress.Options.length > 0) {
      output.push({ id: '5', name: CollectionsWordPress.Title, icon: CollectionsWordPress.Icon });
    }

    return output;
  }, [CollectionsWordPress, videosBySource]);

  const jwPlayerOptions = useMemo(() => {
    const output = [];
    if (videosBySource[SOURCE_JWPLAYER].length > 0) {
      output.push({ id: '6', name: 'Videos', icon: <VideoIcon /> });
    }

    if (CollectionsJWPlayer.Options.length > 0) {
      output.push({ id: '7', name: CollectionsJWPlayer.Title, icon: CollectionsJWPlayer.Icon });
    }

    return output;
  }, [CollectionsJWPlayer, videosBySource]);

  const vimeoOptions = useMemo(() => {
    const output = [];
    if (videosBySource[SOURCE_VIMEO].length > 0) {
      output.push({ id: '8', name: 'Videos', icon: <VideoIcon /> });
    }

    if (CollectionsVimeo.Options.length > 0) {
      output.push({ id: '9', name: CollectionsVimeo.Title, icon: CollectionsVimeo.Icon });
    }

    return output;
  }, [CollectionsVimeo, videosBySource]);

  const youTubeOptions = useMemo(() => {
    const output = [];
    if (videosBySource[SOURCE_YOUTUBE].length > 0) {
      output.push({ id: '10', name: 'Videos', icon: <VideoIcon /> });
    }

    if (CollectionsYouTube.Options.length > 0) {
      output.push({ id: '11', name: CollectionsYouTube.Title, icon: CollectionsYouTube.Icon });
    }

    return output;
  }, [CollectionsYouTube, videosBySource]);

  const vidAppOptions = useMemo(() => {
    const output = [];
    if (videosBySource[SOURCE_VIDAPP].length > 0) {
      output.push({ id: '12', name: 'Posts', icon: <VideoIcon /> });
    }

    if (CollectionsVidApp.Options.length > 0) {
      output.push({ id: '13', name: CollectionsVidApp.Title, icon: CollectionsVidApp.Icon });
    }

    if (TabsVidApp.Options.length > 0) {
      output.push({ id: '14', name: TabsVidApp.Title, icon: TabsVidApp.Icon });
    }

    return output;
  }, [CollectionsVidApp, TabsVidApp, videosBySource]);

  const videoOptionsView = (availableVideos: BuilderVideo[]) => (
    <ModalVideoOptions
      availableVideos={availableVideos}
      includedVideos={includedVideos}
      onVideoClick={(videoId) => {
        setEditedItems((currentItems) => {
          // Add it to the items
          return [
            ...currentItems,
            {
              AppId: appId,
              ProductId: product.ProductId,
              ChildId: videoId as number,
              Type: 'video',
              AddedBy: 'builder',
            },
          ];
        });
      }}
    />
  );

  const collectionOptionsView = (availableCollections: BuilderCollection[]) => (
    <ModalCollectionOptions
      availableCollections={availableCollections}
      includedCollections={includedCollections}
      onCollectionClick={(tabId) => {
        setEditedItems((currentItems) => {
          // Add it to the items
          return [
            ...currentItems,
            {
              AppId: appId,
              ProductId: product.ProductId,
              ChildId: tabId as number,
              Type: collections[tabId].SourceType === SOURCE_TYPE_TAB ? 'tab' : 'collection',
              AddedBy: 'builder',
            },
          ];
        });
      }}
    />
  );

  const switchViews = (activeKey: string) => {
    switch (activeKey) {
      case '1':
        return videoOptionsView(videosBySource[datasource]);
      case '2':
        return collectionOptionsView(Collections1.Options);
      case '3':
        return collectionOptionsView(Collections2.Options);
      case '4':
        return videoOptionsView(videosBySource[SOURCE_WORDPRESS]);
      case '5':
        return collectionOptionsView(CollectionsWordPress.Options);
      case '6':
        return videoOptionsView(videosBySource[SOURCE_JWPLAYER]);
      case '7':
        return collectionOptionsView(CollectionsJWPlayer.Options);
      case '8':
        return videoOptionsView(videosBySource[SOURCE_VIMEO]);
      case '9':
        return collectionOptionsView(CollectionsVimeo.Options);
      case '10':
        return videoOptionsView(videosBySource[SOURCE_YOUTUBE]);
      case '11':
        return collectionOptionsView(CollectionsYouTube.Options);
      case '12':
        return videoOptionsView(videosBySource[SOURCE_VIDAPP]);
      case '13':
        return collectionOptionsView(CollectionsVidApp.Options);
      case '14':
        return collectionOptionsView(TabsVidApp.Options);
    }
    return null;
  };

  return (
    <Modal open={modalVisible} $height="100%" $contentWidth="100%" $padding="0" forceRender>
      <ModalHeading>Included Content</ModalHeading>
      <ModalBody>
        <BodyNavigation>
          {sourceOptions.length > 0 && (
            <ModalBodyNavigationGroup
              activeKey={activeKey}
              heading={getDataSourceDisplayName(datasource)}
              options={sourceOptions}
              onOptionClick={(id) => setActiveKey(id)}
            />
          )}
          {wordPressOptions.length > 0 && (
            <ModalBodyNavigationGroup
              activeKey={activeKey}
              heading="WordPress"
              options={wordPressOptions}
              onOptionClick={(id) => setActiveKey(id)}
            />
          )}
          {jwPlayerOptions.length > 0 && (
            <ModalBodyNavigationGroup
              activeKey={activeKey}
              heading="JW Player"
              options={jwPlayerOptions}
              onOptionClick={(id) => setActiveKey(id)}
            />
          )}
          {vimeoOptions.length > 0 && (
            <ModalBodyNavigationGroup
              activeKey={activeKey}
              heading="Vimeo"
              options={vimeoOptions}
              onOptionClick={(id) => setActiveKey(id)}
            />
          )}
          {youTubeOptions.length > 0 && (
            <ModalBodyNavigationGroup
              activeKey={activeKey}
              heading="YouTube"
              options={youTubeOptions}
              onOptionClick={(id) => setActiveKey(id)}
            />
          )}
          {vidAppOptions.length > 0 && (
            <ModalBodyNavigationGroup
              activeKey={activeKey}
              heading="VidApp"
              options={vidAppOptions}
              onOptionClick={(id) => setActiveKey(id)}
            />
          )}
        </BodyNavigation>
        <LeftPanel>{switchViews(activeKey)}</LeftPanel>
        <OfferContentPreview items={editedItems} setItems={setEditedItems} />
      </ModalBody>
      <ModalActions>
        <CancelButton
          medium
          tertiary
          disabled={confirmDisabled}
          onClick={() => {
            setModalVisible(false);
          }}
        >
          Cancel
        </CancelButton>
        <SaveButton onClick={handleSave} disabled={confirmDisabled} medium>
          Confirm
        </SaveButton>
      </ModalActions>
    </Modal>
  );
};
