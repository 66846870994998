import { Key, useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Empty, Modal, Popover, Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { customAlphabet } from 'nanoid';

import {
  CustomButton,
  CustomLink,
  HighlightText,
  ListContainer,
  PageContainer,
  SearchBar,
  styledTable,
  Thumbnail,
} from 'components';
import { useDataSource } from 'providers';
import { useCollections, useVideos } from 'hooks';
import { Filter, SOURCE_KAJABI } from 'api';
import { ForwardIcon, VideoIcon, FolderIcon, PenIcon } from 'icons';
import {
  HIGHLIGHT_PRIMARY_COLOUR,
  HIGHLIGHT_PRIMARY_HOVER_COLOUR,
  NEUTRAL_10_COLOUR,
  NEUTRAL_3_COLOUR,
  NEUTRAL_4_COLOUR,
  NEUTRAL_5_COLOUR,
  NEUTRAL_6_COLOUR,
  NEUTRAL_7_COLOUR,
  NEUTRAL_9_COLOUR,
  PAGE_CONTAINER_WIDE_WIDTH,
} from 'theme';
import { FONT_10PX_MEDIUM, FONT_12PX_MEDIUM, FONT_12PX_SEMIBOLD, FONT_16PX_MEDIUM, OVERFLOW_ELLIPSIS } from 'font';

import { useContentTagging } from './hooks/useContentTagging';
import { ContentTaggingDrawer, TagSelectInput } from './components';

const nanoid = customAlphabet('123456789', 16);

const defaultStyledTable = styledTable<ContentItem>(Table);

const StyledTable = styled(defaultStyledTable)`
  #react-app && .ant-table {
    border: none;
    margin-top: 20px;
    border-radius: 8px 0 0 0;

    table {
      position: relative;
      min-width: unset !important;
      margin-right: 32px;
    }

    th {
      color: ${NEUTRAL_10_COLOUR};
      background-color: ${NEUTRAL_3_COLOUR} !important;
      ${FONT_12PX_SEMIBOLD};
      padding-left: 12px;
      border-bottom: none;

      span {
        ${FONT_12PX_SEMIBOLD};
      }
    }

    th:last-child {
      border-top-right-radius: 8px !important;
    }

    td {
      vertical-align: top;
    }

    .ant-table-container::after {
      box-shadow: none !important;
    }

    th,
    tr,
    td {
      height: 40px;
    }

    tr:hover td {
      background-color: ${NEUTRAL_3_COLOUR} !important;
    }

    /* Checkbox Column */

    th:first-child,
    td:first-child {
      padding-left: 12px;

      .ant-checkbox {
        .ant-checkbox-inner {
          border-radius: 4px;
          border-width: 2px;
        }

        :hover {
          .ant-checkbox-inner {
            border-color: ${HIGHLIGHT_PRIMARY_HOVER_COLOUR};
            background-color: ${HIGHLIGHT_PRIMARY_HOVER_COLOUR};
          }

          ::after {
            visibility: hidden;
          }
        }

        :not(.ant-checkbox-checked) {
          :hover {
            .ant-checkbox-inner {
              border-color: ${HIGHLIGHT_PRIMARY_COLOUR};
              background: none !important;
            }
          }

          .ant-checkbox-inner {
            border-color: ${NEUTRAL_6_COLOUR};
          }
        }
      }
    }

    td:first-child > .ant-checkbox-wrapper {
      margin-top: 4px;
    }

    /* Name Column */

    td:nth-child(2) {
      padding-top: 8px;
      padding-bottom: 0;
      padding-left: 12px;
    }

    /* Type Column */

    td:nth-child(3) {
      padding: 8px 0 0 12px;
    }

    /* ID Column */

    td:nth-child(4) {
      padding: 11px 0 0 8px;
    }

    /* Tag Columns */

    td:nth-child(n + 5) {
      padding: 0;

      > div {
        height: 100%;
      }

      .ant-select-disabled.ant-select {
        cursor: not-allowed !important;

        .ant-select-selection-item {
          cursor: not-allowed !important;
        }

        .ant-select-selection-item-content {
          padding-right: 10px !important;
        }
      }

      .ant-select:not(.ant-select-disabled) {
        cursor: text !important;
      }
    }

    th:nth-child(n + 3),
    td:nth-child(n + 3) {
      border-left: 1px solid ${NEUTRAL_5_COLOUR};
    }

    tr:not(:first-child) {
      border-top: none;
    }

    tr:nth-child(n + 3) {
      border-top: 1px solid ${NEUTRAL_5_COLOUR};
    }

    .ant-table-cell-content {
      display: inline-flex;
      width: 100%;
    }

    .ant-table-row-selected > td,
    .ant-table-row-selected:hover > td {
      background-color: ${NEUTRAL_3_COLOUR} !important;
    }
  }

  .ant-pagination {
    margin-right: 21px !important;
  }
`;

const CategoryColumnHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 10px;
  color: ${NEUTRAL_10_COLOUR};
  ${FONT_12PX_SEMIBOLD};
`;

const StyledPencilIcon = styled(PenIcon)<{ disabled: boolean }>`
  &&& {
    width: 20px;
    height: 20px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px !important;
    color: ${({ disabled }) => (disabled ? `${NEUTRAL_7_COLOUR} !important` : 'inherit')};

    :hover {
      background-color: ${NEUTRAL_4_COLOUR};
    }
  }
`;

const NameCell = styled.span`
  display: flex;
  align-items: center;
  width: 100%;

  span {
    ${FONT_12PX_MEDIUM};
  }
`;

const NameWrapper = styled.span`
  ${OVERFLOW_ELLIPSIS};
`;

const HoverCard = styled.div`
  display: flex;
  align-items: center;
  ${FONT_12PX_MEDIUM}
`;

const TypeCell = styled.span`
  padding: 3px 4px;
  background-color: ${NEUTRAL_4_COLOUR};
  border-radius: 3px;
  color: ${NEUTRAL_7_COLOUR};
  ${FONT_10PX_MEDIUM};
  text-transform: uppercase;
  user-select: none;
  width: fit-content;
  margin-top: 5px;
`;

const IdCell = styled.span`
  display: flex;
  align-items: center;

  span {
    ${FONT_12PX_MEDIUM};
  }
`;

const ItemThumbnail = styled(Thumbnail)`
  margin-right: 8px;
`;

const SegmentCard = styled.li`
  width: 100%;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 20px 10px;
  cursor: pointer;
  transition: 0.25s ease background-color;

  :hover {
    background-color: ${NEUTRAL_3_COLOUR};
  }

  border-top: 1px solid ${NEUTRAL_5_COLOUR};

  :last-child {
    border-bottom: 1px solid ${NEUTRAL_5_COLOUR};
  }
`;

const SegmentDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 48px;
  margin-left: 10px;
  overflow: hidden;
`;

const SegmentName = styled.div`
  ${FONT_16PX_MEDIUM};
  color: ${NEUTRAL_9_COLOUR};
  ${OVERFLOW_ELLIPSIS};
`;

const Chevron = styled(ForwardIcon)`
  #react-app && {
    font-size: 30px;
    color: ${HIGHLIGHT_PRIMARY_COLOUR};
    margin-left: auto;
    transform: translateX(9px);
  }
`;

export interface ContentItem {
  Name: string;
  Type: 'course' | 'module' | 'sub-module' | 'lesson';
  Id: number;
  SourceId: string;
  Tags: Record<string, string[]>;
  Children?: ContentItem[];
  key: string;
}

export interface ContentItemChild {
  ChildId: number;
  Type: 'course' | 'module' | 'sub-module' | 'lesson';
}

export const ContentTagging = () => {
  const {
    filters,
    products,
    collections,
    videos,
    isDataLoaded,
    isError,
    unsavedChanges,
    isSaving,
    isInitialized,
    filtersToSave,
    getTags,
    getChildren,
    setItemsToSave,
    setFiltersToSave,
    handleSave,
    setIsInitialized,
    reset,
  } = useContentTagging();
  const { getCollectionById, getCollectionThumbnailUrl } = useCollections(Infinity, 'always');
  const { getVideoThumbnailUrl } = useVideos(Infinity, 'always');
  const dataSource = useDataSource();

  const [activeSegment, setActiveSegment] = useState<ContentItem | undefined>(undefined);
  const [activeFilter, setActiveFilter] = useState<Filter | undefined>(undefined);
  const [content, setContent] = useState<ContentItem[]>([]);
  const [filteredContent, setFilteredContent] = useState<ContentItem[] | undefined>(undefined);
  const [tagSelectState, setTagSelectState] = useState<Record<string, string[]>>({});
  const [selectedRows, setSelectedRows] = useState<ContentItem[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
  const [searchValue, setSearchValue] = useState<string | undefined>(undefined);

  const isCourseView = dataSource === SOURCE_KAJABI;
  // Kajabi apps will show a list of courses, when a course is clicked on, a table will display that course and all of its nested content
  // Other data sources will show options for 'Groups' or 'Videos' - Each displaying a table with all VidApp collections or videos listed alphabetically

  const columns = useMemo(() => {
    // Default columns
    const columnsArr: ColumnsType<ContentItem> = [
      {
        title: 'Title',
        dataIndex: 'Name',
        key: 'Name',
        width: 465,
        fixed: 'left',
        ellipsis: true,
        render: (itemName, record) => {
          const thumbnailUrl =
            record.Type === 'lesson'
              ? getVideoThumbnailUrl(record.Id, 'small')
              : getCollectionThumbnailUrl(record.Id, 'small');
          return (
            <Popover
              content={
                <HoverCard>
                  <ItemThumbnail
                    url={thumbnailUrl}
                    width="43px"
                    height="24px"
                    border={thumbnailUrl ? 'none' : undefined}
                    borderRadius="2px"
                  />
                  {itemName}
                </HoverCard>
              }
              overlayStyle={{ paddingTop: 0, paddingLeft: '50px' }}
              overlayInnerStyle={{ borderRadius: '8px' }}
              getPopupContainer={() => document.getElementById('react-app') as HTMLElement}
              trigger="hover"
              mouseEnterDelay={1}
              placement="bottomLeft"
            >
              <NameCell>
                <ItemThumbnail
                  url={thumbnailUrl}
                  width="43px"
                  height="24px"
                  border={thumbnailUrl ? 'none' : undefined}
                  borderRadius="2px"
                />
                <NameWrapper>
                  <HighlightText text={itemName} highlight={searchValue} />
                </NameWrapper>
              </NameCell>
            </Popover>
          );
        },
      },
      {
        title: 'Type',
        dataIndex: 'Type',
        key: 'Type',
        width: 100,
        render: (itemType) => (
          <TypeCell>{isCourseView ? itemType : itemType === 'lesson' ? 'Video' : 'Group'}</TypeCell>
        ),
      },
      {
        title: 'ID',
        dataIndex: 'SourceId',
        key: 'SourceId',
        width: 100,
        ellipsis: true,
        render: (itemSourceId) => (
          <IdCell>
            <HighlightText text={itemSourceId} highlight={searchValue} />
          </IdCell>
        ),
      },
    ];

    // Dynamic columns from each Filter
    filters?.forEach((filter) => {
      if (filter.Enabled) {
        columnsArr.push({
          title: (
            <CategoryColumnHeader>
              {filter.Filter}
              <StyledPencilIcon
                disabled={unsavedChanges || isSaving || !!activeFilter}
                onClick={() => {
                  if (unsavedChanges || isSaving) {
                    Modal.confirm({
                      title: 'You Have Unsaved Changes',
                      content:
                        'Before you can edit a category or its tags, you need to save any changes you have in-progress.',
                      getContainer: '#react-app',
                      okText: 'Save Changes',
                      cancelText: 'Cancel',
                      onOk: () => {
                        handleSave();
                      },
                    });
                  } else {
                    setActiveFilter(filter);
                  }
                }}
              />
            </CategoryColumnHeader>
          ),
          dataIndex: 'Tags',
          key: filter.Filter,
          width: 147,
          ellipsis: true,
          render: (Tags: Record<string, string[]>, record) => (
            // Render a Select input in the cells for Filter columns
            <TagSelectInput
              contentItem={record}
              filter={filter}
              existingTags={Tags[filter.Filter] ?? []}
              tagSelectState={tagSelectState}
              filterToSave={filtersToSave[filter.Id]}
              setTagSelectState={setTagSelectState}
              setItemsToSave={setItemsToSave}
              setFiltersToSave={setFiltersToSave}
              selectedRows={selectedRows}
              disabled={selectedRows.length > 0 && !selectedRowKeys.includes(record.key)}
            />
          ),
        });
      }
    });

    return columnsArr;
  }, [
    filters,
    filtersToSave,
    activeFilter,
    tagSelectState,
    selectedRows,
    selectedRowKeys,
    unsavedChanges,
    setItemsToSave,
    setFiltersToSave,
    getVideoThumbnailUrl,
    getCollectionThumbnailUrl,
    setTagSelectState,
    setActiveFilter,
    handleSave,
  ]);

  useEffect(() => {
    if (isDataLoaded && !isInitialized && collections && videos) {
      if (isCourseView) {
        const contentArr: ContentItem[] = [];

        products?.sort((a, b) => a.Name.localeCompare(b.Name));

        products?.forEach((product) => {
          const children: ContentItemChild[] = [{ ChildId: product.TabId, Type: 'course' }]; // Put product at the start of the children array as it will be displayed in the first row of the product view

          // For each product, push 3 levels of children into 'children' of ContentItem
          product.Items.forEach((item) => {
            children.push({ ChildId: item.ChildId, Type: 'module' });
            getCollectionById(item.ChildId)?.Items.forEach((item) => {
              children.push({ ChildId: item.ChildId, Type: 'sub-module' });
              getCollectionById(item.ChildId)?.Items.forEach((item) => {
                children.push({ ChildId: item.ChildId, Type: 'sub-module' });
              });
            });
          });

          const row: ContentItem = {
            Name: product.Name,
            Type: 'course',
            Id: product.TabId,
            SourceId: product.SourceId,
            Tags: getTags(product.TabId, 'collection'), // Return record of tags for this product, categorised by Filter containing tags
            Children: getChildren(children), // Return a new ContentItemChild for each item in children
            key: nanoid(),
          };

          contentArr.push(row);
        });

        setContent(contentArr);
      } else {
        const contentArr: ContentItem[] = [
          {
            Name: 'Groups',
            Type: 'course',
            Id: 1,
            SourceId: '1',
            Tags: {},
            Children: getChildren(collections.map((collection) => ({ ChildId: collection.TabId, Type: 'course' }))), // Return a new ContentItemChild for each item in children
            key: nanoid(),
          },
          {
            Name: 'Videos',
            Type: 'course',
            Id: 2,
            SourceId: '2',
            Tags: {},
            Children: getChildren(videos.map((video) => ({ ChildId: video.VideoId, Type: 'lesson' }))), // Return a new ContentItemChild for each item in children
            key: nanoid(),
          },
        ];

        setContent(contentArr);
      }

      setIsInitialized(true);
    }
  }, [
    isDataLoaded,
    isInitialized,
    filters,
    products,
    collections,
    videos,
    isCourseView,
    setIsInitialized,
    setContent,
    getChildren,
    getTags,
  ]);

  const clearSelections = useCallback(() => {
    setSelectedRowKeys([]);
    setSelectedRows([]);
  }, [setSelectedRowKeys, setSelectedRows]);

  const clearSearch = useCallback(() => {
    setFilteredContent(undefined);
    setSearchValue(undefined);
  }, [setFilteredContent, setSearchValue]);

  const escKeyHandler = useCallback(
    (e) => {
      if (e.key === 'Escape') {
        clearSelections();
      }
    },
    [clearSelections],
  );

  useEffect(() => {
    document.addEventListener('keydown', escKeyHandler);

    return () => {
      document.removeEventListener('keydown', escKeyHandler);
    };
  }, []);

  const handleSearch = useCallback(
    (value: string) => {
      clearSelections();
      const searchInput = value.toLowerCase();

      if (searchInput === '') {
        // Search term has been cleared, show all content unfiltered and collapse all products
        clearSearch();
      } else {
        // Search term has been entered
        setSearchValue(searchInput);
        const matchingRows: ContentItem[] = [];
        activeSegment?.Children?.forEach((row) => {
          if (row.Name?.toLowerCase().includes(searchInput) || row.SourceId?.toLowerCase().includes(searchInput)) {
            matchingRows.push(row);
          }
        });
        setFilteredContent(matchingRows);
      }
    },
    [activeSegment, setFilteredContent, setSearchValue, clearSelections, clearSearch],
  );

  const openSegment = useCallback(
    (segment: ContentItem) => {
      clearSelections();
      clearSearch();
      setActiveSegment(segment);
    },
    [clearSelections, clearSearch, setActiveSegment],
  );

  const navigateBack = useCallback(() => {
    clearSelections();
    clearSearch();
    setActiveSegment(undefined);
    setTagSelectState({});
    reset();
  }, [clearSelections, clearSearch, setActiveSegment, setTagSelectState, reset]);

  useEffect(() => {
    // StyledTable uses activeSegment or filteredContent as a DataSource, so when content changes (after a save and refetch), these states need to be updated so the table remounts with the updated data
    if (activeSegment) {
      setTagSelectState({});
      clearSelections();
      const updatedSegment = content.find((segment) => segment.SourceId === activeSegment.SourceId);
      setActiveSegment(updatedSegment);
      if (searchValue) {
        handleSearch(searchValue);
      }
    }
  }, [content, activeSegment, searchValue, clearSelections, handleSearch, setTagSelectState, setActiveSegment]);

  const handleNavigateBack = useCallback(() => {
    if (unsavedChanges) {
      Modal.confirm({
        title: 'Unsaved Changes',
        content: 'You have unsaved changes. If you leave without saving your changes will be lost.',
        getContainer: '#react-app',
        okText: 'Continue Editing',
        cancelText: 'Discard Changes',
        onCancel: () => {
          navigateBack();
          Modal.destroyAll();
        },
        onOk: () => {
          return false;
        },
      });
    } else {
      navigateBack();
    }
  }, [unsavedChanges, navigateBack]);

  return (
    <PageContainer
      heading={activeSegment ? activeSegment.Name : 'Content Tagging'}
      subheading={
        activeSegment && (
          <>
            To add or delete a category go to the <CustomLink to="/filters">Filters section.</CustomLink>
          </>
        )
      }
      thumbnailUrl={activeSegment && isCourseView ? getCollectionThumbnailUrl(activeSegment.Id, 'small') : undefined}
      headingButton={
        activeSegment && (
          <CustomButton primary onClick={handleSave} disabled={!unsavedChanges} loading={isSaving}>
            Save
          </CustomButton>
        )
      }
      isLoading={!isInitialized}
      isError={isError}
      breadcrumbs={
        activeSegment && {
          items: [
            {
              label: isCourseView ? 'All Courses' : 'Back',
              handleClick: handleNavigateBack,
            },
          ],
          currentScreen: activeSegment.Name,
        }
      }
      contentMaxWidth={activeSegment ? PAGE_CONTAINER_WIDE_WIDTH : undefined}
    >
      {activeSegment ? (
        <>
          <SearchBar onSearch={handleSearch} />
          <StyledTable
            dataSource={filteredContent || activeSegment.Children}
            pagination={{ showSizeChanger: true, defaultPageSize: 25, pageSizeOptions: [25, 50, 100] }}
            columns={columns}
            tableLayout="fixed"
            scroll={{ x: 697 }}
            locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Results Found" /> }}
            rowSelection={{
              selectedRowKeys,
              onChange: (selectedKeys, selectedRows) => {
                setSelectedRowKeys(selectedKeys);
                setSelectedRows(selectedRows);
              },
            }}
          />
          {activeFilter && (
            <ContentTaggingDrawer activeFilter={activeFilter} setActiveFilter={setActiveFilter} resetTable={reset} />
          )}
        </>
      ) : (
        <ListContainer heading={isCourseView ? 'Courses' : 'Content'} $border="none">
          {content.map((segment) => {
            const thumbnail = isCourseView ? getCollectionThumbnailUrl(segment.Id, 'small') : null;
            return (
              <SegmentCard key={segment.key} onClick={() => openSegment(segment)}>
                {isCourseView ? (
                  <ItemThumbnail url={thumbnail} width="107px" height="60px" border={thumbnail ? 'none' : undefined} />
                ) : segment.Name === 'Videos' ? (
                  <VideoIcon />
                ) : (
                  <FolderIcon />
                )}
                <SegmentDetails>
                  <SegmentName>{segment.Name}</SegmentName>
                  {isCourseView && <TypeCell>{`COURSE - ${segment.SourceId}`}</TypeCell>}
                </SegmentDetails>
                <Chevron />
              </SegmentCard>
            );
          })}
        </ListContainer>
      )}
    </PageContainer>
  );
};
